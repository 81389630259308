import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import environment from '../global/environment';


export const datamainApi: AxiosInstance = axios.create({
  baseURL: environment.ENDPOINT_DATAMAIN,
});

datamainApi.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});
