import { FC, useState, forwardRef, ForwardedRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { TableColumn } from 'react-data-table-component';
import { useTheme } from 'styled-components';
import DotsVertical from 'mdi-react/DotsVerticalIcon';
import { Helmet } from 'react-helmet-async';
import { StyledBadge, StyledBox, StyledDropdownSelect, StyledMenuButton, StyledText } from '../../../common';
import { PERMISSIONS } from '../../../../global/permissions';
import Spinner from '../../../common/Spinner';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { useAuthorization, useMessages } from '../../../../utils/hooks';
import { datamainApi } from '../../../../apis/datamainApi';
import { Asset } from '../../../../global/interfaces';
import { CustomToggleProps } from '../../../layout/app/components/AppsDropdown';
import DataGridTable from '../../../common/DataGridTable';
import { PROPERTY_STATUS_TYPE, PUBLICATION_MODALITY, STATUS_TYPE_VARIANT } from '../../../../global';
import { PropertiesFilterBar } from './components';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';


const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const theme = useTheme();
    return (
      <StyledMenuButton
        ref={ref}
        onClick={onClick}
        size="sm"
        className="border-0 p-2 rounded-circle"
      >
        <DotsVertical size={26} color={theme.palette.iconColor} />
      </StyledMenuButton>
    )
  }
);

const ManagementPropertiesPage: FC = () => {
  const { messages } = useMessages();
  const { checkAccess } = useAuthorization();
  const { title } = messages.modules.management.assets.sections.assets;
  const {
    asset_name,
    investor_name,
    rup,
    status,
    podio_id,
    breal_id,
    region,
    commune,
    condominium,
    asset_type,
    asset_number,
  } = messages.modules.management.assets.sections.assets.columns;
  const {
    see_property,
    see_publication,
  } = messages.modules.management.assets.sections.assets.actions;
  const [assets, setAssets] = useState<Asset[]>([]);
  const [filters, setFilters] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [sortingField, setSortingField] = useState<string>('');

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['management/assets', { filters, page, limit, sortingField }], async () => {
    return await datamainApi.get(`/asset/list?page=${page}&limit=${limit}&ordering=${sortingField}&${filters}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setAssets(response.data?.results);
        setTotalRows(response.data?.count);
      },
      onError: (error: AxiosError) => {
        setAssets([]);
        setTotalRows(0);
      }
    });

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSort = async (column: any, sortDirection: any) => {
    if (sortDirection === 'asc') {
      setSortingField(column.sortField)
    }
    else {
      setSortingField(`-${column.sortField}`)
    }
  };

  const handlePerRowsChange = (limit: number, currentPage: number) => {
    setLimit(limit);
    setPage(currentPage);
  };

  const resetPagination = () => {
    setPage(1);
    setLimit(10);
    setSortingField('');
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos',
  };

  const columns: TableColumn<Asset>[] = [
    {
      name: investor_name,
      sortField: 'investor_name',
      selector: (row: Asset) => row.investor_name,
      center: true,
      sortable: true,
      sortFunction: (rowA: Asset, rowB: Asset) => {
        return rowA.investor_name.toLowerCase().localeCompare(rowB.investor_name.toLowerCase());
      },
      width: '350px',
    },
    {
      name: asset_name,
      sortField: 'asset_name',
      selector: (row: Asset) => row.asset_name,
      center: true,
      sortable: true,
      sortFunction: (rowA: Asset, rowB: Asset) => {
        return rowA.asset_name.toLowerCase().localeCompare(rowB.asset_name.toLowerCase());
      },
      width: '350px',
    },
    {
      name: rup,
      sortField: 'rup',
      selector: (row: Asset) => row.rup,
      center: true,
      sortable: true,
      width: '150px',
    },
    {
      name: podio_id,
      sortField: 'podio_id',
      selector: (row: Asset) => row.podio_app_id,
      center: true,
      sortable: true,
      width: '150px',
    },
    {
      name: breal_id,
      sortField: 'breal_id',
      selector: (row: Asset) => row.breal_id,
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: status,
      sortField: 'state',
      cell: (row: Asset) => (
        <StyledBadge
          className={`${STATUS_TYPE_VARIANT[row.state]} text fw-normal`}

        >
          {row.state}
        </StyledBadge>
      ),
      center: true,
      sortable: true,
      width: '250px',
    },
    {
      name: region,
      sortField: 'region',
      selector: (row: Asset) => row.region,
      center: true,
      sortable: true,
      sortFunction: (rowA: Asset, rowB: Asset) => {
        return rowA.region.toLowerCase().localeCompare(rowB.region.toLowerCase());
      },
      width: '300px',
    },
    {
      name: commune,
      sortField: 'commune',
      selector: (row: Asset) => row.commune,
      center: true,
      sortable: true,
      sortFunction: (rowA: Asset, rowB: Asset) => {
        return rowA.commune.toLowerCase().localeCompare(rowB.commune.toLowerCase());
      },
      width: '300px',
    },
    {
      name: condominium,
      sortField: 'condominium',
      selector: (row: Asset) => row.condominium,
      center: true,
      sortable: true,
      sortFunction: (rowA: Asset, rowB: Asset) => {
        return rowA.condominium?.toLowerCase().localeCompare(rowB.condominium?.toLowerCase());
      },
      width: '300px',
    },
    {
      name: asset_type,
      sortField: 'asset_type',
      selector: (row: Asset) => row.asset_type,
      center: true,
      sortable: true,
      width: '250px',
    },
    {
      name: asset_number,
      sortField: 'asset_number',
      selector: (row: Asset) => row.asset_number,
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      cell: (row: Asset) => (
        <StyledDropdownSelect>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu className="w-100 rounded-2 border-0 shadow">
            {(checkAccess(PERMISSIONS.GES_PROPI_37)) && (
              <Dropdown.Item
                eventKey="1"
                as={NavLink}
                to={`${ROUTES.MANAGEMENT_PROPERTY_BY_ID}/${row.asset_id}`}
              >
                {see_property}
              </Dropdown.Item>
            )}
            {(checkAccess(PERMISSIONS.GES_PROPI_45) && row.state === PROPERTY_STATUS_TYPE.PUBLISHED && row.modality === PUBLICATION_MODALITY.ARRIENDO) && (
              <Dropdown.Item
                eventKey="2"
                as={NavLink}
                to={`${ROUTES.MANAGEMENT_PROPERTY_PUBLICATION_BY_ID}/${row.asset_id}`}
              >
                {see_publication}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </StyledDropdownSelect>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '60px',
      // style: {
      //   position: 'sticky',
      //   right: 0,
      //   backgroundColor: '#ffffff',
      // }
    },
  ];

  return (
    <Authorization allowedRole={PERMISSIONS.GES_PROPI_36}>
      <Helmet>
        <title>{PAGE_TITLE.MANAGEMENT_PROPERTIES}</title>
      </Helmet>
      <Container fluid className="px-0 pt-md-5">
        <Row>
          <Col xs={12}>
            <StyledText className="h2 mb-4 title text-center">{title}</StyledText>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="">
            <StyledBox className="rounded-4 w-100 bg-white shadow-none py-3">
              <DataGridTable
                columns={columns}
                rows={assets}
                subHeader
                subHeaderComponent={<PropertiesFilterBar setFilters={setFilters} filters={filters} resetPagination={resetPagination} />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationDefaultPage={page}
                progressPending={isFetching}
                progressComponent={(
                  <Spinner />
                )}
                onSort={handleSort}
                sortServer
              />
            </StyledBox>
          </Col>
        </Row>
      </Container>
    </Authorization>
  );
};

export default ManagementPropertiesPage;