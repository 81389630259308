import { FC, useState, forwardRef, ForwardedRef } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { TableColumn } from 'react-data-table-component';
import { useTheme } from 'styled-components';
import DotsVertical from 'mdi-react/DotsVerticalIcon';
import { StyledBadge, StyledBox, StyledDropdownSelect, StyledMenuButton, StyledText } from '../../../common';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { PropertyInspection } from '../../../../global/interfaces';
import { useAuthorization, useMessages } from '../../../../utils/hooks';
import { datamainApi } from '../../../../apis/datamainApi';
import Spinner from '../../../common/Spinner';
import DataGridTable from '../../../common/DataGridTable';
import InspectionFilterBar from './components/InspectionFilterBar';
import { INSPECTIONS_STATUS_TYPE_VARIANT, INSPECTION_STATUS_TYPE } from '../../../../global';
import { CustomToggleProps } from '../../../layout/app/components/AppsDropdown';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const theme = useTheme();
    return (
      <StyledMenuButton
        ref={ref}
        onClick={onClick}
        size="sm"
        className="border-0 p-2 rounded-circle"
      >
        <DotsVertical size={26} color={theme.palette.iconColor} />
      </StyledMenuButton>
    )
  }
);

const ManagementInspectionsPage: FC = () => {
  const { messages } = useMessages();
  const { checkAccess } = useAuthorization();
  const { title } = messages.modules.management.inspections;
  const {
    asset_name,
    asset_code,
    status: statusLabel,
    commune,
    inspection_month,
    contract_start_date,
    tenant_fullname,
    tenant_rut,
    tenant_phone,
    tenant_email,
    inspection_phone,
    date: dateLabel,
    rup,
    // email,
  } = messages.modules.management.inspections.sections.self_inspections.columns;
  const { view_report } = messages.modules.management.inspections.sections.self_inspections.actions;
  const [filters, setFilters] = useState<string>('');
  const [inspections, setInspections] = useState<PropertyInspection[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [sortingField, setSortingField] = useState<string>('');

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['management/inspections', { filters, page, limit, sortingField }], async () => {
    return await datamainApi.get(`/inspection/tenants/list?page=${page}&limit=${limit}&ordering=${sortingField}&${filters}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setInspections(response.data?.results);
        setTotalRows(response.data?.count);
      },
      onError: (error: AxiosError) => {
        setInspections([]);
      }
    });

    const handlePageChange = (page: number) => {
      setPage(page);
    };
  
    const handleSort = async (column: any, sortDirection: any) => {
        if(sortDirection === 'asc') {
          setSortingField(column.sortField)
        }
        else {
          setSortingField(`-${column.sortField}`)
        }
      };
  
    const handlePerRowsChange = (limit: number, currentPage: number) => {
      setLimit(limit);
      setPage(currentPage);
    };
  
    const resetPagination = () => {
      setPage(1);
      setLimit(10);
      setSortingField('');
    };

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const columns: TableColumn<PropertyInspection>[] = [
    {
      name: inspection_month,
      sortField: 'inspection_month',
      selector: (row: PropertyInspection) => row.inspection_month,
      center: true,
      width: '200px',
      sortable: true
    },
    {
      name: rup,
      sortField: 'rup',
      selector: (row: PropertyInspection) => row.rup,
      center: true,
      sortable: true,
      width: '150px',
    },
    {
      name: asset_code,
      sortField: 'asset_code',
      selector: (row: PropertyInspection) => row.asset_code,
      center: true,
      width: '200px',
      sortable: true,
    },
    {
      name: asset_name,
      sortField: 'asset_name',
      selector: (row: PropertyInspection) => row.asset_name,
      center: true,
      sortable: true,
      sortFunction: (rowA: PropertyInspection, rowB: PropertyInspection) => {
        return rowA.asset_name.toLowerCase().localeCompare(rowB.asset_name.toLowerCase());
      },
      width: '350px',
    },
    {
      name: commune,
      sortField: 'commune',
      selector: (row: PropertyInspection) => row.commune,
      center: true,
      sortable: true,
      sortFunction: (rowA: PropertyInspection, rowB: PropertyInspection) => {
        return rowA.commune.toLowerCase().localeCompare(rowB.commune.toLowerCase());
      },
      width: '150px',
    },
    {
      name: contract_start_date,
      sortField: 'contract_start_date',
      selector: (row: PropertyInspection) => row.contract_start_date,
      center: true,
      width: '250px',
      sortable: true,
    },
    {
      name: tenant_fullname,
      sortField: 'tenant_fullname',
      selector: (row: PropertyInspection) => row.tenant_fullname,
      center: true,
      sortable: true,
      width: '250px',
      sortFunction: (rowA: PropertyInspection, rowB: PropertyInspection) => {
        return rowA.tenant_fullname.toLowerCase().localeCompare(rowB.tenant_fullname.toLowerCase());
      },
    },
    {
      name: tenant_rut,
      sortField: 'tenant_rut',
      selector: (row: PropertyInspection) => row.tenant_rut,
      center: true,
      sortable: true,
      width: '200px',
      
    },
    {
      name: tenant_phone,
      sortField: 'tenant_phone',
      selector: (row: PropertyInspection) => row.tenant_phone,
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: inspection_phone,
      sortField: 'inspection_phone',
      selector: (row: PropertyInspection) => row.inspection_phone,
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: tenant_email,
      sortField: 'tenant_email',
      selector: (row: PropertyInspection) => row.tenant_email,
      center: true,
      sortable: true,
      width: '250px',
    },
    // {
    //   name: email,
    //   sortField: 'inspection_month',
    //   selector: (row: PropertyInspection) => row.email,
    //   center: true,
    //   sortable: true,
    //   width: '250px',
    // },
    {
      name: statusLabel,
      sortField: 'status',
      sortable: true,
      cell: (row: PropertyInspection) => (
        <StyledBadge
          className={`${INSPECTIONS_STATUS_TYPE_VARIANT[row.status]} text fw-normal`}
        >
          {row.status}
        </StyledBadge>
      ),
      center: true,
      width: '200px',
    },
    {
      name: dateLabel,
      sortField: 'date',
      selector: (row: PropertyInspection) => row.date,
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      cell: (row: PropertyInspection) => (
        <StyledDropdownSelect>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu className="w-100 rounded-2 border-0 shadow">
            {(checkAccess(PERMISSIONS.GES_INSPE_33) && row.status === INSPECTION_STATUS_TYPE.DONE) && (
              <Dropdown.Item
                eventKey="1"
                as={NavLink}
                to={`${ROUTES.MANAGEMENT_INSPECTION_BY_ID}/${row.id}`}
              >
                {view_report}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </StyledDropdownSelect>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '60px',
    },
  ];

  return (
    <Authorization allowedRole={PERMISSIONS.GES_INSPE_24}>
      <Helmet>
        <title>{PAGE_TITLE.MANAGEMENT_INSPECTIONS}</title>
      </Helmet>
      <Container fluid className="px-0 pt-md-5">
        <Row>
          <Col xs={12}>
            <StyledText className="h2 mb-4 title text-center">{title}</StyledText>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="">
            <StyledBox className="rounded-4 w-100 bg-white shadow-none py-3">
              <DataGridTable
                columns={columns}
                rows={inspections}
                subHeader
                subHeaderComponent={<InspectionFilterBar setFilters={setFilters} filters={filters} resetPagination={resetPagination} />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationDefaultPage={page}
                progressPending={isFetching}
                progressComponent={(
                  <Spinner />
                )}
                onSort={handleSort}
                sortServer
              />
            </StyledBox>
          </Col>
        </Row>
      </Container>
    </Authorization>
  );
};

export default ManagementInspectionsPage;