import { FC } from 'react';
import { Col } from 'react-bootstrap';
import NoteCheckOutline from 'mdi-react/NoteCheckOutlineIcon';
import HomeCityOutline from 'mdi-react/HomeCityOutlineIcon';
import { Lease, Property } from '../../../../../global/interfaces';
import { useAuthorization, useMessages, useUtils } from '../../../../../utils/hooks';
import { PERMISSIONS } from '../../../../../global/permissions';
import StatsCard from '../../../../common/StatsCard';
import InfoStatsCard from '../../../../common/InfoStatsCard';
import { PROPERTY_STATUS_TYPE, STATUS_TYPE_VARIANT } from '../../../../../global';

interface PropertyStatsProps {
  property: Property;
}

const PropertyStats: FC<PropertyStatsProps> = ({ property }) => {
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const { getValidString, localFloatToString } = useUtils();
  const { stats } = messages.modules.investor.properties.sections;
  const { payment, status, current_lease, no_current_contract } = stats;

  // const displayPropertyPrice = (status: string) => {
  //   if (status === PROPERTY_STATUS_TYPE.PUBLISHED 
  //     || status === PROPERTY_STATUS_TYPE.RESERVED 
  //     || status === PROPERTY_STATUS_TYPE.LEASED) return true;
  //   return false;
  // }

  const activeLease = property.lesse_contract?.filter((lease: Lease) => lease.contract_status)[0];
  
  const isLeased = (activeLease?.contract_status && property.state?.name === PROPERTY_STATUS_TYPE.LEASED) ? true : false;

  const getLeaseValue = () => {
    if (activeLease || property.price) {
      if (isLeased) {
        return `${property.price?.currency.symbol} ${localFloatToString(activeLease?.amount)}`;
      }
      return `${property.price?.currency.symbol} ${localFloatToString(property.price?.value)}`;
    }
    return 'S/D';
  };

  return (
    <>
      {(checkAccess(PERMISSIONS.INV_SERVI_20) || checkAccess(PERMISSIONS.GES_PROPI_39)) && (
        <Col xs={12} sm={6} md={6} lg={4} xxl={3} className="mb-3">
          <StatsCard
            text={status.title}
            title={getValidString(property.state?.name)}
            icon={HomeCityOutline}
            variant={STATUS_TYPE_VARIANT[property.state?.name as string]}
          />
        </Col>
      )}
      {(checkAccess(PERMISSIONS.INV_SERVI_19) || checkAccess(PERMISSIONS.GES_PROPI_38)) && (
        <Col xs={12} sm={6} md={6} lg={4} xxl={3} className="mb-3">
          <StatsCard
            text={payment.title}
            title={getValidString(payment.status.up_to_date)}
            icon={NoteCheckOutline}
          />
        </Col>
      )}
      {((checkAccess(PERMISSIONS.INV_SERVI_32) || checkAccess(PERMISSIONS.GES_PROPI_44))) && (
        <Col xs={12} sm={6} md={6} lg={4} xxl={3} className="mb-3">
          <InfoStatsCard
            title={isLeased ? current_lease.title : no_current_contract.title}
            subtitle={`${getLeaseValue()}`}
            text={isLeased ? `${current_lease.status.to} ${activeLease?.date_end}` : undefined}
            variant={isLeased ? 'primary' : 'secondary'}
          />
        </Col>
      )}
    </>
  );
};

export default PropertyStats;