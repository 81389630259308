import { FC } from 'react';
import { Col, Tab, Nav } from 'react-bootstrap';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeft from 'mdi-react/ChevronLeftIcon';
import { Property } from '../../../../../global/interfaces';
import { useAuthorization, useCustomTab, useMessages, useWindowResize } from '../../../../../utils/hooks';
import { StyledBox, StyledTabList, StyledText } from '../../../../common';
import { PERMISSIONS } from '../../../../../global/permissions';
import { InfoCard, FeaturesCard, LeasesCard, InspectionsCard } from '.';
import ServicesTab from './ServicesTab';

interface PropertyDetailsProps {
  property: Property;
}

const smBreakpoint = 600;

const PropertyDetails: FC<PropertyDetailsProps> = ({ property }) => {
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const [width] = useWindowResize();
  const { onRef, onScroll, hideButtonLeft, hideButtonRight, containerWidth, tabListWidth, moveRight, moveLeft } = useCustomTab();
  const { information } = messages.modules.investor.properties.sections;
  const { title, data, features, services, leases, inspections } = information;


  return (
    <>
      {(width <= smBreakpoint) ? (
        <Col xs={12}>
          <StyledBox className="rounded-4 py-3 w-100 d-grid bg-white shadow-none">
            <StyledText className="h4 mx-md-5 mx-3 mb-3 title">{title}</StyledText>
            <Tab.Container id="left-tabs-example" defaultActiveKey="info">
              <StyledTabList className="w-100 position-relative d-flex my-3" id="hscroll" ref={onRef} onScroll={() => onScroll()}>
                {(containerWidth < tabListWidth) && (
                  <button
                    className="btn-tab sticky-left border-0 border-bottom"
                    onClick={moveLeft}
                    disabled={hideButtonLeft}
                  >
                    <ChevronLeft />
                  </button>
                )}
                <Nav
                  as="ul"
                  className={`nav-tabs d-flex flex-nowrap ${(width >= smBreakpoint) || (containerWidth > tabListWidth) ? 'w-100 px-md-5' : ''} `}
                  role="tablist"
                  id="tab-list"
                >
                  {(checkAccess(PERMISSIONS.INV_SERVI_25) || checkAccess(PERMISSIONS.GES_PROPI_40)) && (
                    <Nav.Item as="li">
                      <Nav.Link eventKey="info">{data.title}</Nav.Link>
                    </Nav.Item>
                  )}
                  {(checkAccess(PERMISSIONS.INV_SERVI_26) || checkAccess(PERMISSIONS.GES_PROPI_41)) && (
                    <Nav.Item as="li">
                      <Nav.Link eventKey="features">{features.title}</Nav.Link>
                    </Nav.Item>
                  )}
                  {((checkAccess(PERMISSIONS.INV_SERVI_30) || checkAccess(PERMISSIONS.GES_PROPI_43)) && property.lesse_contract?.length !== 0) && (
                    <Nav.Item as="li">
                      <Nav.Link eventKey="leases">{leases.title}</Nav.Link>
                    </Nav.Item>
                  )}
                  {((checkAccess(PERMISSIONS.INV_SERVI_46) || checkAccess(PERMISSIONS.GES_PROPI_48)) && property.inspections?.length !== 0) && (
                    <Nav.Item as="li">
                      <Nav.Link eventKey="inspections">{inspections.title}</Nav.Link>
                    </Nav.Item>
                  )}
                  {(checkAccess(PERMISSIONS.INV_SERVI_27) || checkAccess(PERMISSIONS.GES_PROPI_42)) && (
                    <Nav.Item as="li">
                      <Nav.Link eventKey="services">{services.title}</Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
                {(containerWidth < tabListWidth) && (
                  <button
                    className="btn-tab sticky-right border-0 border-bottom"
                    onClick={moveRight}
                    disabled={hideButtonRight}
                  >
                    <ChevronRight />
                  </button>
                )}
              </StyledTabList>
              <div className="w-100">
                <Tab.Content className="px-3 h-100">
                  {(checkAccess(PERMISSIONS.INV_SERVI_25) || checkAccess(PERMISSIONS.GES_PROPI_40)) && (
                    <Tab.Pane eventKey="info">
                      <InfoCard property={property} />
                    </Tab.Pane>
                  )}
                  {(checkAccess(PERMISSIONS.INV_SERVI_26) || checkAccess(PERMISSIONS.GES_PROPI_41)) && (
                    <Tab.Pane eventKey="features">
                      <FeaturesCard property={property} />
                    </Tab.Pane>
                  )}
                  {((checkAccess(PERMISSIONS.INV_SERVI_30) || checkAccess(PERMISSIONS.GES_PROPI_43)) && property.lesse_contract?.length !== 0) && (
                    <Tab.Pane eventKey="leases">
                      <LeasesCard property={property} />
                    </Tab.Pane>
                  )}
                  {((checkAccess(PERMISSIONS.INV_SERVI_46) || checkAccess(PERMISSIONS.GES_PROPI_48)) && property.inspections?.length !== 0) && (
                    <Tab.Pane eventKey="inspections">
                      <InspectionsCard property={property} />
                    </Tab.Pane>
                  )}
                  {(checkAccess(PERMISSIONS.INV_SERVI_27) || checkAccess(PERMISSIONS.GES_PROPI_42)) && (
                    <Tab.Pane eventKey="services">
                      <ServicesTab property={property} />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </div>
            </Tab.Container>
          </StyledBox>
        </Col>
      ) : (
        <>
          {(checkAccess(PERMISSIONS.INV_SERVI_25) || checkAccess(PERMISSIONS.GES_PROPI_40)) && (
            <Col md={6} lg={4} xxl={3} className="mb-3">
              <StyledBox className="rounded-4 p-2 p-md-4 w-100 bg-white shadow-none h-100">
                <InfoCard property={property} />
              </StyledBox>
            </Col>
          )}
          {(checkAccess(PERMISSIONS.INV_SERVI_26) || checkAccess(PERMISSIONS.GES_PROPI_41)) && (
            <Col md={6} lg={4} xxl={3} className="mb-3">
              <StyledBox className="rounded-4 p-2 p-md-4 w-100 bg-white shadow-none h-100">
                <FeaturesCard property={property} />
              </StyledBox>
            </Col>
          )}
          {((checkAccess(PERMISSIONS.INV_SERVI_30) || checkAccess(PERMISSIONS.GES_PROPI_43)) && property.lesse_contract?.length !== 0) && (
            <Col md={6} lg={4} xxl={3} className="mb-3">
              <StyledBox className="rounded-4 p-2 p-md-4 w-100 bg-white shadow-none h-100" style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <LeasesCard property={property} />
              </StyledBox>
            </Col>
          )}
          {((checkAccess(PERMISSIONS.INV_SERVI_46) || checkAccess(PERMISSIONS.GES_PROPI_48)) && property.inspections?.length !== 0) && (
            <Col md={6} lg={4} xxl={3} className="mb-3">
              <StyledBox className="rounded-4 p-2 p-md-4 w-100 bg-white shadow-none h-100" style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <InspectionsCard property={property} />
              </StyledBox>
            </Col>
          )}
          {(checkAccess(PERMISSIONS.INV_SERVI_27) || checkAccess(PERMISSIONS.GES_PROPI_42)) && (
            <Col md={6} lg={4} xxl={3} className="mb-3">
              <StyledBox className="rounded-4 p-2 p-md-4 w-100 bg-white shadow-none h-100">
                <ServicesTab property={property} />
              </StyledBox>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default PropertyDetails;