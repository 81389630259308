import { FC, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { PrivateRoute } from '../../../routes/PrivateRoute'
import { useAuth, useMessages, useUtils } from '../../../utils/hooks';
import Spinner from '../../common/Spinner';
import { Header } from './components';
import { authRentApi } from '../../../apis/authRentApi';
import { PUBLIC_URL } from '../../../global';
import Sidebar from './components/Sidebar';
import { StyledBox, StyledEmptyContainer, StyledMain } from '../../common';
import { stackRentApi } from '../../../apis/stackRentApi';

const AppLayout: FC = () => {
  const auth = useAuth();
  let navigate = useNavigate();
  const { messages } = useMessages();
  const { pathname } = useLocation();
  const { includePaths } = useUtils();
  const { notificationsHasChanged, apps, setCurrentApplication } = auth;
  const { sessions } = messages.errors;

  const { refetch } = useQuery<AxiosResponse, AxiosError>(['notificationsCount', notificationsHasChanged], async () => {
    return await stackRentApi.get('/users/notification/count');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        const { data } = response;
        auth.setUnreadNotificationCount(data.notification_number);
        auth.setNotificationsHasChanged(false);
      },
      onError: (error: AxiosError) => {
        auth.setUnreadNotificationCount(0);
        auth.setNotificationsHasChanged(false);
      }
    });

  useEffect(() => {
    if (apps.length === 1) {
      setCurrentApplication(apps[0]);
      localStorage.setItem('currentApp', JSON.stringify(apps[0]));
    }
    else {
      if (localStorage.getItem('currentApp')) {
        setCurrentApplication(JSON.parse(localStorage.getItem('currentApp') as string))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, status, remove, fetchStatus, isFetching, isSuccess } = useQuery<AxiosResponse, any>(['validateUser'], async () => {
    return await authRentApi.get('/auth/me/');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        if (response.data.applications.length === 1) {
          auth.setCurrentApplication(response.data.applications[0])
        }
      },
      onError: (error) => {
        const { data } = error.response;
        auth.signout(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.setItem('isFirstTime', JSON.stringify(false));
          localStorage.removeItem('currentApp');
          auth.setErrorAlert(data.description);
        })
      }
    });

  useEffect(() => {
    if (fetchStatus === 'idle' && status === 'success') {
      if (auth.roles !== data.data.scopes) {
        if (data.data.applications.length === 0 || data.data.force_change_password) {
          auth.signout(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.setItem('isFirstTime', JSON.stringify(false));
            localStorage.removeItem('currentApp');
            if (data.data.applications.length === 0) {
              auth.setErrorAlert(sessions.no_license);
            }
            navigate(`${PUBLIC_URL}/`)
          })
        } else {
          auth.signin(data.data.email, data.data.scopes, data.data.applications, () => {
            refetch();
            auth.setShouldForcePasswordChange(data.data.force_change_password);
          })
        }
      } else {
        remove()
      }
    }
  }, [fetchStatus, status, auth, data, remove, navigate, sessions, refetch]);

  return (
    <PrivateRoute>
      <ProSidebarProvider>
        <StyledBox className="app-layout min-vh-100">
          <Header />
          <div className="d-flex flex-row w-100">
            {includePaths(pathname) && (
              <Sidebar />
            )}
            <Container fluid as={StyledMain} className="px-2 px-md-3">
              <Row className="mx-0">
                <Col xs={12} className="px-1 px-md-2 pt-3 pb-2 mb-3">
                  {(isFetching) ? (
                    <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
                      <Spinner />
                    </StyledEmptyContainer>
                  ) : (
                    <>
                      {isSuccess && (<Outlet />)}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </StyledBox>
      </ProSidebarProvider>
    </PrivateRoute>
  );
};

export default AppLayout;