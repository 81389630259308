import { FC, useState, useMemo, ChangeEvent, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { TableColumn } from 'react-data-table-component';
import { AxiosError, AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import Magnify from 'mdi-react/MagnifyIcon';
import AlertOctagonOutline from 'mdi-react/AlertOctagonOutlineIcon';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { PERMISSIONS } from '../../../../global/permissions';
import { StyledBadge, StyledBox, StyledEmptyContainer, StyledText } from '../../../common';
import TicketForm from './components/TicketForm';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';
import { AccountUser, AssetDataProperty, Lease, Property } from '../../../../global/interfaces';
import { stackRentApi } from '../../../../apis/stackRentApi';
import PropertiesFilter from '../properties/components/PropertiesFilter';
import Spinner from '../../../common/Spinner';
import { useApp, useMessages, useStoredQuery, useUtils, useWindowResize } from '../../../../utils/hooks';
import Dialog from '../../../common/Dialog';
import DataGridTable from '../../../common/DataGridTable';
import RouterBreadcrumb from '../../../common/RouterBreadcrumb';
import Confirm from '../../../common/Confirm';
import Fab, { StyledFab } from '../../../common/Fab';
import { STATUS_TYPE_VARIANT } from '../../../../global';

const smBreakpoint = 600;

const InvestorCreateTicketPage: FC = () => {
  const navigate = useNavigate();
  const [width] = useWindowResize();
  const [person, setPerson] = useState<AccountUser>({} as AccountUser);
  const { currentProperty, setCurrentProperty } = useApp();
  const [properties, setProperties] = useState<AssetDataProperty[]>([]);
  const [filterList, setFilterList] = useState<AssetDataProperty[]>([]);
  const [showProperties, setShowProperties] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [show, setShow] = useState<boolean>(true);
  const { localFloatToString } = useUtils();
  const { messages } = useMessages();
  const { service_unavailable } = messages.dialogs;
  const { table, actions } = messages.modules.investor.properties.sections;
  const { title } = messages.forms.create_ticket;
  const { columnsNames } = table;

  const data = useStoredQuery(['profile']);

  useEffect(() => {
    if (data) {
      const { person_data } = data.data;
      setPerson(person_data);
    }
  }, [data]);

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['investor/properties'], async () => {
    return await stackRentApi.get('/asset/me');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setProperties(response.data);
        setFilterList(response.data);
        if (response.data.length !== 0) {
          const [property] = response.data;
          if (Object.entries(currentProperty).length === 0) {
            setCurrentProperty(property.asset_data);
          }
        }
        else {
          setCurrentProperty({} as Property);
          setProperties([]);
          setFilterList([]);
        }
      },
      onError: (error: AxiosError) => {
        setCurrentProperty({} as Property);
        setProperties([]);
        setFilterList([]);
      }
    });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setFilterText('');
      setFilterList(properties)
    };

    const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFilterText(value);
      if (value.length > 0) {
        const filteredProperties = filterList.filter(({ asset_data: {
          address,
          commune
        } }: AssetDataProperty) => (address && address.toLowerCase().includes(value.toLowerCase())) ||
          (commune.name && commune.name.toLowerCase().includes(value.toLowerCase()))
        );
        setFilterList(filteredProperties);
      }
      else {
        setFilterList(properties)
      }
    }

    return (
      <PropertiesFilter onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, properties, filterList]);

  const toggleShowProperties = () => {
    setShowProperties(!showProperties)
  }

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }

  const columns: TableColumn<AssetDataProperty>[] = [
    {
      name: columnsNames.address,
      selector: (row: AssetDataProperty) => row.asset_data.asset_name,
      center: true,
      grow: 1.5,
      sortable: true,
    },
    // {
    //   name: columnsNames.condominium,
    //   selector: (row: AssetDataProperty) => row.asset_data.condominium.name,
    //   center: true
    // },
    {
      name: columnsNames.commune,
      selector: (row: AssetDataProperty) => row.asset_data.commune.name,
      center: true,
      grow: 1,
      sortable: true,
    },
    {
      name: columnsNames.status,
      cell: (row: AssetDataProperty) => (
        <StyledBadge
          className={`${STATUS_TYPE_VARIANT[row.asset_data.state?.name as string]} text fw-normal`}
        >
          {row.asset_data.state.name}
        </StyledBadge>
      ),
      center: true,
      sortable: true,
      // grow: 0.5
    },
    {
      name: columnsNames.price,
      cell: (row: AssetDataProperty) => {
        const activeLease = row.asset_data.lesse_contract.filter((lease: Lease) => lease.contract_status)[0];
        if (row.asset_data.lesse_contract.length !== 0 && activeLease) {
          return `${row.asset_data.price.currency.symbol}${localFloatToString(activeLease?.amount)}`
        }
        return `${row.asset_data.price.currency.symbol}${localFloatToString(row.asset_data.price.value)}`
      },
      center: true,
      sortable: true,
    },
  ];

  const handleCurrentProperty = (row: AssetDataProperty) => {
    setCurrentProperty(row.asset_data);
    toggleShowProperties()
  };

  const toggleShow = () => {
    setShow(!show);
    navigate(ROUTES.INVESTOR_SUPPORT, { replace: true })
  };

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_28}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_SUPPORT_CREATE_TICKET}</title>
      </Helmet>
      <Container fluid className="px-0 px-md-3">
        {(properties.length !== 0) && (
          <>
            {(currentProperty.asset_name !== '' && person.phone !== '') ? (
              <Row className="justify-content-center pt-md-2">
                <Col xs={12} sm={12} md={12} className="d-flex flex-row align-items-center mb-0 mb-md-2">
                  {(properties.length > 1) && (
                    <>
                      {(width <= smBreakpoint) ? (
                        <Fab
                          variant="secondary"
                          icon={Magnify}
                          onClick={setShowProperties}
                          style={{ zIndex: 10 }}
                        >
                          {actions.search}
                        </Fab>
                      ) : (
                        <StyledFab
                          className="btn rounded-5 shadow-sm secondary d-flex flex-row align-items-center"
                          onClick={setShowProperties}
                        >
                          <Magnify className="me-2" />
                          {actions.search}
                        </StyledFab>
                      )}
                      <Dialog
                        open={showProperties}
                        onHide={toggleShowProperties}
                        dialogClassName="w-80"
                      // centered={(width <= smBreakpoint) ? false : true}
                      >
                        <DataGridTable
                          columns={columns}
                          rows={filterList}
                          onRowClicked={handleCurrentProperty}
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                        />
                      </Dialog>
                    </>
                  )}
                  <StyledText className="h4 mx-2 my-0 d-none d-md-block">
                    {currentProperty.asset_name}
                  </StyledText>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <RouterBreadcrumb />
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                      <StyledText className="h2 mb-0 ms-md-0 mb-md-2 text-center fw-semibold title">
                        {title}
                      </StyledText>
                    </Col>
                  </Row>
                  <StyledBox className="rounded-4 p-4 w-100 bg-white mb-4 mb-md-0">
                    <TicketForm property={currentProperty} person={person} />
                  </StyledBox>
                </Col>
              </Row>
            ) : (
              <Confirm
                show={show}
                onHide={toggleShow}
                title={service_unavailable.title}
                text={service_unavailable.text}
                icon={AlertOctagonOutline}
                variant="secondary"
                enableClose
                actions={null}
                size={(width <= smBreakpoint) ? 'lg' : undefined}
              />
            )}
          </>
        )}
      </Container>
    </Authorization>
  );
};

export default InvestorCreateTicketPage;