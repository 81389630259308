import * as Yup from 'yup';
import { PASSWORD_VALIDATION_REGEXP } from '../../global';

export interface SignInValues {
  email: string;
  password: string;
  remember: boolean;
}

export const SIGNIN_INITIAL_VALUES: SignInValues = {
  email: '',
  password: '',
  remember: false,
}

export const SIGNIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Correo electrónico incorrecto').required('Requerido'),
  password: Yup.string().required('Requerido')
    .matches(PASSWORD_VALIDATION_REGEXP, 'La contraseña debe contener al menos 8 caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.'),
});

export interface PasswordChangeValues {
  old_password: string;
  password: string;
  password_confirm: string;
}

export const PASSWORD_CHANGE_INITIAL_VALUES: PasswordChangeValues = {
  old_password: '',
  password: '',
  password_confirm: '',
}

export const PASSWORD_CHANGE_VALIDATION_SCHEMA = Yup.object().shape({
  old_password: Yup.string().required('Requerido')
    .matches(PASSWORD_VALIDATION_REGEXP, 'La contraseña debe ser alfanumérica, conteniendo al menos una letra mayúscula y una letra minúscula, intenta nuevamente.'),
  password: Yup.string().required('Requerido')
    .matches(PASSWORD_VALIDATION_REGEXP, 'La contraseña debe ser alfanumérica, conteniendo al menos una letra mayúscula y una letra minúscula, intenta nuevamente.'),
  password_confirm: Yup.string().required('Requerido')
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .matches(PASSWORD_VALIDATION_REGEXP, 'La contraseña debe ser alfanumérica, conteniendo al menos una letra mayúscula y una letra minúscula, intenta nuevamente.'),
})


export interface RecoverPasswordValues {
  email: string;
}

export const RECOVER_PASSWORD_INITIAL_VALUES: RecoverPasswordValues = {
  email: '',
}

export const RECOVER_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Correo electrónico incorrecto').required('Requerido'),
});