import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Property } from '../../../../../global/interfaces';
import { useMessages, useUtils } from '../../../../../utils/hooks';
import Info from '../../../../common/Info';
import { StyledText } from '../../../../common';

interface InfoCardProps {
  property: Property;
}

const InfoCard: FC<InfoCardProps> = ({ property }) => {
  const { type, asset_type, asset_number, tower, address, region, city, floor, number, commune, orientation, year_construction, description, tax_role, condominium } = property;
  const { messages } = useMessages();
  const { fields, title } = messages.modules.investor.properties.sections.information.data;
  const { getNumberToString, getValidString } = useUtils();

  return (
    <Row>
      <Col xs={12} className="mb-3 d-none d-md-block">
        <StyledText className="h4 title">{title}</StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.type}: </span>
          {type ? getValidString(type?.name) : getValidString(asset_type?.name)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.condominium}: </span>
          {getValidString(condominium?.name)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.department}: </span>
          {number ? getValidString(number) : getValidString(asset_number)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.address}: </span>
          {getValidString(address)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.region}: </span>
          {getValidString(region?.name)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.city}: </span>
          {getValidString(city)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.commune}: </span>
          {getValidString(commune?.name)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.floor}: </span>
          {getValidString(floor)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.tower}: </span>
          {getValidString(tower?.name)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.orientation}: </span>
          {getValidString(orientation?.name)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.building_date}: </span>
          {getNumberToString(year_construction)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.role_number}: </span>
          {getValidString(tax_role)}
        </StyledText>
      </Col>
      <Col xs={12} className="mb-3 px-0 px-md-2 d-none">
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.description}: </span>
          {description}
        </StyledText>
        <Info title={fields.description} description={description} />
      </Col>
    </Row>
  );
};

export default InfoCard;