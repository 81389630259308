export const PUBLIC_URL = process.env.PUBLIC_URL;
export const PASSWORD_VALIDATION_REGEXP = new RegExp('^.*(?=.{8,})((?=.*[!@#$%^&*()-_=+{};:,<.>]){1})(?=.*)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$');
export const NCRYPT_KEY = '75e2475f31efa6656c2f0eda251dc5a7';
export interface ErrorMessage {
  title: string;
  text: string;
  icon?: string | JSX.Element;
  status?: number | string;
  code?: string;
}

export interface BackendError {
  code: string;
  description: string;
}

export interface LocationState {
  from: {
    pathname: string;
  }
}

export enum CONFIRM_STATUS {
  ERROR = 'error',
  BLOCKED = 'blocked',
  SUCCESS = 'success',
  PASSWORD_SUCCESS = 'password-success',
  INFO = 'info',
  WARNING = 'warning',
}

export enum APPS {
  COR = 'COR', // Portal de Corredores.
  INV = 'INV', // Portal de clientes Inversionistas.
  ARR = 'ARR', // Portal de clientes Arrendatarios.
  GES = 'GES', // Servicios back-office de la plataforma/
  PLA = 'PLA', // Servicios comunes de plataforma y funciones de adminstración.
}

export enum SPACES {
  RENT = 'Capitalizarme Rent',
  TEST = 'Prueba'
}

export enum MODULES {
  ACCES = 'ACCES', // Autorización de Accesos
  SERVI = 'SERVI', // Servicios de Plataforma
  CTAUS = 'CTAUS', // Cuenta del usuario
  NOTIF = 'NOTIF', // Notificaciones
}

export enum PUBLISHERS {
  RENT = 'portalrent',
  HOMIE_RENT = 'homirent',
  TOCTOC = 'toctoc',
  PORTAL_INMOBILIARIO = 'portalinmobiliario',
  GOPLACEIT = 'goplaceit',
}

export enum PUBLICATION_MODALITY {
  VENTA = 'Venta',
  ARRIENDO = 'Arriendo'
}

export enum VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTARY = 'tertary',
  QUATERNARY = 'quaternary',
}

export enum CTA_ACTION_TYPE {
  NEW_TAB = 'new_tab',
  DOWNLOAD = 'download',
}

export enum PROPERTY_STATUS_TYPE {
  AT_RECEPTION = 'En recepción',
  RECEIVED = 'Recibida',
  PUBLISHED = 'Publicada',
  RESERVED = 'Reservada',
  LEASED = 'Arrendada',
  ON_DEPARTURE = 'En salida',
}

export const STATUS_TYPE_VARIANT : {
  [key: string]: string
} = {
  'En recepción': 'quaternary',
  'Recibida': 'quaternary',
  'Publicada': 'secondary',
  'Reservada': 'primary',
  'Arrendada': 'primary',
  'En salida': 'tertary',
}

export const STATUS_TYPES = [
  'En recepción',
  'Recibida',
  'Publicada',
  'Reservada',
  'Arrendada',
  'En salida',
]

export const INSPECTIONS_STATUS_TYPE_VARIANT : {
  [key: string]: string
} = {
  'No enviado': 'default',
  'Enviado': 'quaternary',
  'No contactado': 'tertary',
  'Iniciado': 'secondary',
  'Realizado': 'primary',
}

export enum INSPECTION_STATUS_TYPE {
  NOT_SENT = 'No enviado',
  SENT = 'Enviado',
  NOT_CONTACTED = 'No contactado',
  STARTED = 'Iniciado',
  DONE = 'Realizado'
}