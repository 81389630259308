import { FC, useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import MenuIcon from 'mdi-react/MenuIcon';
import MenuOpenIcon from 'mdi-react/MenuOpenIcon';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { StyledMenuButton } from '../../../common';
import { useAuth, useAuthorization } from '../../../../utils/hooks';
import { APPS } from '../../../../global';
import { AppRoute, investorRoutes, managementRoutes } from '.';
import { StyledMenuItem, StyledSidebarMenu } from './Sidebar';

const StyledDrawer = styled(Drawer)``;

const StyledHeader = styled.div`
  background-color: ${(props: any) => props.theme.palette.sidebarHeaderBackgroundColor};
  color: ${(props: any) => props.theme.palette.sidebarHeaderTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
  &:hover {
    cursor: pointer;
  }
`;

const AppDrawer: FC = () => {
  const theme = useTheme();
  const { currentApp } = useAuth();
  const { checkAccess } = useAuthorization();

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const toggleDrawer = () => {
    setIsOpen((prevState: boolean) => !prevState);
  }

  const getCurrentAppRoutes = (appCode: string): AppRoute[] => {
    switch (appCode) {
      case APPS.INV: return investorRoutes;
      case APPS.ARR: return [];
      case APPS.COR: return [];
      case APPS.PLA: return [];
      case APPS.GES: return managementRoutes;
      default: return [];
    };
  };

  return (
    <>
      <StyledMenuButton
        onClick={toggleDrawer}
        size="sm"
        className="me-2 border-0 d-md-none p-2 rounded-circle">
        <MenuIcon size={26} color={theme.palette.iconColor} />
      </StyledMenuButton>
      <StyledDrawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
      >
        {currentApp && (
          <>
            <StyledHeader
              onClick={toggleDrawer}
            >
              {/* {getAppIcon(currentApp.code)} */}
              <span>Portal {currentApp.name}</span>
              <MenuOpenIcon size={30} />
            </StyledHeader>
            <StyledSidebarMenu>
              {getCurrentAppRoutes(currentApp.code).map(({ name, to, Icon, permission }: AppRoute) => (
                <Fragment key={to}>
                  {checkAccess(permission) && (
                    <StyledMenuItem
                      component={<NavLink to={to} />}
                      className={`m-0 ${currentApp.code.toLowerCase()}`}
                      icon={<Icon className="side-icon" size={40} />}
                      onClick={toggleDrawer}
                    >
                      <span>{name}</span>
                    </StyledMenuItem>
                  )}
                </Fragment>
              ))}
            </StyledSidebarMenu>
          </>

        )}
      </StyledDrawer>
    </>
  );
};

export default AppDrawer;