import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PasswordRecoverForm from './components/PasswordRecoverForm';
import { StyledBox } from '../../../common';
import { PAGE_TITLE } from '../../../../global/urls';

const PasswordRecoverPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE.AUTH_RECOVER_PASSWORD}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={6} lg={5} xl={4} xxl={4} className="px-lg-5">
          <StyledBox className="px-4 px-md-5 py-5 m-auto default rounded-4 shadow-none position-relative auth-box">
            <PasswordRecoverForm />
          </StyledBox>
        </Col>
      </Row>
    </>
  );
};

export default PasswordRecoverPage;