import { FC, useRef, useEffect, MouseEvent } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSwiper, useSwiperSlide } from "swiper/react";
import { useTracking } from 'react-tracking';
import { StyledAdImage, StyledAdItem, StyledSubmitButton, StyledText } from '.';
import { useUtils, useWindowResize } from '../../utils/hooks';
import { CallToAction, TrackData } from '../../global/interfaces';
import { stackRentApi } from '../../apis/stackRentApi';
import { CTA_ACTION_TYPE } from '../../global';

enum CALL_TO_ACTION_AS {
  BUTTON = 'button',
  LINK = 'link'
}

interface AdItemProps {
  title: string;
  description: string;
  extraInfo?: string;
  icon?: any;
  callToAction?: CallToAction;
  enableCallToAction: boolean;
  imgSrc: string;
  id: number | string;
}

const smBreakpoint = 600;

const AdItem: FC<AdItemProps> = ({ title, description, extraInfo, icon: Icon, callToAction, enableCallToAction, imgSrc, id }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [width] = useWindowResize();
  const { generateImageFromElement } = useUtils();
  const { activeIndex } = useSwiper();
  const { isActive } = useSwiperSlide();

  const { mutate } = useMutation(() => {
    return stackRentApi.patch(`/ad/me/${id}`);
  });

  const { mutate: onTrack } = useMutation((data: TrackData) => {
    return stackRentApi.post('/event/add', data);
  });

  const { Track, trackEvent } = useTracking({ category: 'Anuncios' }, {
    dispatch: (data: TrackData) => {
      const { data: { isActive } } = data;
      if (isActive) {
        onTrack(data);
      }
    }
  });

  useEffect(() => {
    if (isActive) {
      mutate();
      trackEvent({ action: 'seen', data: { adId: id, isActive } });
    }
  }, [activeIndex, isActive, mutate, trackEvent, id])

  const onCallToActionButton = (event: MouseEvent<HTMLButtonElement>, action_type: CTA_ACTION_TYPE, url?: string) => {
    if (action_type === CTA_ACTION_TYPE.DOWNLOAD) {
      trackEvent({ action: action_type, data: { adId: id, isActive } });
      generateImageFromElement(elementRef.current, 'anuncio.png');
    }
    if (action_type === CTA_ACTION_TYPE.NEW_TAB) {
      trackEvent({ action: action_type, data: { adId: id, url, isActive } });
      event.preventDefault();
      if (url) window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const onCallToActionLink = (event: MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    trackEvent({ action: 'click', data: { adId: id, url, isActive } })
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Track>
      <StyledAdItem ref={elementRef} className={`w-100 d-flex ${(width < smBreakpoint) ? 'flex-column-reverse' : 'flex-row'}`}>
        <div className={`${(width < smBreakpoint) ? 'w-100 h-40' : 'w-60 h-auto'} py-4 px-3 px-md-5 d-flex flex-column align-items-center justify-content-center`}>
          {Icon && (
            <div className="mb-2">
              <Icon size={70} className="ad-item-icon" />
            </div>
          )}
          <StyledText className={`${(width < smBreakpoint) ? 'h2' : 'h3'} text-center mb-3 primary fw-semibold`}>{title}</StyledText>
          <StyledText className="text-center mb-4 px-3 subtitle fw-normal">
            {description}
            <br />
            {extraInfo && (
              <StyledText className="remaining-time h6 fw-normal mt-3 active">{extraInfo}</StyledText>
            )}
          </StyledText>
          {(enableCallToAction && callToAction) && (
            <>
              {(callToAction.as === CALL_TO_ACTION_AS.BUTTON) && (
                <StyledSubmitButton
                  className="w-100 rounded-3 btn-lg py-1 cta-btn primary filled"
                  onClick={(event: MouseEvent<HTMLButtonElement>) => onCallToActionButton(event, callToAction.action_type, callToAction.url)}
                >
                  {callToAction.text}
                </StyledSubmitButton>
              )}
              {(callToAction.as === CALL_TO_ACTION_AS.LINK) && (
                <a
                  href={callToAction.url}
                  className="cta-link"
                  onClick={(event) => onCallToActionLink(event, callToAction.url)}
                >
                  {callToAction.text}
                </a>
              )}
            </>
          )}
        </div>
        <StyledAdImage
          className={`${(width < smBreakpoint) ? 'w-100' : 'w-40 h-auto'}`}
          src={imgSrc}
        />
      </StyledAdItem>
    </Track>
  );
};

export default AdItem;