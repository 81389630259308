import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  palette: {
    primary: {
      light: "#747eb4",
      main: "#525ea2",
      dark: "#394171",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#a16c9d",
      main: "#8a4885",
      dark: "#60325d",
      contrastText: "#ffffff",
    },
    tertary: {
      light: "#f09f5d",
      main: "#ed8735",
      dark: "#a55e25",
      contrastText: "#ffffff",
    },
    quaternary: {
      light: "#4fa397",
      main: "#238d7e",
      dark: "#186258",
      contrastText: "#ffffff",
    },
    buttonBackgroundColor: "transparent",
    buttonActiveBackgroundColor: "#525ea2",
    buttonDisabledBackgroundColor: "#747eb4",
    buttonBorderColor: "#525ea2",
    buttonTextColor: "#ffffff",
    buttonTextActiveColor: "#525ea2",
    buttonIconColor: "#756a6a",
    buttonIconActiveColor: "#525ea2",

    inputBackgroundColor: "#ffffff",
    inputActiveBorderColor: "#525ea2",
    inputInactiveBorderColor: "#8E8D8D",
    inputTextColor: "#525ea2",
    inputInvalidBackgroundColor: "#a16c9d",
    inputInvalidBorderColor: "#a16c9d",
    inputInvalidTextColor: "#a16c9d",
    inputValidBackgroundColor: "#ffffff",
    inputValidBorderColor: "#525ea2",
    inputValidTextColor: "#525ea2",
    inputAddonTextColor: "#525ea2",
    inputAddonActiveTextColor: "#ffffff",
    inputAddonBackgroundColor: "#ffffff",
    inputAddonBorderColor: "#525ea2",
    inputAddonActiveBackgroundColor: "#525ea2",

    checkBoxBackgroundColor: "#ffffff",
    checkBoxBorderColor: "#756a6a",
    checkBoxTextColor: "#756a6a",
    checkBoxInvalidBackgroundColor: "transparent",
    checkBoxInvalidBorderColor: "#756a6a",
    checkBoxInvalidTextColor: "#756a6a",
    checkBoxValidBackgroundColor: "transparent",
    checkBoxValidBorderColor: "#756a6a",
    checkBoxValidTextColor: "#756a6a",

    switchBackgroundColor: "#756a6a",
    switchBorderColor: "#756a6a",
    switchActiveBackgroundColor: "#525ea2",
    switchActiveBorderColor: "#525ea2",
    switchTextColor: "#ffffff",

    dropdownTextColor: "#8E8D8D",
    dropdownBackgroundColor: "#ffffff",
    dropdownBorderColor: "#525ea2",
    dropdownActiveTextColor: "#525ea2",
    dropdownActiveBackgroundColor: "#525ea2",
    dropdownInactiveBackgroundColor: "#ffffff",
    dropdownActiveBorderColor: "#525ea2",

    notificationDropdownBackgroundColor: "#ffffff",
    notificationDropdownTextColor: "#756a6a",
    notificationDropdownBorderColor: "#d9d9d9",
    notificationDropdownActiveBackgroundColor: "#525ea2",
    notificationDropdownInactiveBackgroundColor: "#ffffff",
    notificationDropdownActiveTextColor: "#756a6a",
    notificationDropdownActiveBorderColor: "#d9d9d9",
    notificationDropdownActiveIconColor: "#525ea2",

    notificationBottomOptionBackgroundColor: "#ffffff",
    notificationBottomOptionTextColor: "#756a6a",
    notificationBottomOptionActiveBackgroundColor: "#ffffff",
    notificationBottomOptionActiveTextColor: "#525ea2",

    cardBackgroundColor: "#f5f5f5",
    cardTextColor: "#756a6a",
    cardBorderColor: "#525ea2",

    globalTextColor: "#756a6a",

    actionButtonBackgroundColor: "#ffffff",
    actionButtonTextColor: "#756a6a",
    actionButtonBorderColor: "#756a6a",

    actionButtonActiveBackgroundColor: "#756a6a",
    actionButtonActiveTextColor: "#ffffff",

    notificationBadgeBackgroundColor: "#8a4885",
    notificationBadgeTextColor: "#ffffff",

    alertBackgroundColor: "#525ea2",
    alertTextColor: "#ffffff",
    alertBorderColor: "#525ea2",

    modalBackgroundColor: "#525ea2",
    modalBorderColor: "#525ea2",
    modalTextColor: "#ffffff",
    modalIconColor: "#ffffff",

    tooltipBackgroundColor: "#8e8d8d",
    tooltipTextColor: "#ffffff",

    helperTextColor: "#756a6a",
    helperTextActiveColor: "#525ea2",

    avatarBackgroundColor: "#ffffff",
    avatarTextColor: "#525ea2",

    navbarBackgroundColor: "#ffffff",
    navbarBorderColor: "#dee2e6",
    navbarTextColor: "#756a6a",
    
    sidebarBackgroundColor: "#F5F5F5",
    sidebarBorderColor: "#ffffff",
    sidebarTextColor: "#8E8D8D;",
    sidebarHeaderBackgroundColor: "#525ea2",
    sidebarHeaderTextColor: "#ffffff",
    sidebarActiveBackgroundColor: "#756a6a",
    sidebarActiveBorderColor: "#525ea2",
    sidebarActiveTextColor: "#525ea2",

    statsCardTextColor: "#756a6a",
    statsCardTitleColor: "#525ea2",
    statsCardBorderColor: "#525ea2",
    statsCardBackgroundColor: "#ffffff",
    statsCardIconColor: "#8E8D8D",

    tabsBackgroundColor: "#ffffff",
    tabsBorderColor: "#525ea2",
    tabsTextColor: "#756a6a",
    tabsActiveBackgroundColor: "#ffffff",
    tabsActiveBorderColor: "#525ea2",
    tabsActiveTextColor: "#525ea2",


    adItemBackgroundColor: "#ffffff",
    adItemTextColor: "#000000",
    adItemActiveTextColor: "#525ea2",
    adItemIconColor: "#525ea2",


    postCardTextColor: "#6d6d6d",
    postCardBackgroundColor: "#f5f5f5",
    postCardBorderColor: "#f5f5f5",

    datepickerTextColor: "#8E8D8D",
    datepickerBackgroundColor: "#ffffff",
    datepickerBorderColor: "transparent",
    datepickerActiveTextColor: "#525ea2",
    datepickerActiveIndicatorColor: "#525ea2",

    swiperTextColor: "#6d6d6d",
    swiperActiveTextColor: "#525ea2",
    swiperBackgroundColor: "#ffffff",
    swiperActiveIndicatorColor: "#525ea2",

    dataTableTextColor: "#6d6d6d",
    dataTableActiveTextColor: "#525ea2",
    dataTableBackgroundColor: "#ffffff",
    dataTableActiveBackgroundColor: "#f5f5f5",

    spinnerColor: "#525ea2",

    formControlTextColor: '#6D6D6D',
    formControlActiveTextColor: '#525ea2',
    formControlBackgroundColor: '#F5F5F5',
    formControlBorderColor: '#F5F5F5',


    badgeTextColor: "#ffffff",
    badgeBackgroundColor: "#756a6a",
    badgeBackgroundActiveColor: "#525ea2",

    breadcrumbTextColor: "#756a6a",
    breadcrumbActiveTextColor: "#525ea2",

    titleTextColor: "#515151",
    subtitleTextColor: "#8E8D8D",


    textColor: '#8E8D8D',
    iconColor: '#8E8D8D',
    backgroundColor: "#f5f5f5",
    borderColor: "#525ea2",
    activeTextColor: "#525ea2",
  },
  opacity: {
    low: 0.25,
    mid: 0.5,
    high: 0.75,
  }
}
