import { FC, forwardRef, MouseEventHandler, ForwardedRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import DotsGrid from 'mdi-react/DotsGridIcon';
import AccountCogOutline from 'mdi-react/AccountCogOutlineIcon';
import { APPS } from '../../../../global';
import { useAuth, useUtils } from '../../../../utils/hooks';
import { Broker, Investor, Landlord, Rent } from '../../../assets/icons';
import { StyledDropdownItem, StyledMenuButton } from '../../../common';
import { Application } from '../../../../global/interfaces';

export interface CustomToggleProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const theme = useTheme();
    return (
      <StyledMenuButton
        ref={ref}
        onClick={onClick}
        size="sm"
        className="border-0 p-2 rounded-circle"
        id="dropdown-apps"
      >
        <DotsGrid size={26} color={theme.palette.iconColor} />
      </StyledMenuButton>
    )
  }
);

const AppsDropdown: FC = () => {
  const { apps, setCurrentApplication } = useAuth();
  const { getAppPath } = useUtils();

  const getAppIcon = (appName: string) => {
    switch (appName) {
      case APPS.INV: return (<Investor size={24} />);
      case APPS.ARR: return (<Landlord size={24} />);
      case APPS.COR: return (<Broker size={24} />);
      case APPS.PLA: return (<Broker size={24} />);
      case APPS.GES: return (<AccountCogOutline size={24} />);
      default: return (<Rent size={24} />);
    };
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu className="rounded-0 shadow-sm py-0" id="apps-list">
        {apps.map(({ name, code }: Application) => (
          <StyledDropdownItem
            className="dropdown-item p-0"
            key={code}
            onClick={() => {
              setCurrentApplication({ name, code });
              localStorage.setItem('currentApp', JSON.stringify({ name, code }));
            }}
          >
            <NavLink to={getAppPath(code)} className="w-100 d-flex gap-2 align-items-center p-3">
              {getAppIcon(code)}
              {name}
            </NavLink>
          </StyledDropdownItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>

  );
};

export default AppsDropdown;