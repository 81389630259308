import {
  Button,
  Dropdown,
  Form,
  Alert,
  Modal,
  Tooltip,
  Card,
  ListGroup,
  Tabs,
  Row,
  Badge,
  Image,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import chroma from 'chroma-js';

interface StyledValidations {
  isValid?: any;
  hasError?: any;
  isFilled?: any;
  src?: string;
  width?: string;
  height?: string;
  bgColor?: string;
  textColor?: string;
}

export const StyledSubmitButton = styled(Button)<StyledValidations>`
  background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.buttonActiveBackgroundColor : props.theme.palette.buttonBackgroundColor};
  border: solid 1px ${(props: any) => props.theme.palette.buttonBorderColor};
  color: ${(props: any) => (props.$isValid) ? props.theme.palette.buttonTextColor : props.theme.palette.buttonActiveBackgroundColor};

  &:hover {
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    background-color: ${(props: any) => props.theme.palette.buttonActiveBackgroundColor};
    border: solid 1px ${(props: any) => props.theme.palette.buttonBorderColor};
  }
  &:disabled {
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    background-color: ${(props: any) => props.theme.palette.buttonDisabledBackgroundColor};
    border: solid 1px ${(props: any) => props.theme.palette.buttonDisabledBackgroundColor};
  }
  &:focus, &:active, &.active, &:active:focus {
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    background-color: ${(props: any) => props.theme.palette.buttonActiveBackgroundColor};
    border: solid 1px ${(props: any) => props.theme.palette.buttonBorderColor};
    box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(props.theme.opacity.low).css()};
  }

  &.primary {
    background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.primary.main : props.theme.palette.primary.contrastText};
    border: solid 1px ${(props: any) => props.theme.palette.primary.main};
    color: ${(props: any) => (props.$isValid) ? props.theme.palette.primary.contrastText : props.theme.palette.primary.main};

    &:hover {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.light};
      border: solid 1px ${(props: any) => props.theme.palette.primary.light};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.dark).alpha(props.theme.opacity.low).css()};
    }

    &-outline {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.primary.main : props.theme.palette.primary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.primary.contrastText : props.theme.palette.primary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.main};
        border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.main};
        border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.main};
        border: solid 1px ${(props: any) => props.theme.palette.primary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.primary.light : props.theme.palette.primary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.primary.contrastText : props.theme.palette.primary.light};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &.filled {
      background-color: ${(props: any) => props.theme.palette.primary.main};
      border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      color: ${(props: any) => props.theme.palette.primary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.secondary {
    background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.secondary?.main : props.theme.palette.secondary?.contrastText};
    border: solid 1px ${(props: any) => props.theme.palette.secondary?.main};
    color: ${(props: any) => (props.$isValid) ? props.theme.palette.secondary?.contrastText : props.theme.palette.secondary?.main};

    &:hover {
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.secondary?.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary?.light};
      border: solid 1px ${(props: any) => props.theme.palette.secondary?.light};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.secondary?.main};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary?.main).alpha(props.theme.opacity.low).css()};
    }

    &-outline {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.secondary.main : props.theme.palette.secondary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.secondary.contrastText : props.theme.palette.secondary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.main};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.main};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.main};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.secondary.light : props.theme.palette.secondary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.secondary.contrastText : props.theme.palette.secondary.light};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &.filled {
      background-color: ${(props: any) => props.theme.palette.secondary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.secondary?.main};
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary?.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary?.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary?.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary?.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary?.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary?.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary?.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.tertary {
    background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.tertary?.main : props.theme.palette.tertary?.contrastText};
    border: solid 1px ${(props: any) => props.theme.palette.tertary?.main};
    color: ${(props: any) => (props.$isValid) ? props.theme.palette.tertary?.contrastText : props.theme.palette.tertary?.main};

    &:hover {
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.tertary?.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary?.light};
      border: solid 1px ${(props: any) => props.theme.palette.tertary?.light};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.tertary?.main};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary?.main).alpha(props.theme.opacity.low).css()};
    }

    &-outline {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.tertary.main : props.theme.palette.tertary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.tertary.contrastText : props.theme.palette.tertary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.main};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.main};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.main};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.tertary.light : props.theme.palette.tertary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.tertary.contrastText : props.theme.palette.tertary.light};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &.filled {
      background-color: ${(props: any) => props.theme.palette.tertary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.tertary?.main};
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary?.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary?.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary?.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary?.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary?.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary?.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary?.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.quaternary {
    background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.quaternary?.main : props.theme.palette.quaternary?.contrastText};
    border: solid 1px ${(props: any) => props.theme.palette.quaternary?.main};
    color: ${(props: any) => (props.$isValid) ? props.theme.palette.quaternary?.contrastText : props.theme.palette.quaternary?.main};

    &:hover {
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary?.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary?.light};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary?.light};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary?.main};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary?.main).alpha(props.theme.opacity.low).css()};
    }

    &-outline {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.quaternary.main : props.theme.palette.quaternary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.quaternary.contrastText : props.theme.palette.quaternary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.main};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.main};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.main};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => (props.$isValid || props.$isFilled) ? props.theme.palette.quaternary.light : props.theme.palette.quaternary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      color: ${(props: any) => (props.$isValid) ? props.theme.palette.quaternary.contrastText : props.theme.palette.quaternary.light};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &.filled {
      background-color: ${(props: any) => props.theme.palette.quaternary?.main};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary?.main};
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary?.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary?.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary?.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary?.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary?.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }
`

export const StyledInput = styled(Form.Control)<StyledValidations>`
  color: ${(props: any) => props.theme.palette.inputTextColor};
  border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  resize: none;

  &:focus {
    border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
    box-shadow: none;
  }

  &:invalid, &.is-invalid {
    border-color: ${(props: any) => props.theme.palette.inputInvalidBorderColor};
    background-image: none;

    &:focus {
      border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputInvalidBorderColor : props.theme.palette.inputInactiveBorderColor};
      box-shadow: none;
    }

    &::placeholder {
      color: ${(props: any) => props.theme.palette.inputTextColor};
    }

    &.with-error {
      background-color:  ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
      color: ${(props: any) => props.theme.palette.inputBackgroundColor};

      &::placeholder {
        color: ${(props: any) => props.theme.palette.inputBackgroundColor};
      }
    }
  }
`;

export const StyledInputGroupText = styled(InputGroup.Text)<StyledValidations>`
  color: ${(props: any) => props.theme.palette.inputTextColor};
  border-color: ${(props: any) => (props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor} !important;

  &.with-error {
    background-color:  ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
    border-color:  ${(props: any) => props.theme.palette.inputInvalidBackgroundColor} !important;
    color: ${(props: any) => props.theme.palette.inputBackgroundColor};

  }
 `

export const StyledSelect = styled(Form.Select)<StyledValidations>`
  color: ${(props: any) => props.theme.palette.inputTextColor};
  border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};

  &.form-select {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e) !important;
  }

  & option {
    color: ${(props: any) => props.theme.palette.inputTextColor};

    &::selected {
      color: #ffffff;
    }
  }

  &:focus {
    border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
    box-shadow: none;
  }

  &:invalid {
    border-color: ${(props: any) => props.theme.palette.inputActiveBorderColor};
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;

    &:focus {
      border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
      box-shadow: none;
    }

    &::placeholder {
      color: ${(props: any) => props.theme.palette.inputTextColor};
    }
  }

  &.is-invalid {
    border-color: ${(props: any) => props.theme.palette.inputActiveBorderColor};
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;


    &:focus {
      border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
      box-shadow: none;
    }

    &::placeholder {
      color: ${(props: any) => props.theme.palette.inputTextColor};
    }

    &.with-error {
      background-color:  ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
      color: ${(props: any) => props.theme.palette.inputBackgroundColor};
      border-color: ${(props: any) => props.theme.palette.inputBackgroundColor};

      &::placeholder {
        color: ${(props: any) => props.theme.palette.inputBackgroundColor};
      }
    }
  }

  & option {
    border-radius: 0;
  }
`;

export const StyledAddon = styled.span<StyledValidations>`
  border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  background-color: ${(props: any) => props.theme.palette.inputAddonBackgroundColor};
  color:  ${(props: any) => props.theme.palette.inputAddonTextColor};

  &.with-error {
    background-color:  ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
    color: ${(props: any) => props.theme.palette.inputAddonActiveTextColor};
    border-color: ${(props: any) => props.theme.palette.inputInvalidBorderColor};
  }
`

export const StyledPasswordButton = styled.button<StyledValidations>`
  border: solid 1px ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  background-color: ${(props: any) => props.theme.palette.inputBackgroundColor};
  color:  ${(props: any) => props.theme.palette.inputTextColor};

  &:focus {
    color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputTextColor : props.theme.palette.inputAddonActiveTextColor};
    border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  }
  &:hover {
    color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputTextColor : props.theme.palette.inputAddonActiveTextColor};
    border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  }
  &:active {
    color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputTextColor : props.theme.palette.inputAddonActiveTextColor};
    border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  }

  &:active:focus {
    color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputTextColor : props.theme.palette.inputAddonActiveTextColor};
    border-color: ${(props: any) => (props.hasError || props.isFilled) ? props.theme.palette.inputActiveBorderColor : props.theme.palette.inputInactiveBorderColor};
  }

  &.with-error {
    background-color:  ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    border: ${(props: any) => props.theme.palette.inputInvalidBorderColor};

    &:focus {
      color: ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
      
    }
    &:hover {
      color: ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
    }

    &:active {
      color: ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
    }

    &:active:focus {
      background-color:  ${(props: any) => props.theme.palette.inputAddonActiveBackgroundColor};
      color: ${(props: any) => props.theme.palette.inputInvalidBackgroundColor};
    }
  }
`

export const StyledInvalidFeedback = styled.div`
  color: ${(props: any) => props.theme.palette.inputInvalidTextColor};
`;

export const StyledHeader = styled.header`
  background-color: ${(props: any) => props.theme.palette.backgroundColor};
  z-index: 10;
  @media (max-width: 600px) {
    position: sticky;
    top: 0;
  }
`;

export const StyledMain = styled.main`
  max-height: calc(100vh - 67px);
  overflow-y: auto;
`;

export const StyledCheckbox = styled.input<StyledValidations>`
&.form-check-input {
  &:checked {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23${(props: any) => props.theme.palette.checkBoxTextColor.substring(1)}%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
    background-color: ${(props: any) => props.theme.palette.checkBoxBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.checkBoxBorderColor};
    color: ${(props: any) => props.theme.palette.checkBoxTextColor};
  }

  &:focus {
    border-color: ${(props: any) => props.theme.palette.checkBoxBorderColor};
    outline: 0;
    box-shadow: none;
  }

  & + .form-check-label {
    color: ${(props: any) => props.theme.palette.checkBoxTextColor};
  }
}
`

export const StyledMenuButton = styled(Button)`
  color: ${(props: any) => props.theme.palette.buttonIconColor};
  background-color: ${(props: any) => props.theme.palette.buttonBackgroundColor};
  border: none;

  &:focus {
    color: ${(props: any) => props.theme.palette.buttonIconActiveColor};
    background: ${(props: any) => props.theme.palette.buttonActiveBackgroundColor};
    background-color: ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(0.05).css()};
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
    & #notification-icon {
      transform: rotate(30deg);
    }
  }
  
  &:hover {
    color: ${(props: any) => props.theme.palette.buttonIconActiveColor};
    background-color: ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(0.05).css()};
  }

  &:active {
    color: ${(props: any) => props.theme.palette.buttonIconActiveColor};
    background-color: ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(0.05).css()};

    & #notification-icon {
      transform: rotate(30deg);
    }
  }

  &.active {
    color: ${(props: any) => props.theme.palette.buttonIconActiveColor};
    background-color: ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(0.05).css()};

    & #notification-icon {
      transform: rotate(30deg);
    }
  }

  &:active:focus {
    color: ${(props: any) => props.theme.palette.buttonIconActiveColor};
    background-color: ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(0.05).css()};
  }

  &.primary {
    color: ${(props: any) => props.theme.palette.primary.contrastText} !important;
    background-color: ${(props: any) => props.theme.palette.primary.main};
    border: ${(props: any) => props.theme.palette.primary.main};

    &:focus {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }

    &:hover {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
    }

    &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
    }
  }

  &.secondary {
    color: ${(props: any) => props.theme.palette.secondary?.contrastText} !important;
    background-color: ${(props: any) => props.theme.palette.secondary?.main};
    border: ${(props: any) => props.theme.palette.secondary?.main};

    &:focus {
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary?.dark};
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }

    &:hover {
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary?.dark};
    }

    &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.secondary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary?.dark};
    }
  }

  &.tertary {
    color: ${(props: any) => props.theme.palette.tertary?.contrastText} !important;
    background-color: ${(props: any) => props.theme.palette.tertary?.main};
    border: ${(props: any) => props.theme.palette.tertary?.main};

    &:focus {
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary?.dark};
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }

    &:hover {
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary?.dark};
    }

    &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.tertary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary?.dark};
    }
  }

  &.quaternary {
    color: ${(props: any) => props.theme.palette.quaternary?.contrastText} !important;
    background-color: ${(props: any) => props.theme.palette.quaternary?.main};
    border: ${(props: any) => props.theme.palette.quaternary?.main};

    &:focus {
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }

    &:hover {
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
    }

    &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
    }
  }
`

export const StyledGuestMenuButton = styled.div`
  border-color: ${(props: any) => props.theme.palette.avatarTextColor};

  &:hover {
    & .avatar {
      transition: ease 0.3s;
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.avatarTextColor).alpha(props.theme.opacity.low).css()};
    }
  }

  &.primary {
    border-color: ${(props: any) => props.theme.palette.primary.main};

    &:hover {
      & .avatar {
        transition: ease 0.3s;
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.main).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.secondary {
    border-color: ${(props: any) => props.theme.palette.secondary?.main};

    &:hover {
      & .avatar {
        transition: ease 0.3s;
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary?.main).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.tertary {
    border-color: ${(props: any) => props.theme.palette.tertary?.main};

    &:hover {
      & .avatar {
        transition: ease 0.3s;
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary?.main).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.quaternary {
    border-color: ${(props: any) => props.theme.palette.quaternary?.main};

    &:hover {
      & .avatar {
        transition: ease 0.3s;
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary?.main).alpha(props.theme.opacity.low).css()};
      }
    }
  }
`

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  @media (max-width: 600px) {
    inset: 0px auto auto -5px !important;
  }
`

export const StyledDropdownItem = styled(Dropdown.Item)`
  color: ${(props: any) => props.theme.palette.dropdownTextColor};
  border-bottom: solid 1px ${(props: any) => props.theme.palette.dropdownBorderColor};

  & a {
    color: ${(props: any) => props.theme.palette.dropdownTextColor};
    border-bottom: solid 1px ${(props: any) => props.theme.palette.dropdownBorderColor};
    text-decoration: none;
  }

  &:last-child, a:last-child {
    border: none;
  }

  &.border-bottom, a.border-bottom {
    border-color: ${(props: any) => props.theme.palette.dropdownBorderColor} !important;

  }
  
  &:hover, a:hover {
    background-color: ${(props: any) => chroma(props.theme.palette.dropdownActiveBackgroundColor).alpha(0.05).css()};
    color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};
    text-decoration: none;
    &.no-hover {
      background-color: ${(props: any) => props.theme.palette.dropdownInactiveBackgroundColor};
    }
  }

  &:active, a:active {
    background-color: ${(props: any) => chroma(props.theme.palette.dropdownActiveBackgroundColor).alpha(0.05).css()};
    color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};
    text-decoration: none;
    &.no-hover {
      background-color: ${(props: any) => props.theme.palette.dropdownInactiveBackgroundColor};
    }
  }

  &.active, a.active {
    background-color: ${(props: any) => chroma(props.theme.palette.dropdownActiveBackgroundColor).alpha(0.10).css()};
    color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};
    text-decoration: none;
    &.no-hover {
      background-color: ${(props: any) => props.theme.palette.dropdownInactiveBackgroundColor};
    }
  }
`;

export const StyledNotificationItem = styled(Dropdown.Item)`
  color: ${(props: any) => props.theme.palette.notificationDropdownTextColor};
  background-color: ${(props: any) => props.theme.palette.notificationDropdownBackgroundColor};

  & .read-indicator {
    fill: ${(props: any) => props.theme.palette.notificationDropdownTextColor};
  }

  &:hover {
    background-color: ${(props: any) => chroma(props.theme.palette.notificationDropdownActiveBackgroundColor).alpha(0.05).css()};
    color: ${(props: any) => props.theme.palette.notificationDropdownActiveTextColor};

    &.no-hover {
      background-color: ${(props: any) => props.theme.palette.notificationDropdownInactiveBackgroundColor};
    }
  }

  &:active, &.active {
    background-color: ${(props: any) => chroma(props.theme.palette.notificationDropdownActiveBackgroundColor).alpha(0.10).css()};
    color: ${(props: any) => props.theme.palette.notificationDropdownActiveTextColor};

    &.no-hover {
      background-color: ${(props: any) => props.theme.palette.notificationDropdownInactiveBackgroundColor};
    }
  }

  &.unread {
    background-color: ${(props: any) => chroma(props.theme.palette.notificationDropdownActiveBackgroundColor).alpha(0.10).css()};
    color: ${(props: any) => props.theme.palette.notificationDropdownActiveTextColor};

    &:hover {
      background-color: ${(props: any) => chroma(props.theme.palette.notificationDropdownActiveBackgroundColor).alpha(props.theme.opacity.low).css()};
    }

    & .read-indicator {
      fill: ${(props: any) => props.theme.palette.notificationDropdownActiveIconColor};
    }
  }

  & .notlink {
    text-decoration: none;
    color: inherit;
  }
`;

export const StyledNotificactionBottomOption = styled(ListGroup.Item)`
  color: ${(props: any) => props.theme.palette.notificationBottomOptionTextColor};
  background-color: ${(props: any) => props.theme.palette.notificationBottomOptionBackgroundColor};

  &:hover {
    color: ${(props: any) => props.theme.palette.notificationBottomOptionActiveTextColor};
    background-color: ${(props: any) => props.theme.palette.notificationBottomOptionActiveBackgroundColor};
  }
`

export const StyledDropdownDivider = styled(Dropdown.Divider)`
  border-color: ${(props: any) => props.theme.palette.dropdownBorderColor};
  margin: 0;
`

export const StyledSwitch = styled(Form.Check)`
& .form-check-input {
  background-color: ${(props: any) => props.theme.palette.switchBackgroundColor};
  border-color: ${(props: any) => props.theme.palette.switchBorderColor};
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23${(props: any) => props.theme.palette.switchTextColor.substring(1)}%27/%3e%3c/svg%3e');

  &:checked {
    background-color: ${(props: any) => props.theme.palette.switchActiveBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.switchActiveBorderColor};
  }

  &:focus {
    border-color: ${(props: any) => props.theme.palette.switchActiveBorderColor};
    outline: 0;
    box-shadow: none;
  }

  &:focus {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23${(props: any) => props.theme.palette.switchTextColor.substring(1)}%27/%3e%3c/svg%3e');
  }

  &:checked {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23${(props: any) => props.theme.palette.switchTextColor.substring(1)}%27/%3e%3c/svg%3e');
  }
}
`

export const StyledCard = styled(Card)`
  color: ${(props: any) => props.theme.palette.cardTextColor};
  border-color: transparent;
  background-color: ${(props: any) => props.theme.palette.cardBackgroundColor};

  & .list-group {
    background-color: ${(props: any) => props.theme.palette.cardBackgroundColor};
  }

  & .list-group-item {
    color: ${(props: any) => props.theme.palette.cardTextColor};
    background-color: ${(props: any) => props.theme.palette.cardBackgroundColor};
  }

  &.primary {
    color: ${(props: any) => props.theme.palette.primary.main};
    background-color: ${(props: any) => props.theme.palette.contrastText};

    & .list-group {
      background-color: ${(props: any) => props.theme.palette.primary.contrastText};
    }
  
    & .list-group-item {
      color: ${(props: any) => props.theme.palette.primary.main};
      background-color: ${(props: any) => props.theme.palette.primary.contrastText};
    }
  }
`

export const StyledTypography = styled.p`
  color: ${(props: any) => props.theme.palette.globalTextColor};
`

export const StyledActionButton = styled(Button)`
  color: ${(props: any) => props.theme.palette.actionButtonTextColor};
  background-color: ${(props: any) => props.theme.palette.actionButtonBackgroundColor};
  border-color: ${(props: any) => props.theme.palette.actionButtonBorderColor};

  &:hover {
    background-color: ${(props: any) => props.theme.palette.actionButtonActiveBackgroundColor};
    color: ${(props: any) => props.theme.palette.actionButtonActiveTextColor};
    border-color: ${(props: any) => props.theme.palette.actionButtonBorderColor};
  }

  &:focus {
    color: ${(props: any) => props.theme.palette.actionButtonActiveTextColor};
    background-color: ${(props: any) => props.theme.palette.actionButtonActiveBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.actionButtonBorderColor};
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }

  &:active {
    color: ${(props: any) => props.theme.palette.actionButtonActiveTextColor};
    background-color: ${(props: any) => props.theme.palette.actionButtonActiveBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.actionButtonBorderColor};
  }

  &.active {
    color: ${(props: any) => props.theme.palette.actionButtonActiveTextColor};
    background-color: ${(props: any) => props.theme.palette.actionButtonActiveBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.actionButtonBorderColor};
  }

  &:active:focus {
    color: ${(props: any) => props.theme.palette.actionButtonActiveTextColor};
    background-color: ${(props: any) => props.theme.palette.actionButtonActiveBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.actionButtonBorderColor};
  }
`

export const StyledAlert = styled(Alert)`
  border-radius: 0px;
  border-color: ${(props: any) => props.theme.palette.alertBorderColor};
  background-color: ${(props: any) => props.theme.palette.alertBackgroundColor};
  color: ${(props: any) => props.theme.palette.alertTextColor};
  font-style: italic;
  padding: .5rem;
  line-height: 18px;

  &.primary {
    border-color: ${(props: any) => props.theme.palette.primary.main};
    background-color: ${(props: any) => props.theme.palette.primary.main};
    color: ${(props: any) => props.theme.palette.primary.contrastText};

    &-light {
      border-color: ${(props: any) => props.theme.palette.primary.light};
      background-color: ${(props: any) => props.theme.palette.primary.light};
      color: ${(props: any) => props.theme.palette.primary.contrastText};
    }

    &-dark {
      border-color: ${(props: any) => props.theme.palette.primary.dark};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      color: ${(props: any) => props.theme.palette.primary.contrastText};
    }
  }

  &.secondary {
    border-color: ${(props: any) => props.theme.palette.secondary?.main};
    background-color: ${(props: any) => props.theme.palette.secondary?.main};
    color: ${(props: any) => props.theme.palette.secondary?.contrastText};

    &-light {
      border-color: ${(props: any) => props.theme.palette.secondary.light};
      background-color: ${(props: any) => props.theme.palette.secondary.light};
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
    }

    &-dark {
      border-color: ${(props: any) => props.theme.palette.secondary.dark};
      background-color: ${(props: any) => props.theme.palette.secondary.dark};
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
    }
  }

  &.tertary {
    border-color: ${(props: any) => props.theme.palette.tertary?.main};
    background-color: ${(props: any) => props.theme.palette.tertary?.main};
    color: ${(props: any) => props.theme.palette.tertary?.contrastText};

    &-light {
      border-color: ${(props: any) => props.theme.palette.tertary.light};
      background-color: ${(props: any) => props.theme.palette.tertary.light};
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
    }

    &-dark {
      border-color: ${(props: any) => props.theme.palette.tertary.dark};
      background-color: ${(props: any) => props.theme.palette.tertary.dark};
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
    }
  }

  &.quaternary {
    border-color: ${(props: any) => props.theme.palette.quaternary?.main};
    background-color: ${(props: any) => props.theme.palette.quaternary?.main};
    color: ${(props: any) => props.theme.palette.quaternary?.contrastText};

    &-light {
      border-color: ${(props: any) => props.theme.palette.quaternary.light};
      background-color: ${(props: any) => props.theme.palette.quaternary.light};
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};
    }

    &-dark {
      border-color: ${(props: any) => props.theme.palette.quaternary.dark};
      background-color: ${(props: any) => props.theme.palette.quaternary.dark};
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};
    }
  }
`;

export const StyledModal = styled(Modal)`
  & .modal-content {
    border-color: ${(props: any) => props.theme.palette.modalBorderColor};
    border-color: ${(props: any) => props.theme.palette.modalBackgroundColor};


    &.primary {
      background-color: ${(props: any) => props.theme.palette.primary.main};
      border-color: ${(props: any) => props.theme.palette.primary.main};

      &-light {
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border-color: ${(props: any) => props.theme.palette.primary.light};
      }

      &-dark {
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border-color: ${(props: any) => props.theme.palette.primary.dark};
      }
    }

    &.secondary {
      background-color: ${(props: any) => props.theme.palette.secondary.main};
      border-color: ${(props: any) => props.theme.palette.secondary.main};

      &-light {
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border-color: ${(props: any) => props.theme.palette.secondary.light};
      }

      &-dark {
        background-color: ${(props: any) => props.theme.palette.secondary.dark};
        border-color: ${(props: any) => props.theme.palette.secondary.dark};
      }
    }

    &.tertary {
      background-color: ${(props: any) => props.theme.palette.tertary.main};
      border-color: ${(props: any) => props.theme.palette.tertary.main};

      &-light {
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border-color: ${(props: any) => props.theme.palette.tertary.light};
      }

      &-dark {
        background-color: ${(props: any) => props.theme.palette.tertary.dark};
        border-color: ${(props: any) => props.theme.palette.tertary.dark};
      }
    }

    &.quaternary {
      background-color: ${(props: any) => props.theme.palette.quaternary.main};
      border-color: ${(props: any) => props.theme.palette.quaternary.main};

      &-light {
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border-color: ${(props: any) => props.theme.palette.quaternary.light};
      }

      &-dark {
        background-color: ${(props: any) => props.theme.palette.quaternary.dark};
        border-color: ${(props: any) => props.theme.palette.quaternary.dark};
      }
    }
  }
  
  & .modal-body {
    color: ${(props: any) => props.theme.palette.modalTextColor};

    &.primary {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
    }

    &.secondary {
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
    }

    &.tertary {
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
    }
  }

  & .swiper-pagination {
    position: fixed;
    bottom: auto;

    & .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }

    & .swiper-pagination-bullet-active {
      background: ${(props: any) => props.theme.palette.modalBorderColor};
    }
  }

  @media (max-width: 600px) {
    & .modal-lg {
      width: 85%;
    }

    & .modal-sm {
      width: 65%;

    }

    & .modal-dialog-centered {
      margin: auto;
    }

    & .floating-btn {
      z-index: 10;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  &.show {
    opacity: 1;
  }

  & .tooltip-arrow::before {
    border-bottom-color: ${(props: any) => props.theme.palette.tooltipBackgroundColor};
  }

  & .tooltip-inner {
    max-width: none;
    text-align: left;
    color: ${(props: any) => props.theme.palette.tooltipTextColor};
    background-color: ${(props: any) => props.theme.palette.tooltipBackgroundColor};
  }

  &.primary {
    & .tooltip-arrow::before {
      border-bottom-color: ${(props: any) => props.theme.palette.primary.main};
    }
  
    & .tooltip-inner {
      max-width: none;
      text-align: left;
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.main};
    }
  }

  &.secondary {
    & .tooltip-arrow::before {
      border-bottom-color: ${(props: any) => props.theme.palette.secondary.main};
    }
  
    & .tooltip-inner {
      max-width: none;
      text-align: left;
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary.main};
    }
  }

  &.tertary {
    & .tooltip-arrow::before {
      border-bottom-color: ${(props: any) => props.theme.palette.tertary.main};
    }
  
    & .tooltip-inner {
      max-width: none;
      text-align: left;
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary.main};
    }
  }
`

export const StyledHelperText = styled.div`
  color: ${(props: any) => props.theme.palette.helperTextColor};

  &:hover {
    color: ${(props: any) => props.theme.palette.helperTextActiveColor};
  }
`

export const StyledAvatar = styled(Image)`

  &.border {

    &.primary {
      border-color: ${(props: any) => props.theme.palette.primary.main} !important;
    }

    &.secondary {
      border-color: ${(props: any) => props.theme.palette.secondary.main} !important;
    }

    &.tertary {
      border-color: ${(props: any) => props.theme.palette.tertary.main} !important;
    }

    &.quaternary {
      border-color: ${(props: any) => props.theme.palette.quaternary.main} !important;
    }
  }
`

export const StyledText = styled.p`
  color: ${(props: any) => props.theme.palette.textColor};

  &.title {
    color: ${(props: any) => props.theme.palette.titleTextColor};
  }

  &.subtitle {
    color: ${(props: any) => props.theme.palette.subtitleTextColor};
  }

  &.primary {
    color: ${(props: any) => props.theme.palette.primary.main};

    &-dark {
      color: ${(props: any) => props.theme.palette.primary.dark};
    }

    &-light {
      color: ${(props: any) => props.theme.palette.primary.light};
    }
  }

  &.secondary {
    color: ${(props: any) => props.theme.palette.secondary?.main};

    &-dark {
      color: ${(props: any) => props.theme.palette.secondary?.dark};
    }

    &-light {
      color: ${(props: any) => props.theme.palette.secondary?.light};
    }
  }

  &.tertary {
    color: ${(props: any) => props.theme.palette.tertary?.main};

    &-dark {
      color: ${(props: any) => props.theme.palette.tertary?.dark};
    }

    &-light {
      color: ${(props: any) => props.theme.palette.tertary?.light};
    }
  }

  &.quaternary {
    color: ${(props: any) => props.theme.palette.quaternary?.main};

    &-dark {
      color: ${(props: any) => props.theme.palette.quaternary?.dark};
    }

    &-light {
      color: ${(props: any) => props.theme.palette.quaternary?.light};
    }
  }

  &.active {
    color: ${(props: any) => props.theme.palette.activeTextColor};
  }

  &.grey {
    color: #8E8D8D;
  }

  &.text-justify {
    text-align: justify;
    text-justify: inter-word;
  }
`;

export const StyledBox = styled.div`
  background-color: ${(props: any) => props.theme.palette.backgroundColor};

  &.primary {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    color: ${(props: any) => props.theme.palette.primary.contrastText};

    &-alpha {
      background-color: ${(props: any) => chroma(props.theme.palette.primary.main).alpha(.10).css()};
      color: ${(props: any) => props.theme.palette.primary.main};
    }
  }

  &.secondary {
    background-color: ${(props: any) => props.theme.palette.secondary?.main};
    color: ${(props: any) => props.theme.palette.secondary.contrastText};

    &-alpha {
      background-color: ${(props: any) => chroma(props.theme.palette.secondary?.main).alpha(.10).css()};
      color: ${(props: any) => props.theme.palette.secondary?.main};
    }
  }

  &.tertary {
    background-color: ${(props: any) => props.theme.palette.tertary?.main};
    color: ${(props: any) => props.theme.palette.tertary?.contrastText};

    &-alpha {
      background-color: ${(props: any) => chroma(props.theme.palette.tertary?.main).alpha(.10).css()};
      color: ${(props: any) => props.theme.palette.tertary?.main};
    }
  }

  &.quaternary {
    background-color: ${(props: any) => props.theme.palette.quaternary?.main};
    color: ${(props: any) => props.theme.palette.quaternary.contrastText};

    &-alpha {
      background-color: ${(props: any) => chroma(props.theme.palette.quaternary.main).alpha(.10).css()};
      color: ${(props: any) => props.theme.palette.quaternary?.main};
    }
  }

  &.grey {
    background-color: #8E8D8D;
    color: #ffffff;

    &-alpha {
      background-color: ${(props: any) => chroma('#8E8D8D').alpha(.10).css()};
      color: #8E8D8D;
    }
  }

  &.default {
    background-color: #ffffff;
  }

  &.auth-box {
    min-height: 530px;
  }
`;

export const StyledTabs = styled(Tabs)`
  border-color: ${(props: any) => chroma(props.theme.palette.tabsBorderColor).alpha(props.theme.opacity.low).css()};

  &.nav-tabs {
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap;
 }
 &.nav-tabs .nav-link {
   white-space: nowrap;
 }

  & .nav-link {
    border: none;
    border-radius: 0px;
    background-color: ${(props: any) => props.theme.palette.tabsBackgroundColor};
    color: ${(props: any) => props.theme.palette.tabsTextColor};
    padding: .10rem 1.25rem;

    &.active {
      color: ${(props: any) => props.theme.palette.tabsActiveTextColor};
      border-bottom: solid 2px ${(props: any) => props.theme.palette.tabsActiveBorderColor};
      background-color: ${(props: any) => props.theme.palette.tabsActiveBackgroundColor};
      padding: .10rem 1.25rem;
    }
  }
`;

export const StyledTabList = styled.div`
  overflow-x: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  & .nav {
    border-color: ${(props: any) => chroma(props.theme.palette.tabsBorderColor).alpha(props.theme.opacity.low).css()};
  }

  &.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
 }

 &.nav-tabs .nav-link {
   white-space: nowrap;
 }

  & .nav-link {
    white-space: nowrap;
    border: none;
    border-radius: 0px;
    background-color: ${(props: any) => props.theme.palette.tabsBackgroundColor};
    color: ${(props: any) => props.theme.palette.tabsTextColor};
    padding: .10rem 1.25rem;

    &.active {
      white-space: nowrap;
      color: ${(props: any) => props.theme.palette.tabsActiveTextColor};
      border-bottom: solid 2px ${(props: any) => props.theme.palette.tabsActiveBorderColor};
      background-color: ${(props: any) => props.theme.palette.tabsActiveBackgroundColor};
      padding: .10rem 1.25rem;
    }
  }

  & .btn-tab {
    background-color: ${(props: any) => props.theme.palette.tabsBackgroundColor};
    color: ${(props: any) => props.theme.palette.tabsActiveBorderColor};
    fill: ${(props: any) => props.theme.palette.tabsActiveBorderColor};
    border-color: ${(props: any) => chroma(props.theme.palette.tabsBorderColor).alpha(props.theme.opacity.low).css()};
    z-index: 1;

    &:disabled {
      fill: ${(props: any) => chroma(props.theme.palette.tabsBorderColor).alpha(props.theme.opacity.low).css()};
      color: ${(props: any) => chroma(props.theme.palette.tabsBorderColor).alpha(props.theme.opacity.low).css()};
    }

    &.sticky-left {
      position: sticky;
      left: 0;
      border-left: solid 1px ${(props: any) => props.theme.palette.tabsBackgroundColor};
    }

    &.sticky-right {
      position: sticky;
      right: 0;
      border-right: solid 1px ${(props: any) => props.theme.palette.tabsBackgroundColor};
    }
  }
`;

export const StyledRow = styled(Row)`
  border-bottom: solid ${(props: any) => props.theme.palette.borderColor} 1px;

  @media (max-width: 600px) {
    border-bottom: none;
  }
`;

export const StyledAdImage = styled.div<StyledValidations>`
  background-image: url(${(props: any) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  min-height: 350px;

  @media (max-width: 600px) {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 0;
    min-height: 350px;
  }
`;

export const StyledAdItem = styled.div`
  & .ad-item-icon {
      fill: ${(props: any) => props.theme.palette.adItemIconColor};
      color: ${(props: any) => props.theme.palette.adItemIconColor};
  }

  & .remaining-time {
    color: ${(props: any) => props.theme.palette.adItemActiveTextColor};
  }

  & a.cta-link {
    color: ${(props: any) => props.theme.palette.adItemActiveTextColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledPublicationCard = styled(Card)`
  color: ${(props: any) => props.theme.palette.postCardTextColor};
  background-color: ${(props: any) => props.theme.palette.postCardBackgroundColor};
  border-color: ${(props: any) => props.theme.palette.postCardBorderColor};

  & .swiper-pagination {
    & .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }

    & .swiper-pagination-bullet-active {
      background: ${(props: any) => props.theme.palette.swiperActiveIndicatorColor};
    }

    &.swiper-pagination-fraction {
      min-width: 50px;
      max-width: 80px;
      position: sticky;
      margin: auto;
      border-radius: .5rem;
      background-color: ${(props: any) => props.theme.palette.swiperBackgroundColor};
      padding: .5rem;
      box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    }
  }

  & .prev, .next {
    z-index: 1;
    color: ${(props: any) => props.theme.palette.swiperActiveTextColor};
    fill: ${(props: any) => props.theme.palette.swiperActiveTextColor};

    &.swiper-button-disabled {
      color: ${(props: any) => props.theme.palette.swiperTextColor};
    }
  }
`

export const StyledImage = styled.div<StyledValidations>`
  background-image: url(${(props: any) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
  min-height: 250px;
  width: 100%;
`;

export const StyledPostImage = styled.img`
  inset: 0px;
  width: 0px;
  height: 0px;
  border: none;
  margin: auto;
  display: block;
  padding: 0px;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
  content-visibility: auto;

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    max-height: 250px;
  }
`;

export const StyledTooltipPicker = styled(Tooltip)`
  z-index: 3;

  & .rdp {
    --rdp-cell-size: 30px;
    --rdp-accent-color: ${(props: any) => props.theme.palette.datepickerActiveIndicatorColor};
    --rdp-background-color: ${(props: any) => props.theme.palette.datepickerBackgroundColor};
    --rdp-accent-color-dark: ${(props: any) => chroma(props.theme.palette.datepickerActiveIndicatorColor).brighten().css()};
    --rdp-background-color-dark: ${(props: any) => chroma(props.theme.palette.datepickerActiveIndicatorColor).darken().css()};
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    margin: .5rem;
    color: ${(props: any) => props.theme.palette.datepickerTextColor};

    &-day_outside {
      opacity: 1;
    }

    &-button[disabled]:not(.rdp-day_selected) {
      opacity: 1;
    }

    &-weeknumber, &-day {
      width: 26px;
      max-width: 26px;
      height: 26px;
      margin: 4px;
      font-size: 12px;
    }

    &-caption_label {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};
    }

    &-nav_button {
      &:hover {
        
      }
    }

    &-nav_icon {
      width: 10px;
      height: 10px;
    }

    &-tfoot {
      & p {
        margin-bottom: 0px;
        margin-top: .5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
      }
      
      & .mdi-icon {
        margin-right: .5rem;
      }
    }
  }
  
  &.show {
    opacity: 1;
  }

  & .tooltip-arrow::before {
    display: none;
  }

  & .tooltip-inner {
    max-width: none;
    text-align: left;
    color: ${(props: any) => props.theme.palette.datepickerTextColor};
    background-color: ${(props: any) => props.theme.palette.datepickerBackgroundColor};
  }
`

export const StyledDialog = styled(Modal)`
  & .modal-content {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      height: 400px;
    }
  }

  & .modal-dialog.w-80 {
    max-width: none;
    width: 80%;

    @media (max-width: 600px) {
      width: 90%;
      padding-top: 1.5rem;
      margin: auto;
    }
  }

  & .modal-body {
    color: ${(props: any) => props.theme.palette.modalTextColor};
  }

  @media (max-width: 600px) {
    & .modal-lg {
      width: 85%;
    }

    & .modal-sm {
      width: 70%;
      
    }

    & .modal-dialog-centered {
      margin: auto;
    }

    & .floating-btn {
      z-index: 10;
    }
  }

  & .rdp {
    --rdp-cell-size: 30px;
    --rdp-accent-color: ${(props: any) => props.theme.palette.datepickerActiveIndicatorColor};
    --rdp-background-color: ${(props: any) => props.theme.palette.datepickerBackgroundColor};
    --rdp-accent-color-dark: ${(props: any) => chroma(props.theme.palette.datepickerActiveIndicatorColor).brighten().css()};
    --rdp-background-color-dark: ${(props: any) => chroma(props.theme.palette.datepickerActiveIndicatorColor).darken().css()};
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    margin: .5rem;
    color: ${(props: any) => props.theme.palette.datepickerTextColor};

    &-day_outside {
      opacity: 1;
    }

    &-button[disabled]:not(.rdp-day_selected) {
      opacity: 1;
    }

    &-weeknumber, &-day {
      width: 26px;
      max-width: 26px;
      height: 26px;
      margin: 4px;
      font-size: 12px;
    }

    &-caption_label {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};
    }

    &-nav_button {
      &:hover {
        
      }
    }

    &-nav_icon {
      width: 10px;
      height: 10px;
    }

    &-tfoot {
      & p {
        margin-bottom: 0px;
        margin-top: .5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
      }
      
      & .mdi-icon {
        margin-right: .5rem;
      }
    }
  }
`;

export const StyledListItemButton = styled(ListGroup.Item)`
  background-color: transparent;
  color: ${(props: any) => props.theme.palette.textColor};
  fill: ${(props: any) => props.theme.palette.textColor};

  &:hover, a:hover {
    color: ${(props: any) => props.theme.palette.activeTextColor};
    fill: ${(props: any) => props.theme.palette.activeTextColor};

  }

  & a {
    color: ${(props: any) => props.theme.palette.textColor};
    text-decoration: none;
  }
`

export const StyledEmptyContainer = styled.div`
  min-height: 70vh;
`;

export const StyledFlexBox = styled.div<StyledValidations>`
  width: ${(props: any) => props.width}px;
  height: ${(props: any) => props.height}px;
  background-color: ${(props: any) => props.bgColor};
  color: ${(props: any) => props.textColor};
`;

export const StyledSearchBar = styled(Form.Group)`
  & input {
    border-color: #8E8D8D;
    text-align: center;
    color: #8E8D8D;

    &:is(:focus, &:focus-visible, :active, :hover, :autofill, :-webkit-autofill), :not(:placeholder-shown) {
      border-color: ${(props: any) => props.theme.palette.inputActiveBorderColor} !important;
      color: ${(props: any) => props.theme.palette.inputTextColor} !important;
      box-shadow: none;
      background-color: transparent !important;
      background-image: none !important;
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${(props: any) => props.theme.palette.inputTextColor} !important;
    }

    &:is(:focus, &:focus-visible) {
      &::placeholder {
        color: transparent;
      }
    }
  }

  & input::placeholder {
    text-align: center;
  }

  & button {
    color: #8E8D8D;
    background-color: transparent;
    &:hover, &:focus, &:active:focus {
      background-color: transparent;
    }

    & svg {
      fill: #8E8D8D;
    }
  }
`

export const StyledDatePickerWrapper = styled.div`
  & input {
    color: ${(props: any) => props.theme.palette.datepickerTextColor};
    min-height: 37px;
    border: solid 1px ${(props: any) => props.theme.palette.datepickerTextColor};
    padding: 6px 10px 5px 5px;

    &:is(:focus, :focus-visible, :hover, :active, :autofill, :-webkit-autofill), :not(:placeholder-shown) {
      border: solid 1px ${(props: any) => props.theme.palette.datepickerActiveTextColor};
      color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};
      box-shadow: none;
      outline: 0;
      background-color: transparent !important;
      background-image: none !important;
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${(props: any) => props.theme.palette.inputTextColor} !important;
    }
  }

  & .react-datepicker {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.075);
    border: none;
    border-radius: .5rem;
  }

  & .react-datepicker__calendar-icon {
    padding: 0.4rem;
    right: 10px;
    vertical-align: -0.125em;
    width: 24px;
    height: 24px;
    color: #8E8D8D;
  }

  & .react-datepicker__month-container {
    width: 100%;
  }

  & .react-datepicker__current-month {
    color: ${(props: any) => props.theme.palette.datepickerTextColor};
    text-transform: capitalize;
  }


  & .react-datepicker__header {
    background-color: ${(props: any) => props.theme.palette.datepickerBackgroundColor};
    color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};
  }

  & .react-datepicker__day {
    font-weight: normal;
    color: ${(props: any) => props.theme.palette.datepickerTextColor};
    text-transform: capitalize;

    &:hover {
      border-radius: 100%;
      background-color: ${(props: any) => chroma(props.theme.palette.datepickerActiveTextColor).alpha(.10).css()};;
      color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};
    }

    &--selected {
      border-radius: 100%;
      background-color: ${(props: any) => chroma(props.theme.palette.datepickerActiveTextColor).alpha(.10).css()};
      color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};

      &:hover {
        border-radius: 100%;
        background-color: ${(props: any) => chroma(props.theme.palette.datepickerActiveTextColor).alpha(.25).css()};
        color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};
      }
    }

    &--keyboard-selected {
      background-color: transparent;
      font-weight: normal;
    }

    &--outside-month, &--disabled {
      color: ${(props: any) => chroma(props.theme.palette.datepickerTextColor).alpha(.10).css()};
    }
  }

  & .react-datepicker__month {
    background-color: ${(props: any) => props.theme.palette.datepickerBackgroundColor};
    
    &-text {
      color: ${(props: any) => props.theme.palette.datepickerTextColor};
      margin: .25rem .75rem;
      text-transform: capitalize;

      &:hover {
        background-color: transparent;
      }

      &--keyboard-selected {
        background-color: transparent;
        font-weight: normal;
      }

      &--today {
        font-weight: normal;
      }


    }

    &--selected {
      background-color: transparent;
      color: ${(props: any) => props.theme.palette.datepickerActiveTextColor};

      &:hover {
        background-color: transparent;
      }
    }
  }

  & .react-datepicker__navigation {
    top: 10px;
  }
`;

export const StyledDropdownSelect = styled(Dropdown)`
  
  & .dropdown-toggle {
    background-color: ${(props: any) => props.theme.palette.dropdownBackgroundColor};
    color: ${(props: any) => props.theme.palette.dropdownTextColor};
    border: none;

    &:active {
      color: ${(props: any) => props.theme.palette.dropdownTextColor};
      background-color: ${(props: any) => props.theme.palette.dropdownBackgroundColor};
    }
  }

  & .form-check-input {
    &:checked {
      background-color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};
      border-color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};

    }
    &:focus, &:focus-visible {
      border-color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};
      outline: 0;
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.datepickerActiveTextColor).alpha(.25).css()};
    }
  }

  & .dropdown-item:not(.btn) {
    color: ${(props: any) => props.theme.palette.dropdownTextColor};
    max-width: 250px;

    &:hover {
      background-color: transparent;
      color: ${(props: any) => props.theme.palette.dropdownActiveTextColor};
    }
  }

  & .dropdown-menu {
    z-index: 9999;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: fit-content;
  }

`;

export const StyledInspectionTabs = styled(Tabs)`
  & .nav-link {
    color: ${(props: any) => props.theme.palette.dataTableTextColor};
    background-color: ${(props: any) => props.theme.palette.dataTableActiveBackgroundColor};

    &.active {
      color: ${(props: any) => props.theme.palette.dataTableActiveTextColor};
      background-color: ${(props: any) => props.theme.palette.dataTableActiveBackgroundColor};
      border-bottom: none;
    }
  }
`;


export const StyledFormGroup = styled(Form.Group)`
  & label.form-label {
    color: ${(props: any) => props.theme.palette.formControlTextColor};
  }

  & .form-control {
    background-color: ${(props: any) => props.theme.palette.formControlBackgroundColor};
    border-color: ${(props: any) => props.theme.palette.formControlBorderColor};
    color: ${(props: any) => props.theme.palette.formControlActiveTextColor};

    &::placeholder {
      color: ${(props: any) => props.theme.palette.formControlTextColor};
    }

    &:focus {
      border: none;
      outline: 0;
      box-shadow: none;
    }

    &:active {
      border: none;
      outline: 0;
      box-shadow: none;
    }
  }
`;

export const StyledBadge = styled(Badge)`
  font-size: 14px;
  color: ${(props: any) => props.theme.palette.badgeTextColor};
  background-color: ${(props: any) => props.theme.palette.badgeBackgroundActiveColor} !important;

  &.primary {
    background-color: ${(props: any) => chroma(props.theme.palette.primary.dark).alpha(.10).css()} !important;
    color: ${(props: any) => props.theme.palette.primary.main} !important;

    &.filled {
      background-color: ${(props: any) => props.theme.palette.primary.main} !important;
      color: ${(props: any) => props.theme.palette.primary.contrastText} !important;
    }

    &.outlined {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.primary.main} !important;
      border: 1px solid ${(props: any) => props.theme.palette.primary.main} !important;
    }

    &.text {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.primary.main} !important;
    }
  }

  &.secondary {
    background-color: ${(props: any) => chroma(props.theme.palette.secondary?.dark).alpha(.10).css()} !important;
    color: ${(props: any) => props.theme.palette.secondary?.main} !important;

    &.filled {
      background-color: ${(props: any) => props.theme.palette.secondary?.main} !important;
      color: ${(props: any) => props.theme.palette.secondary?.contrastText} !important;
    }

    &.outlined {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.secondary?.main} !important;
      border: 1px solid ${(props: any) => props.theme.palette.secondary?.main} !important;
    }

    &.text {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.secondary?.main} !important;
    }
  }

  &.tertary {
    background-color: ${(props: any) => chroma(props.theme.palette.tertary?.dark).alpha(.10).css()} !important;
    color: ${(props: any) => props.theme.palette.tertary?.main} !important;

    &.filled {
      background-color: ${(props: any) => props.theme.palette.tertary?.main} !important;
      color: ${(props: any) => props.theme.palette.tertary?.contrastText} !important;
    }

    &.outlined {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.tertary?.main} !important;
      border: 1px solid ${(props: any) => props.theme.palette.tertary?.main} !important;
    }

    &.text {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.tertary?.main} !important;
    }

  }

  &.quaternary {
    background-color: ${(props: any) => chroma(props.theme.palette.quaternary?.dark).alpha(.10).css()} !important;
    color: ${(props: any) => props.theme.palette.quaternary?.main} !important;

    &.filled {
      background-color: ${(props: any) => props.theme.palette.quaternary?.main} !important;
      color: ${(props: any) => props.theme.palette.quaternary?.contrastText} !important;
    }

    &.outlined {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.quaternary?.main} !important;
      border: 1px solid ${(props: any) => props.theme.palette.quaternary?.main} !important;
    }

    &.text {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.quaternary?.main} !important;
    }

  }

  &.default {
    background-color: ${(props: any) => chroma(props.theme.palette.badgeBackgroundColor).alpha(.10).css()} !important;
    color: ${(props: any) => props.theme.palette.badgeBackgroundColor} !important;

    &.filled {
      background-color: ${(props: any) => props.theme.palette.badgeBackgroundColor} !important;
      color: ${(props: any) => props.theme.palette.badgeTextColor} !important;
    }

    &.outlined {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.badgeBackgroundColor} !important;
      border: 1px solid ${(props: any) => props.theme.palette.badgeBackgroundColor} !important;
    }

    &.text {
      background-color: transparent !important;
      color: ${(props: any) => props.theme.palette.badgeBackgroundColor} !important;
    }
  }
`

export const StyledSpinner = styled.div`
  color: #525ea2;

  &.primary {
    color: #525ea2;
  }

  &.secondary {
    color: #8a4885;
  }

  &.tertary {
    color: #ed8735;
  }

  & .spinner-border {
    width: 3rem;
    height: 3rem;
  }
`;