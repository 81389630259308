import { FC, useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import MenuOpenIcon from 'mdi-react/MenuOpenIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import chroma from 'chroma-js';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useAuth, useWindowResize, useAuthorization } from '../../../../utils/hooks';
import { APPS } from '../../../../global';
import { AppRoute, investorRoutes, managementRoutes } from '.';

const StyledSidebar = styled(Sidebar)<{ minHeight?: number | string }>`
  z-index: 2;
  min-height: calc(100vh - 67px);
  height: 100%;
  background-color: ${(props: any) => props.theme.palette.sidebarBackgroundColor};
  color: ${(props: any) => props.theme.palette.sidebarTextColor};
  border-color: ${(props: any) => props.theme.palette.sidebarBorderColor};

  @media (max-width: 600px) {
    &.position-absolute {
      min-height: calc(${(props: any) => props.minHeight}px - 67px);
      height: auto;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  & a.ps-menu-button {
    padding: .5rem 2rem !important;
    height: auto;
    text-decoration: none;
    color: ${(props: any) => props.theme.palette.sidebarTextColor};

    & svg.side-icon {
      margin: 0px;
      width: 40px;
    }
  }

  & .ps-menu-icon {
    width: auto;
    min-width: auto;
    height: auto;
  }

  & a.ps-menu-button.active {
    text-decoration: none;
    color: ${(props: any) => props.theme.palette.sidebarActiveTextColor};
    background-color: ${(props: any) => chroma(props.theme.palette.sidebarActiveBackgroundColor).alpha(0.05).css()};
    border-right: solid 3px ${(props: any) => props.theme.palette.sidebarActiveBorderColor};
  }

  &.inv {
    & a.ps-menu-button.active {
      text-decoration: none;
      color: ${(props: any) => props.theme.palette.tertary.main};
      background-color: ${(props: any) => chroma(props.theme.palette.tertary.main).alpha(0.05).css()};
      border-right: solid 3px ${(props: any) => props.theme.palette.tertary.main};
    }
  }

  &.arr {
    & a.ps-menu-button.active {
      text-decoration: none;
      color: ${(props: any) => props.theme.palette.quaternary.main};
      background-color: ${(props: any) => chroma(props.theme.palette.quaternary.main).alpha(0.05).css()};
      border-right: solid 3px ${(props: any) => props.theme.palette.quaternary.main};
    }
  }

  @media (max-width: 600px) {
    & a.ps-menu-button {
      padding: .5rem 1rem !important;
    }
  }
`;

export const StyledSidebarMenu = styled(Menu)`
  padding-top: 15px;
`

const StyledHeader = styled.div<{ isCollapsed?: boolean }>`
  background-color: ${(props: any) => props.theme.palette.sidebarHeaderBackgroundColor};
  color: ${(props: any) => props.theme.palette.sidebarHeaderTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: any) => props.isCollapsed ? 'center' : 'space-between'};
  padding: ${(props: any) => props.isCollapsed ? '24px 10px' : '24px 46px'};
  &:hover {
    cursor: pointer;
  } 
`;

const smBreakpoint = 600;

const SideBar: FC = () => {
  const { currentApp } = useAuth();
  const { collapseSidebar, collapsed } = useProSidebar();
  const [width, height] = useWindowResize();
  const { checkAccess } = useAuthorization();
  const [bodyHeight, setBodyHeight] = useState<number>(0);

  new ResizeObserver((entry) => setBodyHeight(Math.ceil(entry[0].contentRect.height))).observe(document.body);

  const onCollapse = () => {
    collapseSidebar(!collapsed)
  };

  const onCloseToggle = (e: Event) => {
    if (e.type === 'touchstart') {
      collapseSidebar(true);
    }
  }

  const ref = useDetectClickOutside({ onTriggered: onCloseToggle });

  const getCurrentAppRoutes = (appCode: string): AppRoute[] => {
    switch (appCode) {
      case APPS.INV: return investorRoutes;
      case APPS.ARR: return [];
      case APPS.COR: return [];
      case APPS.PLA: return [];
      case APPS.GES: return managementRoutes;
      default: return [];
    };
  };

  return (
    <div className="">
      {currentApp && (
        <StyledSidebar
          defaultCollapsed
          collapsedWidth={(width <= smBreakpoint) ? '70px' : '100px'}
          width="270px"
          className={`${(width <= smBreakpoint) ? 'd-none' : 'd-block'}`}
          minHeight={(bodyHeight < height) ? height : bodyHeight}
          ref={ref}
        >
          <StyledHeader
            onClick={onCollapse}
            isCollapsed={collapsed}
          >
            {/* {getAppIcon(currentApp.code)} */}
            {!collapsed && (<span>Portal {currentApp.name}</span>)}
            {collapsed ? <MenuIcon size={30} /> : <MenuOpenIcon size={30} />}
          </StyledHeader>
          <StyledSidebarMenu>
            {getCurrentAppRoutes(currentApp.code).map(({ name, to, Icon, permission }: AppRoute) => (
              <Fragment key={to}>
                {checkAccess(permission) && (
                  <StyledMenuItem
                    component={<NavLink to={to} />}
                    className={`m-0 ${currentApp.code.toLowerCase()}`}
                    icon={<Icon className="side-icon" size={40} />}
                  >
                    <span>{name}</span>
                  </StyledMenuItem>
                )}
              </Fragment>
            ))}
          </StyledSidebarMenu>
        </StyledSidebar>
      )}
    </div>
  );
};

export default SideBar;