import { FC, useState, forwardRef, ForwardedRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTheme } from 'styled-components';
import { pdf } from '@react-pdf/renderer';
import DotsVertical from 'mdi-react/DotsVerticalIcon';
import { Helmet } from 'react-helmet-async';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { PERMISSIONS } from '../../../../global/permissions';
import { StyledDropdownSelect, StyledEmptyContainer, StyledMenuButton, StyledText } from '../../../common';
import RouterBreadcrumb from '../../../common/RouterBreadcrumb';
import { Inspection } from '../../../../global/interfaces';
import { datamainApi } from '../../../../apis/datamainApi';
import { useAuth, useAuthorization, useMessages } from '../../../../utils/hooks';
import Spinner from '../../../common/Spinner';
import { InspectionDetails, InspectionInfo, InvestorInspectionReport } from './components';
import { CustomToggleProps } from '../../../layout/app/components/AppsDropdown';
import "react-image-gallery/styles/css/image-gallery.css";
import PictureGallery from '../../../common/PictureGallery';
import { APPS } from '../../../../global';
import { PAGE_TITLE } from '../../../../global/urls';

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const theme = useTheme();
    return (
      <StyledMenuButton
        ref={ref}
        onClick={onClick}
        className="shadow-none border-0 p-2 rounded-circle"
        style={{ zIndex: 10 }}
      >
        <DotsVertical size={26} color={theme.palette.iconColor} />
      </StyledMenuButton>
    )
  }
);

const getData = async (id: number) => {
  try {
    const { data } = await datamainApi.get(`/inspection/tenants/${id}`);
    return data.inspection;
  }
  catch (e: any) {
    return e;
  }
};

interface ManagementInspectionDetailsPageProps { }

const ManagementInspectionDetailsPage: FC<ManagementInspectionDetailsPageProps> = () => {
  const { inspectionId } = useParams();
  const { currentApp } = useAuth();
  const { messages } = useMessages();
  const { checkAccess } = useAuthorization();
  const { title, sections } = messages.modules.management.inspections.sections.self_inspections.online_inspection;
  const { download_report } = messages.modules.management.inspections.sections.self_inspections.actions;
  const { pictures } = sections;
  const [inspection, setInspection] = useState<Inspection>({} as Inspection);

  const { isFetching, isSuccess } = useQuery<AxiosResponse, AxiosError>(['management/inspection'], async () => {
    return await datamainApi.get(`/inspection/tenants/${inspectionId}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setInspection(response.data.inspection);
      },
      onError: (error: AxiosError) => {
        setInspection({} as Inspection);
      }
    });

  const getCurrentPermission = () => {
    if (currentApp?.code === APPS.INV) return PERMISSIONS.INV_SERVI_47;
    return PERMISSIONS.GES_INSPE_33;
  }

  const downloadFile = async (fileName: string, data: { id: number, month: string, date: string }) => {
    const { id, month, date } = data;
    const response = await getData(id);
    const blob = await pdf(<InvestorInspectionReport data={response} month={month} date={date} />).toBlob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }

  return (
    <Authorization allowedRole={getCurrentPermission()}>
      {(currentApp?.code === APPS.INV) ? (
        <Helmet>
          <title>{PAGE_TITLE.INVESTOR_INSPECTION_DETAILS}</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>{PAGE_TITLE.MANAGEMENT_INSPECTION_DETAILS}</title>
        </Helmet>
      )}
      <Container fluid className="px-0 pt-md-5">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <RouterBreadcrumb />
          </Col>

          <Col xs={12} className="px-md-5 mb-4">
            <div className="w-100 d-flex flex-row justify-content-center align-items-center h-100 position-relative">
              <StyledText className="h2 title text-center m-0">{title}</StyledText>
              {(inspection && (checkAccess(PERMISSIONS.GES_INSPE_34) || checkAccess(PERMISSIONS.INV_SERVI_49))) && (
                <StyledDropdownSelect className="d-md-none position-absolute end-0">
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
                  <Dropdown.Menu className="w-100 rounded-2 border-0 shadow">
                    {/* {(checkAccess(PERMISSIONS.GES_INSPE_34)) && ( */}
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => downloadFile(`reporte-autoinspeccion-${inspection.rut}.pdf`, { id: parseInt(inspectionId!), month: inspection.inspection_month, date: inspection.inspection_date })}
                      as="button"
                    >
                      {download_report}
                    </Dropdown.Item>
                    {/* )} */}
                  </Dropdown.Menu>
                </StyledDropdownSelect>
              )}
            </div>
          </Col>
          {(inspection && (checkAccess(PERMISSIONS.GES_INSPE_34) || checkAccess(PERMISSIONS.INV_SERVI_49))) && (
            <Col xs={12} className="px-md-5 mb-4">
              <div className="w-100 d-flex flex-row justify-content-center align-items-center h-100 position-relative">
                <StyledText className="h4 title text-center text-capitalize m-0">{inspection.address}</StyledText>
                <StyledDropdownSelect className="d-none d-md-block position-absolute end-0">
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
                  <Dropdown.Menu className="w-100 rounded-2 border-0 shadow">
                    {/* {(checkAccess(PERMISSIONS.GES_INSPE_34)) && ( */}
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => downloadFile(`reporte-autoinspeccion-${inspection.rut}.pdf`, { id: parseInt(inspectionId!), month: inspection.inspection_month, date: inspection.inspection_date })}
                      as="button"
                    >
                      {download_report}
                    </Dropdown.Item>
                    {/* )} */}
                  </Dropdown.Menu>
                </StyledDropdownSelect>
              </div>
            </Col>
          )}
        </Row>
        {(isSuccess && inspection) && (
          <Row className="px-md-5">
            <Col xs={12} sm={12} md={12} lg={12} className="">
              <InspectionInfo inspection={inspection} />
              <InspectionDetails inspection={inspection} />
            </Col>
          </Row>
        )}
        {(isSuccess && inspection && inspection.images?.length !== 0) && (
          <Row className="justify-content-center px-md-5 pt-2">
            <Col xs={12}>
              <StyledText className="h2 mb-4 title text-center h-100">{pictures}</StyledText>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} className="bg-white rounded-4">
              <PictureGallery images={inspection.images || []} />
            </Col>
          </Row>
        )}
      </Container>
    </Authorization>
  );
};

export default ManagementInspectionDetailsPage;