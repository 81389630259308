import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import toast from 'react-hot-toast';
import { StyledBox, StyledSubmitButton, StyledText } from '../../../../common';
import { PUBLISHERS } from '../../../../../global';
import HomieRentLogo from '../../../../assets/images/homie-rent-logo.svg';
import PortalInmobiliarioLogo from '../../../../assets/images/portal-inmobiliario.svg';
import TocTocLogo from '../../../../assets/images/toctoc.svg';
import GoPlaceItLogo from '../../../../assets/images/goplaceit.svg';
import { useMessages, useWindowResize, } from '../../../../../utils/hooks';

interface PublicationItemProps {
  date: string;
  url?: string | null;
  name: string;
  keyName: string;
  total_views?: string;
}

const smBreakpoint = 665;

const PublicationItem: FC<PublicationItemProps> = ({ total_views, url, keyName, name }) => {
  const theme = useTheme();
  const [width] = useWindowResize();
  const { messages } = useMessages();
  const { see_publications, copy_link, see, copy } = messages.modules.investor.publications.actions;
  const { copied_successful, copied_failed } = messages.modules.investor.publications.feedbacks;
  const { publication_views } = messages.modules.investor.publications.sections.publication.fields;
  const { goplaceit } = messages.urls;

  const getImage = (keyName: string) => {
    switch (keyName) {
      case PUBLISHERS.RENT: return HomieRentLogo;
      case PUBLISHERS.HOMIE_RENT: return HomieRentLogo;
      case PUBLISHERS.PORTAL_INMOBILIARIO: return PortalInmobiliarioLogo;
      case PUBLISHERS.TOCTOC: return TocTocLogo;
      case PUBLISHERS.GOPLACEIT: return GoPlaceItLogo;
    }
  }

  const copyToClipboard = async () => {
    try {
      if (url && typeof url === 'string') {
        await navigator.clipboard.writeText(url as string);
        toast.success(copied_successful, {
          position: 'top-center',
          duration: 4000,
          icon: null,
        });
      }
    } catch (err) {
      toast.error(copied_failed, {
        position: 'top-center',
        duration: 4000,
        icon: null,
      });
    }
  }

  return (
    <StyledBox className="bg-white px-3 py-2 mb-3 rounded-4 w-100 shadow-none">
      <div className="mb-2">
        <img src={getImage(keyName)} width="100px" alt={name} />
      </div>
      {(total_views) && (
        <div className="w-100">
          <InformationOutline size={22} className="m-0 me-2" color={theme.palette.tertary?.main} />
          <StyledText className="tertary mb-2" as="span">{publication_views}: {total_views}</StyledText>
        </div>
      )}
      {(url || keyName !== 'goplaceit') ? (
        <div className="d-flex flex-row justify-content-center my-3 w-100">
          <Row className="w-100 justify-content-center">
            <Col xs={5} className="d-flex flex-row justify-content-end px-1">
              <StyledSubmitButton href={url} target="_blank" className="rounded-2 w-100 primary filled shadow-sm">
                {(width < smBreakpoint) ? see : see_publications}
              </StyledSubmitButton>
            </Col>
            <Col xs={5} className="d-flex flex-row justify-content-start px-1">
              <StyledSubmitButton className="rounded-2 w-100 primary-outline shadow-sm" onClick={copyToClipboard}>
                {(width < smBreakpoint) ? copy : copy_link}
              </StyledSubmitButton>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="d-flex flex-row justify-content-center my-3 w-100">
          <Row className="w-100 justify-content-center">
            <Col xs={5} className="d-flex flex-row justify-content-end px-1">
              <StyledSubmitButton href={goplaceit} target="_blank" className="rounded-2 w-100 primary filled shadow-sm">
                {(width < smBreakpoint) ? see : see_publications}
              </StyledSubmitButton>
            </Col>
            <Col xs={5} />
          </Row>
        </div>
      )}
    </StyledBox>
  );
};

export default PublicationItem;