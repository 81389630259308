export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const SERVER_ERROR = 500;

export enum ERROR_CODE {
  ERR01 = '01', // Closed by inactivity time
  ERR02 = '02', // Closed by admin (forced)
  ERR03 = '03', // Closed by user inactive
  ERR04 = '04', // License not found
  ERR05 = '05', // Role not found
  ERR06 = '06', // Closed by password changed
  ERR07 = '07', // Closed by user (closes all sessions)
  ERR08 = '08', // Token requires refresh
  ERR09 = '09', // Token not found
  ERR11 = '11', // User blocked
  ERR12 = '12', // Invalid credentials
}

export enum SESSION_ERROR {
  CLOSED_BY_INACTIVITY = '01',
  CLOSED_FORCED_BY_ADMIN = '02',
  CLOSED_BY_USER_INACTIVE = '03',
  CLOSED_BY_PASSWORD_CHANGED = '06',
  CLOSED_BY_USER_ALL = '07',
}

export enum AUTH_ERROR {
  LICENSES_NOT_FOUND = '04',
  ROLES_NOT_FOUND = '05',
  TOKEN_REQUIRES_REFRESH = '08',
  TOKEN_NOT_FOUND = '09',
  USER_BLOCKED = '11',
  INVALID_CREDENTIALS = '12',
}

export enum ERROR_MESSAGE {}
