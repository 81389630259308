import { FC, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import RouterBreadcrumb from '../../../common/RouterBreadcrumb';
import { StyledEmptyContainer } from '../../../common';
import Spinner from '../../../common/Spinner';
import { PropertyPublication } from '../../../../global/interfaces';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { PublicationDetails } from './components';
import { PAGE_TITLE } from '../../../../global/urls';


const InvestorPublicationDetailsPage: FC = () => {
  const { publicationId } = useParams();
  
  const [publication, setPublication] = useState<PropertyPublication>({} as PropertyPublication);
  const [totalPublications, setTotalPublications] = useState<number>(0);

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['investor/publication/id'], async () => {
    return await stackRentApi.get(`/publish/${publicationId}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setPublication(response.data.asset_data);
        setTotalPublications(response.data.total_publications)
      },
      onError: (error: AxiosError) => {
        setPublication({} as PropertyPublication);
        setTotalPublications(0)
      }
    });

    if (isFetching) {
      return (
        <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
          <Spinner />
        </StyledEmptyContainer>
      );
    }

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_16}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_PUBLICATION_DETAILS}</title>
      </Helmet>
      <Container className="px-0 pt-md-5">
        {(totalPublications > 1) && (
          <Row>
            <Col xs={12} sm={12} md={12} className="ps-md-5">
              <RouterBreadcrumb />
            </Col>
          </Row>
        )}
        {(publication && Object.entries(publication).length !== 0) && (
          <PublicationDetails publication={publication} />
        )}
      </Container>
    </Authorization>
  );
};

export default InvestorPublicationDetailsPage;