import { FC, useState, useMemo, ChangeEvent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { TableColumn } from 'react-data-table-component';
import { AxiosError, AxiosResponse } from 'axios';
import Magnify from 'mdi-react/MagnifyIcon';
import { Helmet } from 'react-helmet-async';
import { StyledBadge, StyledEmptyContainer, StyledText } from '../../../common';
import { useApp, useMessages, useUtils, useWindowResize } from '../../../../utils/hooks';
import { PropertyDetails, PropertyStats } from './components';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { PERMISSIONS } from '../../../../global/permissions';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { AssetDataProperty, Lease, Property } from '../../../../global/interfaces';
import Spinner from '../../../common/Spinner';
import Dialog from '../../../common/Dialog';
import DataGridTable from '../../../common/DataGridTable';
import PropertiesFilter from './components/PropertiesFilter';
import { STATUS_TYPE_VARIANT } from '../../../../global';
import Fab, { StyledFab } from '../../../common/Fab';
import { PAGE_TITLE } from '../../../../global/urls';

const smBreakpoint = 600;
const InvestorPropertiesPage: FC = () => {
  const { currentProperty, setCurrentProperty } = useApp();
  const [properties, setProperties] = useState<AssetDataProperty[]>([]);
  const [filterList, setFilterList] = useState<AssetDataProperty[]>([]);
  const [showProperties, setShowProperties] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const { messages } = useMessages();
  const [width] = useWindowResize();
  const { localFloatToString } = useUtils();
  const { information, table, actions } = messages.modules.investor.properties.sections;
  const { title } = information;
  const { columnsNames } = table;

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['investor/properties'], async () => {
    return await stackRentApi.get('/asset/me');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setProperties(response.data);
        setFilterList(response.data);
        if (response.data.length !== 0) {
          const [property] = response.data;
          setCurrentProperty(property.asset_data);
        }
        else {
          setCurrentProperty({} as Property);
          setProperties([]);
          setFilterList([]);
        }
      },
      onError: (error: AxiosError) => {
        setCurrentProperty({} as Property);
        setProperties([]);
        setFilterList([]);
      }
    });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      setFilterText('');
      setFilterList(properties)
    };

    const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFilterText(value);
      if (value.length > 0) {
        const filteredProperties = filterList.filter(({ asset_data: {
          asset_name,
          commune
        } }: AssetDataProperty) => (asset_name && asset_name.toLowerCase().includes(value.toLowerCase())) ||
          (commune.name && commune.name.toLowerCase().includes(value.toLowerCase()))
        );
        setFilterList(filteredProperties);
      }
      else {
        setFilterList(properties)
      }
    }

    return (
      <PropertiesFilter onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, properties, filterList]);

  const toggleShowProperties = () => {
    setShowProperties(!showProperties)
  }

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }

  const columns: TableColumn<AssetDataProperty>[] = [
    {
      name: columnsNames.address,
      selector: (row: AssetDataProperty) => row.asset_data.asset_name,
      center: true,
      grow: 1.5,
      sortable: true,
    },
    // {
    //   name: columnsNames.condominium,
    //   selector: (row: AssetDataProperty) => row.asset_data.condominium.name,
    //   center: true
    // },
    {
      name: columnsNames.commune,
      selector: (row: AssetDataProperty) => row.asset_data.commune.name,
      center: true,
      grow: 1,
      sortable: true,
    },
    {
      name: columnsNames.status,
      cell: (row: AssetDataProperty) => (
        <StyledBadge
          className={`${STATUS_TYPE_VARIANT[row.asset_data.state?.name as string]} text fw-normal`}
        >
          {row.asset_data.state.name}
        </StyledBadge>
      ),
      center: true,
      sortable: true,
    },
    {
      name: columnsNames.price,
      cell: (row: AssetDataProperty) => {
        const activeLease = row.asset_data.lesse_contract.filter((lease: Lease) => lease.contract_status)[0];
        if (row.asset_data.lesse_contract.length !== 0 && activeLease) {
          return `${row.asset_data.price?.currency.symbol}${localFloatToString(activeLease?.amount)}`
        }
        return `${row.asset_data.price?.currency.symbol}${localFloatToString(row.asset_data.price?.value)}`
      },
      center: true,
      sortable: true,
    },
  ];

  const handleCurrentProperty = (row: AssetDataProperty) => {
    setCurrentProperty(row.asset_data);
    toggleShowProperties()
  };

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_15}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_PROPERTIES}</title>
      </Helmet>
      <Container fluid className="px-0">
        {(properties.length !== 0 && Object.entries(currentProperty).length !== 0) && (
          <>
            <Row className="mb-2">
              <Col xs={12} className="d-flex flex-row align-items-center mb-4">
                {(properties.length > 1) && (
                  <>
                    {(width <= smBreakpoint) ? (
                      <Fab
                        variant="secondary"
                        icon={Magnify}
                        onClick={setShowProperties}
                        style={{ zIndex: 10 }}
                      >
                        {actions.search}
                      </Fab>
                    ) : (
                      <StyledFab
                        className="btn rounded-5 shadow-sm secondary d-flex flex-row align-items-center"
                        onClick={setShowProperties}
                      >
                        <Magnify className="me-2" />
                        {actions.search}
                      </StyledFab>
                    )}
                    <Dialog
                      open={showProperties}
                      onHide={toggleShowProperties}
                      dialogClassName="w-80"
                      centered={true}
                    >
                      <DataGridTable
                        columns={columns}
                        rows={filterList}
                        onRowClicked={handleCurrentProperty}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                      />
                    </Dialog>
                    <StyledText className="h4 mx-2 my-0">
                      {currentProperty.asset_name}
                    </StyledText>
                  </>
                )}
              </Col>
              <Col xs={12}>
                <StyledText className="h2 mb-4 title text-center fw-semibold">{title}</StyledText>
              </Col>
            </Row>
            <Row className="px-md-5">
              <PropertyStats property={currentProperty} />
            </Row>
            <Row className="flex-grow-1 px-md-5" style={{ height: '60vh' }}>
              <PropertyDetails property={currentProperty} />
            </Row>
          </>
        )}
      </Container>
    </Authorization>
  );
};

export default InvestorPropertiesPage;