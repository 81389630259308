import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import environment from '../global/environment';
import { AUTH_ERROR } from '../global/errors';

export const authRentApi: AxiosInstance = axios.create({
  baseURL: environment.ENDPOINT_AUTH,
});

authRentApi.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});

authRentApi.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: any) => {
    const originalRequest = error.config;
    const { status, data } = error.response;
    const { code } = data;
    if (status === 401 && code === AUTH_ERROR.TOKEN_REQUIRES_REFRESH && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessTokenFn();
      return authRentApi(originalRequest);
    }

    return Promise.reject(error);
  }
);

export const refreshAccessTokenFn = async () => {
  const response = await axios.post(`${environment.ENDPOINT_AUTH}/auth/token-refresh`, {
    'grant_type': 'refresh_token',
    'client_id': environment.AUTH_CLIENT_ID,
    'client_secret': environment.AUTH_CLIENT_SECRET,
    'refresh_token': localStorage.getItem('refresh_token'),
  });

  localStorage.setItem('token', response.data.access_token);
  localStorage.setItem('refresh_token', response.data.refresh_token);
  return response
}
