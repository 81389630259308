import { useState, FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { Img } from 'react-image';
import CameraOff from 'mdi-react/CameraOffIcon';
import { StyledBox, StyledText } from '.';
import { useMessages } from '../../utils/hooks';

interface ZoomImageProps {
  src: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  alt?: string;
}

const StyledFigure = styled.figure`
  width: auto;
  height: auto;
  background-repeat: no-repeat;
  margin: 0;
  border-radius: 1rem;

  &:hover img {
    opacity: 0;
  }
`

const StyledImage = styled(Img)`
  display: block;
  width: 100%;
  pointer-events: none;
  border-radius: 1rem;
`;

const ZoomImage: FC<ZoomImageProps> = ({ src, width = 'auto', height = 'auto', alt, className }) => {
  const [backgroundImage] = useState(`url(${src})`);
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');
  const { messages } = useMessages();
  const { broken_image } = messages.dialogs;

  const handleMouseMove = (e: MouseEvent<HTMLElement>) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = (e.pageX - left) / width * 100;
    const y = (e.pageY - top) / height * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  return (
    <StyledFigure onMouseMove={handleMouseMove} style={{ backgroundImage, backgroundPosition }}>
      <StyledImage
        src={src}
        alt={alt}
        className={className}
        style={{ width, height, maxHeight: height }}
        unloader={(
          <StyledBox className="w-100 p-5 secondary rounded-4">
            <div className="d-flex flex-row justify-content-center align-items-center p-3">
              <CameraOff size={64} />
            </div>
            <StyledText className="text-white my-5 h2 fw-normal text-center">
              {broken_image.title}
              <br />  
              {broken_image.text}
            </StyledText>
          </StyledBox>
        )}
      />
    </StyledFigure>
  );
};

export default ZoomImage;