const environment = {
  ENDPOINT_AUTH: process.env.REACT_APP_ENDPOINT_AUTH,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  AUTH_CLIENT_SECRET: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  ENDPOINT_STACK_RENT: process.env.REACT_APP_ENDPOINT_STACK_RENT,
  ENDPOINT_DATAMAIN: process.env.REACT_APP_ENDPOINT_DATAMAIN,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  APP_ENVIROMENT: process.env.REACT_APP_ENVIROMENT,
  ACCESSTOKEN_ROLLBAR: process.env.REACT_APP_ACCESSTOKEN_ROLLBAR,
}
export default environment
