import { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../global/urls';
import { StyledBox, StyledSubmitButton, StyledText } from '../../common';
import { useMessages } from '../../../utils/hooks';



const Error404Page: FC = () => {
  const navigate = useNavigate();
  const { messages } = useMessages();
  const { title, subtitle, description, cta } = messages.errors.pages.page_not_found;

  const goToHome = () => {
    navigate(ROUTES.HOME, { replace: true })
  }

  return (
    <StyledBox className="vh-100">
      <div className="d-flex flex-column justify-content-center h-100">
        <section className="px-3">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12}>
                <StyledText className="h1 text-center">
                  <StyledText className="subtitle" as="span">{subtitle}</StyledText>
                  {' '}
                  <StyledText className="secondary" as="span">{title}</StyledText>
                </StyledText>
                <StyledText className="h3 title text-center">{description}</StyledText>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3}>
                <StyledSubmitButton className="w-100 my-2" onClick={goToHome}>
                  {cta}
                </StyledSubmitButton>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </StyledBox>
  );
};

export default Error404Page;