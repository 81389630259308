import { FC, ReactElement } from 'react';
import { StyledDialog } from '.';
import CloseButton from './CloseButton';

interface ImageModalProps {
  open: boolean;
  children: Element | ReactElement;
  className?: string;
  onHide: () => void;
  size?: 'sm' | 'lg' | 'xl';
  dialogClassName?: string;
  centered?: boolean;
}

const ImageModal: FC<ImageModalProps> = ({ open, onHide, className, children, size, dialogClassName, centered = true }) => {

  return (
    <StyledDialog
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
      show={open}
      onHide={onHide}
      contentClassName="p-0 w-auto d-flex flex-row rounded-4 shadow"
      backdrop="static"
      keyboard={false}
      {...(size && size !== undefined) && { size }}
      dialogClassName={dialogClassName}
      className={className}
    >
      <>
        <CloseButton onClick={onHide} />
        <>{children}</>
      </>
    </StyledDialog>
  );
};

export default ImageModal;