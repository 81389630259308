import { FC } from 'react';
import { StyledSpinner } from '.';

const Spinner: FC = () => {
  return (
    <StyledSpinner>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </StyledSpinner>
  );
};

export default Spinner;