import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { APPS, VARIANT } from '../../global';
import { ROUTES } from '../../global/urls';

interface AvatarInitialsParams {
  firstName: string;
  lastName: string;
  fontSize?: number;
  isBold?: boolean
}

const useUtils = () => {
  const { publicationId, assetId, inspectionId } = useParams();
  const theme = useTheme();

  /**
   * Get a generated image with initials from a name and lastname.
   * @param {object} params AvatarInitialsParams { firstName: string, lastName: string, fontSize: number, isBold: boolean } - 
   * firtsName is the first letter, lastName is the second letter, fontSize is the font size and should be a number between 0 and 1, isBool check if 
   * letters should be bold or not. 
   * @returns {string} Returns the URL with the initials to be displayed as image.
   */
  const getAvatarInitials = ({ firstName, lastName, fontSize = 0.5, isBold = false }: AvatarInitialsParams, variant?: VARIANT): string => {
    let bgColor = theme.palette.avatarBackgroundColor;
    let textColor = theme.palette.avatarTextColor;
    if (variant) {
      if (variant === VARIANT.PRIMARY) {
        bgColor = theme.palette.primary.main;
        textColor = theme.palette.primary.contrastText;
      }
      if (variant === VARIANT.SECONDARY) {
        bgColor = theme.palette.secondary?.main;
        textColor = theme.palette.secondary?.contrastText;
      }
      if (variant === VARIANT.TERTARY) {
        bgColor = theme.palette.tertary?.main;
        textColor = theme.palette.tertary?.contrastText;
      }
      if (variant === VARIANT.QUATERNARY) {
        bgColor = theme.palette.quaternary?.main;
        textColor = theme.palette.quaternary?.contrastText;
      }
    }
    return `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=${bgColor.substring(1)}&color=${textColor.substring(1)}&format=svg&font-size=${fontSize}&bold=${isBold}`
  };

  /**
   * Includes the valid paths to display an element. Acts like a "middleware"
   * @param {string} pathToInclude - The current path on navigation.
   * @returns {boolean} Returns true or false depending if the pathToInclude exists in the list.
   */
  const includePaths = (pathToInclude: string): boolean => {
    return [
      `${ROUTES.INVESTOR}`,
      `${ROUTES.INVESTOR_HOME}`,
      `${ROUTES.INVESTOR_PROPERTIES}`,
      `${ROUTES.INVESTOR_PUBLICATIONS}`,
      `${ROUTES.INVESTOR_PUBLICATION_BY_ID}/${publicationId}`,
      `${ROUTES.INVESTOR_PROPERTY_INSPECTION}/${inspectionId}`,
      `${ROUTES.INVESTOR_CONTRACTS}`,
      `${ROUTES.INVESTOR_SUPPORT}`,
      `${ROUTES.SUPPORT_CREATE_TICKET}`,
      `${ROUTES.MANAGEMENT}`,
      `${ROUTES.MANAGEMENT_HOME}`,
      `${ROUTES.MANAGEMENT_INSPECTIONS}`,
      `${ROUTES.MANAGEMENT_INSPECTION_BY_ID}/${inspectionId}`,
      `${ROUTES.MANAGEMENT_USERS}`,
      `${ROUTES.MANAGEMENT_PROPERTIES}`,
      `${ROUTES.MANAGEMENT_PROPERTY_BY_ID}/${assetId}`,
      `${ROUTES.MANAGEMENT_PROPERTY_PUBLICATION_BY_ID}/${assetId}`,
    ].includes(pathToInclude);
  };

  /**
   * Get a valid path from the app code.
   * @param {string} appCode App code (GES, INV, ARR, COR, PLA)
   * @returns {string} The path to redirect depending by APP
   */
  const getAppPath = (appCode: string): string => {
    switch (appCode) {
      case APPS.INV: return ROUTES.INVESTOR_HOME;
      case APPS.ARR: return ROUTES.HOME;
      case APPS.COR: return ROUTES.HOME;
      case APPS.PLA: return ROUTES.HOME;
      case APPS.GES: return ROUTES.MANAGEMENT_HOME;
      default: return ROUTES.HOME;
    };
  };

  /**
   * Get yes or not from a boolean value.
   * @param {boolean} value Boolean value that should evaluate
   * @returns If is true return YES else NOT if is false. 
   */
  const isYesOrNot = (value: boolean): string => {
    return (value) ? 'Si' : 'No';
  }

  /**
   * Get a number as string to be displayed.
   * @param {number | any} value The value to convert to string. 
   * @param {boolean} isDecimal Optional - Indicate if the number must have decimals or not.
   * @returns It will return a number as string.
   */
  const getNumberToString = (value: number | any, isDecimal?: boolean): string => {
    if (typeof value === 'string') return value;
    if (isNaN(value) || value === null || value === undefined) return '';
    return (isDecimal) ? value.toFixed(2).toString() : value.toString();
  };

  /**
   * Get a valid string.
   * @param {any} value The value to compare 
   * @returns Will return a string.
   */
  const getValidString = (value: any): string => {
    if (!value || typeof value !== 'string') return '';
    return value as string;
  };

  /**
   * Generates a image from div element.
   * @param {HTMLDivElement | null} element HTML div element reference.
   * @param {string} fileName The name of the generated image. 
   * @returns 
   */
  const generateImageFromElement = async (element: HTMLDivElement | null, fileName: string) => {
    if (!element) return;
    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true
    });
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, fileName, 'image/png');
  };

  /**
   * Return a capitalized text
   * @param {string} text 
   * @returns {string}
   */
  const capitalize = (text: string): string => {
    if (!text || typeof text !== 'string') return ' ';
    const lower = text.toLowerCase();
    const words = lower.split(' ');
    const capitalizedText = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
    return capitalizedText;
  }

  /**
   * 
   * @param {number} number 
   * @returns {string}
   */
  const floatToString = (number: number): string => {
    return number.toString().indexOf('.') === -1 ? number.toFixed(2) : number.toString();
  }

  /**
   * 
   * @param {number} number 
   * @returns {string}
   */
  const localFloatToString = (number: number): string => {
    return number.toLocaleString("es-ES");
  }

  /**
   * 
   * @param {number} number 
   * @returns {string}
   */
  const removeCharactersFromNumber = (number: string): string => {
    if (!number || number.length === 0) return '';
    return number.replace(/[^0-9 ]/, '');
  }

  /**
   * 
   * @param {string} variant Color variant, it can be 'primary', 'secondary', 'tertary', 'quaternary'
   * @returns The color depending the variant, returns default icon color if recieve and invalid param variant.
   */
  const getColorByVariant = (variant: string) => {
    switch (variant) {
      case VARIANT.PRIMARY: return theme.palette.primary.main;
      case VARIANT.SECONDARY: return theme.palette.secondary?.main;
      case VARIANT.TERTARY: return theme.palette.tertary?.main;
      case VARIANT.QUATERNARY: return theme.palette.quaternary?.main;
      default: return theme.palette.iconColor;
    }
  }

  return {
    getAvatarInitials,
    includePaths,
    getAppPath,
    isYesOrNot,
    getNumberToString,
    getValidString,
    generateImageFromElement,
    capitalize,
    floatToString,
    removeCharactersFromNumber,
    getColorByVariant,
    localFloatToString,
  };
};

export default useUtils;