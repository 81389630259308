import { RouterProvider } from 'react-router-dom';
import { DefaultOptions, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { hotjar } from 'react-hotjar';
import { HelmetProvider } from 'react-helmet-async';
import './views/assets/style/App.scss';
import "bootstrap/dist/js/bootstrap.bundle"
import { router } from "./routes";
import { AuthProvider } from "./utils/contexts/AuthContext";
import environment from './global/environment';
import { AppProvider } from './utils/contexts/AppContext';

const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
};

const queryClient = new QueryClient({ defaultOptions: queryConfig });

if (!localStorage.getItem('isFirstTime')) {
  localStorage.setItem('isFirstTime', JSON.stringify(true));
}

export default function App() {
  hotjar.initialize(parseInt(`${environment.HOTJAR_ID}`), 6);

  return (
    <AuthProvider>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </QueryClientProvider>
      </AppProvider>
    </AuthProvider>
  );
}
