import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { StyledBox, StyledText } from '../../../../common';
import { useMessages, useUtils } from '../../../../../utils/hooks';
import { Adjustment, Lease, Property } from '../../../../../global/interfaces';

interface LeasesCardProps {
  property: Property;
}

const LeasesCard: FC<LeasesCardProps> = ({ property }) => {
  const { localFloatToString } = useUtils();
  const { lesse_contract } = property;

  const { messages } = useMessages();
  const { fields, title } = messages.modules.investor.properties.sections.information.leases;

  lesse_contract.sort((a: Lease, b: Lease) => Number(b.contract_status) - Number(a.contract_status));

  return (
    <Row>
      <Col xs={12} className="mb-3 d-none d-md-block">
        <StyledText className="h4 title">{title}</StyledText>
      </Col>
      {lesse_contract.map((lease: Lease) => (
        <Col xs={12} className="mb-3" key={lease.id}>
          <StyledBox className={`rounded-4 p-2 w-100 shadow-none ${lease.contract_status ? 'grey-alpha' : 'grey-alpha'}`}>
            <Row>
              <Col xs={12}>
                <StyledText className={`h6 ${lease.contract_status ? 'grey' : 'grey'} text-center fw-semibold`}>{fields.contract_id}: {lease.id}</StyledText>
                <StyledText className={`h6 ${lease.contract_status ? 'grey' : 'grey'} text-center fw-semibold`}>
                  {fields.validity}: {`${lease.date_start} - ${lease.date_end}`}
                </StyledText>
                {/* <StyledText className={`h6 ${lease.contract_status ? 'grey' : 'grey'} text-center fw-semibold`}>{fields.status}: {lease.contract_status ? 'Activo' : 'Inactivo'}</StyledText> */}
                {(lease.main_lease && Object.entries(lease.main_lease).length > 0) && (
                  <StyledText className={`h6 ${lease.contract_status ? 'grey' : 'grey'} text-center fw-semibold`}>
                    {fields.lease_name}: {lease.main_lease.full_name}
                  </StyledText>
                )}
              </Col>
              <Col xs={12}>
                <StyledBox className="rounded-3 p-2 bg-white w-100 shadow-none">
                  <Row>
                    <Col xs={12}>
                      {lease.adjustments.map(({ amount, status, adjustment_name, id }: Adjustment) => (
                        <StyledText className={`${status && lease.contract_status ? 'grey' : 'grey'} d-flex flew-row justify-content-between m-0`} key={id}>
                          <span className="fw-normal">{adjustment_name}</span>
                          <span className="fw-normal">${localFloatToString(amount)}</span>
                        </StyledText>
                      ))}
                    </Col>
                  </Row>
                </StyledBox>
              </Col>
            </Row>
          </StyledBox>
        </Col>
      ))}
    </Row>
  );
};

export default LeasesCard;