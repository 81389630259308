import {
  FC,
  useState,
  ChangeEvent,
  SetStateAction,
  Dispatch,
  useEffect
} from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { subMonths, subYears, format, subDays, subHours, startOfDay } from 'date-fns';
import es from 'date-fns/locale/es';
import styled from 'styled-components';
import ChevronDown from 'mdi-react/ChevronDownIcon';
import ChevronUp from 'mdi-react/ChevronUpIcon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledBox, StyledDatePickerWrapper, StyledDropdownSelect, StyledSearchBar, StyledSubmitButton, StyledText } from '../../../../common';
import { useMessages } from '../../../../../utils/hooks';

const statusOptions = [
  {
    label: 'Activo',
    value: 'True'
  },
  {
    label: 'Inactivo',
    value: 'False'
  },
];

const connectedUsersOptions = [
  {
    label: 'Si',
    value: 'True'
  },
  {
    label: 'No',
    value: 'False'
  },
  {
    label: 'Todos',
    value: 'All'
  },
];

const lastSessionsOptions = [
  'Últimas 24 horas',
  'Últimos 7 días',
  'Últimos 15 días',
  'Último mes',
  'Últimos 3 meses',
  'Últimos 6 meses',
  'Últimos 12 meses',
  'Todas',
  'Vacías',
  'Intervalo de fechas personalizado',
];

enum LAST_DATE_RANGES {
  LAST_24_HOURS = 'Últimas 24 horas',
  LAST_7_DAYS = 'Últimos 7 días',
  LAST_15_DAYS = 'Últimos 15 días',
  LAST_MONTH = 'Último mes',
  LAST_3_MONTHS = 'Últimos 3 meses',
  LAST_6_MONTHS = 'Últimos 6 meses',
  LAST_YEAR = 'Últimos 12 meses',
  ALL = 'Todas',
  EMPTY = 'Vacías',
}

const StyledDropdownTrigger = styled(Dropdown.Toggle)`
  border: solid 1px #8E8D8D !important;
  color: #8E8D8D !important;

  &:is(:focus, &:focus-visible, :active, :hover), :not([value=""]) {
    border: solid 1px #525ea2 !important;
    color: #525ea2 !important;
    box-shadow: none !important;
  }
`;

interface UsersFilterBarProps {
  setFilters: Dispatch<SetStateAction<string>>;
  filters: string;
}

const UsersFilterBar: FC<UsersFilterBarProps> = ({ setFilters, filters }) => {
  const { messages } = useMessages();
  const {
    filter_by_username,
    filter_by_fullname,
    filter_by_rut,
    filter_by_status,
    filter_by_last_session_date,
    filter_by_connected_users,
    filter_by_start_date,
    filter_by_end_date,
  } = messages.modules.management.users.sections.users.fields;
  const { filter, clear_filter, apply } = messages.modules.management.users.sections.users.actions;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [username, setUsername] = useState<string>('');
  const [lastSession, setLastSession] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [rut, setRut] = useState<string>('');
  const [status, setStatus] = useState<string>('True');
  const [openStatusDropdown, setOpenStatusDropdown] = useState<boolean>(false);
  const [openConnectedUsersDropdown, setOpenConnectedUsersDropdown] = useState<boolean>(false);
  const [openLastSessionDropdown, setOpenLastSessionDropdown] = useState<boolean>(false);
  const [connectedUsers, setConnectedUsers] = useState<string>('');
  const [filtersAux, setFilterAux] = useState<string>('');

  useEffect(() => {
    setFilterAux(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAux]);


  const handleChangeFullName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFullName(value);
  }

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUsername(value);
  }

  const handleChangeRut = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRut(value);
  }

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  }

  const handleChangeConnectedUsers = (value: string) => {
    setConnectedUsers(value);
  }

  const handleChangeLastSession = (value: string) => {
    if (value === LAST_DATE_RANGES.LAST_24_HOURS) {
      setStartDate(subHours(new Date(), 24));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.LAST_7_DAYS) {
      setStartDate(startOfDay(subDays(new Date(), 7)));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.LAST_15_DAYS) {
      setStartDate(startOfDay(subDays(new Date(), 15)));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.LAST_MONTH) {
      setStartDate(startOfDay(subMonths(new Date(), 1)));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.LAST_3_MONTHS) {
      setStartDate(startOfDay(subMonths(new Date(), 3)));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.LAST_6_MONTHS) {
      setStartDate(startOfDay(subMonths(new Date(), 6)));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.LAST_YEAR) {
      setStartDate(startOfDay(subYears(new Date(), 1)));
      setEndDate(new Date());
    }
    if (value === LAST_DATE_RANGES.ALL || LAST_DATE_RANGES.EMPTY) {
      setStartDate(null);
      setEndDate(null);
    }
    setLastSession(value);
  }

  const toggleStatusDropdown = () => {
    setOpenStatusDropdown(!openStatusDropdown);
  };

  const toggleConnectedUsersDropdown = () => {
    setOpenConnectedUsersDropdown(!openConnectedUsersDropdown);
  };

  const toggleLastSessionDropdown = () => {
    setOpenLastSessionDropdown(!openLastSessionDropdown);
  };
    
  const handleFilters = () => {
    const formattedStartDate = (startDate) ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = (endDate) ? format(endDate, 'yyyy-MM-dd') : '';
    setFilters(`?email=${username}&full_name=${fullName}&is_active=${status}&rut=${rut}&last_session_from=${formattedStartDate}&last_session_to=${formattedEndDate}&is_connected=${connectedUsers}`);
  }

  const getStatusLabel = () => {
    if (status === 'True') return 'Activo';
    return 'Inactivo'
  };

  const getConnectedLabel = () => {
    if (connectedUsers === 'True') return 'Si';
    if (connectedUsers === 'False') return 'No';
    return 'Todos';
  };

  const resetFilters = () => {
    setFilters('');
    setFilterAux('');
    setFullName('');
    setUsername('');
    setStatus('');
    setConnectedUsers('');
    setLastSession('');
    setRut('');
    setStartDate(null);
    setEndDate(null);
  };

  const shouldFilter = () => {
    if (startDate !== null || endDate !== null || username !== '' || rut !== '' || fullName !== '' || status !== '' || lastSession !== '' || connectedUsers !== '') return true;
    if (filters !== filtersAux) return true;
    return false;
  }

  return (
    <StyledBox className="d-flex flex-row flex-wrap align-items-center bg-white">
      <StyledSearchBar className="position-relative me-2 mb-2" controlId="username">
        <Form.Control
          value={username}
          onChange={handleChangeUsername}
          type="text"
          placeholder={filter_by_username}
          className="border-1 rounded-2 text-center"
        />
      </StyledSearchBar>
      <StyledSearchBar className="position-relative me-2 mb-2" controlId="full_name">
        <Form.Control
          value={fullName}
          onChange={handleChangeFullName}
          type="text"
          placeholder={filter_by_fullname}
          className="border-1 rounded-2 text-center"
        />
      </StyledSearchBar>
      <StyledSearchBar className="position-relative me-2 mb-2" controlId="full_name">
        <Form.Control
          value={rut}
          onChange={handleChangeRut}
          type="text"
          placeholder={filter_by_rut}
          className="border-1 rounded-2 text-center"
        />
      </StyledSearchBar>

      <StyledDropdownSelect className="mb-2" open={openStatusDropdown} onToggle={toggleStatusDropdown}>
        <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={status}>
          {(status !== '') ? getStatusLabel() : filter_by_status}
          {openStatusDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
        </StyledDropdownTrigger>
        <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
          {statusOptions.map(({ label, value }: { label: string, value: string }, index: number) => (
            <Dropdown.Item as="button" key={index} onClick={() => handleChangeStatus(value)}>{label}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </StyledDropdownSelect>


      <StyledDropdownSelect className="mb-2 d-none" open={openLastSessionDropdown} onToggle={toggleLastSessionDropdown}>
        <StyledDropdownTrigger id="last_session" className="rounded-2 me-2" value={lastSession}>
          {(lastSession !== '') ? lastSession : filter_by_last_session_date}
        </StyledDropdownTrigger>
        <Dropdown.Menu className="rounded-2 border-0 shadow">
          {lastSessionsOptions.map((value: string, index: number) => {
            if (value !== 'Intervalo de fechas personalizado') {
              return (
                <Dropdown.Item
                  as="button"
                  key={index}
                  onClick={() => {
                    handleChangeLastSession(value);
                  }}
                >
                  {value}
                </Dropdown.Item>
              )
            }
            return (
              <button className="dropdown-item" key={index} onClick={() => handleChangeLastSession(value)}>
                {value}
              </button>
            )
          })}
          {(lastSession === 'Intervalo de fechas personalizado') && (
            <div className="px-3 py-2">
              <StyledDatePickerWrapper className="mb-2">
                <DatePicker
                  name="start_date"
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="border-1 rounded-2 text-center w-100"
                  showPopperArrow={false}
                  popperPlacement="bottom"
                  locale={es}
                  placeholderText={filter_by_start_date}
                  maxDate={new Date()}
                />
              </StyledDatePickerWrapper>
              <StyledDatePickerWrapper className="mb-2">
                <DatePicker
                  name="end_date"
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="border-1 rounded-2 text-center w-100"
                  showPopperArrow={false}
                  popperPlacement="bottom"
                  locale={es}
                  placeholderText={filter_by_end_date}
                  maxDate={new Date()}
                />
              </StyledDatePickerWrapper>
              <Dropdown.Item
                as={StyledSubmitButton}
                className="primary-outline filled px-5 mb-2 me-3 text-center rounded w-100"
                disabled={!startDate || !endDate}
                onClick={() => {
                  handleChangeLastSession(`${format(startDate as Date, 'dd/MM/yyyy')} - ${format(endDate as Date, 'dd/MM/yyyy')}`);
                }}
              >
                {apply}
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </StyledDropdownSelect>

      <StyledDropdownSelect className="mb-2" open={openConnectedUsersDropdown} onToggle={toggleConnectedUsersDropdown}>
        <StyledDropdownTrigger id="connected_users" className="rounded-2 me-2" value={connectedUsers}>
          {(connectedUsers !== '') ? getConnectedLabel() : filter_by_connected_users}
          {openConnectedUsersDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
        </StyledDropdownTrigger>
        <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
          {connectedUsersOptions.map(({ label, value }: { label: string, value: string }, index: number) => (
            <Dropdown.Item
              as="button"
              key={index}
              onClick={() => handleChangeConnectedUsers(value)}
            >
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </StyledDropdownSelect>
      {(shouldFilter()) && (
        <>
          <StyledSubmitButton className="primary filled px-5 mb-2 me-3 rounded-2" onClick={handleFilters}>
            {filter}
          </StyledSubmitButton>
          <StyledText
            className="active cursor-pointer text-decoration-underline me-2"
            onClick={resetFilters}
          >
            {clear_filter}
          </StyledText>
        </>
      )}
    </StyledBox>
  );
};

export default UsersFilterBar;