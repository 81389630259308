import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import HomeOutline from 'mdi-react/HomeOutlineIcon';
import { Toolbar, GuestDropdown } from '.';
import { useAuth, useAuthorization, useUtils } from '../../../../utils/hooks';
import { PERMISSIONS } from '../../../../global/permissions';
import { ROUTES } from '../../../../global/urls';
import AppDrawer from './AppDrawer';
import { StyledHeader, StyledMenuButton } from '../../../common';

const Header: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { checkAccess } = useAuthorization();
  const { getAppPath, includePaths } = useUtils();
  const auth = useAuth();
  const { apps } = auth;

  return (
    <StyledHeader className="py-2 border-bottom">
      <div className="container-fluid d-flex flex-row justify-content-between align-content-center px-2 px-md-4" >
        <div className="d-flex flex-row align-items-center">
          {includePaths(pathname) && (
            <AppDrawer />
          )}
          <div className={`logo`}>
            {(apps.length === 1) ? (
              <>
                <StyledMenuButton
                  size="sm"
                  className="border-0 p-2 rounded-circle"
                  onClick={() => {
                    navigate(getAppPath(apps[0]?.code), { replace: true })
                  }}
                >
                  <HomeOutline size={26} color={theme.palette.iconColor} />
                </StyledMenuButton>
              </>
            ) : (
              <>
                <StyledMenuButton
                  size="sm"
                  className="border-0 p-2 rounded-circle"
                  onClick={() => {
                    navigate(ROUTES.HOME, { replace: true })
                  }}
                >
                  <HomeOutline size={26} color={theme.palette.iconColor} />
                </StyledMenuButton>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex flex-row align-items-center">
            <Toolbar />
            {checkAccess(PERMISSIONS.PLA_SERVI_5) && (<GuestDropdown />)}
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;