import { FC, useState, ChangeEvent } from 'react';
import { Row, Col, InputGroup, OverlayTrigger, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import EmoticonHappyOutline from 'mdi-react/EmoticonHappyOutlineIcon';
import Paperclip from 'mdi-react/PaperclipIcon';
import Delete from 'mdi-react/DeleteIcon';
import TrashCanOutline from 'mdi-react/TrashCanOutlineIcon';
import ThumbUpOutline from 'mdi-react/ThumbUpOutlineIcon';
import { Formik } from 'formik';
import { useIMask } from 'react-imask';
import { TICKET_INITIAL_VALUES, TICKET_VALIDATION_SCHEMA } from '../../../../validations/investorValidations';
import {
  StyledInput,
  StyledInputGroupText,
  StyledInvalidFeedback,
  StyledMenuButton,
  StyledSelect,
  StyledSubmitButton,
  StyledText,
  StyledTooltip
} from '../../../../common';
import { useMessages, useWindowResize } from '../../../../../utils/hooks';
import { AccountUser, Property, Ticket } from '../../../../../global/interfaces';
import { stackRentApi } from '../../../../../apis/stackRentApi';
import Dialog from '../../../../common/Dialog';
import { ROUTES } from '../../../../../global/urls';
import Confirm from '../../../../common/Confirm';

interface TicketFormProps {
  property: Property,
  person: AccountUser;
}

const smBreakpoint = 600;

const TicketForm: FC<TicketFormProps> = ({ property, person }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [width] = useWindowResize();
  const { messages } = useMessages();
  const { fields, tooltips, placeholders } = messages.forms.create_ticket;
  const { new_ticket_created, ticket_created_after, delete_file } = messages.dialogs;
  const [show, setShow] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [files, setFiles] = useState<FileList | null>(null);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1);
  const [phone, setPhone] = useState<string>(person.phone);
  const [requirementTypes, setRequerimentTypes] = useState<Array<{ name: string }>>([]);

  const initialValues = {
    ...TICKET_INITIAL_VALUES,
    asset_name: property.asset_name,
    phone: person.phone,
  }

  const { ref } = useIMask({ mask: '{+}00000000000' }, {
    onAccept: (value: string) => {
      setPhone(value)
    }
  });

  useQuery<AxiosResponse, AxiosError>(['investor/requerimentTypes'], async () => {
    return await stackRentApi.get('/person/ticket/types');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setRequerimentTypes(response.data)
      },
      onError: (error: AxiosError) => {
        setRequerimentTypes([]);
      }
    });

  const { mutate, isLoading } = useMutation((values: Ticket) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (key === 'files') {
        if (value && value.length !== 0) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        }
        else {
          formData.delete(key)
        }
      } else {
        formData.append(key, value);
      }
    }
    return stackRentApi.post(`/person/ticket`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });

  const onSubmit = (values: Ticket) => {
    if (fileError !== '') setFileError('');
    mutate({ ...values, files, phone }, {
      onSuccess: (response: AxiosResponse) => {
        const { id } = response.data;
        setTicketId(id);
        setIsSuccess(true);
        toggleShow();
      },
      onError: (error: any) => {
        setIsSuccess(false);
        toggleShow();
      }
    })
  };



  const toggleShow = () => {
    setShow(!show);
    if (isSuccess) {
      navigate(ROUTES.INVESTOR_SUPPORT);
    }
  };

  const toggleConfirm = () => {
    setConfirm(!confirm);

  };

  const navigateToOwn = () => {
    navigate(ROUTES.SUPPORT_CREATE_TICKET);
  };

  const getFileNames = (): string => {
    const names: string[] = [];
    if (files && files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        names.push(files[i].name)
      }
      if (names.length === 1) {
        return names[0]
      }
      return names.join(', ')
    }
    return '';
  }

  const handleFiles = (files: FileList) => {
    const names: string[] = [];
    let filesSize: number = 0;
    setFileError('');
    setFiles(files)
    for (let i = 0; i < files.length; i++) {
      filesSize += files[i].size;
      names.push(files[i].name)
    }
    if (filesSize >= (20 * 1024 * 1024)) {
      setFiles(null);
      setFilenames([]);
      setFileError('Recuerda que el archivo debe ser JPG, PNG, SVG, PDF, XLSX y DOCX hasta 20 MB.')
      return;
    }
    names.forEach((name: string) => {
      if (!(/\.(jpg|jpeg|png|gif|svg|pdf|xls|xlsx|doc|docx)$/i).test(name)) {
        setFiles(null);
        setFilenames([]);
        setFileError('Recuerda que el archivo debe ser JPG, PNG, SVG, PDF, XLSX y DOCX hasta 20 MB.')
        return;
      }
    })
    setFilenames(names);
  }

  const handleDeleteFile = (index: number) => {
    if (files && files.length !== 0) {
      const fileList = new DataTransfer();
      const filteredFiles = Array.from(files).filter((file, i: number) => i !== index);
      for (const file of filteredFiles) {
        fileList.items.add(file as File);
      }
      handleFiles(fileList.files);
    }
    else {
      setFiles(null);
      setFilenames([]);
      setCurrentFileIndex(-1);
      setFileError('');
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={TICKET_VALIDATION_SCHEMA}
        onSubmit={(values) => onSubmit(values)}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          // setFieldError,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className="w-100">
            <Row className="justify-content-center">
              <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                <Form.Group className={`${((errors.phone && touched.phone) && (width >= smBreakpoint)) ? 'mb-0' : 'mb-3'}`} controlId="asset_name">
                  <Form.Label>
                    <StyledText className="title mb-0">{fields.asset_name}</StyledText>
                  </Form.Label>
                  <StyledInput
                    type="text"
                    name="asset_name"
                    isInvalid={errors.asset_name && touched.asset_name}
                    className=""
                    placeholder={placeholders.asset_name}
                    onChange={handleChange}
                    value={values.asset_name}
                    readOnly
                    isFilled={(values.asset_name.length > 0)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                <Form.Group className={`${errors.phone && touched.phone ? 'mb-0' : 'mb-3'}`} controlId="phone">
                  <Form.Label>
                    <StyledText className="title mb-0">
                      {fields.phone} <StyledText as="span" className="tertary">*</StyledText>
                    </StyledText>
                  </Form.Label>
                  <StyledInput
                    ref={ref}
                    type="text"
                    name="phone"
                    className={`${errors.phone && touched.phone ? 'with-error' : ''}`}
                    placeholder={placeholders.phone}
                    onChange={handleChange}
                    value={phone}
                    isInvalid={errors.phone && touched.phone}
                    maxLength={14}
                    isFilled={(values.phone?.length > 0)}
                  />
                </Form.Group>
              </Col>
              {errors.phone && touched.phone && (
                <StyledText as={Form.Text} className="secondary-light fst-italic mb-3">
                  {errors.phone}
                </StyledText>
              )}
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="type">
                  <Form.Label>
                    <StyledText className="title mb-0">
                      {fields.type} <StyledText as="span" className="tertary">*</StyledText>
                    </StyledText>
                  </Form.Label>
                  <StyledSelect
                    className={`${errors.type && touched.type ? 'with-error' : ''}`}
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    isInvalid={errors.type && touched.type}
                    isFilled={(values.type.length > 0)}
                  >
                    <option value="" disabled={true}>{placeholders.type}</option>
                    {requirementTypes.map(({ name }, index) => (
                      <option value={name} key={index} className="bg-white">{name}</option>
                    ))}
                  </StyledSelect>
                  {errors.type && touched.type && (
                    <StyledInvalidFeedback className="invalid-feedback fst-italic">
                      {errors.type}
                    </StyledInvalidFeedback>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="subject">
                  <Form.Label>
                    <StyledText className="title mb-0">
                      {fields.subject} <StyledText as="span" className="tertary">*</StyledText>
                    </StyledText>
                  </Form.Label>
                  {/* Ajustar validacion para textarea, de momento usar input con validacion */}
                  <StyledInput
                    name="subject"
                    className={`${errors.subject && touched.subject ? 'with-error' : ''}`}
                    placeholder={placeholders.subject}
                    onChange={handleChange}
                    value={values.subject}
                    isInvalid={(errors.subject && touched.subject)}
                    maxLength={255}
                    isFilled={(values.subject.length > 0)}
                  />
                  {(errors.subject && touched.subject) && (
                    <StyledInvalidFeedback className="invalid-feedback fst-italic">
                      {errors.subject}
                    </StyledInvalidFeedback>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>
                    <StyledText className="title mb-0">{fields.description}</StyledText>
                  </Form.Label>
                  <StyledInput
                    as="textarea"
                    rows={3}
                    name="description"
                    className="form-control"
                    placeholder={placeholders.description}
                    onChange={handleChange}
                    value={values.description}
                    maxLength={65535}
                    isFilled={(values.description.length > 0)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Label htmlFor="file_name">
                  <StyledText className="title mb-0">{fields.file}</StyledText>
                </Form.Label>
                <InputGroup className="mb-3">
                  <StyledInput
                    className={`border-end-0 ${(fileError !== '') ? 'with-error' : ''}`}
                    value={(files && files.length > 0) ? getFileNames() : ''}
                    readOnly
                    placeholder={placeholders.file}
                    isInvalid={fileError !== ''}
                    isFilled={(files && files.length > 0)}

                  />
                  <StyledInputGroupText
                    className={`border border-start-0 cursor-pointer bg-white rounded-end ${(fileError !== '') ? 'with-error' : ''}`}
                    isFilled={(files && files.length > 0)}
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <StyledTooltip id="help-tooltip">
                          {tooltips.file}
                        </StyledTooltip>
                      }
                    >
                      <StyledMenuButton
                        as="label"
                        htmlFor="file_name"
                        className="btn-sm p-2 py-1 border-0 rounded-circle cursor-pointer"
                      >
                        <Paperclip size={16} />
                      </StyledMenuButton>
                    </OverlayTrigger>
                  </StyledInputGroupText>
                  {fileError !== '' && (
                    <StyledInvalidFeedback className="invalid-feedback fst-italic">
                      {fileError}
                    </StyledInvalidFeedback>
                  )}
                </InputGroup>
                {filenames.map((name: string, index: number) => (
                  <section key={index} className="d-flex flex-row align-items-center">
                    <StyledText className="primary my-1 text-truncate">{name}</StyledText>
                    <StyledMenuButton
                      size="sm"
                      className="p-1 border-0 rounded-circle m-0 ms-md-5 bg-white"
                      onClick={() => {
                        setCurrentFileIndex(index);
                        toggleConfirm()
                      }}
                    >
                      <Delete size={24} />
                    </StyledMenuButton>
                  </section>
                ))}
                <input
                  type="file"
                  name="file_name"
                  className="d-none"
                  id="file_name"
                  placeholder={fields.file}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('file_name', event.target.value)
                    if (event.target.files) {
                      handleFiles(event.target.files);
                    }
                  }}
                  multiple
                  value={values.file_name}
                  accept=".jpg, .jpeg, .png, .gif, .svg, .pdf, .xlsx, .xls, .doc, .docx"
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <StyledSubmitButton
                  className="w-100 btn-lg primary mt-3 filled"
                  type="submit"
                  disabled={isLoading}
                >
                  {fields.submit_button}
                </StyledSubmitButton>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <Dialog open={show} onHide={toggleShow} size={(width <= smBreakpoint) ? 'lg' : undefined}>
        <Row className="px-3 py-3 py-md-5">
          <Col xs={12} className="d-flex flex-row justify-content-center align-items-center mb-3 mt-md-5">
            {isSuccess ? (
              <EmoticonHappyOutline size={(width <= smBreakpoint) ? 50 : 80} color={theme.palette.tertary?.main} />
            ) : (
              <ThumbUpOutline size={(width <= smBreakpoint) ? 50 : 80} color={theme.palette.tertary?.main} />
            )}
          </Col>
          <Col xs={12} className=" pt-4 pb-3">
            <StyledText className={`title text-center ${(width <= smBreakpoint) ? 'h4' : 'h2'} fw-normal`}>
              {isSuccess ? `${new_ticket_created.title.start} #${ticketId} ${new_ticket_created.title.end}` : ticket_created_after.title}
            </StyledText>
          </Col>
          <Col xs={12}>
            <StyledText className="subtitle h6 text-center fw-normal mb-md-5">
              {isSuccess ? new_ticket_created.text : ticket_created_after.text}
            </StyledText>
          </Col>
          <Col xs={12}>
            <Row className="justify-content-center">
              <Col xs={12} sm={12} md={8} className="mt-3 my-md-5">
                <StyledSubmitButton className="w-100 shadow-sm primary btn-lg" onClick={navigateToOwn}>
                  {new_ticket_created.call_to_action}
                </StyledSubmitButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dialog>
      <Confirm
        show={confirm}
        onHide={toggleConfirm}
        title={delete_file.text}
        icon={TrashCanOutline}
        variant="secondary"
        enableClose
        size={(width <= smBreakpoint) ? 'lg' : undefined}
        actions={(
          <Row className="justify-content-center mt-md-5">
            <Col xs={12} sm={12} md={8}>
              <StyledSubmitButton
                className="w-100 rounded-3 primary shadow-sm btn-lg btn-lg"
                onClick={() => {
                  handleDeleteFile(currentFileIndex)
                  toggleConfirm();
                }}
              >
                {delete_file.call_to_action}
              </StyledSubmitButton>
            </Col>
          </Row>
        )}
      />
    </>
  );
};

export default TicketForm;