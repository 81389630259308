import { FC, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Access, Extra, ExtraBill, ExtraKitchen, ExtraRevision, Humidity, Inspection, Item, Room } from '../../../../../global/interfaces';
import { ACCESS_STATUS, BATHROOM_STATUS, BEDROOM_STATUS, EXTRA_TYPE, KITCHEN_STATUS, LIVING_STATUS } from './../components';
import { useMessages } from '../../../../../utils/hooks';
import { StyledBox, StyledText } from '../../../../common';

interface InspectionDetailsProps {
  inspection: Inspection;
}

const getVariant = (status: string) => {
  if (status === 'Bueno') return 'primary';
  if (status === 'Regular') return 'secondary';
  if (status === 'Malo') return 'tertary';
  return 'primary';
}

const getElementsVariant = (element: string) => {
  if (element === 'Todo está en buen estado'
      || element === 'Funciona correctamente'
      || element === 'Ninguno está en mal estado'
      || element === 'Sí los he enviado') return 'primary';
  if (element === 'No') return 'primary';
  if (element === 'Sí' || element === 'Si') return 'tertary';
  return 'tertary';
}

export const getElementsResponseVariant = (element: string) => {
  if (element === 'No') return 'tertary';
  if (element === 'Sí' || element === 'Si') return 'primary';
  return 'primary';
}

const InspectionDetails: FC<InspectionDetailsProps> = ({ inspection }) => {
  const { messages } = useMessages();
  const { sections } = messages.modules.management.inspections.sections.self_inspections.online_inspection;
  const { areas, observation: observationText } = sections;

  const access: Access = (inspection.access) ? inspection.access : {} as Access;
  const kitchen: Access = (inspection.kitchen) ? inspection.kitchen : {} as Access;
  const living: Access = (inspection.living) ? inspection.living : {} as Access;
  const bathroom: Room = (inspection.bathroom) ? inspection.bathroom : {} as Room;
  const bedroom: Room = (inspection.bedroom) ? inspection.bedroom : {} as Room;
  const extra_kitchen: ExtraKitchen = (inspection.extra_kitchen) ? inspection.extra_kitchen : {} as ExtraKitchen;
  const terrace: Access = (inspection?.terrace) ? inspection?.terrace : {} as Access;
  const humidity: Humidity = (inspection.humidity) ? inspection.humidity : {} as Humidity;
  const extra_revision: ExtraRevision = (inspection.extra_revision) ? inspection.extra_revision : {} as ExtraRevision;
  const extra_bill: ExtraBill = (inspection.extra_bill) ? inspection.extra_bill : {} as ExtraBill;
  const extra: Extra = (inspection.extra) ? inspection.extra : {} as Extra;
  const video: string[] = (inspection.video) ? inspection.video : [];

  const getFilteredBedrooms = () => {
    return bedroom.items.filter((item: Item) => item.hasOwnProperty('option') && item.hasOwnProperty('inspection'))
  };

  const getFilteredBathrooms = () => {
    return bathroom.items.filter((item: Item) => item.hasOwnProperty('option') && item.hasOwnProperty('inspection'))
  };

  return (
    <Row>
      {(Object.entries(access).length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.access.title}</StyledText>
            <div>
              <StyledText as="span">{areas.access.elements}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(access.inspection.element)}`}
              >
                {access.inspection.element}
              </StyledText>
            </div>
            {(access.inspection.observation !== '') && (
              <div>
                <StyledText as="span" className="">{observationText}: {' '}</StyledText>
                <StyledText
                  as="span"
                  className={`m-0`}
                >
                  {access.inspection.observation}
                </StyledText>
              </div>
            )}
            {access.status.map(({ type, status, observation }, index: number) => (
              <Fragment key={index}>
                <div>
                  <StyledText as="span" className="">{ACCESS_STATUS[type]}:{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getVariant(status)}`}
                  >
                    {status}
                  </StyledText>
                </div>
                {(observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}: {' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {observation}
                    </StyledText>
                  </div>
                )}
              </Fragment>
            ))}
          </StyledBox>
        </Col>
      )}
      {(Object.entries(kitchen).length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.kitchen.title}</StyledText>
            <div>
              <StyledText as="span">{areas.kitchen.elements}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(kitchen.inspection.element)}`}
              >
                {kitchen.inspection.element}
              </StyledText>
            </div>
            {(kitchen.inspection.observation !== '') && (
              <div>
                <StyledText as="span" className="">{observationText}: {' '}</StyledText>
                <StyledText
                  as="span"
                  className={`m-0`}
                >
                  {kitchen.inspection.observation}
                </StyledText>
              </div>
            )}
            {kitchen.status.map(({ type, status, observation }, index: number) => (
              <Fragment key={index}>
                <div>
                  <StyledText as="span" className="">{KITCHEN_STATUS[type]}:{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getVariant(status)}`}
                  >
                    {status}
                  </StyledText>
                </div>
                {(observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}:{' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {observation}
                    </StyledText>
                  </div>
                )}
              </Fragment>
            ))}
          </StyledBox>
        </Col>
      )}
      {(Object.entries(extra_kitchen).length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.extra_kitchen.title}</StyledText>
            <div>
              <StyledText as="span">{areas.extra_kitchen.elements}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(extra_kitchen.inspection.element)}`}
              >
                {extra_kitchen.inspection.element}
              </StyledText>
            </div>
          </StyledBox>
        </Col>
      )}
      {(Object.entries(living).length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.living.title}</StyledText>
            <div>
              <StyledText as="span">{areas.living.elements}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(living.inspection.element)}`}
              >
                {living.inspection.element}
              </StyledText>
            </div>
            {(living.inspection.observation !== '') && (
              <div>
                <StyledText as="span" className="">{observationText}: {' '}</StyledText>
                <StyledText
                  as="span"
                  className={`m-0`}
                >
                  {living.inspection.observation}
                </StyledText>
              </div>
            )}
            {living.status.map(({ type, status, observation }, index: number) => (
              <Fragment key={index}>
                <div>
                  <StyledText as="span">{LIVING_STATUS[type]}:{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getVariant(status)}`}
                  >
                    {status}
                  </StyledText>
                </div>
                {(observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}:{' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {observation}
                    </StyledText>
                  </div>
                )}
              </Fragment>
            ))}
          </StyledBox>
        </Col>
      )}
      {(Object.entries(terrace).length !== 0) && (
        <>
          {(terrace.inspection) && (
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
              <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
                <StyledText className="h5 mb-2 title">{areas.terrace.title}</StyledText>
                <div>
                  <StyledText as="span">{areas.terrace.elements}{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getElementsVariant(terrace.inspection.element)}`}
                  >
                    {terrace.inspection.element}
                  </StyledText>
                </div>
                {(terrace.inspection.observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}:{' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {terrace.inspection.observation}
                    </StyledText>
                  </div>
                )}
              </StyledBox>
            </Col>
          )}
        </>
      )}
      {(Object.entries(bedroom).length !== 0) && (
        <>
          {getFilteredBedrooms().map(({ option_number, inspection, status }, index: number) => (
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2" key={index}>
              <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
                <StyledText className="h5 mb-2 title">{areas.bedroom.title} {option_number}</StyledText>
                <div>
                  <StyledText as="span">{areas.bedroom.elements}{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getElementsVariant(inspection.option_number)}`}
                  >
                    {inspection.option_number}
                  </StyledText>
                </div>
                {(inspection.observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}: {' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {inspection.observation}
                    </StyledText>
                  </div>
                )}
                {status.map(({ type, status, observation }, index: number) => (
                  <Fragment key={index}>
                    <div>
                      <StyledText as="span">{BEDROOM_STATUS[type]}:{' '}</StyledText>
                      <StyledText
                        as="span"
                        className={`m-0 ${getVariant(status)}`}
                      >
                        {status}
                      </StyledText>
                    </div>
                    {(observation !== '') && (
                      <div>
                        <StyledText as="span" className="">{observationText}:{' '}</StyledText>
                        <StyledText
                          as="span"
                          className={`m-0`}
                        >
                          {observation}
                        </StyledText>
                      </div>
                    )}
                  </Fragment>
                ))}
              </StyledBox>
            </Col>

          ))}
        </>
      )}
      {(Object.entries(bathroom).length !== 0) && (
        <>
          {getFilteredBathrooms().map(({ option_number, inspection, status }, index: number) => (
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2" key={index}>
              <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
                <StyledText className="h5 mb-2 title">{areas.bathroom.title} {option_number}</StyledText>
                <div>
                  <StyledText as="span">{areas.bathroom.elements}{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getElementsVariant(inspection.option_number)}`}
                  >
                    {inspection.option_number}
                  </StyledText>
                </div>
                {(inspection.observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}: {' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {inspection.observation}
                    </StyledText>
                  </div>
                )}
                {status.map(({ type, status, observation }, index: number) => (
                  <Fragment key={index}>
                    <div>
                      <StyledText as="span">{BATHROOM_STATUS[type]}:{' '}</StyledText>
                      <StyledText
                        as="span"
                        className={`m-0 ${getVariant(status)}`}
                      >
                        {status}
                      </StyledText>
                    </div>
                    {(observation !== '') && (
                      <div>
                        <StyledText as="span" className="">{observationText}:{' '}</StyledText>
                        <StyledText
                          as="span"
                          className={`m-0`}
                        >
                          {observation}
                        </StyledText>
                      </div>
                    )}
                  </Fragment>
                ))}
              </StyledBox>
            </Col>

          ))}
        </>
      )}
      {(Object.entries(humidity).length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.humidity.title}</StyledText>
            <div>
              <StyledText as="span">{areas.humidity.elements}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(humidity.has_humidity)}`}
              >
                {humidity.has_humidity}
              </StyledText>
            </div>
            {(humidity.has_humidity !== 'No') && (
              <div>
                <StyledText as="span">{areas.humidity.areas}{' '}</StyledText>
                <StyledText
                  as="span"
                  className={`m-0 ${getElementsVariant(humidity.areas)}`}
                >
                  {humidity.areas}
                </StyledText>
              </div>
            )}
          </StyledBox>
        </Col>
      )}
      {(Object.entries(extra).length !== 0) && (
        <>
          {extra.status.map(({ status, type, observation }, index: number) => (
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2" key={index}>
              <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
                <StyledText className="h5 mb-2 title">{areas.extra.title.start} {EXTRA_TYPE[type]} {areas.extra.title.end}</StyledText>
                <div>
                  <StyledText as="span">{areas.extra.status}{' '}</StyledText>
                  <StyledText
                    as="span"
                    className={`m-0 ${getVariant(status)}`}
                  >
                    {status}
                  </StyledText>
                </div>
                {(observation !== '') && (
                  <div>
                    <StyledText as="span" className="">{observationText}:{' '}</StyledText>
                    <StyledText
                      as="span"
                      className={`m-0`}
                    >
                      {observation}
                    </StyledText>
                  </div>
                )}
              </StyledBox>
            </Col>
          ))}
        </>
      )}
      {(Object.entries(extra_revision).length !== 0 && extra_revision.status.option_number !== '' && extra_revision.status.option_number !== 'No Aplica') && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.extra_revision.title}</StyledText>
            <div>
              <StyledText as="span">{areas.extra_revision.elements}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(extra_revision.status.status)}`}
              >
                {extra_revision.status.option_number}
              </StyledText>
            </div>
            <div>
              <StyledText as="span">
                {areas.extra_revision.status} {' '}
              </StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(extra_revision.status.status)}`}
              >
                {extra_revision.status.status}
              </StyledText>
            </div>
            {(extra_revision.status.revision !== '') && (
              <div>
                <StyledText as="span">
                  {areas.extra_revision.response} {' '}
                </StyledText>
                <StyledText
                  as="span"
                  className={`m-0 ${getElementsResponseVariant(extra_revision.status.revision)}`}
                >
                  {extra_revision.status.revision}
                </StyledText>
              </div>
            )}
          </StyledBox>
        </Col>
      )}
      {(Object.entries(extra_bill).length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.extra_bill.title}</StyledText>
            <div>
              <StyledText as="span">{areas.extra_bill.has_sent}{' '}</StyledText>
              <StyledText
                as="span"
                className={`m-0 ${getElementsVariant(extra_bill.option)}`}
              >
                {extra_bill.option}
              </StyledText>
            </div>
            {(extra_bill.revision !== '') && (
              <div>
                <StyledText as="span">
                  {areas.extra_bill.response} {' '}
                </StyledText>
                <StyledText
                  as="span"
                  className={`m-0 ${getElementsResponseVariant(extra_bill.revision)}`}
                >
                  {extra_bill.revision}
                </StyledText>
              </div>
            )}
          </StyledBox>
        </Col>
      )}
      {(video && video?.length !== 0) && (
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-2">
          <StyledBox className="rounded-4 bg-white text-white shadow-none p-3 h-100">
            <StyledText className="h5 mb-2 title">{areas.video.title}</StyledText>
            {video?.map((src: string, index: number) => (
              <div key={index}>
                <StyledText as="span">{areas.video.link}{' '}</StyledText>
                <StyledText
                  as="a"
                  className="m-0 primary"
                  href={src}
                  target="_blank"
                >
                  {areas.video.link_name}
                </StyledText>
              </div>
            ))}
          </StyledBox>
        </Col>
      )}
    </Row>
  );
};

export default InspectionDetails;