import { FC, ChangeEvent } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { StyledSearchBar, StyledText } from '../../../../common';
import { useMessages } from '../../../../../utils/hooks';

interface PropertiesFilterProps {
  filterText: string;
  onClear: () => void;
  onFilter: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PropertiesFilter: FC<PropertiesFilterProps> = ({ filterText, onFilter, onClear }) => {
  const { messages } = useMessages();
  const { filtersNames } = messages.modules.investor.properties.sections.table;

  return (
    <Row className="align-items-center justify-content-center">
      <Col xs={9} sm={10} md={4}>
        <StyledSearchBar className="mb-3 position-relative" controlId="search">
          <Form.Control
            type="text"
            name="search"
            placeholder={filtersNames.search_property}
            className="border-1 rounded-2 text-center"
            value={filterText}
            onChange={onFilter}
          />
        </StyledSearchBar>
      </Col>
      {filterText !== '' && (
        <Col xs={12} sm={12} md={4}>
          <StyledText className="active cursor-pointer text-decoration-underline" onClick={onClear}>
            {filtersNames.see_all}
          </StyledText>
        </Col>
      )}
    </Row>
  );
};

export default PropertiesFilter;