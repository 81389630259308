import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PasswordChangeForm from '../../account/passwordChange/components/PasswordChangeForm';
import CloseButton from '../../../common/CloseButton';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';
import { StyledBox } from '../../../common';

const PasswordChangePage: FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(ROUTES.ROOT, { replace: true })
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE.AUTH_CHANGE_PASSWORD}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={6} lg={5} xl={4} xxl={4} className="px-lg-5">
          <StyledBox className="px-4 px-md-5 py-5 m-auto default rounded-4 shadow-none position-relative auth-box">
            <CloseButton className="d-none" onClick={onClick} />
            <PasswordChangeForm />
          </StyledBox>
        </Col>
      </Row>
    </>
  );
};

export default PasswordChangePage;