import { FC } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { VARIANT } from '../../global';
import { StyledText } from '.';

interface StatsCardProps {
  title: string;
  text: string;
  icon?: any;
  variant?: VARIANT | string;
}

const StyledStatsCard = styled(Card)`
  background-color: ${(props: any) => props.theme.palette.statsCardBackgroundColor};
  color: ${(props: any) => props.theme.palette.statsCardTextColor};
  border-color: ${(props: any) => props.theme.palette.statsCardBorderColor};
  z-index: 0;

  & .stats-icon {
    color: ${(props: any) => props.theme.palette.statsCardIconColor};
    fill: ${(props: any) => props.theme.palette.statsCardIconColor};
  }
`;

const StatsCard: FC<StatsCardProps> = ({ title, text, icon: Icon, variant = '' }) => {
  return (
    <StyledStatsCard className="mb-2 border-0 shadow-none rounded-4 h-100">
      <Card.Body className="d-flex flex-row align-items-center">
        <div className="w-25 d-flex flex-row justify-content-center">
          <Icon size={70} className="stats-icon" />
        </div>
        <div className="w-75 text-center">
          <StyledText className="h5 fw-semibold title lh-sm mb-1">
            {text}
          </StyledText>
          <StyledText className={`fw-semibold lh-sm h5 my-0 ${variant}`}>
            {title}
          </StyledText>
        </div>
      </Card.Body>
    </StyledStatsCard>
  );
};

export default StatsCard;