
import { FC, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import axios from "axios";
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Cookies from 'js-cookie';
import CryptoJS, { AES } from 'crypto-js';
import EyeOutline from 'mdi-react/EyeOutlineIcon';
import EyeOffOutline from 'mdi-react/EyeOffOutlineIcon';
import { useMutation } from '@tanstack/react-query';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { SIGNIN_INITIAL_VALUES, SIGNIN_VALIDATION_SCHEMA } from '../../../../validations/authValidations';
import environment from '../../../../../global/environment';
import { useAuth, useMessages, useWindowResize } from '../../../../../utils/hooks';
import { BackendError, CONFIRM_STATUS, ErrorMessage, LocationState, NCRYPT_KEY } from '../../../../../global';
import Confirm from '../../../../common/Confirm';
import { ROUTES } from '../../../../../global/urls';
import { HomieRentLogo, LockBold, Mail } from '../../../../assets/icons';
import { AUTH_ERROR } from '../../../../../global/errors';
import {
  StyledAddon,
  StyledAlert,
  StyledCheckbox,
  StyledInput,
  StyledInvalidFeedback,
  StyledPasswordButton,
  StyledSubmitButton,
  StyledText,
} from '../../../../common';
import { Application } from '../../../../../global/interfaces';

const breakpoints = {
  md: 768,
}

export const SignInForm: FC = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  let auth = useAuth();
  const { messages } = useMessages();
  const [width] = useWindowResize();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
  const [localLogin, setLocalLogin] = useState<typeof SIGNIN_INITIAL_VALUES | null>(null);
  const { dialogs: { first_time, next_time }, fields, links } = messages.forms.sign_in;
  const { dialogs } = messages;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('isFirstTime') as string)) {
      setIsFirstTime(JSON.parse(localStorage.getItem('isFirstTime') as string));
    }
  }, []);

  useEffect(() => {
    if (Cookies.get('localLogin')) {
      setLocalLogin(JSON.parse(AES.decrypt(Cookies.get('localLogin') as string, NCRYPT_KEY).toString(CryptoJS.enc.Utf8)));
    }
  }, []);

  const toggleConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onConfirm = () => {
    setErrorMessage(null);
    setOpenConfirm(false);
  };

  const baseURL = process.env.PUBLIC_URL;
  let from = (location.state as LocationState)?.from?.pathname || `${baseURL}/home`;

  const { mutate, isLoading } = useMutation((values: any) => {
    return axios.post(`${environment.ENDPOINT_AUTH}/auth/token`, {
      'grant_type': 'password',
      'client_id': environment.AUTH_CLIENT_ID,
      'username': values.email,
      'password': values.password,
      'client_secret': environment.AUTH_CLIENT_SECRET
    });
  });


  const handleSubmit = (values: typeof SIGNIN_INITIAL_VALUES, setSubmitting: (isSubmitting: boolean) => void) => {
    setErrorMessage(null);
    auth.setErrorAlert(null);
    mutate(values, {
      onSuccess: (response: any) => {
        const { email, remember } = values;
        let rols = response.data.scope ? response.data.scope.split(" ") : [];
        let apps: Application[] = [];
        let shouldChangePassword = response.data.force_change_password;
        if (!shouldChangePassword) {
          auth.signin(email, rols, apps, () => {
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            navigate(from, { replace: true });
          });
          if (!remember) {
            Cookies.remove('localLogin');
          }
          if (remember) {
            Cookies.set('localLogin', AES.encrypt(JSON.stringify(values), NCRYPT_KEY).toString());
          }
        } else {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          localStorage.setItem('isForcedChangePassword', JSON.stringify(true));
          navigate(ROUTES.LOGIN_CHANGE_PASSWORD, { replace: true });
        }
      },
      onError: (error: any) => {
        const { status, data } = error.response;
        const { code }: BackendError = data;
        if (status === 400 && code === AUTH_ERROR.INVALID_CREDENTIALS) {
          setErrorMessage({
            title: dialogs.invalid_credentials.title,
            text: dialogs.invalid_credentials.text,
            status: CONFIRM_STATUS.ERROR,
          });
          setOpenConfirm(true);
        }
        if (status === 403 && code === AUTH_ERROR.USER_BLOCKED) {
          setErrorMessage({
            title: dialogs.temporally_blocked.title,
            text: dialogs.temporally_blocked.text,
            status: CONFIRM_STATUS.BLOCKED,
          });
          setOpenConfirm(true);
        }
        if (status === 403 && data.description) {
          auth.setErrorAlert(data.description);
        }
        // TODO: Definir si se mostrará mensaje de error con servidor o de conexión - No tiene diseño en figma
        setSubmitting(false);
      }
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={(localLogin) ? localLogin : SIGNIN_INITIAL_VALUES}
      validateOnChange={false}
      validationSchema={SIGNIN_VALIDATION_SCHEMA}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldError,
        isSubmitting,
        handleReset,
        isValid,
      }) => (
        <>
          <form onSubmit={handleSubmit} className="py-3 px-md-3">
            <Row>
              <Col xs={12}>
                <div className="w-100 d-flex flex-row justify-content-center mb-3">
                  <HomieRentLogo width={150} height={80} color={theme.palette.primary.main} className={`mb-2 w-50 ${auth.errorAlert ? 'px-4' : 'px-3'}`} />
                </div>
                {!auth.errorAlert && (
                  <>
                    <StyledText className={`${(width <= breakpoints.md) ? 'h1' : 'h2'} title text-center mb-2 fw-normal`}>
                      {isFirstTime ? first_time.title : next_time.title}
                    </StyledText>
                    <StyledText className="subtitle fst-italic text-center mb-4">
                      {isFirstTime ? first_time.description : next_time.description}
                    </StyledText>
                  </>
                )}
              </Col>
              {auth.errorAlert && (
                <Col xs={12}>
                  <StyledAlert className="d-flex flex-row align-items-center secondary-light">
                    <div className="me-3">
                      <InformationOutline size={40} />
                    </div>
                    {auth.errorAlert}
                  </StyledAlert>
                </Col>
              )}
              <Col xs={12}>
                <div className="input-group">
                  <StyledAddon
                    className={`input-group-text border-end-0 rounded-0 ${(errors.email && touched.email) && 'border-end-0 with-error'}`}
                    id="addon-wrapping"
                    hasError={(errors.email && touched.email)}
                    isFilled={(values.email.length > 0)}
                  >
                    <Mail
                      size={24}
                      color={
                        ((errors.email && touched.email) || (values.email.length > 0))
                          ? 'currentColor' : theme.palette.inputTextColor}
                    />
                  </StyledAddon>
                  <StyledInput
                    type="email"
                    name="email"
                    className={`border-start-0 rounded-0 ${(errors.email && touched.email) ? 'with-error' : ''}`}
                    id="email"
                    placeholder={fields.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldError('email', undefined)}
                    value={values.email}
                    isFilled={(values.email.length > 0)}
                    isInvalid={errors.email && touched.email}
                  />
                  {errors.email && touched.email && (
                    <StyledInvalidFeedback className="invalid-feedback">
                      {errors.email}
                    </StyledInvalidFeedback>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-group my-3">
                  <StyledAddon
                    className={`input-group-text border-end-0 rounded-0 ${(errors.password && touched.password) && 'border-end-0 with-error'}`}
                    id="addon-wrapping"
                    hasError={(errors.password && touched.password)}
                    isFilled={(values.password.length > 0)}
                  >
                    <LockBold
                      size={24}
                      color={
                        ((errors.password && touched.password) || (values.password.length > 0))
                          ? 'currentColor' : theme.palette.inputTextColor}
                    />
                  </StyledAddon>
                  <StyledInput
                    type={(showPassword) ? 'text' : 'password'}
                    className={
                      errors.password && touched.password ? 'form-control is-invalid border-start-0 border-end-0 rounded-0 with-error' : 'form-control rounded-0 border-start-0 border-end-0'
                    }
                    name="password"
                    id="password"
                    placeholder={fields.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldError('password', undefined)}
                    value={values.password}
                    isFilled={(values.password.length > 0)}
                  />
                  <StyledPasswordButton
                    className={`btn border-start-0 rounded-0 ${(errors.password && touched.password) && 'with-error'}`}
                    hasError={(errors.password && touched.password)}
                    isFilled={(values.password.length > 0)}
                    type="button"
                    id="toggle-password"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ?
                      (<EyeOffOutline
                        size={24}
                        color={((errors.password && touched.password) || (values.password.length > 0))
                          ? 'currentColor' : theme.palette.inputTextColor}
                      />) : (
                        <EyeOutline
                          size={24}
                          color={((errors.password && touched.password) || (values.password.length > 0))
                            ? 'currentColor' : theme.palette.inputTextColor}
                        />)
                    }
                  </StyledPasswordButton>
                  {errors.password && touched.password && (
                    <StyledInvalidFeedback className="invalid-feedback">
                      {errors.password}
                    </StyledInvalidFeedback>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <StyledSubmitButton
                  className="btn w-100 btn-lg py-1 primary filled"
                  type="submit"
                  disabled={isSubmitting}
                // $isValid={(isValid && (values.email.length > 0) && (values.password.length > 0) && !errorMessage && !auth.errorAlert)}
                >
                  {isFirstTime || auth.errorAlert ? first_time.submit_button : next_time.submit_button}
                  {isLoading &&
                    <span
                      className="spinner-grow float-end"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  }
                </StyledSubmitButton>
              </Col>
              <Col xs={12}>
                <div className="form-check mt-2">
                  <StyledCheckbox
                    className="form-check-input"
                    type="checkbox"
                    id="remember"
                    checked={values.remember}
                    onChange={handleChange}
                    name="remember"
                  />
                  <label className="form-check-label" htmlFor="remember">
                    {fields.remember}
                  </label>
                </div>
              </Col>
              <Col xs={12} className="text-center my-2">
                <StyledText as={NavLink} to={ROUTES.RECOVER_PASSWORD} className="primary fw-semibold h5">{links.recover_password}</StyledText>
              </Col>
            </Row>
          </form>
          {errorMessage && (
            <Confirm
              show={openConfirm}
              onHide={toggleConfirm}
              title={errorMessage.title}
              text={errorMessage.text}
              status={errorMessage?.status as string}
              enableClose
              variant="secondary-light"
              actions={(errorMessage?.status as string !== 'blocked') ? (
                <div className="row">
                  <div className="col-6 col-sm-6">
                    <StyledSubmitButton
                      bsPrefix="btn w-100 rounded-4 primary-outline shadow-sm"
                      onClick={onConfirm}
                    >
                      Verificar
                    </StyledSubmitButton>
                  </div>
                  <div className="col-6 col-sm-6">
                    <StyledSubmitButton
                      bsPrefix="btn w-100 rounded-4 primary-outline shadow-sm"
                      onClick={() => {
                        onConfirm();
                        handleReset();
                      }}
                    >
                      Ingresar
                    </StyledSubmitButton>
                  </div>
                </div>
              ) : null}
            />
          )}
        </>
      )}
    </Formik>
  );
};
