import { FC } from 'react'
import { PERMISSIONS } from '../../../global/permissions';
import { Authorization } from '../../../routes/ProtectedRoute';

const NotificationsPage: FC = () => {
  return (
    <Authorization allowedRole={PERMISSIONS.PLA_NOTIF_12}>
      <div>NotificationsPage</div>
    </Authorization>
  );
};

export default NotificationsPage;