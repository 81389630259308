import { FC, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import NewspaperVariantOutline from 'mdi-react/NewspaperVariantOutlineIcon';
import CalendarMonthOutline from 'mdi-react/CalendarMonthOutlineIcon';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import AlertBoxOutline from 'mdi-react/AlertBoxOutlineIcon';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { StyledBox, StyledEmptyContainer, StyledPostImage, StyledPublicationCard, StyledSubmitButton, StyledText } from '../../../common';
import { useMessages, useWindowResize } from '../../../../utils/hooks';
import { AssetDataPublication } from '../../../../global/interfaces';
import { PUBLICATION_MODALITY } from '../../../../global';
import { stackRentApi } from '../../../../apis/stackRentApi';
import Spinner from '../../../common/Spinner';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';

const smBreakpoint = 768;

const InvestorPublicationsPage: FC = () => {
  const navigate = useNavigate();
  const [publications, setPublications] = useState<AssetDataPublication[]>([]);
  const { messages } = useMessages();
  const [width] = useWindowResize();
  const theme = useTheme();
  const { title } = messages.modules.investor.publications;
  const { title: no_publications, description } = messages.modules.investor.publications.sections.no_publication;
  const { published_at, publish_date, republish_date, request_total, portals } = messages.modules.investor.publications.sections.publication.fields;
  const { see_details } = messages.modules.investor.publications.actions;

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['investor/properties/publications'], async () => {
    return await stackRentApi.get('publish/me', {
      params: { modality_name: PUBLICATION_MODALITY.ARRIENDO }
    });
  },
    {
      onSuccess: (response: AxiosResponse) => {
        if (response.data.length !== 0) {
          setPublications(response.data);
        }
        else {
          setPublications([]);
        }
      },
      onError: (error: AxiosError) => {
        setPublications([]);
      }
    });

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }

  if (publications && publications.length === 1) {
    const { asset_data: { asset_id } }: AssetDataPublication = publications[0];
    navigate(`${ROUTES.INVESTOR_PUBLICATION_BY_ID}/${asset_id}`, { replace: true })
  };

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_16}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_PUBLICATIONS}</title>
      </Helmet>
      <Container className="px-0 pt-md-5">
        {(publications.length !== 0) ? (
          <>
            <Row>
              <Col xs={12}>
                <StyledText className="h2 mb-4 title text-center">{title}</StyledText>
              </Col>
            </Row>
            {publications.map(({ asset_data: publication, id }: AssetDataPublication, index: number) => (
              <div className="mb-3 mx-md-5 px-md-5" key={index}>
                <StyledPublicationCard className="p-0 bg-white rounded-4">
                  <div className="d-flex flex-column flex-md-row">
                    <div className={`${width < smBreakpoint ? 'w-100' : 'w-40'} d-flex flex-row justify-content-center`}>
                      <StyledPostImage
                        src={publication.images[0]?.url}
                        alt={publication.asset_address}
                      />
                    </div>
                    <div className={`${width < smBreakpoint ? 'w-100' : 'w-60'} pt-1 pb-3 px-4`}>
                      <StyledText className="tertary text-end w-100 mb-0">{publication.date_text}</StyledText>
                      <StyledText className="h4 mb-3 mt-2 my-md-2 title text-center text-md-start">{publication.asset_name}</StyledText>
                      <StyledBox className="rounded-4 p-2 w-100 shadow-none primary-alpha mb-3">
                        <div className="w-100 px-2">
                          <NewspaperVariantOutline size={22} className="m-0 me-2" />
                          <StyledText className="primary fw-semibold m-0" as="span">{published_at}:{' '}</StyledText>
                          <StyledText className="primary m-0" as="span">{publication.publications.length} {portals}</StyledText>
                        </div>
                        <div className="w-100 px-2">
                          <CalendarMonthOutline size={22} className="m-0 me-2" />
                          <StyledText className="primary fw-semibold m-0" as="span">{publish_date}:{' '}</StyledText>
                          <StyledText className="primary m-0" as="span">{publication.publish_date}</StyledText>
                        </div>
                        <div className="w-100 px-2">
                          <CalendarMonthOutline size={22} className="m-0 me-2" />
                          <StyledText className="primary fw-semibold m-0" as="span">{republish_date}:{' '}</StyledText>
                          <StyledText className="primary m-0" as="span">{publication.republish_date}</StyledText>
                        </div>
                      </StyledBox>
                      <StyledBox className="rounded-4 p-2 w-100 shadow-none primary-alpha d-none">
                        <div className="w-100 px-2">
                          <InformationOutline size={22} className="m-0 me-2" />
                          <StyledText className="primary fw-semibold m-0" as="span">{request_total}:{' '}</StyledText>
                          <StyledText className="primary m-0" as="span">130</StyledText>
                        </div>
                      </StyledBox>
                      <Row className="justify-content-center mt-4">
                        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                          <StyledSubmitButton
                            className="btn w-100 primary filled rounded-2 shadow-sm"
                            as={NavLink}
                            to={`${ROUTES.INVESTOR_PUBLICATION_BY_ID}/${publication.asset_id}`}
                          >
                            {see_details}
                          </StyledSubmitButton>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </StyledPublicationCard>
              </div>
            ))}
          </>
        ) : (
          <>
            <Row className="justify-content-center">
              <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                <div className="d-flex flex-row justify-content-center w-100">
                  <AlertBoxOutline size={100} color={theme.palette.activeTextColor} />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xs={12}>
                <StyledText className="h3 fw-semibold text-center">
                  {no_publications}
                </StyledText>
              </Col>
              <Col xs={12} sm={12} md={9} lg={6}>
                <StyledText className="h5 fw-normal text-center mt-2">
                  {description}
                </StyledText>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Authorization >
  );
};

export default InvestorPublicationsPage;