import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMessages, useUtils } from '../../../../../utils/hooks';
import { Property } from '../../../../../global/interfaces';
import { StyledText } from '../../../../common';

interface FeaturesCardProps {
  property: Property;
}

const FeaturesCard: FC<FeaturesCardProps> = ({ property }) => {
  const { total_area, useful_surface, quantity_rooms, quantity_bathrooms, quantity_lift, parking, warehouse, furnished, warehouse_number, parking_number } = property;
  const { messages } = useMessages();
  const { isYesOrNot, getNumberToString } = useUtils();
  const { fields, title } = messages.modules.investor.properties.sections.information.features;

  return (
    <Row>
      <Col xs={12} className="mb-3 d-none d-md-block">
        <StyledText className="h4 title">{title}</StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.total_surface}: </span>
          {getNumberToString(total_area, true)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.useful_surface}: </span>
          {getNumberToString(useful_surface, true)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.bedrooms_quantity}: </span>
          {getNumberToString(quantity_rooms)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.bathrooms_quantity}: </span>
          {getNumberToString(quantity_bathrooms)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.elevators_quantity}: </span>
          {getNumberToString(quantity_lift)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.furnished}: </span>
          {isYesOrNot(furnished)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.parking}: </span>
          {isYesOrNot(parking)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.parking_number}: </span>
          {getNumberToString(parking_number)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.warehouse}: </span>
          {isYesOrNot(warehouse)}
        </StyledText>
      </Col>
      <Col xs={12}>
        <StyledText className="subtitle mb-1">
          <span className="fw-semibold">{fields.warehouse_number}: </span>
          {getNumberToString(warehouse_number)}
        </StyledText>
      </Col>
    </Row>
  );
};

export default FeaturesCard;