import * as Yup from 'yup';

export interface UpdateRequestValues {
  email: string;
  phone: string;
}

export const UPDATE_REQUEST_INITIAL_VALUES: UpdateRequestValues = {
  email: '',
  phone: '',
}

export const UPDATE_REQUEST_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Correo electrónico incorrecto'),
  phone: Yup.string(),
});