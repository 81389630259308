import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { PATHS, ROUTES } from '../global/urls';
import { AppLayout, AuthLayout, Layout } from '../views/layout';
import {
  SignInPage,
  PasswordChangePage,
  ForcedPasswordChangePage,
  NotificationsPage,
  ProfilePage,
  InvestorHomePage,
  InvestorPropertiesPage,
  InvestorPublicationsPage,
  InvestorContractsPage,
  InvestorSupportPage,
  ManagementHomePage,
  ManagementInspectionsPage,
  HomePage,
  InvestorCreateTicketPage,
  ManagementUsersPage,
  ManagementInspectionDetailsPage,
  InvestorPublicationDetailsPage,
  ManagementPropertiesPage,
  ManagementPropertyDetailsPage,
  ManagementPublicationDetailsPage,
  PasswordRecoverPage,
} from '../views/modules';
import { Error404Page } from '../views/modules/errors';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);


export const router = sentryCreateBrowserRouter([
  {
    path: PATHS.ROOT,
    element: <Layout />,
    children: [
      { // Authentication Routes
        path: PATHS.ROOT,
        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <SignInPage />,
          },
          {
            path: ROUTES.LOGIN,
            element: <SignInPage />,
          },
          {
            path: ROUTES.RECOVER_PASSWORD,
            element: <PasswordRecoverPage />,
          },
          {
            path: ROUTES.LOGIN,
            children: [
              {
                path: PATHS.CHANGE_PASSWORD,
                element: <ForcedPasswordChangePage />,
              }
            ]
          },
        ]
      },
      { // App Root Routes
        path: PATHS.ROOT,
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: PATHS.HOME,
            element: <HomePage />,
          },
          {
            path: PATHS.NOTIFICATIONS,
            children: [
              {
                index: true,
                element: <NotificationsPage />,
              },
            ]
          },
          { // Account path routes
            path: PATHS.ACCOUNT,
            children: [
              {
                path: PATHS.CHANGE_PASSWORD,
                element: <PasswordChangePage />,
              },
              {
                path: PATHS.PROFILE,
                element: <ProfilePage />,
              },
              {
                path: PATHS.SETTINGS,
                element: <></>,
              },
              {
                path: PATHS.HELP,
                element: <></>,
              },
            ]
          },
          { // Investor path routes
            path: PATHS.INVESTOR,
            children: [
              {
                index: true,
                element: <InvestorHomePage />,
              },
              {
                path: PATHS.HOME,
                element: <InvestorHomePage />,
              },
              {
                path: PATHS.PROPERTIES,
                element: <InvestorPropertiesPage />
              },
              {
                path: PATHS.PROPERTIES,
                children: [
                  {
                    path: PATHS.INSPECTION,
                    children: [
                      {
                        path: ':inspectionId',
                        element: <ManagementInspectionDetailsPage />,
                      },
                    ]
                  },
                ]
              },
              {
                path: PATHS.PUBLICATIONS,
                element: <InvestorPublicationsPage />,
              },
              {
                path: PATHS.PUBLICATIONS,
                children: [
                  {
                    path: ':publicationId',
                    element: <InvestorPublicationDetailsPage />,
                  },
                ]
              },
              {
                path: PATHS.CONTRACTS,
                element: <InvestorContractsPage />,
              },
              {
                path: PATHS.SUPPORT,
                element: <InvestorSupportPage />,
              },
              {
                path: PATHS.SUPPORT,
                children: [
                  {
                    path: PATHS.CREATE_TICKET,
                    element: <InvestorCreateTicketPage />,
                  }
                ]
              }
            ]
          },
          { // Broker path routes
            path: PATHS.BROKER,
            children: [
              {
                index: true,
                element: <></>,
              },
              {
                path: PATHS.HOME,
                element: <></>,
              },
            ]
          },
          { // Landlord / Tenant path routes
            path: PATHS.LANDLORD,
            children: [
              {
                index: true,
                element: <></>,
              },
              {
                path: PATHS.HOME,
                element: <></>,
              },
            ]
          },
          { // Management path routes
            path: PATHS.MANAGEMENT,
            errorElement: <Error404Page />,
            children: [
              {
                index: true,
                element: <ManagementHomePage />,
              },
              {
                path: PATHS.HOME,
                element: <ManagementHomePage />,
              },
              {
                path: PATHS.INSPECTIONS,
                element: <ManagementInspectionsPage />,
              },
              {
                path: PATHS.INSPECTIONS,
                children: [
                  {
                    path: ':inspectionId',
                    element: <ManagementInspectionDetailsPage />,
                  },
                ]
              },
              {
                path: PATHS.USERS,
                element: <ManagementUsersPage />,
              },
              {
                path: PATHS.PROPERTIES,
                element: <ManagementPropertiesPage />,
              },
              {
                path: PATHS.PROPERTIES,
                children: [
                  {
                    path: ':assetId',
                    element: <ManagementPropertyDetailsPage />,
                  },
                  {
                    path: PATHS.PUBLICATION,
                    children: [
                      {
                        path: ':assetId',
                        element: <ManagementPublicationDetailsPage />,
                      },
                    ]
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: '*',
        element: <Error404Page />,
      },
    ]
  },
]);