import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import NewspaperVariantOutline from 'mdi-react/NewspaperVariantOutlineIcon';
import CalendarMonthOutline from 'mdi-react/CalendarMonthOutlineIcon';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { StyledBox, StyledText } from '../../../../common';
import PictureGallery from '../../../../common/PictureGallery';
import { PublicationItem } from '.';
import { useMessages, useUtils } from '../../../../../utils/hooks';
import { PropertyPublication } from '../../../../../global/interfaces';

interface PublicationDetailsProps {
  publication: PropertyPublication;
}

const PublicationDetails: FC<PublicationDetailsProps> = ({ publication }) => {
  const { messages } = useMessages();
  const { localFloatToString } = useUtils();
  const { publishers, published_at, portals, publish_date, republish_date, request_total, publication_value } = messages.modules.investor.publications.sections.publication.fields;
  
  const hasRepublishDate = (publication.republish_date && publication.republish_date !== '') ? true : false;

  return (
    <Row>
      <Col xs={12} className="px-md-5">
        <StyledText className="h2 mb-4 title text-center">{publication.asset_name}</StyledText>
      </Col>
      <Col xs={12} className="px-md-5">
        <StyledBox className="rounded-4 bg-white text-white shadow-none px-3 pb-2 h-100">
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="h-100">
              <PictureGallery images={publication.images?.map((image: { url: string }) => ({ src: image.url }))} />
              <Row className="py-3 pb-0 pb-md-2">
                <Col xs={12}>
                  <StyledText className="tertary text-end w-100 m-0 mb-2 d-block d-md-none">{publication.date_text}</StyledText>
                  <StyledBox className="rounded-4 w-100 primary shadow-none mb-3 mb-md-2 p-2 py-3 p-md-4">
                    <div className="d-flex flex-row align-items-center">
                      <div className="w-60 d-flex flex-row justify-content-center">
                        <StyledText className="fw-semibold text-center lh-sm h5 text-white m-0 px-md-5">
                          {publication_value}
                        </StyledText>
                      </div>
                      <div className="w-40 text-center">
                        <StyledText className="fw-semibold lh-sm mb-1 h3 text-center text-white">
                          {`${publication.price?.currency.symbol} ${localFloatToString(publication.price?.value)}`}
                        </StyledText>
                      </div>
                    </div>
                  </StyledBox>
                </Col>
                <Col xs={12}>
                  <StyledBox className={`rounded-4 p-2 ${!hasRepublishDate ? 'pb-3' : ''} w-100 shadow-none primary-alpha mb-3 mt-md-1 h-100`}>
                    <div className="w-100 px-2">
                      <NewspaperVariantOutline size={22} className="m-0 me-2" />
                      <StyledText className="primary fw-semibold m-0" as="span">{published_at}:{' '}</StyledText>
                      <StyledText className="primary m-0" as="span">{publication.publications.length} {portals}</StyledText>
                    </div>
                    <div className={`w-100 px-2 ${!hasRepublishDate ? 'pb-1' : ''}`}>
                      <CalendarMonthOutline size={22} className="m-0 me-2" />
                      <StyledText className="primary fw-semibold m-0" as="span">{publish_date}:{' '}</StyledText>
                      <StyledText className="primary m-0" as="span">{publication.publish_date}</StyledText>
                    </div>
                    {hasRepublishDate && (
                      <div className="w-100 px-2">
                        <CalendarMonthOutline size={22} className="m-0 me-2" />
                        <StyledText className="primary fw-semibold m-0" as="span">{republish_date}:{' '}</StyledText>
                        <StyledText className="primary m-0" as="span">{publication.republish_date}</StyledText>
                      </div>
                    )}
                  </StyledBox>
                  <StyledBox className="rounded-4 p-2 w-100 shadow-none primary-alpha d-none">
                    <div className="w-100 px-2">
                      <InformationOutline size={22} className="m-0 me-2" />
                      <StyledText className="primary fw-semibold m-0" as="span">{request_total}:{' '}</StyledText>
                      <StyledText className="primary m-0" as="span">{publication.leads_count}</StyledText>
                    </div>
                  </StyledBox>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="h-100">
              <StyledText className="tertary text-end w-100 my-3 d-none d-md-block">{publication.date_text}</StyledText>
              <StyledBox className={`rounded-4 ${hasRepublishDate ? 'p-3' : 'pt-3 pb-1 px-3'} w-100 shadow-none primary-alpha`}>
                <StyledText className="primary text-start h5 w-100 mb-3">{publishers}</StyledText>
                {publication.publications.map(({ key, name, date, url }) => (
                  <PublicationItem
                    name={name}
                    keyName={key}
                    date={date}
                    url={url}
                  />
                ))}
              </StyledBox>
            </Col>
          </Row>
        </StyledBox>
      </Col>
    </Row>
  );
};

export default PublicationDetails;