export enum PATHS {
  ROOT = '',
  AUTH = 'auth',
  HOME = 'home',
  LOGIN = 'login',
  LOGOUT = 'logout',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  RECOVER_PASSWORD = 'recover-password',
  RESET_PASSWORD = 'reset-password',
  PROFILE = 'profile',
  CHANGE_PASSWORD_TEST = 'changepass',
  NOTIFICATIONS = 'notifications',
  HELP = 'help',
  SETTINGS = 'settings',
  ACCOUNT = 'account',
  CHANGE_PASSWORD = 'change-password',
  INVESTOR = 'investor',
  BROKER = 'broker',
  LANDLORD = 'landlord',
  MANAGEMENT = 'management',
  PROPERTIES = 'properties',
  PUBLICATIONS = 'publications',
  CONTRACTS = 'contracts',
  SUPPORT = 'support',
  INSPECTIONS = 'inspections',
  INSPECTION = 'inspection',
  USERS = 'users',
  CREATE_TICKET = 'create-ticket',
  MANAGE = 'manage',
  PUBLICATION = 'publication'
}

export enum ROUTES {
  ROOT = '/',
  AUTH = '/auth',
  HOME = '/home',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  RECOVER_PASSWORD = '/recover-password',
  RESET_PASSWORD = '/reset-password',
  CHANGE_PASSWORD_TEST = '/home/changepass',
  NOTIFICATIONS = '/notifications',
  ACCOUNT_HELP = '/account/help',
  ACCOUNT_SETTINGS = '/account/settings',
  ACCOUNT_PROFILE = '/account/profile',
  ACCOUNT_CHANGE_PASSWORD = '/account/change-password',
  LOGIN_CHANGE_PASSWORD = '/login/change-password',
  INVESTOR = '/investor',
  INVESTOR_HOME = '/investor/home',
  INVESTOR_PROPERTIES = '/investor/properties',
  INVESTOR_PUBLICATIONS = '/investor/publications',
  INVESTOR_PUBLICATION_BY_ID = '/investor/publications',
  INVESTOR_CONTRACTS = '/investor/contracts',
  INVESTOR_SUPPORT = '/investor/support',
  SUPPORT = '/support',
  SUPPORT_CREATE_TICKET = '/investor/support/create-ticket',
  MANAGEMENT = '/management',
  MANAGEMENT_HOME = '/management/home',
  MANAGEMENT_INSPECTIONS = '/management/inspections',
  MANAGEMENT_INSPECTION_BY_ID = '/management/inspections',
  MANAGEMENT_USERS = '/management/users',
  MANAGEMENT_PROPERTIES = '/management/properties',
  MANAGEMENT_PROPERTY_BY_ID = '/management/properties',
  MANAGEMENT_PROPERTY_PUBLICATION = '/management/properties/publication',
  MANAGEMENT_PROPERTY_PUBLICATION_BY_ID = '/management/properties/publication',
  INVESTOR_PROPERTY_INSPECTION = '/investor/properties/inspection',
  INVESTOR_PROPERTY_INSPECTION_BY_ID = '/investor/properties',

}

export enum ROUTES_LABELS {
  ACCOUNT_SETTINGS = 'Configuración',
  ACCOUNT_HELP = 'Ayuda',
  ACCOUNT_CHANGE_PASSWORD = 'Cambiar Contraseña',
  ACCOUNT_PROFILE = 'Cuenta de Usuario',
  HOME = 'Inicio',
  PROPERTIES = 'Propiedades',
  PUBLICATIONS = 'Publicaciones',
  PUBLICATION = 'Información de la publicación',
  CONTRACTS = 'Contratos',
  SUPPORT = 'Servicio al Cliente',
  SUPPORT_CREATE_TICKET = 'Crea tu ticket',
  MANAGEMENT = 'Inicio',
  INSPECTIONS = 'Inspecciones',
  INSPECTION = 'Informe de Autoinspección',
  USERS = 'Usuarios',
  PROPERTY = 'Información de la propiedad',
};

export enum PAGE_TITLE {
  AUTH_SIGN_IN = 'Homie rent | Clientes | Iniciar Sesión',
  AUTH_RECOVER_PASSWORD = 'Homie rent | Clientes | Recuperar Contraseña',
  AUTH_CHANGE_PASSWORD = 'Homie rent | Clientes | Cambiar Contraseña',

  ACCOUNT_PROFILE = 'Homie rent | Clientes | Cuenta de Usuario',
  ACCOUNT_CHANGE_PASSWORD = 'Homie rent | Clientes | Cambiar Contraseña',
  HOME = 'Homie rent | Clientes | Inicio',


  INVESTOR_HOME = 'Homie rent | Clientes | Inicio | Portal de Inversionistas',
  INVESTOR_PROPERTIES = 'Homie rent | Clientes | Propiedades | Portal de Inversionistas',
  INVESTOR_PUBLICATIONS = 'Homie rent | Clientes | Publicaciones | Portal de Inversionistas',
  INVESTOR_PUBLICATION_DETAILS = 'Homie rent | Clientes | Detalles de la Publicación | Portal de Inversionistas',
  INVESTOR_CONTRACTS = 'Homie rent | Clientes | Contratos | Portal de Inversionistas',
  INVESTOR_SUPPORT = 'Homie rent | Clientes | Soporte | Portal de Inversionistas',
  INVESTOR_SUPPORT_CREATE_TICKET = 'Homie rent | Clientes | Crear Ticket de Soporte | Portal de Inversionistas',
  INVESTOR_INSPECTION_DETAILS = 'Homie rent | Clientes | Detalles de la Inspección | Portal de Inversionistas',
  
  MANAGEMENT_HOME = 'Homie rent | Clientes | Inicio | Portal de Gestión',
  MANAGEMENT_INSPECTIONS = 'Homie rent | Clientes | Inspecciones | Portal de Gestión',
  MANAGEMENT_INSPECTION_DETAILS = 'Homie rent | Clientes | Detalles de la Inspección | Portal de Gestión',
  MANAGEMENT_USERS = 'Homie rent | Clientes | Usuarios | Portal de Gestión',
  MANAGEMENT_PROPERTIES = 'Homie rent | Clientes | Propiedades | Portal de Gestión',
  MANAGEMENT_PROPERTY_DETAILS = 'Homie rent | Clientes | Detalles de la Propiedad | Portal de Gestión',
  MANAGEMENT_PROPERTY_PUBLICATION_DETAILS = 'Homie rent | Clientes | Detalles de la Publicación | Portal de Gestión',
}