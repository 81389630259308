import { FC } from 'react'
import { Button } from 'react-bootstrap';
import Close from 'mdi-react/CloseIcon';
import styled from 'styled-components';
import chroma from 'chroma-js';

interface CloseButtonProps {
  onClick?: () => void;
  className?: string;
}

const StyledButtonClose = styled(Button)`
  background-color: ${(props: any) => props.theme.palette.primary.contrastText};
  color: ${(props: any) => props.theme.palette.primary.main};
  &:hover {
    background-color: ${(props: any) => props.theme.palette.primary.contrastText};
    color: ${(props: any) => props.theme.palette.primary.main};
  }

  &:focus, &.active, &:active, &:active:focus, .active:focus {
    background-color: ${(props: any) => props.theme.palette.primary.contrastText};
    color: ${(props: any) => props.theme.palette.primary.main};
  }

  &.primary {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    &:hover {
      background-color: ${(props: any) => props.theme.palette.primary.dark};
    }
    &:focus, &.active, &:active, &:active:focus, .active:focus {
      border-color: ${(props: any) => props.theme.palette.primary.dark};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.main).alpha(props.theme.opacity.low).css()};
    }
  }

  &.secondary {
    background-color: ${(props: any) => props.theme.palette.secondary?.main};
    &:hover {
      background-color: ${(props: any) => props.theme.palette.secondary?.dark};
    }
    &:focus, &.active, &:active, &:active:focus, .active:focus {
      border-color: ${(props: any) => props.theme.palette.secondary?.dark};
      background-color: ${(props: any) => props.theme.palette.secondary?.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary?.main).alpha(props.theme.opacity.low).css()};
    }
  }

  &.tertary {
    background-color: ${(props: any) => props.theme.palette.tertary?.main};
    &:hover {
      background-color: ${(props: any) => props.theme.palette.tertary?.dark};
    }
    &:focus, &.active, &:active, &:active:focus, .active:focus {
      border-color: ${(props: any) => props.theme.palette.tertary?.dark};
      background-color: ${(props: any) => props.theme.palette.tertary?.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary?.main).alpha(props.theme.opacity.low).css()};
    }
  }

  &.quaternary {
    background-color: ${(props: any) => props.theme.palette.quaternary?.main};
    &:hover {
      background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
    }
    &:focus, &.active, &:active, &:active:focus, .active:focus {
      border-color: ${(props: any) => props.theme.palette.quaternary?.dark};
      background-color: ${(props: any) => props.theme.palette.quaternary?.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary?.main).alpha(props.theme.opacity.low).css()};
    }
  }
`;

const CloseButton: FC<CloseButtonProps> = ({ onClick, className }) => {
  return (
    <StyledButtonClose
      size="sm"
      className={`border-0 p-2 rounded-circle position-absolute top-0 end-0 shadow-sm m-2 ${(className) ? className : ''}`}
      onClick={onClick ? onClick : undefined}
      style={{ zIndex: 10 }}
    >
      <Close size={26} />
    </StyledButtonClose>
  )
}

export default CloseButton;