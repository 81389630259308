import { createContext, useState, Dispatch, SetStateAction } from 'react';
import { Property, Ticket } from '../../global/interfaces';

interface AppContextType {
  currentProperty: Property;
  setCurrentProperty: Dispatch<SetStateAction<Property>>;
  currentTicket: Ticket;
  setCurrentTicket: Dispatch<SetStateAction<Ticket>>;
}

export const AppContext = createContext<AppContextType>(null!);

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  let [currentProperty, setCurrentProperty] = useState<Property>({} as Property);
  let [currentTicket, setCurrentTicket] = useState<Ticket>({} as Ticket);

  return (
    <AppContext.Provider value={{
      currentProperty,
      setCurrentProperty,
      currentTicket,
      setCurrentTicket
    }}>
      {children}
    </AppContext.Provider>
  );
}
