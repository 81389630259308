import { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import styled from 'styled-components';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeft from 'mdi-react/ChevronLeftIcon';
import { StyledMenuButton, StyledPostImage } from '../../../../common';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Image } from '../../../../../global/interfaces';

interface PublicationGalleryProps {
  images: Image[];
}

const StyledSwiperSlide = styled(SwiperSlide)`
  background-color: #D9D9D9 !important;

  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  
  @media (max-width: 600px) {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const PublicationGallery: FC<PublicationGalleryProps> = ({ images }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <Swiper
      centeredSlides={true}
      slidesPerView="auto"
      pagination={{
        clickable: true,
        type: "fraction",
      }}
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      modules={[Pagination, Navigation]}
      className="mySwiper"
      onBeforeInit={(swiper: any) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
      }}
      style={{ height: '250px' }}
    >
      {images.map(({ url }) => (
        <StyledSwiperSlide key={url} >
          <StyledPostImage src={url} />
        </StyledSwiperSlide>
      ))}
      <div className="d-flex flex-row justify-content-between align-items-center position-absolute top-0 w-100 h-100">
        <StyledMenuButton
          bsPrefix="prev bg-white shadow-sm border-0 p-2 rounded-circle mx-2"
          ref={prevRef}
        >
          <ChevronLeft />
        </StyledMenuButton>
        <StyledMenuButton
          bsPrefix="next bg-white shadow-sm border-0 p-2 rounded-circle mx-2"
          ref={nextRef}
        >
          <ChevronRight />
        </StyledMenuButton>
      </div>
    </Swiper>
  );
};

export default PublicationGallery;