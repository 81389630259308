import HomeOutline from 'mdi-react/HomeOutlineIcon';
import Domain from 'mdi-react/DomainIcon';
import NewspaperVariantOutline from 'mdi-react/NewspaperVariantOutlineIcon';
import FileDocumentEditOutline from 'mdi-react/FileDocumentEditOutlineIcon';
import HomeSearchOutline from 'mdi-react/HomeSearchOutlineIcon';
import AccountBoxOutline from 'mdi-react/AccountBoxOutlineIcon';
import { ROUTES, ROUTES_LABELS } from '../../../../global/urls';
import { PERMISSIONS } from '../../../../global/permissions';
import { CustomerSupport } from '../../../assets/icons';

export { default as AppsDropdown } from './AppsDropdown';
export { default as GuestDropdown } from './GuestDropdown';
export { default as NotificationDropdown } from './NotificationDropdown';
export { default as Header } from './Header';
export { default as SideNav } from './SideNav';
export { default as Toolbar } from './Toolbar';

export interface AppRoute {
  name: ROUTES_LABELS | string,
  to: ROUTES | string;
  Icon: any;
  permission: PERMISSIONS | string;
}

export const investorRoutes: AppRoute[] = [
  {
    name: ROUTES_LABELS.HOME,
    to: ROUTES.INVESTOR_HOME,
    Icon: HomeOutline,
    permission: PERMISSIONS.INV_SERVI_14,
  },
  {
    name: ROUTES_LABELS.PROPERTIES,
    to: ROUTES.INVESTOR_PROPERTIES,
    Icon: Domain,
    permission: PERMISSIONS.INV_SERVI_15,
  },
  {
    name: ROUTES_LABELS.PUBLICATIONS,
    to: ROUTES.INVESTOR_PUBLICATIONS,
    Icon: NewspaperVariantOutline,
    permission: PERMISSIONS.INV_SERVI_16,
  },
  {
    name: ROUTES_LABELS.CONTRACTS,
    to: ROUTES.INVESTOR_CONTRACTS,
    Icon: FileDocumentEditOutline,
    permission: PERMISSIONS.INV_SERVI_18,
  },
  {
    name: ROUTES_LABELS.SUPPORT,
    to: ROUTES.INVESTOR_SUPPORT,
    Icon: CustomerSupport,
    permission: PERMISSIONS.INV_SERVI_18,
  },
];

export const managementRoutes: AppRoute[] = [
  {
    name: ROUTES_LABELS.HOME,
    to: ROUTES.MANAGEMENT_HOME,
    Icon: HomeOutline,
    permission: PERMISSIONS.GES_SERVI_23,
  },
  {
    name: ROUTES_LABELS.PROPERTIES,
    to: ROUTES.MANAGEMENT_PROPERTIES,
    Icon: Domain,
    permission: PERMISSIONS.GES_PROPI_36,
  },
  {
    name: ROUTES_LABELS.INSPECTIONS,
    to: ROUTES.MANAGEMENT_INSPECTIONS,
    Icon: HomeSearchOutline,
    permission: PERMISSIONS.GES_INSPE_24,
  },
  {
    name: ROUTES_LABELS.USERS,
    to: ROUTES.MANAGEMENT_USERS,
    Icon: AccountBoxOutline,
    permission: PERMISSIONS.GES_USUAR_31,
  },
];
