import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { PATHS } from '../../../global/urls';
import { useAuth } from '../../../utils/hooks';
import { StyledBox } from '../../common';

const AuthLayout: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(`${PATHS.HOME}`, { replace: true });
    }
  }, [user, navigate]);

  return (
    <StyledBox className="vh-100">
      <div className="d-flex flex-column justify-content-center h-100">
        <section className="px-3">
          <Outlet />
        </section>
      </div>
    </StyledBox>
  );
};

export default AuthLayout;