import { FC, useState, forwardRef, ForwardedRef } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { TableColumn } from 'react-data-table-component';
import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import DotsVertical from 'mdi-react/DotsVerticalIcon';
import AccountCancelOutline from 'mdi-react/AccountCancelOutlineIcon';
import AccountReactivateOutline from 'mdi-react/AccountReactivateOutlineIcon';
import DataGridTable from '../../../common/DataGridTable';
import { StyledBadge, StyledBox, StyledDropdownSelect, StyledMenuButton, StyledSubmitButton, StyledText } from '../../../common';
import { PERMISSIONS } from '../../../../global/permissions';
import Spinner from '../../../common/Spinner';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { AccountUser } from '../../../../global/interfaces';
import { UsersFilterBar } from './components';
import { useAuthorization, useMessages, useWindowResize } from '../../../../utils/hooks';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { authRentApi } from '../../../../apis/authRentApi';
import { CustomToggleProps } from '../../../layout/app/components/AppsDropdown';
import Confirm from '../../../common/Confirm';
import { PAGE_TITLE } from '../../../../global/urls';

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const theme = useTheme();
    return (
      <StyledMenuButton
        ref={ref}
        onClick={onClick}
        size="sm"
        className="border-0 p-2 rounded-circle"
      >
        <DotsVertical size={26} color={theme.palette.iconColor} />
      </StyledMenuButton>
    )
  }
);

const smBreakpoint = 600;

const ManagementUsersPage: FC = () => {
  const { messages } = useMessages();
  const { checkAccess } = useAuthorization();
  const [width] = useWindowResize();
  const { title } = messages.modules.management.users.sections.users;
  const {
    username,
    full_name,
    status,
    rut,
    // last_session_date,
    connected_users
  } = messages.modules.management.users.sections.users.columns;
  const {
    enable_user,
    disable_user,
  } = messages.modules.management.users.sections.users.actions;
  const {
    enable,
    disable
  } = messages.modules.management.users.sections.users.dialogs.change_user_status;
  const [users, setUsers] = useState<AccountUser[]>([]);
  const [currentUser, setCurrentUser] = useState<AccountUser>({} as AccountUser);
  const [filters, setFilters] = useState<string>('?is_active=True');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const { isFetching, refetch } = useQuery<AxiosResponse, AxiosError>(['management/users', { filters }], async () => {
    return await stackRentApi.get(`/users${filters}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setUsers(response.data);
      },
      onError: (error: AxiosError) => {
        setUsers([]);
      }
    });

  const { mutate, isLoading: loading } = useMutation((userId: number) => {
    return authRentApi.post(`/users/change-status/${userId}`);
  });

  const toggleConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const selectCurrentUser = (user: AccountUser) => {
    setCurrentUser(user);
    toggleConfirm();
  };

  const changeUserStatus = (userId: number) => {
    mutate(userId, {
      onSuccess: (response: AxiosResponse) => {
        refetch();
        setCurrentUser({} as AccountUser)
        toggleConfirm();
      },
      onError: (error: any) => { }
    });
  };


  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const columns: TableColumn<AccountUser>[] = [
    {
      name: username,
      selector: (row: AccountUser) => row.email,
      center: true,
      sortable: true,
      sortFunction: (rowA: AccountUser, rowB: AccountUser) => {
        const a = rowA.email.toLowerCase();
        const b = rowB.email.toLowerCase();
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      }
    },
    {
      name: full_name,
      selector: (row: AccountUser) => row.full_name,
      center: true,
      sortable: true,
      sortFunction: (rowA: AccountUser, rowB: AccountUser) => {
        const a = rowA.full_name.toLowerCase();
        const b = rowB.full_name.toLowerCase();
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      }
    },
    {
      name: rut,
      selector: (row: AccountUser) => row.rut,
      center: true,
      sortable: true,
    },
    {
      name: status,
      cell: (row: AccountUser) => (
        <StyledBadge
          className={`${row.is_active ? 'primary' : 'default'} text fw-normal`}
        >
          {row.is_active ? 'Activo' : 'Inactivo'}
        </StyledBadge>
      ),
      center: true,
      sortable: false,
    },
    // {
    //   name: last_session_date,
    //   selector: (row: AccountUser) => `${row.last_session}`,
    //   center: true,
    //   sortable: true,
    // },
    {
      name: connected_users,
      cell: (row: AccountUser) => (
        <StyledBadge
          className={`default text fw-normal`}
        >
          {row.is_connected ? 'Si' : 'No'}
        </StyledBadge>
      ),
      center: true,
      sortable: false,
    },
    {
      cell: (row: AccountUser) => (
        <StyledDropdownSelect>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu className="w-100 rounded-2 border-0 shadow">
            {(checkAccess(PERMISSIONS.GES_USUAR_35)) && (
              <Dropdown.Item
                eventKey="1"
                onClick={() => selectCurrentUser(row)}
                as="button"
              >
                {row.is_active ? disable_user : enable_user}
              </Dropdown.Item>
            )}

          </Dropdown.Menu>
        </StyledDropdownSelect>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '60px',
    },
  ];


  // TODO: RECORDAR CAMBIAR LOS PERMISOS EN EL MODULO DE USUARIOS Y EN LAS RUTAS

  return (
    <Authorization allowedRole={PERMISSIONS.GES_USUAR_31}>
      <Helmet>
        <title>{PAGE_TITLE.MANAGEMENT_USERS}</title>
      </Helmet>
      <Container fluid className="px-0 pt-md-5">
        <Row>
          <Col xs={12}>
            <StyledText className="h2 mb-4 title text-center">{title}</StyledText>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="">
            <StyledBox className="rounded-4 w-100 bg-white shadow-none py-3">
              <DataGridTable
                columns={columns}
                rows={users}
                subHeader
                subHeaderComponent={<UsersFilterBar setFilters={setFilters} filters={filters} />}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                progressPending={isFetching}
                progressComponent={(
                  <Spinner />
                )}
              />
            </StyledBox>
          </Col>
        </Row>
        <Confirm
          show={openConfirm}
          onHide={toggleConfirm}
          title={currentUser.is_active ? disable.title : enable.title}
          icon={currentUser.is_active ? AccountCancelOutline : AccountReactivateOutline}
          variant="secondary"
          enableClose
          size={(width <= smBreakpoint) ? 'lg' : undefined}
          actions={(
            <Row className="justify-content-center mt-md-5">
              <Col xs={12} sm={12} md={8}>
                <StyledSubmitButton
                  className="w-100 rounded-3 primary shadow-sm btn-lg"
                  disabled={loading}
                  onClick={() => {
                    changeUserStatus(currentUser.id)
                  }}
                >
                  {currentUser.is_active ? disable.call_to_action : enable.call_to_action}
                </StyledSubmitButton>
              </Col>
            </Row>
          )}
        />
      </Container>
    </Authorization>
  );
};

export default ManagementUsersPage;