import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import ReactGA from "react-ga4";
import App from './App';
import reportWebVitals from './reportWebVitals';
import environment from './global/environment';

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
  })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const rollbarConfig = {
  accessToken: environment.ACCESSTOKEN_ROLLBAR,
  environment: environment.APP_ENVIROMENT,
  captureUncaught: true,
  captureUnhandledRejections: true
};

const root = createRoot(
  document.getElementById('root')!
);

ReactGA.initialize('G-TJZ50VDJEY');

root.render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
