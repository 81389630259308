import { FC, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import { PropertyPublication } from '../../../../global/interfaces';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { StyledEmptyContainer } from '../../../common';
import Spinner from '../../../common/Spinner';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { PERMISSIONS } from '../../../../global/permissions';
import RouterBreadcrumb from '../../../common/RouterBreadcrumb';
import { PublicationDetails } from '../../investor/publications/components';
import { PAGE_TITLE } from '../../../../global/urls';

const ManagementPublicationDetailsPage: FC = () => {
  const { assetId } = useParams();
  const [publication, setPublication] = useState<PropertyPublication>({} as PropertyPublication);

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['management/publication/id'], async () => {
    return await stackRentApi.get(`/publication/${assetId}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setPublication(response.data.asset_data);
      },
      onError: (error: AxiosError) => {
        setPublication({} as PropertyPublication);
      }
    });

    if (isFetching) {
      return (
        <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
          <Spinner />
        </StyledEmptyContainer>
      );
    }

  return (
    <Authorization allowedRole={PERMISSIONS.GES_PROPI_45}>
      <Helmet>
        <title>{PAGE_TITLE.MANAGEMENT_PROPERTY_PUBLICATION_DETAILS}</title>
      </Helmet>
      <Container className="px-0 pt-md-5">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <RouterBreadcrumb />
          </Col>
        </Row>
        {(publication && Object.entries(publication).length !== 0) && (
          <PublicationDetails publication={publication} />
        )}
      </Container>
    </Authorization>
  );
};

export default ManagementPublicationDetailsPage;