import { useCallback } from 'react';
import { useAuth } from '.';

const useAuthorization = () => {
  const user = useAuth();

  if (!user) {
    throw Error('User does not exist!');
  }

  const checkAccess = useCallback(
    (allowedRole: string) => {
      if (allowedRole && allowedRole.length > 0) {
        return user.roles.includes(allowedRole);
      }
      return true;
    },
    [user.roles]
  );

  return {
    checkAccess,
    role: user.roles
  };
};

export default useAuthorization;