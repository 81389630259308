import { FC, useState, ChangeEvent, SetStateAction, Dispatch, useEffect, forwardRef, ReactNode, ForwardedRef } from 'react';
import { Form, Dropdown, Row, Col } from 'react-bootstrap';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import es from 'date-fns/locale/es';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import ChevronDown from 'mdi-react/ChevronDownIcon';
import ChevronUp from 'mdi-react/ChevronUpIcon';
import "react-datepicker/dist/react-datepicker.css";
import Plus from 'mdi-react/PlusIcon';
import { StyledDatePickerWrapper, StyledDropdownSelect, StyledSearchBar, StyledSubmitButton, StyledText } from '../../../../common';
import { useMessages } from '../../../../../utils/hooks';

const statusOptions = [
  'No enviado',
  'Enviado',
  'No contactado',
  'Iniciado',
  'Realizado',
]

const StyledDropdownTrigger = styled(Dropdown.Toggle)`
  height: 38px;
  border: solid 1px #8E8D8D !important;
  color: #8E8D8D !important;

  &:is(:focus, &:focus-visible, :active, :hover), :not([value=""]) {
    border: solid 1px #525ea2 !important;
    color: #525ea2 !important;
    box-shadow: none !important;
  }
`;

interface CustomItemProps {
  children: ReactNode;
  id: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | void[];
}

const CheckDropdownItem = forwardRef<HTMLInputElement, CustomItemProps>(
  ({ children, id, checked, onChange }: CustomItemProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="checkbox"
          label={children}
          checked={checked}
          onChange={onChange}
        />
      </Form.Group>
    );
  }
);


interface MoreFilters {
  label: string;
  id: string;
  checked: boolean;
}


interface InspectionFilterBarProps {
  setFilters: Dispatch<SetStateAction<string>>;
  filters: string;
  resetPagination?: () => void;
}

const InspectionFilterBar: FC<InspectionFilterBarProps> = ({ setFilters, filters, resetPagination }) => {
  const { messages } = useMessages();
  const {
    filter_by_month,
    filter_by_status,
    filter_by_asset_code,
    filter_by_asset_name,
    filter_by_asset_date,
    filter_by_rup,
    filter_by_tenant_fullname,
    filter_by_tenant_rut
  } = messages.modules.management.inspections.sections.self_inspections.fields;
  const { filter, clear_filter, more } = messages.modules.management.inspections.sections.self_inspections.actions;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [assetCode, setAssetCode] = useState<string>('');
  const [assetName, setAssetName] = useState<string>('');
  const [status, setstatus] = useState<string>('');
  const [openStatusDropdown, setOpenStatusDropdown] = useState<boolean>(false);
  const [tenantRut, setTenantRut] = useState<string>('');
  const [tenantFullname, setTenantFullname] = useState<string>('');
  const [rup, setRup] = useState<string>('');
  const [filtersAux, setFilterAux] = useState<string>('');
  const [moreFilters, setMoreFilters] = useState<MoreFilters[]>([
    {
      label: filter_by_asset_code,
      id: 'asset_code',
      checked: false,
    },
    {
      label: filter_by_tenant_rut,
      id: 'tenant_rut',
      checked: false,
    },
  ]);
  const [openMoreFiltersDropdown, setOpenMoreFiltersDropdown] = useState<boolean>(false);

  useEffect(() => {
    setFilterAux(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAux]);

  const handleChangeMonth = (date: Date) => {
    if (date) {
      setStartDate(startOfMonth(date));
      setEndDate(endOfMonth(date));
    }
  };

  const handleChangeAssetName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAssetName(value);
  }

  const handleChangeAssetCode = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAssetCode(value);
  }

  const handleChangeRup = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRup(value);
  }

  const handleChangeTenantFullname = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTenantFullname(value);
  }

  const handleChangeTenantRut = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTenantRut(value);
  }

  const handleChangeStatus = (value: string) => {
    setstatus(value);
  }

  const toggleStatusDropdown = () => {
    setOpenStatusDropdown(!openStatusDropdown);
  };

  const toggleMoreFiltersDropdown = () => {
    setOpenMoreFiltersDropdown(!openMoreFiltersDropdown);
  };

  const handleFilters = () => {
    if (resetPagination) {
      resetPagination();
    }
    const formattedStartDate = (startDate) ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = (endDate) ? format(endDate, 'yyyy-MM-dd') : '';
    const formattedDate = (date) ? format(date, 'yyyy-MM-dd') : '';
    setFilters(`date=${formattedDate}&inspection_month_gte=${formattedStartDate}&inspection_month_lte=${formattedEndDate}&asset_code=${assetCode}&asset_name=${assetName}&status=${status}&rup=${rup}&tenant_fullname=${tenantFullname}&tenant_rut=${tenantRut}`);
  }

  const resetFilters = () => {
    setFilters('');
    setFilterAux('');
    if (resetPagination) {
      resetPagination();
    }
    setAssetName('');
    setAssetCode('');
    setTenantFullname('');
    setTenantRut('');
    setRup('');
    setstatus('');
    setStartDate(null);
    setEndDate(null);
    setDate(null);
  };

  const shouldFilter = () => {
    if (startDate !== null || endDate !== null || 
        date !== null || assetCode !== '' || 
        assetName !== '' || status !== '' ||
        rup !== '' || tenantFullname !== '' ||
        tenantRut !== '') return true;
    if (filters !== filtersAux) return true;
    return false;
  }

  const handleMoreFilters = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters: MoreFilters[] = moreFilters.map((filter: MoreFilters) => {
      if (filter.id === e.target.id) {
        filter.checked = e.target.checked
      }
      return filter
    })
    setMoreFilters(updatedFilters)
  }

  return (
    <Row>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="pe-0"
      >
        <Row>
          <Col xs={12} className="d-flex flex-row flex-wrap align-items-center">
            <StyledDatePickerWrapper className="mb-2">
              <DatePicker
                name="month"
                selected={startDate}
                onChange={handleChangeMonth}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                className="border-1 rounded-2 text-center me-2"
                showPopperArrow={false}
                popperPlacement="bottom"
                locale={es}
                showFullMonthYearPicker
                placeholderText={filter_by_month}
                showIcon
                icon={<ChevronDown />}
                toggleCalendarOnIconClick
              />
            </StyledDatePickerWrapper>

            <StyledSearchBar className="position-relative me-2 mb-2" controlId="rup">
              <Form.Control
                value={rup}
                onChange={handleChangeRup}
                type="text"
                placeholder={filter_by_rup}
                className="border-1 rounded-2 text-center"
                htmlSize={6}
              />
            </StyledSearchBar>

            <StyledSearchBar className="position-relative me-2 mb-2" controlId="asset_name">
              <Form.Control
                value={assetName}
                onChange={handleChangeAssetName}
                type="text"
                placeholder={filter_by_asset_name}
                className="border-1 rounded-2 text-center"
              />
            </StyledSearchBar>

            <StyledSearchBar className="position-relative me-2 mb-2" controlId="tenant_fullname">
              <Form.Control
                value={tenantFullname}
                onChange={handleChangeTenantFullname}
                type="text"
                placeholder={filter_by_tenant_fullname}
                className="border-1 rounded-2 text-center"
                htmlSize={10}
              />
            </StyledSearchBar>




            <StyledDropdownSelect className="mb-2" open={openStatusDropdown} onToggle={toggleStatusDropdown}>
              <StyledDropdownTrigger id="status" className="rounded-2 me-2 d-flex" value={status}>
                {(status !== '') ? status : filter_by_status}
                {openStatusDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
              </StyledDropdownTrigger>
              <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                {statusOptions.map((value: string, index: number) => (
                  <Dropdown.Item as="button" key={index} onClick={() => handleChangeStatus(value)}>{value}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </StyledDropdownSelect>

            <StyledDatePickerWrapper className="mb-2">
              <DatePicker
                name="day"
                selected={date}
                onChange={(date: Date) => setDate(date)}
                dateFormat="dd/MM/yyyy"
                className="border-1 rounded-2 text-center me-2"
                showPopperArrow={false}
                popperPlacement="bottom"
                locale={es}
                placeholderText={filter_by_asset_date}
                showIcon
                icon={<ChevronDown />}
                toggleCalendarOnIconClick
              />
            </StyledDatePickerWrapper>

            <StyledDropdownSelect className="mb-2" open={openMoreFiltersDropdown} onToggle={toggleMoreFiltersDropdown}>
              <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={!moreFilters.some((element: MoreFilters) => element.checked) ? '' : true}>
                {more}
                <Plus className="ms-1" />
              </StyledDropdownTrigger>
              <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                {moreFilters.map(({ id, label, checked }: MoreFilters, index: number) => (
                  <Dropdown.Item
                    as={CheckDropdownItem}
                    key={index}
                    id={id}
                    onChange={handleMoreFilters}
                    checked={checked}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </StyledDropdownSelect>

            {(shouldFilter()) && (
              <>
                <StyledSubmitButton className="primary filled px-5 mb-2 me-3 rounded-2 d-none d-sm-block" onClick={handleFilters}>
                  {filter}
                </StyledSubmitButton>
                <StyledText
                  className="active cursor-pointer text-decoration-underline me-2 d-none d-sm-block"
                  onClick={resetFilters}
                >
                  {clear_filter}
                </StyledText>
              </>
            )}
          </Col>
          
          <Col xs={12} className="d-flex flex-row flex-wrap align-items-center">
            {moreFilters.find((filter: MoreFilters) => filter.id === 'asset_code')?.checked && (
              <StyledSearchBar className="position-relative me-2 mb-2" controlId="asset_code">
                <Form.Control
                  value={assetCode}
                  onChange={handleChangeAssetCode}
                  type="text"
                  placeholder={filter_by_asset_code}
                  className="border-1 rounded-2 text-center"
                  htmlSize={15}
                />
              </StyledSearchBar>
            )}

            {moreFilters.find((filter: MoreFilters) => filter.id === 'tenant_rut')?.checked && (
              <StyledSearchBar className="position-relative me-2 mb-2" controlId="tenant_rut">
                <Form.Control
                  value={tenantRut}
                  onChange={handleChangeTenantRut}
                  type="text"
                  placeholder={filter_by_tenant_rut}
                  className="border-1 rounded-2 text-center"
                  htmlSize={15}
                />
              </StyledSearchBar>
            )}
          </Col>
        </Row>
      </Col>
      {shouldFilter() && (
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className="d-flex flex-row flex-wrap align-items-start"
        >
          <div className="d-flex flex-row align-items-center d-sm-none">
            <StyledSubmitButton className="primary filled px-5 mb-2 me-3 rounded-2" onClick={handleFilters}>
              {filter}
            </StyledSubmitButton>
            <StyledText
              className="active cursor-pointer text-decoration-underline me-2"
              onClick={resetFilters}
            >
              {clear_filter}
            </StyledText>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default InspectionFilterBar;