import { FC } from 'react';
import { ListGroup } from 'react-bootstrap';
import Drawer from 'react-bottom-drawer';
import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import CheckboxOutline from 'mdi-react/CheckboxOutlineIcon';
import TrashCanOutline from 'mdi-react/TrashCanOutlineIcon';
import BookmarkOutline from 'mdi-react/BookmarkOutlineIcon';
import CheckboxMarked from 'mdi-react/CheckboxMarkedIcon';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { useAuth, useMessages } from '../../../../utils/hooks';
import { ReadNotification, Notification } from '../../../../global/interfaces';
import { StyledNotificactionBottomOption } from '../../../common';

interface NotificationsBottomDrawerProps {
  isVisible: boolean;
  onClose: () => void;
  refetch: () => void;
  notification: Notification | null;
}

const NotificationsBottomDrawer: FC<NotificationsBottomDrawerProps> = ({ isVisible, onClose, notification, refetch }) => {
  const auth = useAuth();
  const { messages } = useMessages();
  const { setNotificationsHasChanged } = auth;
  const { actions } = messages.modules.notifications;

  const { mutate } = useMutation((notification: ReadNotification) => {
    return stackRentApi.put(`/users/notification/${notification?.id}`, notification);
  });

  const onUnreadNotification = (notification: ReadNotification) => {
    mutate(notification, {
      onSuccess: (response: AxiosResponse) => {
        setNotificationsHasChanged(true);
        refetch();
      },
      onError: (error: any) => { }
    });
  }

  return (
    <Drawer
      isVisible={isVisible}
      onClose={onClose}
      className="drawer"
    >
      <div className="container py-3">
        <ListGroup variant="flush">
          <StyledNotificactionBottomOption
            className="border-0 d-flex flex-row align-items-center py-2"
            as="button"
            onClick={() => {
              if (notification) {
                onUnreadNotification({ id: notification.id, read_status: !notification.read_status })
                onClose();
              }
            }}
          >
            {notification?.read_status ? <CheckboxMarked className="me-3" /> : <CheckboxOutline className="me-3" />}
            <span>{notification?.read_status ? actions.mark_as_unread : actions.mark_as_read}</span>
          </StyledNotificactionBottomOption>
          <StyledNotificactionBottomOption className="border-0 d-flex flex-row align-items-center py-2" as="button">
            <TrashCanOutline className="me-3" />
            <span>{actions.delete}</span>
          </StyledNotificactionBottomOption>
          <StyledNotificactionBottomOption className="border-0 d-flex flex-row align-items-center py-2" as="button">
            <BookmarkOutline className="me-3" />
            <span>{actions.save}</span>
          </StyledNotificactionBottomOption>
        </ListGroup>
      </div>
    </Drawer>
  );
};

export default NotificationsBottomDrawer;