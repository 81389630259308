import { Navigate } from 'react-router-dom';
import { useAuthorization } from '../utils/hooks';

type AuthorizationProps = {
  children: React.ReactNode;
  allowedRole: string;
};

export const Authorization = ({ allowedRole, children }: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRole) {
    canAccess = checkAccess(allowedRole);
  };

  return <>{canAccess ? children : <Navigate to="/home" replace />}</>;
};
