import { FC } from 'react';
import { Card } from 'react-bootstrap';
import CameraPlusOutline from 'mdi-react/CameraPlusOutlineIcon';
import { StyledAvatar, StyledCard, StyledMenuButton, StyledText } from '../../../../common';
import { AccountUser } from '../../../../../global/interfaces';
import { useUtils } from '../../../../../utils/hooks';

interface AccountProfileCardProps {
  user: AccountUser;
}

const AccountProfileCard: FC<AccountProfileCardProps> = ({ user }) => {
  const { first_name, last_name } = user;
  const { getAvatarInitials, capitalize } = useUtils();

  return (
    <StyledCard className="primary shadow-none bg-white active mb-4">
      <Card.Body className="d-flex flex-row justify-content-between">
        <div className="w-35 p-1 p-md-3">
          <div className="h-100 d-flex flex-row align-items-center">
            <StyledAvatar
              src={getAvatarInitials({ firstName: first_name, lastName: last_name, isBold: true })}
              roundedCircle
              className="w-100 border primary"
            />
            <StyledMenuButton
              as="label"
              htmlFor="formFile"
              className="d-none me-2 border-0 p-2 cursor-pointer rounded-circle position-absolute top-100 start-100 translate-inner">
              <CameraPlusOutline />
            </StyledMenuButton>
            <input className="d-none" type="file" id="formFile" />
          </div>
        </div>
        <div className="w-65 p-2 p-md-3 d-flex flex-row justify-content-start align-items-center">
          <div className="text-capitalize">
            <StyledText className="h3 m-0 title">{capitalize(first_name)}</StyledText>
            <StyledText className="h3 m-0 title">{capitalize(last_name)}</StyledText>
          </div>
        </div>
      </Card.Body>
    </StyledCard>
  );
};

export default AccountProfileCard;