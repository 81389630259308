import { FC } from 'react';
import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { debounce } from 'lodash';
import ReactHtmlParser from 'html-react-parser';
import DotsVertical from 'mdi-react/DotsVerticalIcon';
import CircleMedium from 'mdi-react/CircleMediumIcon';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { useAuth, useWindowResize } from '../../../../utils/hooks';
import { StyledMenuButton, StyledNotificationItem } from '../../../common';
import { ReadNotification, Notification } from '../../../../global/interfaces';
import NotificationItemBody from './NotificationItemBody';

interface NotificationItemProps {
  notification: Notification;
  onSelect: () => void;
  refetch: () => void;
  onOpen: () => void;
  onSelectItem: (notification: Notification) => void;
}

const smBreakpoint = 600;

const NotificationItem: FC<NotificationItemProps> = ({ notification, onSelect, refetch, onOpen, onSelectItem }) => {
  const auth = useAuth();
  const [width] = useWindowResize();
  const { setNotificationsHasChanged } = auth;
  const { id, tittle, date, read_status, body } = notification;


  const { mutate } = useMutation((notification: ReadNotification) => {
    return stackRentApi.put(`/users/notification/${notification.id}`, notification);
  });

  const onReadNotification = (notification: ReadNotification) => {
    if (!notification.read_status) {
      mutate({ id: notification.id, read_status: true }, {
        onSuccess: (response: AxiosResponse) => {
          setNotificationsHasChanged(true);
          refetch();
        },
        onError: (error: any) => { }
      });
    }
  };

  const onMouseEnter = debounce(onReadNotification, 2000);
  const onMouseLeave = () => onMouseEnter.cancel();

  const toggleReadNotification = (notification: ReadNotification) => {
    mutate(notification, {
      onSuccess: (response: AxiosResponse) => {
        setNotificationsHasChanged(true);
        refetch();
      },
      onError: (error: any) => { }
    });
  };

  return (
    <StyledNotificationItem
      onSelect={onSelect}
      className={`dropdown-item d-flex justify-content-between align-items-center cursor-pointer ${!read_status && 'unread'}`}
      key={id}
      onMouseEnter={() => (width >= smBreakpoint) && onMouseEnter({ id, read_status })}
      onMouseLeave={onMouseLeave}
      as="div"
    >
      <NotificationItemBody notification={notification}>
        <div className="mx-1 me-3 truncate">
          <div className="fw-bold text-wrap text-black">{tittle} </div>
            <div className="parsed-html text-wrap fw-normal notlink">
              {ReactHtmlParser(body)}
            </div>
          <div>
            <small className="fst-italic">{date}</small>
          </div>
        </div>
      </NotificationItemBody>
      {(width < smBreakpoint) ? (
        <StyledMenuButton
          size="sm"
          className="border-0 p-1 rounded-circle"
          onClick={() => {
            onSelectItem(notification);
            onOpen();
          }}
        >
          <DotsVertical />
        </StyledMenuButton>
      ) : (
        <span
          className="cursor-pointer p-1" 
          onClick={() => toggleReadNotification({ id, read_status: !read_status })}
        >
          <CircleMedium size={20} className="read-indicator" />
        </span>
      )}
    </StyledNotificationItem>
  )
}

export default NotificationItem