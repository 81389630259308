import { FC } from 'react';
import { Notification } from '../../../../global/interfaces';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../global/urls';


interface NotificationItemBodyProps {
  children: React.ReactNode;
  notification: Notification;
}

const NotificationItemBody: FC<NotificationItemBodyProps> = ({ children, notification }) => {

  const getNotificationUrl = (notification: Notification) => {
    let url: String | undefined = notification.url?.url;
    Object.keys(ROUTES).forEach((item) => {
      url = url?.replace(`{${item}}`, `${ROUTES[item as keyof typeof ROUTES]}`);
    })

    return url;
  }

  return (
    <>
      {(notification.url_set) ? (
        <NavLink to={`${getNotificationUrl(notification)}`} target={notification.url?.type} className='notlink'>
          {children}
        </NavLink>
      ) : 
        <>{children}</>
      }
    </>
  )
}

export default NotificationItemBody
