import { FC, Fragment, forwardRef, MouseEventHandler, ForwardedRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from 'styled-components';
import { AxiosError, AxiosResponse } from 'axios';
import CloseCircleOutline from 'mdi-react/CloseCircleOutlineIcon';
import CogOutline from 'mdi-react/CogOutlineIcon';
import HelpCircleOutline from 'mdi-react/HelpCircleOutlineIcon';
import AccountCircleOutline from 'mdi-react/AccountCircleOutlineIcon';
import KeyOutline from 'mdi-react/KeyOutlineIcon';
import { PUBLIC_URL, VARIANT } from '../../../../global';
import environment from '../../../../global/environment';
import { useAuth, useAuthorization, useMessages, useUtils } from '../../../../utils/hooks';
import { PERMISSIONS } from '../../../../global/permissions';
import { ROUTES, ROUTES_LABELS } from '../../../../global/urls';
import { StyledAvatar, StyledDropdownItem, StyledFlexBox, StyledGuestMenuButton } from '../../../common';
import { AccountUser } from '../../../../global/interfaces';
import { stackRentApi } from '../../../../apis/stackRentApi';
import { authRentApi } from '../../../../apis/authRentApi';
import { HomieRentLogo } from '../../../assets/icons';

const protectedRoutes = [
  {
    to: ROUTES.ACCOUNT_SETTINGS,
    name: ROUTES_LABELS.ACCOUNT_SETTINGS,
    permission: PERMISSIONS.PLA_SERVI_8,
    Icon: <CogOutline className="me-2" />,
    isVisible: false
  },
  {
    to: ROUTES.ACCOUNT_HELP,
    name: ROUTES_LABELS.ACCOUNT_HELP,
    permission: PERMISSIONS.PLA_SERVI_7,
    Icon: <HelpCircleOutline className="me-2" />,
    isVisible: false,
  },
  {
    to: ROUTES.ACCOUNT_PROFILE,
    name: ROUTES_LABELS.ACCOUNT_PROFILE,
    permission: PERMISSIONS.PLA_CTAUS_13,
    Icon: <AccountCircleOutline className="me-2" />,
    isVisible: true,
  },
  {
    to: ROUTES.ACCOUNT_CHANGE_PASSWORD,
    name: ROUTES_LABELS.ACCOUNT_CHANGE_PASSWORD,
    permission: PERMISSIONS.PLA_CTAUS_9,
    Icon: <KeyOutline className="me-2" />,
    isVisible: true,
  },
];

interface CustomToggleProps {
  onClick: MouseEventHandler<HTMLDivElement>;
}

const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const [accountUser, setAccountUser] = useState<AccountUser>({} as AccountUser)
    const { getAvatarInitials } = useUtils();

    const { isFetching } = useQuery<AxiosResponse, AxiosError>(['profile'], async () => {
      return await stackRentApi.get('/person/me');
    },
      {
        onSuccess: (response: AxiosResponse) => {
          const { person_data } = response.data;
          setAccountUser(person_data);
        }
      });

    return (
      <div
        ref={ref}
        onClick={onClick}
        className="list-group cursor-pointer ms-2 ms-md-3"
      >
        <StyledGuestMenuButton className="list-group-item d-flex flex-row primary px-2 px-md-3">
          <HomieRentLogo color={theme.palette.primary.main} width={75} height={30} className="me-2" />
          <div className="d-block">
            {isFetching ? (
              <StyledFlexBox
                width="32"
                height="32"
                bgColor={theme.palette.avatarBackgroundColor}
                textColor={theme.palette.avatarTextColor}
                className="d-flex flex-row justify-content-center align-items-center rounded-circle border"
              >
                <Spinner animation="border" size="sm" />
              </StyledFlexBox>
            ) : (
              <StyledAvatar
                src={getAvatarInitials({ firstName: accountUser.first_name, lastName: accountUser.last_name, isBold: true }, VARIANT.PRIMARY)}
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle avatar primary"
              />
            )}
          </div>
        </StyledGuestMenuButton>
      </div>
    )
  }
);

const GuestDropdown: FC = () => {
  let navigate = useNavigate();
  const { messages } = useMessages();
  const { actions } = messages.modules.auth;
  const auth = useAuth();
  const { checkAccess } = useAuthorization();

  const logout = () => {
    const local = localStorage.getItem('token');
    authRentApi.post('/auth/revoke-token', {
      'token': local,
      'client_id': environment.AUTH_CLIENT_ID,
      'client_secret': environment.AUTH_CLIENT_SECRET
    });
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('currentApp');
    localStorage.removeItem('isForcedChangePassword');
    localStorage.setItem('isFirstTime', JSON.stringify(false));
    auth.setErrorAlert(null);
    navigate(`${PUBLIC_URL}/`)
  }

  return (
    <Dropdown className="py-0" align="end">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-guest" />
      <Dropdown.Menu className="text-small shadow rounded-0 py-0">
        {protectedRoutes.map(({ to, name, permission, Icon, isVisible }) => (
          <Fragment key={name}>
            {(checkAccess(permission)) &&
              <StyledDropdownItem
                className={`dropdown-item p-0 ${!isVisible && 'd-block d-sm-block d-md-none'}`}
                key={permission}
              >
                <NavLink
                  to={to}
                  className="w-100 d-flex gap-2 align-items-center no-hover py-2 px-3"
                >
                  {Icon}
                  {name}
                </NavLink>
              </StyledDropdownItem>
            }
          </Fragment>
        ))}
        {checkAccess(PERMISSIONS.PLA_ACCES_11) && (
          <StyledDropdownItem
            className="dropdown-item d-flex flew-row align-items-center cursor-pointer py-2"
            onClick={() => auth.signout(() => logout())}
            as="div"
            id=""
          >
            <CloseCircleOutline className="me-2" />
            {actions.sign_out}
          </StyledDropdownItem>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GuestDropdown;