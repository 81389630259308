import { FC, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import axios from "axios";
import { useNavigate, NavLink } from 'react-router-dom';
import EmoticonHappyOutline from 'mdi-react/EmoticonHappyOutlineIcon';
import { useTheme } from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { RECOVER_PASSWORD_INITIAL_VALUES, RECOVER_PASSWORD_VALIDATION_SCHEMA } from '../../../../validations/authValidations';
import environment from '../../../../../global/environment';
import { useMessages, useWindowResize } from '../../../../../utils/hooks';
import { ROUTES } from '../../../../../global/urls';
import { HomieRentLogo, Mail } from '../../../../assets/icons';
import {
  StyledAddon,
  StyledInput,
  StyledInvalidFeedback,
  StyledSubmitButton,
  StyledText,
} from '../../../../common';
import Dialog from '../../../../common/Dialog';

const breakpoints = {
  md: 768,
}

const PassworRecoverForm: FC = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { messages } = useMessages();
  const [width] = useWindowResize();
  const [show, setShow] = useState<boolean>(false);
  const { fields, links, dialogs: { recover_by_email } } = messages.forms.password_recover;
  const { password_recovered } = messages.dialogs;


  const toggleShow = () => {
    setShow(!show);
    navigate(ROUTES.ROOT);
  };

  const { mutate, isLoading } = useMutation((values: typeof RECOVER_PASSWORD_INITIAL_VALUES) => {
    return axios.post(`${environment.ENDPOINT_AUTH}/auth/recover-password`, values);
  });


  const handleSubmit = (values: typeof RECOVER_PASSWORD_INITIAL_VALUES) => {
    mutate(values, {
      onSuccess: (response: any) => {
        setShow(!show);

      },
      onError: (error: any) => {
        setShow(!show);
      }
    });
  };

  return (
    <Formik
      initialValues={RECOVER_PASSWORD_INITIAL_VALUES}
      validateOnChange={false}
      validationSchema={RECOVER_PASSWORD_VALIDATION_SCHEMA}
      enableReinitialize
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldError,
        isSubmitting,
      }) => (
        <>
          <form onSubmit={handleSubmit} className="py-3 px-md-3">
            <Row>
              <Col xs={12}>
                <div className="d-flex flex-row justify-content-center mb-3">
                  <HomieRentLogo width={150} height={80} color={theme.palette.primary.main} className="mb-2 px-4 w-50" />
                </div>
              </Col>
              <Col xs={12}>
                <StyledText className={`${(width <= breakpoints.md) ? 'h1' : 'h2'} title text-center mb-2 fw-normal`}>
                  {recover_by_email.title}
                </StyledText>
                <StyledText className="subtitle text-center mb-4 ">
                  {recover_by_email.description}
                </StyledText>
              </Col>
              <Col xs={12} className="mb-3">
                <div className="input-group">
                  <StyledAddon
                    className={`input-group-text border-end-0 rounded-0 ${(errors.email && touched.email) && 'border-end-0 with-error'}`}
                    id="addon-wrapping"
                    hasError={(errors.email && touched.email)}
                    isFilled={(values.email.length > 0)}
                  >
                    <Mail
                      size={24}
                      color={
                        ((errors.email && touched.email) || (values.email.length > 0))
                          ? 'currentColor' : theme.palette.inputTextColor}
                    />
                  </StyledAddon>
                  <StyledInput
                    type="email"
                    name="email"
                    className={`border-start-0 rounded-0 ${(errors.email && touched.email) ? 'with-error' : ''}`}
                    id="email"
                    placeholder={fields.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldError('email', undefined)}
                    value={values.email}
                    isFilled={(values.email.length > 0)}
                    isInvalid={errors.email && touched.email}
                  />
                  {errors.email && touched.email && (
                    <StyledInvalidFeedback className="invalid-feedback">
                      {errors.email}
                    </StyledInvalidFeedback>
                  )}
                </div>

              </Col>
              <Col xs={12}>
                <StyledSubmitButton
                  className="btn w-100 btn-lg py-1 primary filled"
                  type="submit"
                  disabled={isSubmitting}
                // $isValid={(isValid && (values.email.length > 0) && (values.password.length > 0) && !errorMessage && !auth.errorAlert)}
                >
                  {recover_by_email.submit_button}
                  {isLoading &&
                    <span
                      className="spinner-grow float-end"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  }
                </StyledSubmitButton>
              </Col>
              <Col xs={12} className="text-center mt-4">
                <StyledText as={NavLink} to={ROUTES.LOGIN} className=" primary fw-semibold h5 my-2">
                  {links.go_back}
                </StyledText>
              </Col>
            </Row>
          </form>
          <Dialog open={show} onHide={toggleShow} size={(width <= breakpoints.md) ? 'lg' : undefined}>
            <Row className="px-3 py-3 py-md-5">
              <Col xs={12} className="d-flex flex-row justify-content-center align-items-center mb-3 mt-md-5">
                  <EmoticonHappyOutline size={(width <= breakpoints.md) ? 50 : 80} color={theme.palette.tertary?.main} />
              </Col>
              <Col xs={12} className=" pt-4 pb-3">
                <StyledText className={`title text-center ${(width <= breakpoints.md) ? 'h4' : 'h2'} fw-normal`}>
                  {password_recovered.title}
                </StyledText>
              </Col>
              <Col xs={12}>
                <StyledText className={`subtitle ${(width <= breakpoints.md) ? 'h6' : 'h5'} text-center fw-normal mb-md-5`}>
                  {password_recovered.text}
                </StyledText>
              </Col>
            </Row>
          </Dialog>
        </>
      )}
    </Formik>
  );
};


export default PassworRecoverForm;