import { useState, FC, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { Formik } from 'formik';
import EyeOutline from 'mdi-react/EyeOutlineIcon';
import EyeOffOutline from 'mdi-react/EyeOffOutlineIcon';
import { useTheme } from 'styled-components';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { authRentApi } from '../../../../../apis/authRentApi';
import {
  PasswordChangeValues,
  PASSWORD_CHANGE_INITIAL_VALUES,
  PASSWORD_CHANGE_VALIDATION_SCHEMA
} from '../../../../validations/authValidations';
import { CONFIRM_STATUS, ErrorMessage } from '../../../../../global';
import Confirm from '../../../../common/Confirm';
import { PATHS, ROUTES } from '../../../../../global/urls';
import { HomieRentLogo, Lock, LockOpen, LockTime } from '../../../../assets/icons';
import {
  StyledAddon,
  StyledAlert,
  StyledHelperText,
  StyledInput,
  StyledInvalidFeedback,
  StyledPasswordButton,
  StyledSubmitButton,
  StyledText,
  StyledTooltip
} from '../../../../common';
import { useMessages, useWindowResize } from '../../../../../utils/hooks';

const fieldsErorrs = {
  old_password: false,
  password: false,
  password_confirm: false,
}

interface FieldError {
  [x: string]: boolean;
}

const breakpoints = {
  md: 768,
}

const PasswordChangeForm: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const { messages } = useMessages();
  const [width] = useWindowResize();
  const [parentPath, setParentPath] = useState<string | null>(null);
  const [fieldsHasError, setFieldsHasErrors] = useState<FieldError>(fieldsErorrs);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const { fields, helper_texts, dialogs: { self_management, forced } } = messages.forms.password_change;
  const { links } = messages.forms.password_recover;
  const { password_changed_successful } = messages.dialogs;
  const theme = useTheme();

  useEffect(() => {
    setParentPath(pathname.split('/')[1]);
  }, [pathname]);

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowNewPasswordRepeat = () => {
    setShowNewPasswordRepeat(!showNewPasswordRepeat);
  };

  const { mutate, isLoading } = useMutation((values: any) => {
    return authRentApi.post(`/accounts/change-password/`, {
      'old_password': values.old_password,
      'password': values.password,
      'password_confirm': values.password_confirm
    })
  }
  );

  useEffect(() => setFieldsHasErrors(fieldsHasError), [fieldsHasError]);

  const onSubmit = (values: typeof PASSWORD_CHANGE_INITIAL_VALUES, setSubmitting: (isSubmitting: boolean) => void) => {
    setErrorMessage(null);
    setErrorAlert(null);
    setFieldsHasErrors({ ...fieldsErorrs })
    mutate(values, {
      onSuccess: (response: any) => {
        setSubmitting(false);
        if (parentPath === PATHS.ACCOUNT) {
          setErrorMessage({
            title: password_changed_successful.title,
            text: password_changed_successful.text,
            status: CONFIRM_STATUS.PASSWORD_SUCCESS,
          });
          setOpenConfirm(true);
        }
        if (parentPath === PATHS.LOGIN) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('isForcedChangePassword');
          navigate(ROUTES.LOGIN, { replace: true });
        }
      },
      onError: (error: any) => {
        let message: string = '';
        const { data, status } = error.response;
        setSubmitting(false);
        if ((status === 401 || status === 400) && data.description) {
          message = data.description;
        }
        // ? NOTA: PRobablemente esto cambie mas adelante, pero dependiendo del error del backend se activa en un estado de error para poder mostrar en pantalla
        if (status === 400) {
          for (const property in data) {
            setFieldsHasErrors((prev: FieldError) => ({ ...prev, [property]: true }));
            message += `${data[property]} `;
          }
        }
        setErrorAlert(message);
      }
    });
  }

  const toggleConfirm = () => {
    setOpenConfirm(!openConfirm);
    if (parentPath === PATHS.ACCOUNT) {
      navigate(ROUTES.HOME, { replace: true });
    }
  };

  const validateForm = (fields: PasswordChangeValues) => {
    for (const property in fields) {
      if (fields[property as keyof PasswordChangeValues].trim().length === 0) {
        return false;
      }
      return true;
    }
  }

  return (
    <>
      <Formik
        initialValues={PASSWORD_CHANGE_INITIAL_VALUES}
        validationSchema={PASSWORD_CHANGE_VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldError,
          isValid
        }) => (
          <form onSubmit={handleSubmit} className="py-3 px-md-3">
            <Row>
              <Col xs={12}>
                <div className="d-flex flex-row justify-content-center mb-3">
                  <HomieRentLogo width={150} height={60} color={theme.palette.primary.main} className="mb-2 px-4 w-50" />
                </div>
              </Col>
              {!errorAlert && (
                <Col xs={12}>
                  <StyledText className={`${(width <= breakpoints.md) ? 'h1' : 'h2'} title text-center mb-4 fw-normal`}>
                    {(parentPath === PATHS.ACCOUNT) ? self_management.title : forced.title}
                  </StyledText>
                </Col>
              )}
              {errorAlert && (
                <Col xs={12}>
                  <StyledAlert variant="dark" className="d-flex flex-row align-items-center secondary">
                    <div className="me-3">
                      <InformationOutline size={40} />
                    </div>
                    {errorAlert}
                  </StyledAlert>
                </Col>
              )}
              <Col xs={12}>
                <div className="input-group mb-3">
                  <StyledAddon
                    className={`input-group-text border-end-0 rounded-0 ${((errors.old_password && touched.old_password) || fieldsHasError.old_password) && 'border-end-0 with-error'}`}
                    id="addon-wrapping"
                    hasError={((errors.old_password && touched.old_password) || fieldsHasError.old_password)}
                    isFilled={(values.old_password.length > 0)}
                  >
                    <LockTime
                      size={24}
                      color={
                        ((errors.old_password && touched.old_password) || fieldsHasError.old_password || (values.old_password.length > 0))
                          ? 'currentColor' : '#756A6A'}
                    />
                  </StyledAddon>
                  <StyledInput
                    type="password"
                    name="old_password"
                    className={
                      ((errors.old_password && touched.old_password) || fieldsHasError.old_password) ? 'form-control is-invalid rounded-0 border-start-0 with-error' : 'form-control rounded-0 border-start-0'
                    }
                    id="old_password"
                    placeholder={fields.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldError('old_password', undefined)}
                    value={values.old_password}
                    isFilled={(values.old_password.length > 0)}
                  />
                  {errors.old_password && touched.old_password && (
                    <StyledInvalidFeedback className="invalid-feedback">
                      {errors.old_password}
                    </StyledInvalidFeedback>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div className="input-group">
                  <StyledAddon
                    className={`input-group-text border-end-0 rounded-0 ${((errors.password && touched.password) || fieldsHasError.password) && 'border-end-0 with-error'}`}
                    id="addon-wrapping"
                    hasError={((errors.password && touched.password) || fieldsHasError.password)}
                    isFilled={(values.password.length > 0)}
                  >
                    <LockOpen
                      size={24}
                      color={
                        ((errors.password && touched.password) || fieldsHasError.password || (values.password.length > 0))
                          ? 'currentColor' : '#756A6A'}
                    />
                  </StyledAddon>
                  <StyledInput
                    type={(showNewPassword) ? 'text' : 'password'}
                    className={
                      ((errors.password && touched.password) || fieldsHasError.password) ? 'form-control is-invalid border-start-0 border-end-0 rounded-0 with-error' : 'form-control rounded-0 border-start-0 border-end-0'
                    }
                    name="password"
                    id="password"
                    placeholder={fields.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldError('password', undefined)}
                    value={values.password}
                    isFilled={(values.password.length > 0)}
                  />
                  <StyledPasswordButton
                    className={`btn border-start-0 rounded-0 ${((errors.password && touched.password) || fieldsHasError.password) && 'with-error'}`}
                    hasError={((errors.password && touched.password) || fieldsHasError.password)}
                    isFilled={(values.password.length > 0)}
                    type="button"
                    id="toggle-new-password"
                    onClick={toggleShowNewPassword}
                  >
                    {showNewPassword ?
                      (<EyeOffOutline
                        size={24}
                        color={((errors.password && touched.password) || (values.password.length > 0) || fieldsHasError.password)
                          ? 'currentColor' : '#756A6A'}
                      />) : (
                        <EyeOutline
                          size={24}
                          color={((errors.password && touched.password) || (values.password.length > 0) || fieldsHasError.password)
                            ? 'currentColor' : '#756A6A'}
                        />)}
                  </StyledPasswordButton>
                  {errors.password && touched.password && (
                    <StyledInvalidFeedback className="invalid-feedback">
                      {errors.password}
                    </StyledInvalidFeedback>
                  )}
                </div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <StyledTooltip id="password-tooltip" className="text-left secondary fst-italic">
                      La contraseña debe:
                      <ul className="my-0">
                        <li>Contener al menos 8 caracteres.</li>
                        <li>Ser diferente a las anteriores.</li>
                        <li>Ser diferente al email de registro.</li>
                        <li>Contener números que no sean consecutivos.</li>
                        <li>Ser alfanumérica y contener al menos una letra mayúscula y una minúscula.</li>
                        <li>Ser seguida, sin espacios en blanco.</li>
                        <li>Ser diferente a contraseñas comunes.</li>
                      </ul>
                    </StyledTooltip>
                  }
                >
                  <StyledHelperText className="w-100">
                    <small><em><u>{helper_texts.password_format}</u></em></small>
                  </StyledHelperText>
                </OverlayTrigger>
              </Col>
              <Col xs={12}>
                <div className="input-group my-3">
                  <StyledAddon
                    className={`input-group-text border-end-0 rounded-0 ${((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm) && 'border-end-0 with-error'}`}
                    id="addon-wrapping"
                    hasError={((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm)}
                    isFilled={(values.password_confirm.length > 0)}
                  >
                    <Lock
                      size={24}
                      color={
                        ((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm || (values.password_confirm.length > 0))
                          ? 'currentColor' : '#756A6A'}
                    />
                  </StyledAddon>
                  <StyledInput
                    type={(showNewPasswordRepeat) ? 'text' : 'password'}
                    className={
                      ((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm) ? 'form-control is-invalid border-start-0 border-end-0 rounded-0 with-error' : 'form-control rounded-0 border-start-0 border-end-0'
                    }
                    name="password_confirm"
                    id="password_confirm"
                    placeholder={fields.repeat_new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => setFieldError('password_confirm', undefined)}
                    value={values.password_confirm}
                    isFilled={(values.password_confirm.length > 0)}
                  />
                  <StyledPasswordButton
                    className={`btn border-start-0 rounded-0 ${((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm) && 'with-error'}`}
                    hasError={((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm)}
                    isFilled={(values.password_confirm.length > 0)}
                    type="button"
                    id="toggle-new-password-repeat"
                    onClick={toggleShowNewPasswordRepeat}
                  >
                    {showNewPasswordRepeat ?
                      (<EyeOffOutline
                        size={24}
                        color={((errors.password && touched.password_confirm) || fieldsHasError.password_confirm || (values.password_confirm.length > 0))
                          ? 'currentColor' : '#756A6A'}
                      />) : (
                        <EyeOutline
                          size={24}
                          color={((errors.password_confirm && touched.password_confirm) || fieldsHasError.password_confirm || (values.password_confirm.length > 0))
                            ? 'currentColor' : '#756A6A'}
                        />)
                    }
                  </StyledPasswordButton>
                  {errors.password_confirm && touched.password_confirm && (
                    <StyledInvalidFeedback className="invalid-feedback">
                      {errors.password_confirm}
                    </StyledInvalidFeedback>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <StyledSubmitButton
                  className="btn w-100 btn-lg py-1 primary filled"
                  type="submit"
                  disabled={isSubmitting}
                  $isValid={(isValid && validateForm(values) && Object.values(fieldsHasError).every((value) => value === true))}
                >
                  {(parentPath === PATHS.ACCOUNT) ? self_management.submit_button : forced.submit_button}
                  {isLoading &&
                    <span
                      className="spinner-grow float-end"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  }
                </StyledSubmitButton>
              </Col>
              {(parentPath === PATHS.LOGIN) && (
                <Col xs={12} className="text-center mt-4">
                  <StyledText as={NavLink} to={ROUTES.LOGIN} className=" primary fw-semibold h5 my-2">
                    {links.go_back}
                  </StyledText>
                </Col>
              )}
            </Row>
          </form>
        )}
      </Formik>
      {(errorMessage && parentPath === PATHS.ACCOUNT) && (
        <Confirm
          show={openConfirm}
          onHide={toggleConfirm}
          title={errorMessage.title}
          // text={<NavLink className="text-dark" to={ROUTES.HOME}>{errorMessage.text}</NavLink>}
          status={errorMessage?.status as string}
          enableClose
          actions={
            <div className="row justify-content-center">
              <div className="col-6 col-sm-6">
                <StyledSubmitButton
                  bsPrefix="btn w-100 rounded-4 primary-outline"
                  onClick={toggleConfirm}
                >
                  Continuar
                </StyledSubmitButton>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default PasswordChangeForm;