import { FC, useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import { AccountUser, UserAd } from '../../../../global/interfaces';
import AdsViewer from '../../../common/AdsViewer';
import { stackRentApi } from '../../../../apis/stackRentApi';
import Spinner from '../../../common/Spinner';
import { useAuthorization, useStoredQuery, useMessages, useUtils } from '../../../../utils/hooks';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { StyledEmptyContainer, StyledText } from '../../../common';
import AppStart from '../../../assets/images/app_start.svg';
import { PAGE_TITLE } from '../../../../global/urls';

const InvestorHomePage: FC = () => {
  const [adList, setAdList] = useState<UserAd[]>([]);
  const [person, setPerson] = useState<AccountUser>({} as AccountUser);
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const { capitalize } = useUtils();
  const { title, description } = messages.modules.investor.home;

  const data = useStoredQuery(['profile']);

  useEffect(() => {
    if (data) {
      const { person_data } = data.data;
      setPerson(person_data);
    }
  }, [data])


  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['investor/properties'], async () => {
    return await stackRentApi.get('/ad/me');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setAdList(response.data);
      },
      onError: (error: AxiosError) => {
        setAdList([]);
      }
    });

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_14}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_HOME}</title>
      </Helmet>
      {(adList.length !== 0 && checkAccess(PERMISSIONS.PLA_SERVI_21)) && (
        <AdsViewer adList={adList} />
      )}
      <Container fluid className="px-0">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6} xl={3}>
            <div className="d-flex flex-row justify-content-center">
              <Image src={AppStart} className=" w-100" />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col xs={12}>
            <StyledText className="h1 fw-semibold text-center active">
              {`${title.start}${capitalize(person.first_name)}${title.end}`}
            </StyledText>
          </Col>
          <Col xs={12} sm={12} md={9} lg={6}>
            <StyledText className="h5 fw-normal text-center mt-2">{description}</StyledText>
          </Col>
        </Row>
      </Container>
    </Authorization>
  );
};

export default InvestorHomePage;