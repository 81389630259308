export interface Message {
  [key: string]: any;
}

export const defaultMessages: Message = {
  lang: "es",
  modules: {
    auth: {
      title: "Autenticación",
      actions: {
        sign_in: "Iniciar Sesión",
        sign_out: "Cerrar Sesión",
      }
    },
    account: {
      profile: {
        title: "Cuenta de Usuario",
        actions: {
          update: "Actualizar datos",
          send: 'Enviar'
        },
      }
    },
    notifications: {
      title: 'Notificaciones',
      actions: {
        mark_all_as_read: 'Marcar todo como leído',
        mark_as_read: 'Marcar como leído',
        mark_as_unread: 'Marcar como no leído',
        delete: 'Eliminar notificación',
        save: 'Guardar notificación',
      },
      sections: {
        current: 'HOY',
        older: 'MÁS ANTIGUAS'
      }
    },
    settings: {
      title: 'Configuración',
      actions: {},
    },
    help: {
      title: 'Ayuda',
      actions: {},
    },
    investor: {
      title: "Portal Inversionista",
      home: {
        title: {
          start: '¡Hola, ',
          end: '!',
        },
        description: 'En tu plataforma podrás conocer información y ver el estado de la administración de tus propiedades.',
        sections: {

        }
      },
      properties: {
        title: 'Información de la propiedad',
        sections: {
          actions: {
            search: 'Buscar propiedad',
          },
          table: {
            columnsNames: {
              address: 'Dirección de la propiedad',
              condominium: 'Condominio',
              commune: 'Comuna',
              status: 'Estado',
              price: 'Valor de arriendo'
            },
            filtersNames: {
              search_property: 'Buscar propiedad',
              see_all: 'Ver todas'
            }
          },
          stats: {
            title: 'Propiedad: ',
            payment: {
              title: 'El pago del arrendatario',
              status: {
                up_to_date: 'Está al día'
              }
            },
            status: {
              title: 'Estado de la propiedad',
              status: {
                rented: 'Arrendada'
              }
            },
            current_lease: {
              title: 'Arriendo vigente',
              status: {
                to: 'Hasta'
              }
            },
            no_current_contract: {
              title: 'Arriendo para publicación',
              status: {
                published_value: ''
              }
            },
          },
          information: {
            title: 'Información de la propiedad',
            data: {
              title: 'Datos',
              fields: {
                type: 'Tipo',
                address: 'Dirección',
                region: 'Región',
                city: 'Ciudad',
                floor: 'Piso',
                department: 'N° de departamento',
                project_name: 'Nombre Proyecto',
                orientation: 'Orientación',
                building_date: 'Año de construcción',
                description: 'Descripción',
                commune: 'Comuna',
                role_number: 'N° Rol Propiedad',
                tower: 'Torre',
                condominium: 'Condominio',
              }
            },
            features: {
              title: 'Características',
              fields: {
                total_surface: 'Superficie total (m2)',
                useful_surface: 'Superficie útil (m2)',
                bedrooms_quantity: 'N° de habitaciones',
                bathrooms_quantity: 'N° de baños',
                parking: 'Estacionamiento',
                warehouse: 'Bodega',
                elevators_quantity: 'N° de elevadores',
                warehouse_number: 'N° de bodega',
                parking_number: 'N° de estacionamiento',
                furnished: 'Amoblado',
              }
            },
            services: {
              title: 'Servicios básicos',
              fields: {
                electricity_supplier: 'Proveedor de electricidad',
                electricity_client_number: 'N° Cliente electricidad',
                water_supplier: 'Proveedor de agua',
                water_client_number: 'N° Cliente agua',
                gas_supplier: 'Proveedor de gas',
                gas_client_number: 'N° Cliente gas',
                common_expenses: 'Gastos comunes',
                website: 'Portal web',
              }
            },
            leases: {
              title: 'Arriendos',
              fields: {
                contract_id: 'N° Contrato',
                status: 'Estado',
                validity: 'Vigencia',
                lease_name: 'Arrendatario',
              },
            },
            inspections: {
              title: 'Inspecciones',
              fields: {
                contract_start_date: 'Inicio de contrato',
                status: 'Estado',
                inspection_date: 'Inspección realizada',
              },
              actions: {
                see_report: 'Ver informe',
                see: 'Ver'
              }
            }
          }
        }
      },
      publications: {
        title: 'Publicaciones',
        sections: {
          publication: {
            fields: {
              publishers: "Portales de publicación",
              publish_value: "Valor de arriendo publicado",
              published_at: "Publicada en",
              portals: 'Portales',
              publish_date: "Fecha publicación",
              republish_date: "Fecha republicación",
              request_total: "Total de consultas",
              publication_views: "Visitas en publicación",
              department: 'N° Departamento',
              condominium: 'Condominio',
              posted: 'Publicado',
              post_date: 'Fecha de publicación',
              time_posted: {
                start: 'La propiedad lleva',
                end: 'día(s) publicada'
              },
              calendar_footer: 'Publicación en el portal',
              publication_value: 'Valor de arriendo publicado',
            }
          },
          no_publication: {
            title: '¡Sin publicaciones!',
            description: 'En estos momentos tu propiedad no se encuentra publicada en portales. Navega en tu plataforma, podrás conocer información sobre la administración de tus propiedades.'
          }
        },
        actions: {
          see_publications: 'Ver publicación',
          see_details: 'Ver detalle',
          copy_link: 'Copiar link',
          see: 'Ver',
          copy: 'Copiar',
        },
        feedbacks: {
          copied_successful: '¡Listo! Ya copiaste el link, ahora puedes compartirlo.',
          copied_failed: 'No se pudo copiar el link.'
        }
      },
      contracts: {
        title: 'Mis contratos disponibles',
      },
      support: {
        title: 'Servicio al cliente',
        description: '¿Tienes alguna consulta? Creando un ticket podrás obtener atención de un ejecutivo',
        create_tickets: {
          title: 'Realizar consultas o solicitudes',
          action: 'Crear ticket ahora',
        },
        manage_tickets: {
          title: 'Gestionar mis tickets',
          action: 'Ingresar al portal',
          url: 'https://soporte.homierent.com/portal/es/signin'
        }
      }
    },
    management: {
      title: "Portal Gestión",
      home: {
        title: '',
        description: '',
        sections: {

        }
      },
      inspections: {
        title: 'Consulta de Inspecciones',
        description: 'Selecciona la propiedad a consultar o puedes utilizar el buscador',
        actions: {
          search: 'Buscar',
        },
        sections: {
          properties: {
            columns: {
              property_name: 'Nombre de la Propiedad',
              commune: 'Comuna',
            }
          },
          self_inspections: {
            name: 'Autoinspección',
            title: 'Consulta de Inspecciones',
            fields: {
              help: 'Mostrar opciones de  búsqueda',
              filter_by_month: 'Mes programado',
              filter_by_status: 'Estado',
              filter_by_asset_code: 'Código Propiedad',
              filter_by_asset_name: 'Nombre de la propiedad',
              filter_by_asset_date: 'Fecha realizada',
              filter_by_rup: 'RUP',
              filter_by_tenant_fullname: 'Arrendatario',
              filter_by_tenant_rut: 'RUT Arrendatario',
            },
            columns: {
              inspection_month: 'Mes programado',
              asset_code: 'Código propiedad',
              asset_name: 'Nombre propiedad',
              commune: 'Comuna',
              contract_start_date: 'Fecha inicio de contrato',
              tenant_fullname: 'Arrendatario',
              tenant_rut: 'Rut arrendatario',
              tenant_phone: 'Tel. arrendatario',
              tenant_email: 'Email arrendatario',
              inspection_phone: 'Tel. inspección',
              status: 'Estado',
              date: 'Fecha realizada',
              email: 'Email dueño',
              rup: 'RUP',
              actions: 'Acciones',
            },
            actions: {
              filter: 'Filtrar',
              clear_filter: 'Limpiar filtro',
              more: 'Más',
              view_report: 'Ver Informe',
              download_report: 'Descargar Informe',
              send_ticket: 'Enviar ticket Inversionista',
              see_picture: 'Ver foto'
            },
            online_inspection: {
              name: 'Inspección',
              title: 'Informe de Autoinspección',
              sections: {
                tenant_data: {
                  title: 'Datos del arrendatario',
                  fullname: 'Nombre:',
                  rut: 'Rut:',
                  email: 'Correo electrónico:',
                  phone: 'Teléfono:',
                  date: 'Fecha Autoinspección',
                },
                features: {
                  title: 'Características de la propiedad',
                  bedrooms_quantity: 'N° de habitaciones:',
                  bathrooms_quantity: 'N° de baños:'
                },
                observations: {
                  title: 'Observaciones',
                  no_observations: 'La inspección de la propiedad no tiene observaciones.'
                },
                pictures: 'Fotografías',
                observation: 'Observación',
                areas: {
                  access: {
                    title: 'Inspección Área de Acceso',
                    elements: 'Elementos con problemas (Puerta, Cerradura/Chapa, Alarma, Citófono, Enchufes, Interruptor, Lámpara, Tablero Eléctrico, Timbre y Armario):'
                  },
                  kitchen: {
                    title: 'Inspección en la Cocina',
                    elements: 'Elementos con problemas (Desagüe, Grifo, Lavaplatos, Enchufes, Interruptor, Lámparas, Mesón de cocina, Mobiliario de cocina, Puerta de cocina y Ventana de cocina):'
                  },
                  living: {
                    title: 'Inspección del Living/Comedor',
                    elements: 'Elementos con problemas (Armario, Cortinero, Puerta, Ventana/Termopanel, Lámparas, Enchufes, Interruptor y Calefactor):'
                  },
                  bathroom: {
                    title: 'Inspección del Baño',
                    elements: 'Elementos con problemas (Puerta, Ventana/Termopanel, Lavamanos, Grifería, Desagües/Sifones, Inodoro, Tina, Mobiliario, Espejo, Accesorios, Extractor/Rejilla, Lámparas, Interruptor y Enchufes):'
                  },
                  bedroom: {
                    title: 'Inspección del Dormitorio',
                    elements: 'Elementos con problemas (Puerta, Ventana/Termopanel, Clóset, Cortinero, Enchufes, Lámparas, Interruptor, Calefactor y Walking Clóset):'
                  },
                  extra_kitchen: {
                    title: 'Inspección Horno, Campana y Encimera',
                    elements: 'Elementos con problemas (Horno, Campana y Encimera):'
                  },
                  terrace: {
                    title: 'Inspección del Terraza/Balcón',
                    elements: 'Elementos con problemas (Barandas, Lámparas, Murallas, Piso y Techo):'
                  },
                  humidity: {
                    title: 'Estado de Humedad de la propiedad',
                    areas: 'Áreas que tienen humedad:',
                    elements: '¿Tiene humedad? (Acceso, Cocina, Dormitorio/s, Living, Terraza/Balcón y Baño/s):'
                  },
                  extra_revision: {
                    title: 'Inspección Termo eléctrico, Calefón o Caldera individual:',
                    elements: 'Tipo de calentador (Termo Eléctrico, Calefón y Caldera individual):',
                    status: 'Estado de funcionamiento:',
                    response: 'Respuesta de mantención de elemento:'
                  },
                  extra_bill: {
                    title: 'Soporte de Mantención',
                    has_sent: 'Envío de soporte de mantención a través de Zoho:',
                    response: 'Respuesta de mantención de elementos:'
                  },
                  extra: {
                    title: {
                      start: 'Estado de',
                      end: 'de la propiedad'
                    },
                    status: 'Estado:'
                  },
                  video: {
                    title: 'Video',
                    link: 'Link:',
                    link_name: 'Ver video'
                  }
                }
              }
            }
          }
        }
      },
      users: {
        title: 'Usuarios',
        description: '',
        actions: {
          search: '',
        },
        sections: {
          users: {
            name: 'Usuarios',
            title: 'Consulta de Usuarios',
            fields: {
              help: 'Mostrar opciones de  búsqueda',
              filter_by_username: 'Nombre de usuario',
              filter_by_fullname: 'Nombre de persona',
              filter_by_rut: 'RUT',
              filter_by_status: 'Estado usuario',
              filter_by_last_session_date: 'Fecha última sesión',
              filter_by_connected_users: 'Usuarios conectados',
              filter_by_start_date: 'Desde',
              filter_by_end_date: 'Hasta',
              filter_by_credentials_sent: 'Envío de credenciales', 
            },
            columns: {
              username: 'Nombre de usuario',
              full_name: 'Nombre de persona',
              rut: 'RUT',
              status: 'Estado usuario',
              last_session_date: 'Fecha última sesión',
              connected_users: 'Usuarios conectados',
              sent_credentials: 'Envío de credenciales',
              sent_credentials_sent: 'Fecha de envío',
              actions: 'Acciones',
            },
            actions: {
              filter: 'Filtrar',
              clear_filter: 'Limpiar filtro',
              apply: 'Aplicar',
              more: 'Más',
              enable_user: 'Activar usuario',
              disable_user: 'Inactivar usuario'
            },
            dialogs: {
              change_user_status: {
                enable: {
                  title: '¿Estás seguro que quieres activar el usuario?',
                  description: '',
                  call_to_action: 'Activar'
                },
                disable: {
                  title: '¿Estás seguro que quieres inactivar el usuario?',
                  description: '',
                  call_to_action: 'Inactivar'
                },
              }
            }
          }
        }
      },
      assets: {
        title: 'Propiedades',
        description: '',
        actions: {
          search: '',
        },
        sections: {
          assets: {
            name: 'Propiedades',
            title: 'Consulta de Propiedades',
            fields: {
              filter_by_investor_name: 'Nombre del inversionista',
              filter_by_asset_name: 'Nombre de la propiedad',
              filter_by_rup: 'RUP',
              filter_by_status: 'Estado',
              filter_by_podio_id: 'Podio ID',
              filter_by_breal_id: 'Breal ID',
              filter_by_region: 'Región',
              filter_by_condominium: 'Condominio',
              filter_by_commune: 'Comuna', 
              filter_by_asset_number: 'N° Departamento', 
              filter_by_asset_type: 'Tipo', 
            },
            columns: {
              asset_name: 'Nombre de la propiedad',
              investor_name: 'Nombre del inversionista',
              rup: 'RUP',
              status: 'Estado',
              podio_id: 'Podio ID',
              breal_id: 'Breal ID',
              region: 'Región',
              commune: 'Comuna',
              condominium: 'Condominio',
              asset_type: 'Tipo',
              asset_number: 'N° Departamento',
              actions: 'Acciones',
            },
            actions: {
              filter: 'Filtrar',
              clear_filter: 'Limpiar filtro',
              apply: 'Aplicar',
              more: 'Más',
              see_property: 'Ver propiedad',
              see_publication: 'Ver publicación'
            },
            dialogs: {}
          },
          stats: {
            title: 'Propiedad: ',
            payment: {
              title: 'El pago del arrendatario',
              status: {
                up_to_date: 'Está al día'
              }
            },
            status: {
              title: 'Estado de la propiedad',
              status: {
                rented: 'Arrendada'
              }
            },
            current_lease: {
              title: 'Arriendo vigente',
              status: {
                to: 'Hasta'
              }
            },
            no_current_contract: {
              title: 'Sin contrato vigente',
              status: {
                published_value: 'Valor de arriendo publicado'
              }
            },
          },
          information: {
            title: 'Información de la propiedad',
            data: {
              title: 'Datos',
              fields: {
                type: 'Tipo',
                address: 'Dirección',
                region: 'Región',
                city: 'Ciudad',
                floor: 'Piso',
                department: 'N° Departamento',
                project_name: 'Nombre Proyecto',
                orientation: 'Orientación',
                building_date: 'Año de Construcción',
                description: 'Descripción',
                commune: 'Comuna',
                role_number: 'N° Rol Propiedad',
                tower: 'Torre',
              }
            },
            features: {
              title: 'Características',
              fields: {
                total_surface: 'Superficie total (m2)',
                useful_surface: 'Superficie útil (m2)',
                bedrooms_quantity: 'N° de habitaciones',
                bathrooms_quantity: 'N° de baños',
                parking: 'Estacionamiento',
                warehouse: 'Bodega',
                elevators_quantity: 'N° de elevadores',
                warehouse_number: 'N° de bodega',
                parking_number: 'N° de estacionamiento',
                furnished: 'Amoblado',
              }
            },
            services: {
              title: 'Servicios básicos',
              fields: {
                electricity_supplier: 'Proveedor de electricidad',
                electricity_client_number: 'N° Cliente electricidad',
                water_supplier: 'Proveedor de agua',
                water_client_number: 'N° Cliente agua',
                gas_supplier: 'Proveedor de gas',
                gas_client_number: 'N° Cliente gas',
                common_expenses: 'Gastos comunes',
                website: 'Portal web',
              }
            },
            leases: {
              title: 'Arriendos',
              fields: {
                contract_id: 'N° Contrato',
                status: 'Estado',
                validity: 'Vigencia',
              },
            }
          }
        }
      },
    },
  },
  errors: {
    sessions: {
      "01": 'La última sesión se desconectó debido a la inactividad, puedes volver a ingresar.',
      "03": 'La última sesión fue desconectada.',
      "02": '¡Lo sentimos! Tu sesión ha expirado.',
      "04": 'La última sesión se desconectó debido a que tu licencia de uso ha caducado.',
      "05": '¡Lo sentimos! Tu sesión ha expirado.',
      "06": 'Sesión cerrada por cambio de contraseña.',
      "07": 'Sesión cerrada por el usuario.',
    },
    credentials: {
      "04": 'Los datos de tu cuenta no son válidos para ingresar.',
      "05": 'Los datos de tu cuenta no son válidos para ingresar.',
      "11": 'Su usuario se encuentra bloqueado temporalmente.',
      "12": 'Las credenciales de acceso son inválidas, ingresa nuevamente la contraseña.',
    },
    tokens: {
      "08": 'Token requiere ser renovado.',
      "09": 'Token no encontrado.',
    },
    server: {},
    pages: {
      page_not_found: {
        title: 'Error 404',
        subtitle: '¡Oh no!',
        description: 'La página que buscas no está disponible.',
        cta: 'Volver a Inicio'
      }
    }
  },
  dialogs: {
    temporally_blocked: {
      title: '¡Lo sentimos!',
      text: 'La cuenta ha sido bloqueada de manera temporal, haz superado la cantidad de intentos permitidos. Reinténtalo más tarde.'
    },
    invalid_credentials: {
      title: '¡Oh!',
      text: 'Los datos ingresados no son válidos, te solicitamos que los verifques y/o vuelve a ingresar los datos.'
    },
    password_changed_successful: {
      title: '¡Tu nueva contraseña ha sido guardada!',
      text: 'Volver a la página de inicio',
    },
    new_ticket_created: {
      title: {
        start: '¡Tú ticket ID ',
        end: 'fue enviado con éxito!',
      },
      text: 'Recibirás un correo electrónico con la confirmación de la recepción del mismo.',
      call_to_action: 'Crear nuevo ticket'
    },
    service_unavailable: {
      title: '¡Lo sentimos!',
      text: 'El servicio no está disponible en este momento. Intenta nuevamente más tarde.',
    },
    delete_file: {
      title: '',
      text: '¿Estás seguro que quieres eliminar el archivo?',
      call_to_action: 'Eliminar'
    },
    ticket_created_after: {
      title: '¡Listo!',
      text: 'Pronto recibirás un correo electrónico con la confirmación del ticket.',
    },
    discard_changes: {
      title: '',
      text: '¿Estás seguro que deseas descartar los datos ingresados?',
      call_to_action: 'Aceptar'
    },
    broken_image: {
      title: '¡Lo sentimos!',
      text: 'La foto no se encuentra disponible en este momento.',
    },
    password_recovered: {
      title: '¡Tú solicitud ha sido recibida, te enviamos un correo electrónico para restablecer tu contraseña!',
      text: 'Si no has recibido el correo electrónico, por favor verifica en tu casilla de spam o vuelve a intentarlo nuevamente más tarde.',
    }
  },
  forms: {
    sign_in: {
      dialogs: {
        first_time: {
          title: '¡Hola!',
          description: 'Ingresa tus datos para comenzar',
          submit_button: 'Iniciar Sesión',
        },
        next_time: {
          title: '¡Ya estás de vuelta!',
          description: 'Nos encanta tenerte aquí.',
          submit_button: 'Comencemos',
        },
      },
      fields: {
        email: 'Ingresa tu correo electrónico',
        password: 'Ingresa tu contraseña',
        remember: 'Recordar contraseña',
      },
      helper_texts: {
        email: '',
        password: '',
        remember: '',
      },
      placeholders: {
        email: '',
        password: '',
        remember: '',
      },
      links: {
        recover_password: '¿Olvidaste tu contraseña?'
      }
    },
    password_change: {
      dialogs: {
        forced: {
          title: '¡Cambia tu contraseña temporal!',
          description: "",
          submit_button: 'Cambiar contraseña',
        },
        self_management: {
          title: '¿Quieres cambiar tu contraseña?',
          description: "",
          submit_button: 'Confirmar',
        },
      },
      fields: {
        old_password: 'Ingresa tu contraseña actual',
        new_password: 'Crea tu nueva contraseña',
        repeat_new_password: 'Repite tu nueva contraseña',
      },
      helper_texts: {
        old_password: '',
        new_password: '',
        repeat_new_password: '',
        password_format: 'Criterios válidos para la contraseña.'
      },
      placeholders: {
        old_password: '',
        new_password: '',
        repeat_new_password: '',
      }
    },
    password_recover: {
      dialogs: {
        recover_by_email: {
          title: 'Recuperar contraseña',
          description: "Por favor ingresa tu correo electrónico, te enviaremos las instrucciones para restablecer tu contraseña.",
          submit_button: 'Enviar',
        },
      },
      fields: {
        email: 'Ingresa tu correo electrónico',
      },
      helper_texts: {},
      placeholders: {},
      links: {
        go_back: 'Volver'
      }
    },
    profile: {
      dialogs: {
        account: {
          title: "Datos de tu cuenta"
        },
        change_request: {
          title: 'Solicitud de actualización de datos',
          subtitle: 'Ingresa el dato de contacto que deseas actualizar y presiona enviar.',
        },
        success_feedback: {
          title: '¡Tu solicitud de actualización de datos fue derivada a nuestro equipo!',
          subtitle: 'Validaremos tus datos y pronto serán actualizados en la plataforma.',
        }
      },
      fields: {
        first_name: "Nombre",
        last_name: "Apellido",
        email: "E-mail",
        phone: "Teléfono",
        rut: "RUT",
        first_session: "Fecha de primer inicio de sesión",
      },
      placeholders: {
        email: 'Ingresa tu email',
        phone: 'Ingresa tu teléfono'
      }
    },
    create_ticket: {
      title: 'Crea tu ticket',
      fields: {
        asset_name: "Nombre de la propiedad",
        type: "Tipo de requerimiento",
        subject: "Asunto",
        phone: "Teléfono",
        description: "Descripción",
        file: "Adjuntar archivo",
        submit_button: 'Enviar'
      },
      tooltips: {
        file: 'Adjuntar archivo'
      },
      placeholders: {
        asset_name: "Ingresa el nombre de la propiedad",
        type: "Selecciona el tipo de requerimiento",
        subject: "Ingresa el asunto",
        phone: "Ingresa el teléfono",
        description: "Ingresa la descripción",
        file: "JPG, PNG, PDF, XLSX y DOCX hasta 20 MB en total",
      }
    }
  },
  urls: {
    portal_inmobiliario: '',
    toc_toc: '',
    rent_vitrina: '',
    goplaceit: 'https://www.goplaceit.com/cl/empresa/1950-capitalizarme-rent'
  }
}