import { Breadcrumb } from 'react-bootstrap';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES, ROUTES_LABELS } from '../../global/urls';

const StyledBreadcrumb = styled(Breadcrumb)`
  --bs-breadcrumb-divider: '>';

  & .breadcrumb-item {
    & a {
      text-decoration: none;
      color: ${(props: any) => props.theme.palette.breadcrumbTextColor};

      &:hover {
        color: ${(props: any) => props.theme.palette.breadcrumbActiveTextColor};
        text-decoration: underline;
      }

    }
    
    &.active {
      color: ${(props: any) => props.theme.palette.breadcrumbActiveTextColor};

      & span {
        max-width: 100%;

        @media (max-width: 375px) {
          max-width: 120px;
        }
  
        @media (min-width: 376px) and (max-width: 430px) {
          max-width: 170px;
        }
      }
    }

    &.primary {
      & a {
        text-decoration: none;
        color: ${(props: any) => props.theme.palette.breadcrumbTextColor};
      }

      &.active {
        color: ${(props: any) => props.theme.palette.primary.main};
      }
    }

    &.secondary {
      & a {
        text-decoration: none;
        color: ${(props: any) => props.theme.palette.breadcrumbTextColor};
      }

      &.active {
        color: ${(props: any) => props.theme.palette.secondary?.main};
      }
    }

    &.tertary {
      & a {
        text-decoration: none;
        color: ${(props: any) => props.theme.palette.breadcrumbTextColor};
      }

      &.active {
        color: ${(props: any) => props.theme.palette.tertary?.main};
      }
    }

    &.quaternary {
      & a {
        text-decoration: none;
        color: ${(props: any) => props.theme.palette.breadcrumbTextColor};
      }

      &.active {
        color: ${(props: any) => props.theme.palette.quaternary?.main}
      }
    }
  }
`

const RouterBreadcrumb = () => {
  const location = useLocation();
  const { publicationId, assetId, inspectionId } = useParams();
  const pathnames = location.pathname.split('/').filter((x: string) => x);
  const maxLevel = 3;
  const totalPaths = pathnames.length;

  if (totalPaths > 3) {
    let elementsCount = totalPaths - maxLevel;
    pathnames.splice(2, elementsCount);
  }

  const breadcrumbNameMap: { [key: string]: string } = {
    [ROUTES.INVESTOR]: ROUTES_LABELS.HOME,
    [ROUTES.INVESTOR_HOME]: ROUTES_LABELS.HOME,
    [ROUTES.INVESTOR_PROPERTIES]: ROUTES_LABELS.PROPERTIES,
    [ROUTES.INVESTOR_PUBLICATIONS]: ROUTES_LABELS.PUBLICATIONS,
    [`${ROUTES.INVESTOR_PUBLICATION_BY_ID}/${publicationId}`]: ROUTES_LABELS.PUBLICATION,
    [ROUTES.INVESTOR_PROPERTY_INSPECTION]: ROUTES_LABELS.PROPERTIES,
    [`${ROUTES.INVESTOR_PROPERTY_INSPECTION_BY_ID}/${inspectionId}`]: ROUTES_LABELS.INSPECTION,
    [ROUTES.INVESTOR_CONTRACTS]: ROUTES_LABELS.CONTRACTS,
    [ROUTES.INVESTOR_SUPPORT]: ROUTES_LABELS.SUPPORT,
    [ROUTES.SUPPORT_CREATE_TICKET]: ROUTES_LABELS.SUPPORT_CREATE_TICKET,
    [ROUTES.MANAGEMENT]: ROUTES_LABELS.MANAGEMENT,
    [ROUTES.MANAGEMENT_INSPECTIONS]: ROUTES_LABELS.INSPECTIONS,
    [`${ROUTES.MANAGEMENT_INSPECTION_BY_ID}/${inspectionId}`]: ROUTES_LABELS.INSPECTION,
    [ROUTES.MANAGEMENT_PROPERTIES]: ROUTES_LABELS.PROPERTIES,
    [`${ROUTES.MANAGEMENT_PROPERTY_BY_ID}/${assetId}`]: ROUTES_LABELS.PROPERTY,
    [ROUTES.MANAGEMENT_PROPERTY_PUBLICATION]: ROUTES_LABELS.PROPERTIES,
    [`${ROUTES.MANAGEMENT_PROPERTY_PUBLICATION_BY_ID}/${assetId}`]: ROUTES_LABELS.PUBLICATION,
  };

  return (
    <StyledBreadcrumb aria-label="breadcrumb">
      {pathnames.map((value: string, index: number) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Breadcrumb.Item active key={to}>
            <span className="d-inline-block text-truncate">
              {breadcrumbNameMap[to]}
            </span>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={to} linkAs={NavLink} linkProps={{ to }}>
            {breadcrumbNameMap[to]}
          </Breadcrumb.Item>
        );
      })}
    </StyledBreadcrumb>
  )
}

export default RouterBreadcrumb