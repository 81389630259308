import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { CONFIRM_STATUS } from '../../global';
import { Error, LockSuccess, SafetyCheck } from '../assets/icons';
import { StyledModal } from '.';
import CloseButton from './CloseButton';

interface ConfirmProps {
  title: string;
  text?: string | JSX.Element;
  show: boolean;
  onHide: () => void;
  variant?: string;
  icon?: any;
  status?: string;
  actions?: JSX.Element | null;
  enableClose?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}

const Confirm: FC<ConfirmProps> = ({ title, text, show, onHide, status, actions, enableClose, variant = 'primary', icon: Icon, size }) => {
  const getIconByStatus = (status: string) => {
    switch (status) {
      case CONFIRM_STATUS.BLOCKED:
        return (<SafetyCheck size={80} />);
      case CONFIRM_STATUS.ERROR:
        return (<Error size={80} />);
      case CONFIRM_STATUS.PASSWORD_SUCCESS:
        return (<LockSuccess size={80} />);
    }
  };

  return (
    <StyledModal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      contentClassName={`p-3 w-100 rounded rounded-4 shadow ${variant} py-3 py-md-5`}
      backdrop="static"
      keyboard={false}
      {...(size && size !== undefined) && { size }}
    >
      <>
        {enableClose && (
          <CloseButton onClick={onHide} />
        )}
        <Modal.Body className={`${variant} mb-md-5`}>
          {(status) && (
            <div className="d-flex flex-row justify-content-center align-items-center p-3">
              {getIconByStatus(status)}
            </div>
          )}
          {(Icon) && (
            <div className="d-flex flex-row justify-content-center align-items-center p-3">
              {<Icon size={80} />}
            </div>
          )}
          <p className="text-center h2 my-4 fw-normal">{title}</p>
          {(text) && (<p className="text-center h5 fw-normal">{text}</p>)}
        </Modal.Body>
        {(actions) && (
          <Modal.Body>
            {actions}
          </Modal.Body>
        )}
      </>
    </StyledModal>
  );
};

export default Confirm;