import { useQueryClient, QueryKey } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

// TODO: Implemente query response type: returns error first time when the data is not loaded.
const useStoredQuery = (queryName: QueryKey): AxiosResponse | undefined => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(queryName);
}

export default useStoredQuery;