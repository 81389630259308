export enum ROLES {}

export enum PERMISSIONS {
  CHANGE_PASSWORD = 'changepassword',
  SEE_PROFILE = 'seeprofile',
  READ_NOTIFICATIONS = 'read-notifications',
  PLA_ACCES_1 = 'PLA-ACCES-1', // Inicio de sesión
  PLA_SERVI_2 = 'PLA-SERVI-2', // Inicio
  PLA_SERVI_3 = 'PLA-SERVI-3', // Identificación de Espacio
  PLA_SERVI_4 = 'PLA-SERVI-4', // Menú Aplicaciones
  PLA_SERVI_5 = 'PLA-SERVI-5', // Menú Cuenta
  PLA_SERVI_6 = 'PLA-SERVI-6', // Menú Notificaciones
  PLA_SERVI_7 = 'PLA-SERVI-7', // Menú Ayuda
  PLA_SERVI_8 = 'PLA-SERVI-8', // Menú Configuración
  PLA_CTAUS_9 = 'PLA-CTAUS-9', // Autogestión del cambio de contraseña
  PLA_CTAUS_10 = 'PLA-CTAUS-10', // Cambio de contraseña al iniciar sesión
  PLA_ACCES_11 = 'PLA-ACCES-11', // Cierre de sesión
  PLA_NOTIF_12 = 'PLA-NOTIF-12', // Bandeja de Notificaciones
  PLA_CTAUS_13 = 'PLA-CTAUS-13', // Perfil de cuenta de usuario
  INV_SERVI_14 = 'INV-SERVI-14', // Inicio de Portal Inversionista	
  INV_SERVI_15 = 'INV-SERVI-15', // Propiedades del Inversionista
  INV_SERVI_16 = 'INV-SERVI-16', // Publicaciones del Inversionista
  PLA_CTAUS_17 = 'PLA-CTAUS-17', // Actualizar datos del usuario
  INV_SERVI_18 = 'INV-SERVI-18', // Contratos del Inversionista
  INV_SERVI_19 = 'INV-SERVI-19', // Tarjeta de Estado - Estado de Pago de la Propiedad
  INV_SERVI_20 = 'INV-SERVI-20', // Tarjeta de Estado - Estado de la Propiedad
  PLA_SERVI_21 = 'PLA-SERVI-21', // Visor de Anuncios
  INV_SERVI_22 = 'INV-SERVI-22', // Servicio al cliente
  GES_SERVI_23 = 'GES-SERVI-23', // Inicio del Portal de Gestión
  GES_INSPE_24 = 'GES-INSPE-24', // Gestión de Inspecciones
  INV_SERVI_25 = 'INV-SERVI-25', // Ver Datos de las Propiedades del Inversionista
  INV_SERVI_26 = 'INV-SERVI-26', // Ver Características de las Propiedades del Inversionista
  INV_SERVI_27 = 'INV-SERVI-27', // Ver Servicios Básicos de las Propiedades del Inversionista
  INV_SERVI_28 = 'INV-SERVI-28', // Tickets de Soporte del Inversionista
  INV_SERVI_29 = 'INV-SERVI-29', // Ver Servicios Básicos de las Propiedades del Inversionista
  INV_SERVI_30 = 'INV-SERVI-30', // Ver Datos del Arriend de la Propiedad del Inversionista
  GES_USUAR_31 = 'GES-USUAR-31', // Gestión de Usuarios
  INV_SERVI_32 = 'INV-SERVI-32', // Estado del Arriendo
  GES_INSPE_33 = 'GES-INSPE-33', // Ver Informe de Inspección
  GES_INSPE_34 = 'GES-INSPE-34', // Descargar Informe de Inspección
  GES_USUAR_35 = 'GES-USUAR-35', // Activar/Inactivar Usuario
  GES_PROPI_36 = 'GES-PROPI-36', // Gestión de Propiedades
  GES_PROPI_37 = 'GES-PROPI-37', // Detalles de la Propiedad
  GES_PROPI_38 = 'GES-PROPI-38', // Estado de Pago de la Propiedad - Gestion
  GES_PROPI_39 = 'GES-PROPI-39', // Estado de la Propiedad - Gestion
  GES_PROPI_40 = 'GES-PROPI-40', // Ver Datos de la Propiedad - Gestion
  GES_PROPI_41 = 'GES-PROPI-41', // Ver Características de la Propiedad - Gestion
  GES_PROPI_42 = 'GES-PROPI-42', // Ver Servicios Básicos de la Propiedad - Gestion
  GES_PROPI_43 = 'GES-PROPI-43', // Ver Datos del Arriendo de la Propiedad - Gestion
  GES_PROPI_44 = 'GES-PROPI-44', // Estado del Arriendo - Gestion
  GES_PROPI_45 = 'GES-PROPI-45', // Ver Publicación - Gestión
  INV_SERVI_46 = 'INV-SERVI-46', // Ver Inspecciones Realizadas de las Propiedades del Inversionista
  INV_SERVI_47 = 'INV-SERVI-47', // Detalles del Informe de Inspección - Inversionista
  GES_PROPI_48 = 'GES-PROPI-48', // Ver Inspecciones de la Propiedad - Gestión
  INV_SERVI_49 = 'INV-SERVI-49', // Detalles del Informe de Inspección - Inversionista
}