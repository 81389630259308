export { default as InvestorInspectionReport } from './InvestorInspectionReport';
export { default as ObservationData } from './ObservationData';
export { default as InspectionInfo } from './InspectionInfo';
export { default as InspectionDetails } from './InspectionDetails';

interface StatusInterface {
  [x: string]: string;
}

export enum INSPECTION_AREAS_DATA {
  ACCESS = 'access',
  KITCHEN = 'kitchen',
  EXTRA_KITCHEN = 'extra_kitchen',
  BEDROOM = 'bedroom',
  LIVING = 'living',
  TERRACE = 'terrace',
  BATHROOM = 'bathroom',
  EXTRA = 'extra',
  EXTRA_REVISION = 'extra_revision',
  EXTRA_BILL = 'extra_bill',
  HUMIDITY = 'humidity',
}

export const AREAS_NAMES: StatusInterface = {
  'access': 'Acceso',
  'kitchen': 'Cocina',
  'extra_kitchen': 'Horno, Campana y Encimera',
  'bedroom': 'Dormitorio(s)',
  'living': 'Living',
  'terrace': 'Terraza/Balcón',
  'bathroom': 'Baño(s)',
  'extra': 'Olores/Limpieza',
  'extra_revision': 'Termo eléctrico, Calefón o Estufa a Gas',
  'extra_bill': 'Soporte de Mantención',
  'humidity': 'Humedad'
}

export const ELEMENT_STATUS_NAMES: StatusInterface = {
  'muralla_acceso': 'Estado de las murallas',
  'piso_acceso': 'Estado del piso',
  'techo_acceso': 'Estado del techo',
  'muralla_cocina': 'Estado de las murallas',
  'piso_cocina': 'Estado del piso',
  'techo_cocina': 'Estado del techo',
  'muralla_comedor': 'Estado de las murallas',
  'piso_comedor': 'Estado del piso',
  'techo_comedor': 'Estado del techo',
  'muralla_dormitorio': 'Estado de las murallas',
  'piso_dormitorio': 'Estado del piso',
  'techo_dormitorio': 'Estado del techo',
  'muralla_baño': 'Estado de las murallas',
  'piso_baño': 'Estado del piso',
  'techo_baño': 'Estado del techo',
}

export const ACCESS_STATUS: StatusInterface = {
  'muralla_acceso': 'Estado de las murallas',
  'piso_acceso': 'Estado del piso',
  'techo_acceso': 'Estado del techo',
};

export const KITCHEN_STATUS: StatusInterface = {
  'muralla_cocina': 'Estado de las murallas',
  'piso_cocina': 'Estado del piso',
  'techo_cocina': 'Estado del techo',
}

export const LIVING_STATUS: StatusInterface = {
  'muralla_comedor': 'Estado de las murallas',
  'piso_comedor': 'Estado del piso',
  'techo_comedor': 'Estado del techo',
}

export const BEDROOM_STATUS: StatusInterface = {
  'muralla_dormitorio': 'Estado de las murallas',
  'piso_dormitorio': 'Estado del piso',
  'techo_dormitorio': 'Estado del techo',
}

export const BATHROOM_STATUS: StatusInterface = {
  'muralla_baño': 'Estado de las murallas',
  'piso_baño': 'Estado del piso',
  'techo_baño': 'Estado del techo',
}

export const INSPECTION_STATUS: StatusInterface = {
  'BAD': 'Malo',
  'REGULAR': 'Regular',
  'GOOD': 'Bueno',
  'ALLGOOD': 'Todo bien'
}

export const YES_OR_NOT: StatusInterface = {
  'YES': 'Si',
  'NO': 'No'
}

export const ACCESS_ELEMENTS: StatusInterface = {
  'DOOR': 'Puerta',
  'LOCK': 'Cerradura/Chapa',
  'ALARM': 'Alarma',
  'INTERCOM': 'Alarma',
  'SOCKET': 'Enchufes',
  'SWITCH': 'Interruptor',
  'LAMP': 'Lámpara',
  'ELECTRICBOARD': 'Tablero Eléctrico',
  'DOORBELL': 'Timbre',
  'CUPBOARD': 'Armario',
  'ALLGOOD': 'Todo está en buen estado',
};

export const KITCHEN_ELEMENTS: StatusInterface = {
  'DRAIN': 'Desagüe',
  'RAP': 'Grifo',
  'DISHWASHER': 'Lavaplatos',
  'COUNTER': 'Mesón de cocina',
  'SOCKET': 'Enchufes',
  'SWITCH': 'Interruptor',
  'LAMP': 'Lámpara',
  'FURNITURE': 'Mobiliario de cocina',
  'DOOR': 'Puerta de cocina',
  'WINDOW': 'Puerta de cocina',
  'ALLGOOD': 'Todo está en buen estado',
};

export const LIVING_ELEMENTS: StatusInterface = {
  'CUPBOARD': 'Armario',
  'CURTAINROD': 'Cortinero',
  'HEATER': 'Calefactor',
  'SOCKET': 'Enchufes',
  'SWITCH': 'Interruptor',
  'LAMP': 'Lámparas',
  'DOOR': 'Puerta',
  'WINDOW': 'Ventana/Termopanel',
  'ALLGOOD': 'Todo está en buen estado',
};

export const OVEN_ELEMENTS: StatusInterface = {
  'OVEN': 'Horno',
  'BELL': 'Encimera',
  'WORKTOP': 'Encimera',
  'ALLGOOD': 'Ninguno está en mal estado',
};

export const BATHROOMS_ELEMENTS: StatusInterface = {
  'WASHBASIN': 'Lavamanos',
  'TAP': 'Grifería',
  'DRAIN': 'Desagües/Sifones',
  'LAVATORY': 'Inodoro',
  'SOCKET': 'Enchufes',
  'SWITCH': 'Interruptor',
  'LAMP': 'Lámparas',
  'DOOR': 'Puerta',
  'WINDOW': 'Ventana/Termopanel',
  'TUB': 'Tina',
  'FURNITURE': 'Mobiliario',
  'MIRROR': 'Espejo',
  'ACCESSORY': 'Accesorios',
  'RACK': 'Extractor/Rejilla',
  'ALLGOOD': 'Todo está en buen estado',
};

export const ROOFTOP_BALCONY_ELEMENTS: StatusInterface = {
  'RAILING': 'Barandas',
  'LAMP': 'Lámparas',
  'RAMPART': 'Murallas',
  'FLOOR': 'Piso',
  'CEILING': 'Techo',
  'ALLGOOD': 'Ninguno está en mal estado',
};

export const BEDROOMS_ELEMENTS: StatusInterface = {
  'CLOSET': 'Clóset',
  'HEATER': 'Calefactor',
  'WALKINGCLOSET': 'Walking Clóset',
  'SOCKET': 'Enchufes',
  'SWITCH': 'Interruptor',
  'LAMP': 'Lámparas',
  'DOOR': 'Puerta',
  'WINDOW': 'Ventana/Termopanel',
  'ALLGOOD': 'Todo está en buen estado',
};

export const HUMIDITY_PROBLEMS: StatusInterface = {
  'ACCESS': 'Acceso',
  'KITCHEN': 'Cocina',
  'BEDROOM': 'Dormitorio(s)',
  'LIVING': 'Living',
  'TERRACE': 'Terraza/Balcón',
  'BATHROOM': 'Baño(s)',
  'ALLGOOD': 'Todo está en buen estado',
};

export const HOT_WATER_TANK_ELEMENTS: StatusInterface = {
  'ELETRICTHERMO': 'Termo Eléctrico',
  'WATERHEATER': 'Calefón',
  'GASSTOVE': 'Calefactor a Gas',
  'NONE': 'NONE',
};

export const EXTRA_TYPE: StatusInterface = {
  'olores': 'Olores',
  'limpieza': 'Limpieza',
};

export enum STATUS_LIST {
  NOT_SENT = 'No enviado',
  SENT = 'Enviado',
  NOT_CONTACTED = 'No contactado',
  INITIATED = 'Iniciado',
  DONE = 'Realizado',
}

export const getVariant = (status: string) => {
  if (status === 'Bueno') return 'textPrimary';
  if (status === 'Regular') return 'textSecondary';
  if (status === 'Malo') return 'textTertary';
  return 'textPrimary';
}

export const getElementsVariant = (element: string) => {
  if (element === 'Todo está en buen estado' || element === 'Funciona correctamente' || element === 'Ninguno está en mal estado' || element === 'Sí los he enviado') return 'textPrimary';
  if (element === 'No') return 'textPrimary';
  if (element === 'Sí' || element === 'Si') return 'textTertary';
  return 'textTertary';
}

export const getElementsResponseVariant = (element: string) => {
  if (element === 'No') return 'textTertary';
  if (element === 'Sí' || element === 'Si') return 'textPrimary';
  return 'textPrimary';
}