import { FC } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'html-react-parser';
import { StyledText } from '.';

interface InfoProps {
  title: string;
  description: string;
  icon?: any;
  className?: string;
}

const StyledInfo = styled.div`
  color: ${(props: any) => props.theme.palette.textColor};

  & .info-title {
    color: ${(props: any) => props.theme.palette.textColor};
  }

  & .info-description {
    color: ${(props: any) => props.theme.palette.activeTextColor};
  }
`

const Info: FC<InfoProps> = ({ title, description, icon: Icon, className }) => {
  return (
    <StyledInfo className={`d-flex flex-row ${(className) ? className : ''}`}>
      {Icon && (
        <div className="info-icon me-2">
          <Icon size={28} />
        </div>
      )}
      <div className="lh-sm">
        <StyledText className="title fw-semibold m-0 fs-6">{title}</StyledText>
        {(description) ? (
          <StyledText className="subtitle m-0 fs-6 lh-base text-justify text-break">
            {ReactHtmlParser(description)}
          </StyledText>
        ) : (<p />)}
      </div>
    </StyledInfo>
  );
};

export default Info