import React, { FC, ReactNode } from 'react';
import DataTable, { TableColumn, ConditionalStyles } from 'react-data-table-component';
import { useTheme } from 'styled-components';
import chroma from 'chroma-js';
import ChevronUp from 'mdi-react/ChevronUpIcon';
import ChevronDown from 'mdi-react/ChevronDownIcon';

interface DataGridTableProps<T> {
  columns: TableColumn<T>[];
  rows: T[];
  isExpandable?: boolean;
  ExpandableComponent?: FC;
  onRowClicked?: (row: T) => void;
  onRowExpandToggled?: (bool: boolean, row: T) => void;
  expandableRowExpanded?: (row: T) => boolean;
  // isFilterable?: boolean;
  subHeader?:  boolean;
  subHeaderComponent?: ReactNode | ReactNode[];
  conditionalRowStyles?: ConditionalStyles<T>[];
  [x: string]: any;
}

const DataGridTable: FC<DataGridTableProps<any>> = ({
  columns,
  rows,
  isExpandable,
  expandableComponent: ExpandableComponent,
  onRowClicked,
  onRowExpandToggled,
  expandableRowExpanded,
  subHeader,
  subHeaderComponent,
  conditionalRowStyles,
  ...props
}) => {
  const theme = useTheme();

  const customStyles = {
    tableWrapper: {
      style: {
        display: 'table',
        minHeight: '35vh',
      },
    },
    headCells: {
      style: {
        fontSize: 18,
        color: theme.palette.dataTableTextColor,
      },
    },
    responsiveWrapper: {
      style: {
        width: '100%'
      },
    },
    expanderCell: {
      style: {
        display: 'flex',
        justifyContent: 'center'
      },
    },
    expanderButton: {
      style: {
        width: '60%',
        height: '60%',
        borderRadius: '100%',
        '&:hover:not(:disabled)': {
          color: theme.palette.dataTableActiveTextColor,
          fill: theme.palette.dataTableActiveTextColor,
          backgroundColor: theme.palette.dataTableBackgroundColor,
        },
        '&:focus': {
          color: theme.palette.dataTableActiveTextColor,
          fill: theme.palette.dataTableActiveTextColor,
          backgroundColor: theme.palette.dataTableBackgroundColor,
        },
        svg: {
          width: 28,
          height: 28
        }
      }
    },
    rows: {
      style: {
        color: theme.palette.dataTableTextColor,
        fontSize: 15,
        '@media (max-width: 576px)': {
          '&:not(:last-of-type)': {
            borderBottom: 'none',
          },
        },
      },
      highlightOnHoverStyle: {
        color: theme.palette.dataTableTextColor,
        backgroundColor: chroma(theme.palette.dataTableActiveTextColor).alpha(.10).css(),
        border: 'none',
        cursor: 'pointer',
        '@media (min-width: 576px)': {
          borderTop: 'solid 1px rgba(0,0,0,.12)',
          borderBottom: 'solid 1px rgba(0,0,0,.12)',
        },
      },
    },
    subHeader: {
      style: {
        display: 'block'
      },
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '35vh'
      },
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '35vh'
      },
    },
  };


  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos',
  };


  return (
    <div className="w-100 py-3">
      <DataTable
        columns={columns}
        data={rows}
        expandableRows={isExpandable}
        expandableRowsComponent={ExpandableComponent}
        expandableIcon={({
          expanded: <ChevronUp />,
          collapsed: <ChevronDown />
        })}
        expandableRowExpanded={expandableRowExpanded}
        onRowClicked={onRowClicked}
        onRowExpandToggled={onRowExpandToggled}
        customStyles={customStyles}
        highlightOnHover
        selectableRowsHighlight
        pagination
        paginationComponentOptions={paginationComponentOptions}
        conditionalRowStyles={conditionalRowStyles}
        clearSelectedRows
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponent}
        noDataComponent="No hay registros para mostrar."
        {...props}
      />
    </div>
  );
};

export default DataGridTable;