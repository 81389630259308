import { FC, Fragment } from 'react';
import Image from 'mdi-react/ImageIcon';
import { AreaStatus, Observation } from '../../../../../global/interfaces';
import { AREAS_NAMES, ELEMENT_STATUS_NAMES, EXTRA_TYPE, INSPECTION_AREAS_DATA } from '.';
import { StyledText } from '../../../../common';
import { useMessages } from '../../../../../utils/hooks';

interface ObservationDataProps {
  data: Observation;
  onSelectImage: (image: string) => void;
}

const ObservationData: FC<ObservationDataProps> = ({ data, onSelectImage }) => {
  const { area, option_number, observation, images, status, areas_status, type } = data;
  const { messages } = useMessages();
  const { sections } = messages.modules.management.inspections.sections.self_inspections.online_inspection;
  const { see_picture } = messages.modules.management.inspections.sections.self_inspections.actions;
  const { areas } = sections;

  if (area === INSPECTION_AREAS_DATA.EXTRA_KITCHEN) {
    return (
      <div className="mb-2">
        <StyledText className="m-0 text-white fw-semibold">{AREAS_NAMES[area as string]}</StyledText>
        <StyledText className="m-0 text-white">{areas.extra_kitchen.elements} {status}</StyledText>
        {images.map((image: string, index: number) => (
          <div className="d-flex flex-row align-items-center" key={index}>
            <Image />
            <StyledText
              className="m-0 text-white text-decoration-underline cursor-pointer"
              onClick={() => onSelectImage(image)}
            >
              {see_picture}
            </StyledText>
          </div>
        ))}
      </div>
    )
  }

  if (area === INSPECTION_AREAS_DATA.HUMIDITY) {
    return (
      <div className="mb-2">
        <StyledText className="m-0 text-white fw-semibold">{AREAS_NAMES[area as string]}</StyledText>
        <StyledText className="m-0 text-white">{areas.humidity.areas} {observation}</StyledText>
      </div>
    )
  }

  if (area === INSPECTION_AREAS_DATA.EXTRA) {
    return (
      <div className="mb-2">
        <StyledText className="m-0 text-white fw-semibold">{type ? EXTRA_TYPE[type as string] : AREAS_NAMES[area as string]}</StyledText>
        <StyledText className="m-0 text-white">{areas.extra.status} {status}{(observation !== '') && ` - ${observation}`}</StyledText>
      </div>
    )
  }

  if (area === INSPECTION_AREAS_DATA.EXTRA_BILL) {
    return (
      <div className="mb-2">
        <StyledText className="m-0 text-white fw-semibold">{AREAS_NAMES[area as string]}</StyledText>
        <StyledText className="m-0 text-white">{areas.extra_bill.has_sent} {observation}</StyledText>
        {status !== '' && (
          <StyledText className="m-0 text-white">{areas.extra_bill.response} {status}</StyledText>
        )}
      </div>
    )
  }

  return (
    <div className="mb-2">
      <StyledText className="m-0 text-white fw-semibold">{AREAS_NAMES[area as string]}</StyledText>
      {(status !== 'Todo está en buen estado' && status !== 'Ninguno está en mal estado') && (
        <StyledText className="m-0 text-white">
          {option_number ? `${status} - ${AREAS_NAMES[area as string]} ${option_number}` : status}{(observation !== '') && `: ${observation}`}
        </StyledText>
      )}
      {images.map((image: string, index: number) => (
        <div className="d-flex flex-row align-items-center" key={index}>
          <Image />
          <StyledText
            className="m-0 text-white text-decoration-underline cursor-pointer"
            onClick={() => onSelectImage(image)}
          >
            {see_picture}
          </StyledText>
        </div>
      ))}
      {areas_status?.map(({ type, observation, status }: AreaStatus, i: number) => (
        <Fragment key={i}>
          <StyledText className="m-0 text-white fw-semibold mt-1">{`${ELEMENT_STATUS_NAMES[type as string]} - ${AREAS_NAMES[area as string]} ${option_number ? option_number : ''}`}</StyledText>
          <StyledText className="m-0 text-white">{status}{(observation !== '') && `: ${observation}`}</StyledText>
        </Fragment>
      ))}
    </div>
  )
};

export default ObservationData;