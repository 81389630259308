import { useCallback, useState } from 'react';

/**
 * Custom hook to control the custom tab implementation.
 * @param {string} scrollerId HTML id reference to keep control of container.
 * @param {string} tabListId HTML id reference to keep control of tab list component.
 * @returns 
 */
const useCustomTab = (scrollerId: string = 'hscroll', tabListId: string = 'tab-list') => {
  const [slideLeft, setSlideLeft] = useState<number>(0);
  const [hideButtonLeft, setHideButtonLeft] = useState<boolean>(true);
  const [hideButtonRight, setHideButtonRight] = useState<boolean>(false);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [tabListWidth, setTabListWidth] = useState<number>(0);

  const onRef = useCallback(() => {
    const scroller = document.getElementById(scrollerId);
    const tabList = document.getElementById(tabListId);

    if (scroller !== null && tabList !== null) {
      setContainerWidth(scroller.clientWidth);
      setTabListWidth(tabList.clientWidth);
    }
  }, [scrollerId, tabListId]);

  const moveRight = () => {
    const el = document.getElementById(scrollerId);
    if (el !== null) setSlideLeft((el.scrollLeft += 80));
  };

  const moveLeft = () => {
    const el = document.getElementById(scrollerId);
    if (el !== null) setSlideLeft((el.scrollLeft -= 80));
  };

  const onScroll = () => {
    const el = document.getElementById(scrollerId);
    if (el !== null) {
      let fullWidth = el.scrollWidth - el.clientWidth;
      if (el.scrollLeft > 0) {
        setHideButtonLeft(false);
      } else {
        setHideButtonLeft(true);
      }
      if (Math.ceil(el.scrollLeft) < fullWidth) {
        setHideButtonRight(false);
      } else {
        setHideButtonRight(true);
      }
    }
  };

  return {
    onRef,
    moveRight,
    moveLeft,
    onScroll,
    containerWidth,
    tabListWidth,
    hideButtonLeft,
    hideButtonRight,
    slideLeft
  };
};

export default useCustomTab;