import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { StyledBox, StyledSubmitButton, StyledText } from '../../../../common';
import { useAuth, useAuthorization, useMessages, useWindowResize } from '../../../../../utils/hooks';
import { InspectionElement, InspectionItem, Property } from '../../../../../global/interfaces';
import { PERMISSIONS } from '../../../../../global/permissions';
import { APPS } from '../../../../../global';
import { ROUTES } from '../../../../../global/urls';

interface InspectionsCardProps {
  property: Property;
}

const smBreakpoint = 665;

const InspectionsCard: FC<InspectionsCardProps> = ({ property }) => {
  const { currentApp } = useAuth();
  const [width] = useWindowResize();
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const { title, fields, actions } = messages.modules.investor.properties.sections.information.inspections;
  const { inspections } = property;

  const getAppUrl = () => {
    if (currentApp?.code === APPS.INV) return ROUTES.INVESTOR_PROPERTY_INSPECTION;
    return ROUTES.MANAGEMENT_INSPECTION_BY_ID;
  }

  return (
    <Row>
      <Col xs={12} className="mb-3 d-none d-md-block">
        <StyledText className="h4 title">{title}</StyledText>
      </Col>
      {inspections.map(({ date, items }: InspectionElement, index: number) => (
        <Col xs={12} className="mb-3" key={index}>
          <StyledBox className="rounded-4 p-2 w-100 shadow-none grey-alpha">
            <Row>
              <Col xs={12}>
                <StyledText className="h6 grey text-center fw-semibold">{fields.contract_start_date}: {date}</StyledText>
              </Col>
              {items.map(({ inspection_date, id }: InspectionItem) => (
                <Col xs={12} className="mt-2" key={id}>
                  <StyledBox className="rounded-3 p-2 bg-white w-100 shadow-none">
                    <Row className="justify-content-center">
                      <Col xs={12}>
                        <StyledText className="grey fw-normal text-center">{fields.inspection_date} {inspection_date}</StyledText>
                      </Col>
                      {(checkAccess(PERMISSIONS.INV_SERVI_47) || checkAccess(PERMISSIONS.GES_INSPE_33)) && (
                        <Col xs={4} md={6} xxl={6}>
                          <StyledSubmitButton as={NavLink} to={`${getAppUrl()}/${id}`} className="btn primary filled shadow-sm rounded-3 w-100">
                            {(width < smBreakpoint) ? actions.see : actions.see_report}
                          </StyledSubmitButton>
                        </Col>
                      )}
                    </Row>
                  </StyledBox>
                </Col>
              ))}
            </Row>
          </StyledBox>
        </Col>
      ))}
    </Row>
  );
};

export default InspectionsCard;