import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PUBLIC_URL } from '../global';
import { useAuth } from '../utils/hooks';

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (auth.user === null || auth.user === undefined) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={`${PUBLIC_URL}/login`} state={{ from: location }} replace />;
  }

  return children;
};