import React, { createContext, useState, useEffect } from 'react';
import { Message } from '../../global/messages';

interface MessageContextType {
  messages: Message;
}

export const MessageContext = createContext<MessageContextType>(undefined!);

interface MessageProviderProps {
  children: React.ReactNode;
  messages: Message;
}

export const MessageProvider = ({ children, messages }: MessageProviderProps) => {
  const [state, setState] = useState<Message>(messages);

  useEffect(() => setState(messages), [messages])

  let values = {
    messages: state
  };

  return (
    <MessageContext.Provider value={values}>{children}</MessageContext.Provider>
  );
};