import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import PasswordChangeForm from './components/PasswordChangeForm';
import CloseButton from '../../../common/CloseButton';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';
import { StyledBox } from '../../../common';

const PasswordChangePage: FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(ROUTES.HOME, { replace: true })
  };

  return (
    <Authorization allowedRole={PERMISSIONS.PLA_CTAUS_9}>
      <Helmet>
        <title>{PAGE_TITLE.ACCOUNT_CHANGE_PASSWORD}</title>
      </Helmet>
      <div className="pt-5">
        <StyledBox className="px-4 bg-white py-5 default m-auto rounded-4 shadow-none col-12 col-md-6 col-lg-4 col-xl-4 position-relative">
          <CloseButton className="d-none d-sm-block" onClick={onClick} />
          <PasswordChangeForm />
        </StyledBox>
      </div>
    </Authorization>
  );
};

export default PasswordChangePage;