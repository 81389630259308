 import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Image } from 'react-bootstrap';
import AccountGroup from 'mdi-react/AccountGroupIcon';
import TableAccount from 'mdi-react/TableAccountIcon';
import CustomerSupport from '../../../assets/images/customer_support.svg'
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { useAuthorization, useMessages, useWindowResize } from '../../../../utils/hooks';
import { StyledText } from '../../../common';
import OptionCard from '../../../common/OptionCard';
import { PAGE_TITLE, ROUTES } from '../../../../global/urls';

const smBreakpoint = 600;

const SupportPage: FC = () => {
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const {
    title,
    description,
    create_tickets,
    manage_tickets
  } = messages.modules.investor.support;
  const [width] = useWindowResize();

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_22}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_SUPPORT}</title>
      </Helmet>
      <Container fluid className="px-0">
        <Row className="justify-content-center">
          <Col xs={10} sm={10} md={8} lg={3} xl={2}>
            <div className="d-flex flex-row justify-content-center">
              <Image src={CustomerSupport} className={`${(width <= smBreakpoint) ? 'w-75' : 'w-100'} mt-3 mb-3 mb-md-5`} />
            </div>
          </Col>
          <Col xs={12}>
            <StyledText className="h2 text-center mb-0 title fw-semibold">{title}</StyledText>
          </Col>
          <Col xs={12} sm={12} md={8} className="my-3">
            <StyledText className={`${(width <= smBreakpoint) ? 'h6' : 'h5'} subtitle fw-normal text-center m-0 mx-4 mx-md-0`}>{description}</StyledText>
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-2">
          {checkAccess(PERMISSIONS.INV_SERVI_28) && (
            <Col xs={12} sm={10} md={4} lg={3} xl={3} className="mb-3">
              <OptionCard
                title={create_tickets.title}
                buttonText={create_tickets.action}
                variant="primary"
                buttonClassName="filled"
                icon={AccountGroup}
                to={ROUTES.SUPPORT_CREATE_TICKET}
              />
            </Col>
          )}
          {checkAccess(PERMISSIONS.INV_SERVI_29) && (
            <Col xs={12} sm={10} md={4} lg={3} xl={3} className="mb-3">
              <OptionCard
                title={manage_tickets.title}
                buttonText={manage_tickets.action}
                variant="primary"
                icon={TableAccount}
                href={manage_tickets.url}
              />
            </Col>
          )}
        </Row>
      </Container>
    </Authorization>
  );
};

export default SupportPage;