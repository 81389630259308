import { FC, useState } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { StyledEmptyContainer, StyledText } from '../../../common';
import { useMessages } from '../../../../utils/hooks';
import ContractItem from './components/ContractItem';
import { stackRentApi } from '../../../../apis/stackRentApi';
// import { Contract } from '../../../../global/interfaces';
import Spinner from '../../../common/Spinner';
import { PAGE_TITLE } from '../../../../global/urls';

const StyledListGroup = styled(ListGroup)`
  background-color: transparent;
`

const InvestorContractsPage: FC = () => {
  const [contractsList, setContractsList] = useState([]);
  const { messages } = useMessages();
  const { title } = messages.modules.investor.contracts;

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['investor/contracts'], async () => {
    return await stackRentApi.get('/contract/me');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        if (response.data.length !== 0) {
          setContractsList(response.data);
        }
        else {
          setContractsList([]);
        }
      },
      onError: (error: AxiosError) => {
        setContractsList([]);
      }
    });

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }

  return (
    <Authorization allowedRole={PERMISSIONS.INV_SERVI_18}>
      <Helmet>
        <title>{PAGE_TITLE.INVESTOR_CONTRACTS}</title>
      </Helmet>
      <Container fluid className="px-0">
        <Row>
          <Col xs={12}>
            <StyledText className="h4 ps-md-5 mb-3 title">{title}</StyledText>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8} md={6}>
            <StyledListGroup className="m-0 ps-md-5">
              {(contractsList.map((contract: any) => (
                <ContractItem name={contract?.contract_data.name} url={contract?.contract_data.file} />
              )))}
            </StyledListGroup>
          </Col>
        </Row>
      </Container>
    </Authorization>
  );
};

export default InvestorContractsPage;