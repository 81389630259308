import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import { Authorization } from '../../../../routes/ProtectedRoute';
import RouterBreadcrumb from '../../../common/RouterBreadcrumb';
import { StyledEmptyContainer, StyledText } from '../../../common';
import { PERMISSIONS } from '../../../../global/permissions';
import { Property } from '../../../../global/interfaces';
import { datamainApi } from '../../../../apis/datamainApi';
import { useMessages } from '../../../../utils/hooks';
import Spinner from '../../../common/Spinner';
import { PropertyDetails, PropertyStats } from '../../investor/properties/components';
import { PAGE_TITLE } from '../../../../global/urls';

const ManagementPropertyDetailsPage: FC = () => {
  const { assetId } = useParams();
  const { messages } = useMessages();
  const { information } = messages.modules.investor.properties.sections;
  const { title } = information;
  const [asset, setAsset] = useState<Property>({} as Property);

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['management/asset/id'], async () => {
    return await datamainApi.get(`/asset/${assetId}`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setAsset(response.data);
      },
      onError: (error: AxiosError) => {
        setAsset({} as Property);
      }
    });

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  }
  
  return (
    <Authorization allowedRole={PERMISSIONS.GES_PROPI_37}>
      <Helmet>
        <title>{PAGE_TITLE.MANAGEMENT_PROPERTY_DETAILS}</title>
      </Helmet>
      <Container fluid className="px-0 pt-md-5">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <RouterBreadcrumb />
          </Col>
          <Col xs={12} className="px-md-5">
            <StyledText className="h2 mb-4 title text-center">{title}</StyledText>
          </Col>
        </Row>
        {(asset && Object.entries(asset).length !== 0) && (
          <>
            <Row className="px-md-5">
              <PropertyStats property={asset} />
            </Row>
            <Row className="flex-grow-1 px-md-5" style={{ height: '60vh' }}>
              <PropertyDetails property={asset} />
            </Row>
          </>
        )}
      </Container>
    </Authorization>
  );
};

export default ManagementPropertyDetailsPage;
