import { FC } from 'react';
import { StyledBox, StyledText } from '.';

interface InfoStatsCardProps {
  title: string;
  subtitle: string;
  text?: string;
  variant?: string;
}

const InfoStatsCard: FC<InfoStatsCardProps> = ({ title, subtitle, text, variant = 'default' }) => {
  return (
    <StyledBox className={`rounded-4 w-100 ${variant} shadow-none p-3 h-100 d-flex flex-row align-items-center`}>
      <div className="d-flex flex-row align-items-center w-100">
        <div className="w-50 d-flex flex-row justify-content-center px-2 px-xxl-3">
          <StyledText className="fw-semibold text-center lh-sm h5 text-white m-0">
            {title}
          </StyledText>
        </div>
        <div className="w-50 text-center">
          <StyledText className="fw-semibold lh-sm mb-1 h4 text-center text-white">
            {subtitle}
          </StyledText>
          {(text) && (
            <StyledText className="fw-normal lh-sm text-center text-white mb-1">
              {text}
            </StyledText>
          )}
        </div>
      </div>
    </StyledBox>
  );
};

export default InfoStatsCard;