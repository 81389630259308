import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { StyledSubmitButton, StyledText } from '.';
import { ROUTES } from '../../global/urls';
import { useWindowResize } from '../../utils/hooks';

interface OptionCardProps {
  variant: string,
  icon?: any;
  title: string;
  buttonText: string;
  to?: ROUTES | string;
  href?: string;
  buttonClassName?: string;
}

const smBreakpoint = 600;

const OptionCard: FC<OptionCardProps> = ({ variant, icon: Icon, title, buttonText, to, href, buttonClassName = '' }) => {
  const theme = useTheme();
  const [width] = useWindowResize();

  return (
    <Card className="rounded-4 border-0">
      <Card.Body className="p-3 p-md-4">
        {Icon && (
          <div className="d-flex flex-row justify-content-center">
            <Icon size={64} color={theme.palette.iconColor} />
          </div>
        )}
        <StyledText className="text-center h6 fw-normal my-4">{title}</StyledText>
        <div className="d-flex flex-row justify-content-center">
          {(to) && (
              <StyledSubmitButton
                as={Link}
                to={to}
                className={`btn ${variant ? variant : ''} ${width <= smBreakpoint ? 'w-100' : 'w-75'} shadow-sm ${buttonClassName}`}
              >
                {buttonText}
              </StyledSubmitButton>

          )}
          {(href) && (
            <StyledSubmitButton
              className={`btn ${variant ? variant : ''} ${width <= smBreakpoint ? 'w-100' : 'w-75'} shadow-sm ${buttonClassName}`}
              href={href}
              target="_blank"
            >
              {buttonText}
            </StyledSubmitButton>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

export default OptionCard