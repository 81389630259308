import { FC } from 'react';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import styled, { useTheme } from 'styled-components';
import { Img } from 'react-image';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeft from 'mdi-react/ChevronLeftIcon';
import FullscreenExit from 'mdi-react/FullscreenExitIcon';
import Fullscreen from 'mdi-react/FullscreenIcon';
import { StyledMenuButton, StyledText } from '.';
import "react-image-gallery/styles/css/image-gallery.css";
import { useMessages } from '../../utils/hooks';


export interface GalleryImage {
  src: string;
  alt?: string;
  label?: string;
}

interface ImageGalleryProps {
  images: GalleryImage[];

}

const StyledImageGalleryWrapper = styled.div`
  & .image-gallery-slide-wrapper {
    background-color: #ffffff;
  }

  & .image-gallery-content {
    height: 435px;
  }

  & .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 330px;
  }

  & .image-gallery-content .image-gallery-slide .image-fallback {
    height: 330px;
  }

  & .fullscreen .image-gallery-slide .image-gallery-image {
    height: calc(100vh - 100px);
    background-color: #fff;
  }

  & .fullscreen .image-gallery-slide .image-fallback {
    height: calc(100vh - 100px);
  }

  & .fullscreen {
    & .image-gallery-thumbnails {
      padding: 5px 0;
      background-color: #fff;
    }
  }

  & .image-gallery-thumbnails {
    padding: 5px 0;
    background-color: transparent;
  }

  & .image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 100px;
    height: auto;
  }

  & .image-gallery-thumbnail {
    border: none;
    &:hover, &.active, &:focus {
      border: none;
    }
  }
`;

const PictureGallery: FC<ImageGalleryProps> = ({ images }) => {
  const theme = useTheme();
  const { messages } = useMessages();
  const { broken_image } = messages.dialogs;
  
  const renderItem = (item: ReactImageGalleryItem) => {
    return (
      <Img
        src={item.original}
        className="image-gallery-image"
        unloader={(
          <div className="image-fallback w-100 d-flex flex-row justify-content-center align-items-center">
            <StyledText className="tertary m-0">{broken_image.title} {broken_image.text}</StyledText>
          </div>
        )}
      />
    )
  };

  return (
    <StyledImageGalleryWrapper className="mb-1">
      <ImageGallery
        items={images.map(({ src }) => ({ original: src, thumbnail: src }))}
        renderItem={renderItem}
        showPlayButton={false}
        showThumbnails
        showNav
        thumbnailPosition="bottom"
        renderLeftNav={(onClick, disabled) => (
          <StyledMenuButton
            className="bg-white shadow-sm border-0 p-2 rounded-circle mx-2 position-absolute image-gallery-left-nav"
            onClick={onClick}
            disabled={disabled}
            style={{ zIndex: 10 }}

          >
            <ChevronLeft color={theme.palette.primary.main} />
          </StyledMenuButton>
        )}
        renderRightNav={(onClick, disabled) => (
          <StyledMenuButton
            className="bg-white shadow-sm border-0 p-2 rounded-circle mx-2 position-absolute image-gallery-right-nav"
            onClick={onClick}
            disabled={disabled}
            style={{ zIndex: 10 }}
          >
            <ChevronRight color={theme.palette.primary.main} />
          </StyledMenuButton>
        )}
        renderFullscreenButton={(onClick, isFullscreen) => (
          <StyledMenuButton
            className="bg-white shadow-sm border-0 p-2 rounded-circle m-2 position-absolute image-gallery-fullscreen-button"
            onClick={onClick}
            style={{ zIndex: 10 }}
          >
            {isFullscreen ? (<FullscreenExit color={theme.palette.primary.main} />) : (<Fullscreen color={theme.palette.primary.main} />)}
          </StyledMenuButton>
        )}
      />
    </StyledImageGalleryWrapper>
  );
};

export default PictureGallery;