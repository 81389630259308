import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { PAGE_TITLE } from '../../../../global/urls';

const ManagementHomePage: FC = () => {
  return (
    <Authorization allowedRole={PERMISSIONS.GES_SERVI_23}>
      <Helmet>
        <title>{PAGE_TITLE.MANAGEMENT_HOME}</title>
      </Helmet>
      <div></div>
    </Authorization>
  );
};

export default ManagementHomePage;