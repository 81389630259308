import { FC } from 'react';
import DownloadBoxOutline from 'mdi-react/DownloadBoxOutlineIcon';
import { StyledListItemButton } from '../../../../common';

interface ContractItemProps {
  name: string;
  url: string;
}

const ContractItem: FC<ContractItemProps> = ({ name, url }) => {

  const downloadContract = () => {
    const link = document.createElement("a");
    link.href = url;
    link.target = '_blank'; 
    link.click();
  };

  return (
    <StyledListItemButton className="border-0 py-2 px-0">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <p className="m-0 text-break w-90 pe-2">{name}</p>
        <div className="w-10 d-flex flex-row justify-content-end">
          <DownloadBoxOutline size={30} className="d-block cursor-pointer" onClick={downloadContract} />
        </div>
      </div>
    </StyledListItemButton>
  );
};

export default ContractItem;