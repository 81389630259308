import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuth, useUtils } from '../../../utils/hooks';
import { PAGE_TITLE } from '../../../global/urls';

const HomePage: FC = () => {
  const auth = useAuth();
  const { apps } = auth;
  const { getAppPath } = useUtils();

  if (apps.length === 1) {
    return <Navigate to={getAppPath(apps[0]?.code)} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE.HOME}</title>
      </Helmet>
    </>
  );
};

export default HomePage;