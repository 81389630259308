import { FC, forwardRef, MouseEventHandler, ForwardedRef, useState, useCallback } from 'react';
import { Dropdown, OverlayTrigger, Form } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { ToggleMetadata } from '@restart/ui/esm/Dropdown';
import styled, { useTheme } from 'styled-components'
import { AxiosError, AxiosResponse } from 'axios';
import BellOutline from 'mdi-react/BellOutlineIcon';
import { useAuth, useMessages, useWindowResize } from '../../../../utils/hooks';
import { stackRentApi } from '../../../../apis/stackRentApi';
import NotificationItem from './NotificationItem';
import NotificationBottomDrawer from './NotificationBottomDrawer';
import { StyledDropdownMenu, StyledMenuButton, StyledSwitch, StyledTooltip } from '../../../common';
import { Notification } from '../../../../global/interfaces';

interface CustomToggleProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const StyledBadge = styled.span`
  background-color: #ffffff;
  color: #000000;

  &.primary {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    color: ${(props: any) => props.theme.palette.primary.contrastText};
  }

  &.secondary {
    background-color: ${(props: any) => props.theme.palette.secondary?.main};
    color: ${(props: any) => props.theme.palette.secondary?.contrastText};
  }

  &.tertary {
    background-color: ${(props: any) => props.theme.palette.tertary?.main};
    color: ${(props: any) => props.theme.palette.tertary?.contrastText};
  }
`;

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ onClick }: CustomToggleProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const theme = useTheme();
    const auth = useAuth();
    const { unreadNotificationCount } = auth;
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <StyledTooltip id="notifications-tooltip">
            Notificaciones
          </StyledTooltip>
        }
      >
        <StyledMenuButton
          ref={ref}
          onClick={onClick}
          size="sm"
          className="border-0 p-2 rounded-circle ms-2"
        >
          <BellOutline size={26} id="notification-icon" color={theme.palette.iconColor} />
          {unreadNotificationCount > 0 && (
            <StyledBadge className="position-absolute top-20 start-100 translate-mid-inner badge rounded-pill secondary">
              {unreadNotificationCount}
              <span className="visually-hidden">mensajes sin leer</span>
            </StyledBadge>
          )}
        </StyledMenuButton>
      </OverlayTrigger>
    )
  }
);

const smBreakpoint = 600;

const NotificationDropdown: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showAllUnread, setShowAllUnread] = useState<boolean>(false);
  const [todayNotifications, setTodayNotifications] = useState<Notification[]>([]);
  const [oldNotifications, setOldNotifications] = useState<Notification[]>([]);
  const [selectedItem, setSelectItem] = useState<Notification | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [width] = useWindowResize();
  const { messages } = useMessages();
  const auth = useAuth();
  const { unreadNotificationCount } = auth;
  const { title, actions, sections } = messages.modules.notifications;

  const { data, refetch } = useQuery<AxiosResponse, AxiosError>(['notificationsList', { unreadNotificationCount }],
    async () => {
      return await stackRentApi.get('/users/notification');
    },
    {
      onSuccess: (response: AxiosResponse) => {
        const { data } = response;
        setTodayNotifications(data.filter((notification: Notification) => notification.today));
        setOldNotifications(data.filter((notification: Notification) => !notification.today));
        
      },
      onError: (error: AxiosError) => { }
    });

  const onClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const onOpen = useCallback(() => {
    if (width < smBreakpoint) {
      setIsVisible(true);
      // setShow(false);
    }
  }, [width]);

  const toggleAllUnread = () => {
    setShowAllUnread(!showAllUnread);
    if (!showAllUnread) {
      const filteredTodayNotifications = todayNotifications.filter((notification: Notification) => !notification.read_status);
      const filteredOldNotifications = oldNotifications.filter((notification: Notification) => !notification.read_status);
      setTodayNotifications(filteredTodayNotifications);
      setOldNotifications(filteredOldNotifications);
    }
    else {
      setTodayNotifications(data?.data.filter((notification: Notification) => notification.today))
      setOldNotifications(data?.data.filter((notification: Notification) => !notification.today))
    }
  };

  const onToggleDropdown = (isOpen: boolean, metadata: ToggleMetadata) => {
    if (metadata.source !== "select") {
      setShow(isOpen);
    }
  };

  const onSelectDropdownItem = () => {
    setShow(false);
  };

  const onSelectItem = (notification: Notification) => {
    setSelectItem(notification);
  };

  return (
    <>
      <Dropdown
        // align="end"
        show={show}
        onToggle={onToggleDropdown}
        autoClose="outside"
        className="my-0"
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
        <StyledDropdownMenu className="dropdown-scroll rounded-0 shadow">
          <Dropdown.Header className="d-flex flex-row align-items-center px-2">
            <span className="h5 me-4">{title}</span>
            <Form>
              <StyledSwitch
                type="switch"
                id="custom-switch"
                reverse
                label="Mostrar solo las no leídas"
                onClick={toggleAllUnread}
              />
            </Form>
          </Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Header className="d-flex flex-row justify-content-between align-items-center">
            <span className="h6">{sections.current}</span>
            <span className="cursor-pointer d-none">{actions.mark_all_as_read}</span>
          </Dropdown.Header>
          {todayNotifications.map((notification: Notification) => (
            <NotificationItem
              notification={notification}
              onSelect={onSelectDropdownItem}
              refetch={refetch}
              onOpen={onOpen}
              onSelectItem={onSelectItem}
              key={notification.id}
            />
          ))}
          <Dropdown.Divider />
          <Dropdown.Header>
            <span className="h6">{sections.older}</span>
          </Dropdown.Header>
          {oldNotifications.map((notification: Notification) => (
            <NotificationItem
              notification={notification}
              onSelect={onSelectDropdownItem}
              refetch={refetch}
              onOpen={onOpen}
              onSelectItem={onSelectItem}
              key={notification.id}
            />
          ))}
        </StyledDropdownMenu>
      </Dropdown>
      <NotificationBottomDrawer
        onClose={onClose}
        isVisible={isVisible}
        notification={selectedItem}
        refetch={refetch}
      />
    </>
  );
};

export default NotificationDropdown;