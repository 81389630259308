import { FC, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Inspection, Observation } from '../../../../../global/interfaces';
import { useAuthorization, useMessages, useWindowResize } from '../../../../../utils/hooks';
import { StyledBox, StyledText } from '../../../../common';
import ImageModal from '../../../../common/ImageModal';
import ZoomImage from '../../../../common/ZoomImage';
import ObservationData from './ObservationData';
import { PERMISSIONS } from '../../../../../global/permissions';

interface InspectionInfoProps {
  inspection: Inspection;
}

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const InspectionInfo: FC<InspectionInfoProps> = ({ inspection }) => {
  const { messages } = useMessages();
  const { checkAccess } = useAuthorization();
  const [width] = useWindowResize();
  const { sections } = messages.modules.management.inspections.sections.self_inspections.online_inspection;
  const { tenant_data, features, observations } = sections;
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>('');

  const toggleImageModal = () => {
    setOpenImageModal(!openImageModal)
  };

  const handleCurrentImage = (image: string) => {
    toggleImageModal();
    setCurrentImage(image);
  };

  const getMediaQuery = () => {
    if (width < breakpoints.sm) {
      return '200px';
    }
    if (width >= breakpoints.sm && width < breakpoints.md) {
      return '200px';
    }
    if (width >= breakpoints.md && width < breakpoints.lg) {
      return '260px';
    }
    if (width >= breakpoints.lg && width < breakpoints.xl) {
      return '230px';
    }
    if (width > breakpoints.xl) {
      return '208px';
    }
    return '100%';
  }

  return (
    <Row className="mb-2">
      <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mb-2">
        <StyledBox className="rounded-4 primary shadow-none h-100 p-3">
          <StyledText className="h5 mb-2 text-white">{tenant_data.title}</StyledText>
          <StyledText className="text-white m-0">{tenant_data.fullname} {inspection.full_name}</StyledText>
          {checkAccess(PERMISSIONS.GES_INSPE_33) && (
            <>
              <StyledText className="text-white m-0">{tenant_data.rut} {inspection.rut}</StyledText>
              <StyledText className="text-white m-0">{tenant_data.email} {inspection.email}</StyledText>
              <StyledText className="text-white m-0">{tenant_data.phone} {inspection.phone}</StyledText>  
            </>
          )}
        </StyledBox>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mb-2 h-100">
        <Row>
          <Col xs={12} className="mb-2">
            <StyledBox className="rounded-4 bg-white shadow-none p-3">
              <StyledText className="h5 mb-2 title">{features.title}</StyledText>
              <StyledText className="m-0 primary">{features.bedrooms_quantity} {inspection.bedrooms_number}</StyledText>
              <StyledText className="m-0 primary">{features.bathrooms_quantity} {inspection.bathrooms_number}</StyledText>
            </StyledBox>
          </Col>
          <Col xs={12}>
            <StyledBox className="rounded-4 bg-white text-white shadow-none p-3">
              <StyledText className="h5 mb-2 title">{tenant_data.date}</StyledText>
              <StyledText className="m-0 primary">{inspection.inspection_date}</StyledText>
            </StyledBox>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mb-2" style={{ maxHeight: getMediaQuery() }}>
        <StyledBox
          className={`rounded-4 ${(inspection.observations?.length !== 0) ? 'tertary text-white' : 'default'}  shadow-none p-3 h-100`}
          style={{
            overflowY: 'auto',
            maxHeight: '100%',
            position: 'relative',
          }}
        >
          <StyledText className={`h5 mb-2 ${(inspection.observations?.length !== 0) ? 'text-white' : 'title'}`}>{observations.title}</StyledText>
          {(inspection.observations?.length !== 0) ? (
            <>
              {inspection.observations?.map((observation: Observation, index: number) => (
                <ObservationData key={index} data={observation} onSelectImage={handleCurrentImage} />
              ))}
            </>
          ) : (
            <StyledText className="m-0 primary">{observations.no_observations}</StyledText>
          )}
        </StyledBox>
        <ImageModal
          open={openImageModal}
          onHide={toggleImageModal}
          dialogClassName="d-flex flex-row justify-content-center"
          size={(width <= breakpoints.md) ? 'lg' : undefined}
        >
          <ZoomImage src={currentImage} height={600} />
        </ImageModal>
      </Col>
    </Row>
  );
};

export default InspectionInfo;