import { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import HomeLightningBoltOutline from 'mdi-react/HomeLightningBoltOutlineIcon';
import WaterOutline from 'mdi-react/WaterOutlineIcon';
import StorageTankOutline from 'mdi-react/StorageTankOutlineIcon';
import OfficeBuildingPlus from 'mdi-react/OfficeBuildingPlusIcon';
import Info from '../../../../common/Info';
import { useMessages, useWindowResize } from '../../../../../utils/hooks';
import { Property } from '../../../../../global/interfaces';

interface ServicesTabProps {
  property: Property;
}

const smBreakpoint = 600;

const ServicesTab: FC<ServicesTabProps> = ({ property }) => {
  const { messages } = useMessages();
  const [width] = useWindowResize()
  const { fields } = messages.modules.investor.properties.sections.information.services;

  return (
    <Container fluid className="pt-3">
      <Row className="mx-md-4">
        <Col>
          <Row className="mb-3">
            <Col xs={12} sm={12} md={6} lg={6} className="mb-3 px-0">
              <Info title={fields.electricity_supplier} description="" icon={HomeLightningBoltOutline} />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="mb-3 px-0"
              style={{ marginLeft: (width < smBreakpoint) ? '35px' : '0px' }}
              // Estilos en linea provisionales
            >
              <Info title={fields.electricity_client_number} description="" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} sm={12} md={6} lg={6} className="mb-3 px-0">
              <Info title={fields.water_supplier} description="" icon={WaterOutline} />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="mb-3 px-0"
              style={{ marginLeft: (width < smBreakpoint) ? '35px' : '0px' }}
            >
              <Info title={fields.water_client_number} description="" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mb-3 px-0">
              <Info title={fields.gas_supplier} description="" icon={StorageTankOutline} />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="mb-3 px-0"
              style={{ marginLeft: (width < smBreakpoint) ? '35px' : '0px' }}
            >
              <Info title={fields.gas_client_number} description="" />
            </Col>
          </Row>
          <Row className="mb-3 d-none">
            <Col xs={12} sm={12} md={6} lg={6} className="mb-3 px-0">
              <Info title={fields.common_expenses} description="" icon={OfficeBuildingPlus} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="mb-3 px-0">
              <Info title={fields.website} description="" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesTab;