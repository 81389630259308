import { FC } from 'react';
import { ListGroup } from 'react-bootstrap';
import { AccountUser } from '../../../../../global/interfaces';
import { useMessages } from '../../../../../utils/hooks';
import { StyledCard, StyledText } from '../../../../common';

interface AccountLogsCardProps {
  user: AccountUser;
}

const AccountLogsCard: FC<AccountLogsCardProps> = ({ user }) => {
  const { first_session } = user;
  const { messages } = useMessages();
  const { fields } = messages.forms.profile;
  return (
    <StyledCard className="primary shadow-none">
      <ListGroup className="p-2">
        <ListGroup.Item className="border-0 py-1">
          <StyledText className="m-0 title fw-semibold">{fields.first_session}:</StyledText>
          <StyledText className="m-0 subtitle">{first_session}</StyledText>
        </ListGroup.Item>
      </ListGroup>
    </StyledCard>
  );
};

export default AccountLogsCard;