import { FC } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import CogOutline from 'mdi-react/CogOutlineIcon';
import HelpCircleOutline from 'mdi-react/HelpCircleOutlineIcon';
import NotificationDropdown from './NotificationDropdown';
import { StyledMenuButton, StyledTooltip } from '../../../common';
import { useAuth, useAuthorization, useMessages } from '../../../../utils/hooks';
import { PERMISSIONS } from '../../../../global/permissions';
import { AppsDropdown } from '.';

const Toolbar: FC = () => {
  const theme = useTheme();
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const auth = useAuth();
  const { apps } = auth;
  const { help, settings } = messages.modules;
  
  return (
    <div className="d-flex flex-row align-items-center">
      {(checkAccess(PERMISSIONS.PLA_SERVI_4) && apps.length > 1) && (<AppsDropdown />)}
      {checkAccess(PERMISSIONS.PLA_SERVI_6) && (<NotificationDropdown />)}
      {checkAccess(PERMISSIONS.PLA_SERVI_7) && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <StyledTooltip id="help-tooltip">
              {help.title}
            </StyledTooltip>
          }
        >
          <StyledMenuButton
            size="sm"
            className="mx-2 border-0 d-none d-sm-none d-md-block rounded-circle">
            <HelpCircleOutline size={26} color={theme.palette.iconColor} />
          </StyledMenuButton>
        </OverlayTrigger>
      )}
      {checkAccess(PERMISSIONS.PLA_SERVI_8) && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <StyledTooltip id="settings-tooltip">
              {settings.title}
            </StyledTooltip>
          }
        >
          <StyledMenuButton
            size="sm"
            className="me-2 border-0 d-none d-sm-none d-md-block p-2 rounded-circle">
            <CogOutline size={26} color={theme.palette.iconColor} />
          </StyledMenuButton>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default Toolbar;