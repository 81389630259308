import { FC } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { VARIANT } from '../../global';

export const StyledFab = styled(Button)`
  background-color: ${(props: any) => props.theme.palette.primary.contrastText};
  border: solid 1px ${(props: any) => props.theme.palette.buttonBorderColor};
  color: ${(props: any) => props.theme.palette.buttonActiveBackgroundColor};
  font-size: 16px;

  &:hover {
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    background-color: ${(props: any) => props.theme.palette.buttonActiveBackgroundColor};
    border: solid 1px ${(props: any) => props.theme.palette.buttonBorderColor};
  }
  &:disabled {
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    background-color: ${(props: any) => props.theme.palette.buttonDisabledBackgroundColor};
    border: solid 1px ${(props: any) => props.theme.palette.buttonDisabledBackgroundColor};
  }
  &:focus, &:active, &.active, &:active:focus {
    color: ${(props: any) => props.theme.palette.buttonTextColor};
    background-color: ${(props: any) => props.theme.palette.buttonActiveBackgroundColor};
    border: solid 1px ${(props: any) => props.theme.palette.buttonBorderColor};
    box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.buttonActiveBackgroundColor).alpha(props.theme.opacity.low).css()};
  }

  &.primary {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    border: solid 1px ${(props: any) => props.theme.palette.primary.main};
    color: ${(props: any) => props.theme.palette.primary.contrastText};

    &:hover {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.main};
      border: solid 1px ${(props: any) => props.theme.palette.primary.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.primary.contrastText};
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.main).alpha(props.theme.opacity.low).css()};
    }

    &-outlined {
      background-color: ${(props: any) => props.theme.palette.primary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      color: ${(props: any) => props.theme.palette.primary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.main};
        border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.main};
        border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.main};
        border: solid 1px ${(props: any) => props.theme.palette.primary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => props.theme.palette.primary.light};
      border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      color: ${(props: any) => props.theme.palette.primary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &-dark {
      background-color: ${(props: any) => props.theme.palette.primary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      color: ${(props: any) => props.theme.palette.primary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.dark).alpha(props.theme.opacity.low).css()};
      }
    }

    &.contained {
      background-color: ${(props: any) => props.theme.palette.primary.main};
      border: solid 1px ${(props: any) => props.theme.palette.primary.main};
      color: ${(props: any) => props.theme.palette.primary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.light};
        border: solid 1px ${(props: any) => props.theme.palette.primary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.primary.contrastText};
        background-color: ${(props: any) => props.theme.palette.primary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.primary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.primary.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.secondary {
    background-color: ${(props: any) => props.theme.palette.secondary.main};
    border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
    color: ${(props: any) => props.theme.palette.secondary.contrastText};

    &:hover {
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary.main};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.secondary.contrastText};
      background-color: ${(props: any) => props.theme.palette.secondary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.main).alpha(props.theme.opacity.low).css()};
    }

    &-outlined {
      background-color: ${(props: any) => props.theme.palette.secondary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      color: ${(props: any) => props.theme.palette.secondary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.main};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.main};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.main};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => props.theme.palette.secondary.light};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      color: ${(props: any) => props.theme.palette.secondary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &-dark {
      background-color: ${(props: any) => props.theme.palette.secondary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
      color: ${(props: any) => props.theme.palette.secondary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.dark).alpha(props.theme.opacity.low).css()};
      }
    }

    &.contained {
      background-color: ${(props: any) => props.theme.palette.secondary.main};
      border: solid 1px ${(props: any) => props.theme.palette.secondary.main};
      color: ${(props: any) => props.theme.palette.secondary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.light};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.secondary.contrastText};
        background-color: ${(props: any) => props.theme.palette.secondary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.secondary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.secondary.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.tertary {
    background-color: ${(props: any) => props.theme.palette.tertary.main};
    border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
    color: ${(props: any) => props.theme.palette.tertary.contrastText};

    &:hover {
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary.main};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.tertary.contrastText};
      background-color: ${(props: any) => props.theme.palette.tertary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.main).alpha(props.theme.opacity.low).css()};
    }

    &-outlined {
      background-color: ${(props: any) => props.theme.palette.tertary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      color: ${(props: any) => props.theme.palette.tertary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.main};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.main};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.main};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => props.theme.palette.tertary.light};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      color: ${(props: any) => props.theme.palette.tertary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &-dark {
      background-color: ${(props: any) => props.theme.palette.tertary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
      color: ${(props: any) => props.theme.palette.tertary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.dark).alpha(props.theme.opacity.low).css()};
      }
    }

    &.contained {
      background-color: ${(props: any) => props.theme.palette.tertary.main};
      border: solid 1px ${(props: any) => props.theme.palette.tertary.main};
      color: ${(props: any) => props.theme.palette.tertary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.light};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.tertary.contrastText};
        background-color: ${(props: any) => props.theme.palette.tertary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.tertary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.tertary.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }

  &.quaternary {
    background-color: ${(props: any) => props.theme.palette.quaternary.main};
    border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
    color: ${(props: any) => props.theme.palette.quaternary.contrastText};

    &:hover {
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary.main};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
    }
    &:disabled {
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
    }
    &:focus, &:active, &.active, &:active:focus {
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};
      background-color: ${(props: any) => props.theme.palette.quaternary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
      box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.main).alpha(props.theme.opacity.low).css()};
    }

    &-outlined {
      background-color: ${(props: any) => props.theme.palette.quaternary.contrastText};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      color: ${(props: any) => props.theme.palette.quaternary.main};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.main};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.main};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.main};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.main).darken().css()};
      }
    }

    &-light {
      background-color: ${(props: any) => props.theme.palette.quaternary.light};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.light).alpha(props.theme.opacity.low).css()};
      }
    }

    &-dark {
      background-color: ${(props: any) => props.theme.palette.quaternary.dark};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.dark).alpha(props.theme.opacity.low).css()};
      }
    }

    &.contained {
      background-color: ${(props: any) => props.theme.palette.quaternary.main};
      border: solid 1px ${(props: any) => props.theme.palette.quaternary.main};
      color: ${(props: any) => props.theme.palette.quaternary.contrastText};

      &:hover {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
      }
      &:disabled {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.light};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.light};
      }
      &:focus, &:active, &.active, &:active:focus {
        color: ${(props: any) => props.theme.palette.quaternary.contrastText};
        background-color: ${(props: any) => props.theme.palette.quaternary.dark};
        border: solid 1px ${(props: any) => props.theme.palette.quaternary.dark};
        box-shadow: 0 0 0 0.25rem ${(props: any) => chroma(props.theme.palette.quaternary.dark).alpha(props.theme.opacity.low).css()};
      }
    }
  }
`;

interface FabProps {
  position?: 'bottom-end' | 'bottom-start';
  variant?: VARIANT | string;
  children: string | Element;
  icon?: any;
  iconPosition?: 'start' | 'end';
  className?: string;
  [x: string]: any;
}

const Fab: FC<FabProps> = ({ position = 'bottom-end', variant = '', children, icon: Icon, iconPosition = 'start', className, ...props }) => {
  return (
    <StyledFab
      className={`btn-lg position-fixed ${position === 'bottom-end' ? 'bottom-0 end-0' : 'bottom-0 start-0'} m-3 rounded-5 shadow ${variant} ${className} d-flex flex-row alig-items-center`}
      {...props}
    >
      {(Icon && iconPosition === 'start') && <Icon className="me-2" />}
      {children}
      {(Icon && iconPosition === 'end') && <Icon className="ms-2" />}
    </StyledFab>
  )
}

export default Fab