import { FC, useState } from 'react';
import { Formik } from 'formik';
import { useTheme } from 'styled-components';
import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, Form } from 'react-bootstrap';
import Autorenew from 'mdi-react/AutorenewIcon';
import CheckboxMarkedCircleOutline from 'mdi-react/CheckboxMarkedCircleOutlineIcon';
import { useMessages } from '../../../../../utils/hooks';
import { UPDATE_REQUEST_INITIAL_VALUES, UPDATE_REQUEST_VALIDATION_SCHEMA } from '../../../../validations/profileValidations';
import { StyledFormGroup, StyledSubmitButton, StyledText } from '../../../../common';
import { stackRentApi } from '../../../../../apis/stackRentApi';

const UpdateRequestForm: FC = () => {
  const [isSent, setIsSent] = useState<boolean>(false);
  const theme = useTheme();
  const { messages } = useMessages();
  const { fields, placeholders, dialogs } = messages.forms.profile;
  const { change_request, success_feedback } = dialogs;
  const { actions } = messages.modules.account.profile;

  const { mutate, isLoading } = useMutation((values: typeof UPDATE_REQUEST_INITIAL_VALUES) => {
    return stackRentApi.post(`/person/update`, values);
  });

  const handleSubmit = (values: typeof UPDATE_REQUEST_INITIAL_VALUES) => {
    mutate(values, {
      onSuccess: (response: AxiosResponse) => {
        setIsSent(true);
      },
      onError: (error: any) => { }
    })
  };

  return (
    <Formik
      initialValues={UPDATE_REQUEST_INITIAL_VALUES}
      validateOnChange={false}
      validationSchema={UPDATE_REQUEST_VALIDATION_SCHEMA}
      enableReinitialize
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        values,
        // errors,
        // touched,
        handleChange,
        handleBlur,
        handleSubmit,
        // setFieldError,
        // isSubmitting,
        // handleReset,
        // isValid,
      }) => (
        <form onSubmit={handleSubmit} >
          <Row className="px-3 py-4 p-md-5">
            <Col xs={12} className="d-flex flex-row justify-content-center align-items-center">
              {isSent ? (
                <CheckboxMarkedCircleOutline size={80} color={theme.palette.modalBackgroundColor} />
              ) : (
                <Autorenew size={80} color={theme.palette.modalBackgroundColor} />
              )}
            </Col>
            <Col xs={12} className=" pt-4 pb-3">
              <StyledText className="title text-center h4 fw-normal">
                {isSent ? success_feedback.title : change_request.title}
              </StyledText>
            </Col>
            <Col xs={12}>
              <StyledText className="text-center subtitle">
                {isSent ? success_feedback.title : change_request.subtitle}
              </StyledText>
            </Col>
            {!isSent && (
              <>
                <Col xs={12}>
                  <StyledFormGroup className="mb-4" controlId="email">
                    <Form.Label>
                      <StyledText className="title mb-0">{fields.email}</StyledText>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={placeholders.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </StyledFormGroup>
                </Col>
                <Col xs={12}>
                  <StyledFormGroup className="mb-4" controlId="phone">
                    <Form.Label>
                      <StyledText className="title mb-0">{fields.phone}</StyledText>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={placeholders.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                  </StyledFormGroup>
                </Col>
                <Col xs={12} className="d-flex flex-row justify-content-center">
                  <StyledSubmitButton
                    type="submit"
                    className="btn w-75 rounded-4 primary filled shadow-sm"
                    disabled={isLoading}
                  >
                    {actions.send}
                  </StyledSubmitButton>
                </Col>
              </>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default UpdateRequestForm;