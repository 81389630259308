import { FC } from 'react';
import { ListGroup } from 'react-bootstrap';
import { AccountUser } from '../../../../../global/interfaces';
import { useMessages } from '../../../../../utils/hooks';
import { StyledCard, StyledText } from '../../../../common';

interface AccountDataCardProps {
  user: AccountUser;
}

const AccountDataCard: FC<AccountDataCardProps> = ({ user }) => {
  const { email, phone, rut } = user;
  const { messages } = useMessages();
  const { fields } = messages.forms.profile;
  return (
    <StyledCard className="border-0 shadow-none primary">
      <ListGroup className="p-2">
        <ListGroup.Item className="border-0 py-1">
          <StyledText className="m-0 title fw-semibold">{fields.email}:</StyledText>
          <StyledText className="m-0 subtitle">{email}</StyledText>
        </ListGroup.Item>
        <ListGroup.Item className="border-0 py-1">
          <StyledText className="m-0 title fw-semibold">{fields.phone}:</StyledText>
          <StyledText className="m-0 subtitle">{phone}</StyledText>
        </ListGroup.Item>
        <ListGroup.Item className="border-0 py-1">
          <StyledText className="m-0 title fw-semibold">{fields.rut}:</StyledText>
          <StyledText className="m-0 subtitle">{rut}</StyledText>
        </ListGroup.Item>
      </ListGroup>
    </StyledCard>
  );
};

export default AccountDataCard;