import * as Yup from 'yup';
import { Ticket } from '../../global/interfaces';

export const TICKET_INITIAL_VALUES: Ticket = {
  asset_name: '',
  phone: '',
  type: '',
  subject: '',
  description: '',
  files: '',
  file_name: ''
}

export const TICKET_VALIDATION_SCHEMA = Yup.object().shape({
  asset_name: Yup.string().required(),
  phone: Yup.string().required('Recuerda ingresar un número telefónico para ser informado sobre el requerimiento de este ticket.'),
  type: Yup.string().required('Recuerda ingresar el tipo de requerimiento que necesitas para crear este ticket.'),
  subject: Yup.string().required('Recuerda ingresar el asunto para crear este ticket.'),
  description: Yup.string(),
});