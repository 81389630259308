import { FC, Fragment } from 'react'
import { NavLink } from 'react-router-dom';
import { PERMISSIONS } from '../../../../global/permissions';
import { useAuthorization } from '../../../../utils/hooks';

const protectedRoutes = [
  {
    to: '/home/changepass',
    name: 'Cambiar Contraseña',
    permission: PERMISSIONS.CHANGE_PASSWORD,
  },
];

const SideNav: FC = () => {
  const { checkAccess } = useAuthorization();

  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3 sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink to="/home" className="nav-link" end>
              <span data-feather="home" className="align-text-bottom"></span>
              Inicio
            </NavLink>
          </li>
          {protectedRoutes.map(({ to, name, permission }) => (
            <Fragment key={permission}>
              {checkAccess(permission) &&
                <li className="nav-item">
                  <NavLink to={to} className="nav-link">
                    <i className="bi bi-fingerprint"></i>
                    {name}
                  </NavLink>
                </li>
              }
            </Fragment>
          ))}
          <li className="nav-item">
            <NavLink to="/home/profile" className="nav-link">
              <i className="bi bi-fingerprint"></i>
              Info
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/home/protected" className="nav-link">
              
              <i className="bi bi-fingerprint"></i>
              Protegida Ejemplo
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SideNav;