
import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { useAuth } from '../../utils/hooks';
import { authRentApi } from '../../apis/authRentApi';
import Spinner from '../common/Spinner';
import { defaultTheme } from '../assets/themes/defaultTheme';
import { Message, defaultMessages } from '../../global/messages';
import { MessageProvider } from '../../utils/contexts/MessageContext';
import { StyledBox } from '../common';

const Layout: FC = () => {
  const auth = useAuth();
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);
  const [messages, setMessages] = useState<Message>(defaultMessages);

  useQuery<any, any>(['LoginUser'], () =>
    authRentApi
      .get('/auth/me/')
      .then((res) => res.data), {
    enabled: auth.user === undefined && !!localStorage.getItem('token'),
    onSuccess: (data) => {
      if (!data.force_change_password) {
        auth.signin(data.email, data.scopes, data.applications, () => { })
      } else {
        auth.signout(() => {
          if (!localStorage.getItem('isForcedChangePassword')) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
          }
          localStorage.removeItem('currentApp');
          localStorage.setItem('isFirstTime', JSON.stringify(false));
        })
      }
    },
    onError: (error: any) => {
      const { status } = error.response;
      if (status === 400 || status === 401) {
        auth.signout(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('currentApp');
          localStorage.setItem('isFirstTime', JSON.stringify(false));
        })
      }
    }
  });

  useQuery<AxiosResponse, AxiosError>(['messages'], async () => {
    return await authRentApi.get('/config/messages');
  }, {
    staleTime: Infinity,
    onSuccess: (response: AxiosResponse) => {
      const { data } = response;
      if (data.value && Object.keys(JSON.parse(data.value)).length !== 0) {
        const messagesObject = JSON.parse(data.value);
        setMessages(messagesObject);
      }
      else {
        setMessages(defaultMessages);
      }
    },
    onError: (error: AxiosError) => {
      setMessages(defaultMessages);
    }
  });

  useQuery<AxiosResponse, AxiosError>(['theme'], async () => {
    return await authRentApi.get('/config/theme');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        const { data } = response;
        if (data.value && Object.keys(JSON.parse(data.value)).length !== 0) {
          const theme = JSON.parse(data.value);
          setTheme(theme);
        }
        else {
          setTheme(defaultTheme);
        }
      },
      onError: (error: AxiosError) => {
        setTheme(defaultTheme);
      }
    });



  useEffect(() => {
    if (auth.user === undefined && !localStorage.getItem('token')) {
      auth.signout(() => {
      })
    }
  }, [auth]);

  if (auth.user === undefined) {
    return (
      <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
        <Spinner />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <MessageProvider messages={messages}>
        <Toaster
          toastOptions={{
            style: {
              background: theme.palette.tertary?.main,
              color: theme.palette.tertary?.contrastText,
              maxWidth: '450%',
              textAlign: 'center',
            },
            success: {
              style: {
                background: theme.palette.tertary?.main,
                color: theme.palette.tertary?.contrastText,
              }
            },
            error: {
              style: {
                background: theme.palette.secondary?.main,
                color: theme.palette.secondary?.contrastText,
              },
            }
          }}
        />
        <StyledBox>
          <Outlet />
        </StyledBox>
      </MessageProvider>
    </ThemeProvider>
  );
};

export default Layout;