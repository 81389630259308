import { FC, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { AxiosError, AxiosResponse } from 'axios';
import { PERMISSIONS } from '../../../../global/permissions';
import { Authorization } from '../../../../routes/ProtectedRoute';
import { AccountDataCard, AccountLogsCard, AccountProfileCard } from './components';
import { StyledEmptyContainer, StyledSubmitButton, StyledText } from '../../../common';
import { useAuthorization, useMessages, useWindowResize } from '../../../../utils/hooks';
import { AccountUser } from '../../../../global/interfaces';
import { stackRentApi } from '../../../../apis/stackRentApi';
import Spinner from '../../../common/Spinner';
import Dialog from '../../../common/Dialog';
import UpdateRequestForm from './components/UpdateRequestForm';
import { PAGE_TITLE } from '../../../../global/urls';

const smBreakpoint = 600;

const ProfilePage: FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [accountUser, setAccountUser] = useState<AccountUser>({} as AccountUser);
  const [width] = useWindowResize();
  const { checkAccess } = useAuthorization();
  const { messages } = useMessages();
  const { dialogs } = messages.forms.profile;
  const { actions } = messages.modules.account.profile;

  const toggleShow = () => {
    setShow(!show)
  };

  const { isFetching } = useQuery<AxiosResponse, AxiosError>(['profile'], async () => {
    return await stackRentApi.get('/person/me');
  },
    {
      onSuccess: (response: AxiosResponse) => {
        const { person_data } = response.data;
        setAccountUser(person_data);
      }
    });

  if (isFetching) {
    return (
      <StyledEmptyContainer className="d-flex justify-content-center align-items-center">
        <Spinner />
      </StyledEmptyContainer>
    );
  };

  return (
    <Authorization allowedRole={PERMISSIONS.PLA_CTAUS_13}>
      <Helmet>
        <title>{PAGE_TITLE.ACCOUNT_PROFILE}</title>
      </Helmet>
      <Container fluid className="p-0">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={9} lg={6} xxl={4}>
            <AccountProfileCard user={accountUser} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} className="text-center py-2">
            <StyledText className="h3 mt-1 title">{dialogs.account.title}</StyledText>
          </Col>
          <Col xs={12} sm={12} md={9} lg={6} xxl={4}>
            <AccountDataCard user={accountUser} />
          </Col>
        </Row>
        {(accountUser.first_session) && (
          <Row className="justify-content-center my-4">
            <Col xs={12} sm={12} md={9} lg={6} xxl={4}>
              <AccountLogsCard user={accountUser} />
            </Col>
          </Row>
        )}
        {checkAccess(PERMISSIONS.PLA_CTAUS_17) && (
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={9} lg={6} xxl={6} className="d-flex flex-row justify-content-center">
              <StyledSubmitButton
                className="btn w-50 rounded-4 primary filled shadow-sm"
                onClick={toggleShow}
              >
                {actions.update}
              </StyledSubmitButton>
              <Dialog open={show} onHide={toggleShow} size={(width < smBreakpoint) ? 'lg' : undefined}>
                <UpdateRequestForm />
              </Dialog>
            </Col>
          </Row>
        )}
      </Container>
    </Authorization>
  );
};

export default ProfilePage;