import { FC, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import CloseButton from './CloseButton';
import { StyledModal } from '.';
import AdItem from './AdItem';
import "swiper/css";
import "swiper/css/pagination";
import { UserAd } from '../../global/interfaces';

interface AdsViewerProps {
  adList: Array<UserAd>;
}

const AdsViewer: FC<AdsViewerProps> = ({ adList }) => {
  const [modalShow, setModalShow] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const changeIndex = (slide: any) => {
    setActiveIndex(slide.activeIndex)
  };

  return (
    <StyledModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="p-0"
      contentClassName="w-100 d-flex flex-row rounded-4 border-0 shadow-sm"
    >
      <CloseButton
        onClick={() => setModalShow(false)}
      />
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper w-100"
        initialSlide={activeIndex}
        onRealIndexChange={changeIndex}
      >
        {adList.map(({ id, ad: { title, description, extra_text, file, call_to_action, call_to_action_status } }: UserAd) => (
          <SwiperSlide key={id}>
            <AdItem
              title={title}
              description={description}
              extraInfo={extra_text}
              callToAction={call_to_action}
              imgSrc={file}
              id={id}
              enableCallToAction={call_to_action_status}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledModal>
  );
};

export default AdsViewer;