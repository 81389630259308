import { createContext, useState, Dispatch, SetStateAction } from 'react';
import { Application } from '../../global/interfaces';

interface AuthContextType {
  user: any;
  roles: string[];
  apps: Application[];
  signin: (user: string, roles: string[], apps: Application[], callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
  errorAlert: string | null;
  setErrorAlert: Dispatch<SetStateAction<string | null>>;
  unreadNotificationCount: number;
  setUnreadNotificationCount: Dispatch<SetStateAction<number>>;
  notificationsHasChanged: boolean;
  setNotificationsHasChanged: Dispatch<SetStateAction<boolean>>;
  shouldForcePasswordChange: boolean;
  setShouldForcePasswordChange: Dispatch<SetStateAction<boolean>>;
  currentApp: Application | null;
  setCurrentApplication: Dispatch<SetStateAction<Application | null>>;
}

export const AuthContext = createContext<AuthContextType>(null!);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  let [user, setUser] = useState<any>(undefined);
  let [roles, setRoles] = useState<string[]>([]);
  let [apps, setApps] = useState<Application[]>([]);
  let [errorAlert, setErrorAlert] = useState<string | null>(null);
  let [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0);
  let [notificationsHasChanged, setNotificationsHasChanged] = useState<boolean>(false);
  let [shouldForcePasswordChange, setShouldForcePasswordChange] = useState<boolean>(false);
  let [currentApp, setCurrentApplication] = useState<Application | null>(null);

  let signin = (newUser: string, roles: string[], apps: Application[], callback: VoidFunction) => {
    setUser(newUser);
    setRoles(roles);
    setApps(apps);
    callback();
  };

  let signout = (callback: VoidFunction) => {
    setUser(null);
    callback();
  };

  let value = {
    user,
    roles,
    apps,
    signin,
    signout,
    errorAlert,
    setErrorAlert,
    unreadNotificationCount,
    setUnreadNotificationCount,
    notificationsHasChanged,
    setNotificationsHasChanged,
    shouldForcePasswordChange,
    setShouldForcePasswordChange,
    currentApp,
    setCurrentApplication
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
}
