import {
  FC,
  useState,
  ChangeEvent,
  SetStateAction,
  Dispatch,
  useEffect,
  forwardRef,
  ForwardedRef,
  ReactNode
} from 'react';
import { Form, Dropdown, Row, Col } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import styled from 'styled-components';
import ChevronDown from 'mdi-react/ChevronDownIcon';
import ChevronUp from 'mdi-react/ChevronUpIcon';
import Plus from 'mdi-react/PlusIcon';
import "react-datepicker/dist/react-datepicker.css";
import { StyledDropdownSelect, StyledSearchBar, StyledSubmitButton, StyledText } from '../../../../common';
import { useMessages } from '../../../../../utils/hooks';
import { datamainApi } from '../../../../../apis/datamainApi';
import { GenericCatalog } from '../../../../../global/interfaces';
import { STATUS_TYPES } from '../../../../../global';

interface CustomItemProps {
  children: ReactNode;
  id: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | void[];
}

const CheckDropdownItem = forwardRef<HTMLInputElement, CustomItemProps>(
  ({ children, id, checked, onChange }: CustomItemProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="checkbox"
          label={children}
          checked={checked}
          onChange={onChange}
        />
      </Form.Group>
    );
  }
);

interface MoreFilters {
  label: string;
  id: string;
  checked: boolean;
}


const StyledDropdownTrigger = styled(Dropdown.Toggle)`
  height: 38px;
  border: solid 1px #8E8D8D !important;
  color: #8E8D8D !important;

  &:is(:focus, &:focus-visible, :active, :hover), :not([value=""]) {
    border: solid 1px #525ea2 !important;
    color: #525ea2 !important;
    box-shadow: none !important;
  }
`;

interface PropertiiesFilterBarProps {
  setFilters: Dispatch<SetStateAction<string>>;
  filters: string;
  resetPagination?: () => void;
}

const PropertiesFilterBar: FC<PropertiiesFilterBarProps> = ({ setFilters, filters, resetPagination }) => {
  const { messages } = useMessages();
  const {
    filter_by_investor_name,
    filter_by_asset_name,
    filter_by_rup,
    filter_by_status,
    filter_by_podio_id,
    filter_by_breal_id,
    filter_by_region,
    filter_by_condominium,
    filter_by_commune,
    filter_by_asset_number,
    filter_by_asset_type,
  } = messages.modules.management.assets.sections.assets.fields;
  const { filter, clear_filter, more } = messages.modules.management.assets.sections.assets.actions;
  const [investorName, setInvestorName] = useState<string>('');
  const [assetName, setAssetName] = useState<string>('');
  const [rup, setRup] = useState<string>('');
  const [podioId, setPodioId] = useState<string>('');
  const [brealId, setBrealId] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [moreFilters, setMoreFilters] = useState<MoreFilters[]>([
    {
      label: filter_by_region,
      id: 'region',
      checked: false,
    },
    {
      label: filter_by_commune,
      id: 'commune',
      checked: false,
    },
    {
      label: filter_by_condominium,
      id: 'condominium',
      checked: false,
    },
    {
      label: filter_by_asset_type,
      id: 'asset_type',
      checked: false,
    },
    {
      label: filter_by_asset_number,
      id: 'asset_number',
      checked: false,
    },
  ]);
  const [region, setRegion] = useState<number | string>('');
  const [commune, setCommune] = useState<number | string>('');
  const [condominium, setCondominium] = useState<string>('');
  const [assetType, setAssetType] = useState<number | string>('');
  const [assetNumber, setAssetNumber] = useState<string>('');
  const [filtersAux, setFilterAux] = useState<string>('');
  const [regions, setRegions] = useState<GenericCatalog[]>([]);
  const [communes, setCommunes] = useState<GenericCatalog[]>([]);
  const [assetTypes, setAssetTypes] = useState<GenericCatalog[]>([]);
  const [openStatusDropdown, setOpenStatusDropdown] = useState<boolean>(false);
  const [openRegionDropdown, setOpenRegionDropdown] = useState<boolean>(false);
  const [openCommuneDropdown, setOpenCommuneDropdown] = useState<boolean>(false);
  const [openAssetTypeDropdown, setOpenAssetTypeDropdown] = useState<boolean>(false);
  const [openMoreFiltersDropdown, setOpenMoreFiltersDropdown] = useState<boolean>(false);

  useEffect(() => {
    setFilterAux(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAux]);

  useQuery<AxiosResponse, AxiosError>(['management/assets/regions'], async () => {
    return await datamainApi.get(`/asset/regions`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setRegions(response.data);
      },
      onError: (error: AxiosError) => {
        setRegions([]);
      }
    });

  useQuery<AxiosResponse, AxiosError>(['management/assets/communes'], async () => {
    return await datamainApi.get(`/asset/communes`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setCommunes(response.data);
      },
      onError: (error: AxiosError) => {
        setCommunes([]);
      }
    });

  useQuery<AxiosResponse, AxiosError>(['management/assets/asset-types'], async () => {
    return await datamainApi.get(`/asset/asset-types`);
  },
    {
      onSuccess: (response: AxiosResponse) => {
        setAssetTypes(response.data);
      },
      onError: (error: AxiosError) => {
        setAssetTypes([]);
      }
    });

  const handleChangeInvestorName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInvestorName(value);
  }

  const handleChangeAssetName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAssetName(value);
  }

  const handleChangeRup = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRup(value);
  }

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  }

  const handleChangePodioId = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPodioId(value);
  }

  const handleChangeBrealId = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setBrealId(value);
  }

  const handleChangeRegion = (value: number) => {
    setRegion(value);
  }

  const handleChangeCommune = (value: number) => {
    setCommune(value);
  }

  const handleChangeCondominium = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCondominium(value);
  }

  const handleChangeAssetType = (value: number) => {
    setAssetType(value);
  }

  const handleChangeAssetNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAssetNumber(value);
  }

  const handleFilters = () => {
    if (resetPagination) {
      resetPagination();
    }
    setFilters(`investor_name=${investorName}&asset_name=${assetName}&rup=${rup}&podio_id=${podioId}&breal_id=${brealId}&state=${status}&region=${region}&commune=${commune}&condominium=${condominium}&asset_type=${assetType}&asset_number=${assetNumber}`);
  }

  const toggleMoreFiltersDropdown = () => {
    setOpenMoreFiltersDropdown(!openMoreFiltersDropdown);
  };


  const toggleStatusDropdown = () => {
    setOpenStatusDropdown(!openStatusDropdown);
  };

  const toggleRegionDropdown = () => {
    setOpenRegionDropdown(!openRegionDropdown);
  };

  const toggleCommuneDropdown = () => {
    setOpenCommuneDropdown(!openCommuneDropdown);
  };

  const toggleAssetTypeDropdown = () => {
    setOpenAssetTypeDropdown(!openAssetTypeDropdown);
  };

  const resetFilters = () => {
    setFilters('');
    setFilterAux('');
    if (resetPagination) {
      resetPagination();
    }
    setInvestorName('');
    setAssetName('');
    setRup('');
    setStatus('');
    setPodioId('');
    setBrealId('');
    setRegion('');
    setCommune('');
    setCondominium('');
    setAssetType('');
    setAssetNumber('');
    setMoreFilters(moreFilters.map((filter: MoreFilters) => ({ ...filter, checked: false })));
  };

  const handleMoreFilters = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters: MoreFilters[] = moreFilters.map((filter: MoreFilters) => {
      if (filter.id === e.target.id) {
        filter.checked = e.target.checked
      }
      return filter
    })
    setMoreFilters(updatedFilters)
  }

  const shouldFilter = () => {
    if (investorName !== '' || assetName !== '' || rup !== '' ||
      podioId !== '' || brealId !== '' || status !== '' || condominium !== '' ||
      typeof region === 'number' ||
      typeof commune === 'number' ||
      typeof assetType === 'number' ||
      assetNumber !== ''
    ) return true;
    if (filters !== filtersAux) return true;
    return false;
  };

  return (
    <Row>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="pe-0"
      >
        <Row>
          <Col xs={12} className="d-flex flex-row flex-wrap align-items-center">
            <StyledSearchBar className="position-relative me-2 mb-2" controlId="investor_name">
              <Form.Control
                value={investorName}
                onChange={handleChangeInvestorName}
                type="text"
                placeholder={filter_by_investor_name}
                className="border-1 rounded-2 text-center"
              />
            </StyledSearchBar>

            <StyledSearchBar className="position-relative me-2 mb-2" controlId="asset_name">
              <Form.Control
                value={assetName}
                onChange={handleChangeAssetName}
                type="text"
                placeholder={filter_by_asset_name}
                className="border-1 rounded-2 text-center"
              />
            </StyledSearchBar>
            <StyledSearchBar className="position-relative me-2 mb-2" controlId="rup">
              <Form.Control
                value={rup}
                onChange={handleChangeRup}
                type="text"
                placeholder={filter_by_rup}
                className="border-1 rounded-2 text-center"
                htmlSize={11}
              />
            </StyledSearchBar>
            <StyledSearchBar className="position-relative me-2 mb-2" controlId="podio_id">
              <Form.Control
                value={podioId}
                onChange={handleChangePodioId}
                type="text"
                placeholder={filter_by_podio_id}
                className="border-1 rounded-2 text-center"
                htmlSize={7}
              />
            </StyledSearchBar>
            <StyledSearchBar className="position-relative me-2 mb-2" controlId="breal_id">
              <Form.Control
                value={brealId}
                onChange={handleChangeBrealId}
                type="text"
                placeholder={filter_by_breal_id}
                className="border-1 rounded-2 text-center"
              />
            </StyledSearchBar>

            <StyledDropdownSelect className="mb-2" open={openStatusDropdown} onToggle={toggleStatusDropdown}>
              <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={status}>
                {(status !== '') ? status : filter_by_status}
                {openStatusDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
              </StyledDropdownTrigger>
              <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                {STATUS_TYPES.map((value: string, index: number) => (
                  <Dropdown.Item as="button" key={index} onClick={() => handleChangeStatus(value)}>{value}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </StyledDropdownSelect>

            <StyledDropdownSelect className="mb-2" open={openMoreFiltersDropdown} onToggle={toggleMoreFiltersDropdown}>
              <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={!moreFilters.some((element: MoreFilters) => element.checked) ? '' : true}>
                {more}
                <Plus className="ms-1" />
              </StyledDropdownTrigger>
              <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                {moreFilters.map(({ id, label, checked }: MoreFilters, index: number) => (
                  <Dropdown.Item
                    as={CheckDropdownItem}
                    key={index}
                    id={id}
                    onChange={handleMoreFilters}
                    checked={checked}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </StyledDropdownSelect>


            {(shouldFilter()) && (
              <>
                <StyledSubmitButton className="primary filled px-5 mb-2 me-3 rounded-2 d-none d-sm-block" onClick={handleFilters}>
                  {filter}
                </StyledSubmitButton>
                <StyledText
                  className="active cursor-pointer text-decoration-underline me-2 d-none d-sm-block"
                  onClick={resetFilters}
                >
                  {clear_filter}
                </StyledText>
              </>
            )}
          </Col>
          <Col xs={12} className="d-flex flex-row flex-wrap align-items-center">
            {moreFilters.find((filter: MoreFilters) => filter.id === 'region')?.checked && (
              <StyledDropdownSelect className="mb-2" open={openRegionDropdown} onToggle={toggleRegionDropdown}>
                <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={region}>
                  {(region) ? regions.find((x) => x.id === region)?.name : filter_by_region}
                  {openRegionDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
                </StyledDropdownTrigger>
                <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                  {regions.map(({ id, name }: GenericCatalog, index: number) => (
                    <Dropdown.Item as="button" key={index} onClick={() => handleChangeRegion(id)}>{name}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </StyledDropdownSelect>
            )}

            {moreFilters.find((filter: MoreFilters) => filter.id === 'commune')?.checked && (
              <StyledDropdownSelect className="mb-2" open={openCommuneDropdown} onToggle={toggleCommuneDropdown}>
                <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={commune}>
                  {(commune) ? communes.find((x) => x.id === commune)?.name : filter_by_commune}
                  {openCommuneDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
                </StyledDropdownTrigger>
                <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                  {communes.map(({ id, name }: GenericCatalog, index: number) => (
                    <Dropdown.Item as="button" key={index} onClick={() => handleChangeCommune(id)}>{name}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </StyledDropdownSelect>

            )}

            {moreFilters.find((filter: MoreFilters) => filter.id === 'condominium')?.checked && (
              <StyledSearchBar className="position-relative me-2 mb-2" controlId="condominium">
                <Form.Control
                  value={condominium}
                  onChange={handleChangeCondominium}
                  type="text"
                  placeholder={filter_by_condominium}
                  className="border-1 rounded-2 text-center"
                />
              </StyledSearchBar>
            )}

            {moreFilters.find((filter: MoreFilters) => filter.id === 'asset_type')?.checked && (
              <StyledDropdownSelect className="mb-2" open={openAssetTypeDropdown} onToggle={toggleAssetTypeDropdown}>
                <StyledDropdownTrigger id="status" className="rounded-2 me-2" value={assetType}>
                  {(assetType) ? assetTypes.find((x) => x.id === assetType)?.name : filter_by_asset_type}
                  {openAssetTypeDropdown ? <ChevronUp className="ms-1" /> : <ChevronDown className="ms-1" />}
                </StyledDropdownTrigger>
                <Dropdown.Menu className="w-100 rounded-2 border-0  shadow">
                  {assetTypes.map(({ id, name }: GenericCatalog, index: number) => (
                    <Dropdown.Item as="button" key={index} onClick={() => handleChangeAssetType(id)}>{name}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </StyledDropdownSelect>
            )}

            {moreFilters.find((filter: MoreFilters) => filter.id === 'asset_number')?.checked && (
              <StyledSearchBar className="position-relative me-2 mb-2" controlId="asset_number">
                <Form.Control
                  value={assetNumber}
                  onChange={handleChangeAssetNumber}
                  type="text"
                  placeholder={filter_by_asset_number}
                  className="border-1 rounded-2 text-center"
                  htmlSize={14}
                />
              </StyledSearchBar>
            )}

          </Col>
        </Row>
      </Col>
      {(shouldFilter()) && (
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          className="d-flex flex-row flex-wrap align-items-start"
        >
          <div className="d-flex flex-row align-items-center d-sm-none">
            <StyledSubmitButton className="primary filled px-5 mb-2 me-3 rounded-2" onClick={handleFilters}>
              {filter}
            </StyledSubmitButton>
            <StyledText
              className="active cursor-pointer text-decoration-underline me-2"
              onClick={resetFilters}
            >
              {clear_filter}
            </StyledText>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default PropertiesFilterBar;